import i18next from 'i18next';

import { FILE_UPLOADER_MODAL_ITEM } from '~constants/fileUploader';

export const FILE_UPLOADER_ITEMS = ({ fileInfoTexts, needsToBeUpdated = false, linkText = '', link }) => [
  {
    key: 'imageOrPDF',
    fileInfoTexts,
    show: true
  },
  {
    key: 'updated',
    fileInfoTexts: [FILE_UPLOADER_MODAL_ITEM(null, i18next.t('FileUploaderModal:updated'))],
    show: needsToBeUpdated
  },
  {
    key: 'maxSize',
    fileInfoTexts: [FILE_UPLOADER_MODAL_ITEM(null, i18next.t('FileUploaderModal:maxSize'))],
    show: true
  },
  {
    key: 'link',
    linkText,
    link,
    show: linkText && link
  }
];
