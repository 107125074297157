import i18next from 'i18next';
import { bool, func, shape, string } from 'prop-types';
import React from 'react';

import Button from '~components/Button';
import { IS_UNIQUE_KEY, TARGET_FILES_NAMES } from '~constants/fileTargets';
import { UNIQUE_KEY_RECOVER_PASSWORD } from '~constants/urls';
import { getAfpOptions } from '~components/UniqueKeyScraper/components/UniqueKeyForm/components/AfpOptions/utils';
import { FIELD_NAMES } from '~components/UniqueKeyScraper/constants';

import { FIELDS } from './constants';
import styles from './styles.module.scss';

function UniqueKeyFormContent({
  values,
  errors,
  touched,
  onChange,
  onBlur,
  rutDisabled,
  customError,
  type,
  setFieldValue
}) {
  const AFP_TYPE_NAME = FIELD_NAMES.AFP_TYPE;
  const handleRecoverPassword = () => {
    window.open(UNIQUE_KEY_RECOVER_PASSWORD, '_blank');
  };
  const isCertificateType = fieldType => {
    if (fieldType !== TARGET_FILES_NAMES.AFP) {
      return true;
    }

    return fieldType === type;
  };
  const isAFPType = option => option === 'AFP';
  const handleChangeSelect = option => {
    setFieldValue(AFP_TYPE_NAME, option.value);
  };
  const afpSelectOption = getAfpOptions().find(option => option.value === values.afpType);
  return (
    <div className={styles.container}>
      {FIELDS(rutDisabled).map(
        ({ component: Field, name, sectionTitle, alternativeTitle, needsCustomError, ...props }) =>
          isCertificateType(props.type) && (
            <div key={name} className={styles.fieldContainer}>
              <span className={styles.sectionTitle}>
                {isAFPType(type) && alternativeTitle ? alternativeTitle : sectionTitle}
              </span>
              <Field
                name={name}
                value={name === AFP_TYPE_NAME ? afpSelectOption : values[name]}
                error={(needsCustomError && customError) || errors[name]}
                touched={customError || touched[name]}
                handleChange={onChange}
                onChange={name === AFP_TYPE_NAME ? handleChangeSelect : onChange}
                handleBlur={onBlur}
                {...props}
              />
            </div>
          )
      )}
      {IS_UNIQUE_KEY(type) && (
        <Button lowercase info underline onClick={handleRecoverPassword}>
          {i18next.t('UniqueKeyScraper:forgotPassword')}
        </Button>
      )}
    </div>
  );
}

UniqueKeyFormContent.propTypes = {
  customError: bool,
  errors: shape({}),
  rutDisabled: bool,
  setFieldValue: func,
  touched: shape({}),
  type: string,
  values: shape({}),
  onBlur: func,
  onChange: func
};

export default UniqueKeyFormContent;
