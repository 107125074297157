import i18next from 'i18next';
import * as Yup from 'yup';

export const getMaxValue = totalValue => 0.8 * totalValue;

export const getMinValue = (totalValue, minimumDownpaymentPercentage) =>
  (minimumDownpaymentPercentage / 100) * totalValue;

export const getValidationSchema = (totalValue, minimumDownpaymentPercentage) =>
  Yup.object().shape({
    advancePayment: Yup.number()
      .required(i18next.t('AdvancePaymentFormErrors:required'))
      .max(getMaxValue(totalValue), i18next.t('AdvancePaymentFormErrors:max'))
      .min(
        getMinValue(totalValue, minimumDownpaymentPercentage),
        i18next.t('AdvancePaymentFormErrors:min', { value: `${minimumDownpaymentPercentage}%` })
      )
  });
