import React from 'react';
import { bool, func, shape, string } from 'prop-types';

import { MODAL_TYPES } from '~constants/modals';
import { AFP_STATUS, SII_STATUS } from '~components/IncomesForm/constants';
import CertificateCard from '~components/CertificateCard';
import UniqueKeyScraperModal from '~components/UniqueKeyScraperModal';
import { MODALS } from '~redux/Modal/constants';
import { useModal } from '~app/hooks/useModal';
import { TARGET_FILES } from '~constants/fileTargets';
import ScrapersActions from '~redux/Scrapers/actions';

import { needsToBeDisplayed } from './utils';

function CertificateCards({
  values,
  setModalType,
  afpReceived,
  afpWarning,
  siiReceived,
  siiWarning,
  typeHandlers,
  incumbent,
  ...certificateCardsProps
}) {
  const { handleOpenModal } = useModal();
  return (
    values.employmentSituation && (
      <>
        <CertificateCard
          type={MODAL_TYPES.AFP}
          setModalType={setModalType}
          infoReceived={afpReceived}
          warning={afpWarning}
          onWarning={typeHandlers[MODAL_TYPES.AFP].handleWarning}
          onResetInfo={typeHandlers[MODAL_TYPES.AFP].handleReset}
          show={needsToBeDisplayed(AFP_STATUS, values.employmentSituation)}
          customConnectAction={handleOpenModal(MODALS.CERTIFICATE_AFP)}
          {...certificateCardsProps}
        />
        <CertificateCard
          type={MODAL_TYPES.SII}
          setModalType={setModalType}
          infoReceived={siiReceived}
          warning={siiWarning}
          onWarning={typeHandlers[MODAL_TYPES.SII].handleWarning}
          onResetInfo={typeHandlers[MODAL_TYPES.SII].handleReset}
          show={needsToBeDisplayed(SII_STATUS, values.employmentSituation)}
          customConnectAction={handleOpenModal(MODALS.UNIQUE_KEY_SII)}
          {...certificateCardsProps}
        />
        <UniqueKeyScraperModal
          targetFiles={TARGET_FILES.SII}
          incumbent={incumbent}
          modal={MODALS.UNIQUE_KEY_SII}
          type={MODAL_TYPES.SII}
          scraperAction={ScrapersActions.scrapeSiiData}
          {...typeHandlers[MODAL_TYPES.SII]}
        />
        <UniqueKeyScraperModal
          targetFiles={TARGET_FILES.AFP}
          incumbent={incumbent}
          modal={MODALS.CERTIFICATE_AFP}
          type={MODAL_TYPES.AFP}
          scraperAction={ScrapersActions.scrapeAfpData}
          {...typeHandlers[MODAL_TYPES.AFP]}
        />
      </>
    )
  );
}

CertificateCards.propTypes = {
  afpReceived: bool,
  afpWarning: bool,
  incumbent: string,
  setModalType: func,
  siiReceived: bool,
  siiWarning: bool,
  typeHandlers: shape,
  values: shape
};

export default CertificateCards;
