import React from 'react';
import i18next from 'i18next';
import { bool, func, string } from 'prop-types';
import { useDispatch } from 'react-redux';

import Button from '~app/components/Button';
import Checkbox from '~app/components/Checkbox';
import { MODALS } from '~redux/Modal/constants';
import ModalActions from '~redux/Modal/actions';

import styles from './styles.module.scss';

function CertificateContent({
  type,
  setModalType,
  warning,
  handleWarning,
  hasError,
  customConnectAction,
  customUploadFileAction
}) {
  const dispatch = useDispatch();
  const openModal = modal => () => {
    setModalType?.(type);
    dispatch(ModalActions.openModal(modal));
  };
  const handleCheckboxChange = () => {
    handleWarning?.();
  };
  return (
    <>
      <div className={styles.buttonsContainer}>
        {type === 'AFP' ? (
          <>
            <Button
              lowercase
              className={styles.mainButton}
              onClick={customConnectAction || openModal(MODALS.SCRAPER)}>
              {i18next.t('CertificateCard:connect', { scraper: i18next.t(`CertificateCard:info${type}`) })}
            </Button>
            <Button
              lowercase
              underline
              className={styles.manualButton}
              onClick={customUploadFileAction || openModal(MODALS.FILE_UPLOADER)}>
              {i18next.t('CertificateCard:manually')}
            </Button>
          </>
        ) : (
          <Button
            lowercase
            className={styles.mainButton}
            onClick={customUploadFileAction || openModal(MODALS.FILE_UPLOADER)}>
            {i18next.t('CertificateCard:manually')}
          </Button>
        )}
      </div>
      <Checkbox checked={warning} onChange={handleCheckboxChange} hasError={hasError}>
        {i18next.t('CertificateCard:withoutInfo')}
      </Checkbox>
    </>
  );
}

CertificateContent.propTypes = {
  customConnectAction: func,
  customUploadFileAction: func,
  handleWarning: func,
  hasError: bool,
  setModalType: func,
  type: string,
  warning: bool
};

export default CertificateContent;
