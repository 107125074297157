import i18next from 'i18next';
import React from 'react';
import { useDispatch } from 'react-redux';
import { string } from 'yup/lib/locale';

import Button from '~app/components/Button';
import ModalActions from '~redux/Modal/actions';
import { MODALS } from '~redux/Modal/constants';
import { useIsView } from '~app/hooks/useIsView';
import { INCUMBENT_TYPES } from '~constants/incumbent';

import GuarantorInfoText from '../GuarantorInfoText';

import styles from './styles.module.scss';

function ContinueWithGuarantorInfo({ containerClassName, moreInfoClassName, neededInfo }) {
  const dispatch = useDispatch();
  const isGuarantorView = useIsView(INCUMBENT_TYPES.GUARANTOR);
  const handleOpenInfoModal = () => dispatch(ModalActions.openModal(MODALS.GUARANTOR_INFO));
  return (
    <div className={containerClassName}>
      <span className={styles.continueWithGuarantorInfo}>
        {neededInfo || <GuarantorInfoText firstInfo={i18next.t('ContinueWithGuarantor:toContinue')} />}
      </span>
      {!isGuarantorView && (
        <Button
          className={`${moreInfoClassName} ${styles.questionButton}`}
          lowercase
          underline
          onClick={handleOpenInfoModal}>
          {i18next.t('ContinueWithGuarantor:whatIsAGuarantor')}
        </Button>
      )}
    </div>
  );
}

ContinueWithGuarantorInfo.propTypes = {
  containerClassName: string,
  moreInfoClassName: string,
  neededInfo: string
};

export default ContinueWithGuarantorInfo;
