import React from 'react';
import { useSelector } from 'react-redux';

import FormWrapper from '~app/components/FormWrapper';
import SimulationLoadingWrapper from '~app/components/SimulationLoadingWrapper';
import { getCompleteRut } from '~utils/rut';

import GuarantorIdentityForm from './components/GuarantorIdentityForm';
import { INITIAL_VALUES } from './constants';
import { VALIDATION_SCHEMA } from './utils';

function GuarantorIdentity() {
  const { currentGuarantor, currentClient } = useSelector(state => state.simulation);
  return (
    <SimulationLoadingWrapper withInitialLoading>
      <FormWrapper
        form={GuarantorIdentityForm}
        initialValues={
          currentGuarantor
            ? { ...currentGuarantor, rut: getCompleteRut(currentGuarantor.rut, currentGuarantor.dv) }
            : INITIAL_VALUES
        }
        validationSchema={VALIDATION_SCHEMA(currentClient?.rut)}
      />
    </SimulationLoadingWrapper>
  );
}

export default GuarantorIdentity;
