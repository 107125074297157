export const BIRTHDAY_FIELDS = {
  GENDER: 'gender',
  BIRTHDAY: 'birthday',
  NATIONALITY: 'nationality'
};

export const INITIAL_VALUES = {
  [BIRTHDAY_FIELDS.GENDER]: null,
  [BIRTHDAY_FIELDS.BIRTHDAY]: '',
  [BIRTHDAY_FIELDS.NATIONALITY]: null
};

export const BIRTH_REMAINING_KEYS = [
  BIRTHDAY_FIELDS.GENDER,
  BIRTHDAY_FIELDS.BIRTHDAY,
  BIRTHDAY_FIELDS.NATIONALITY
];
