import { ReactComponent as Money } from '~assets/money.svg';
import { ReactComponent as Flow } from '~assets/moneyflow.svg';
import { ReactComponent as Terms } from '~assets/terms.svg';
import { CREDIT_TYPES_NAMES } from '~constants/credits';
import {
  BASE_TARGETS,
  SIMULATION_IMAGE_LINKS,
  SIMULATION_TYPES_NAMES,
  SIMULATION_TYPES_TARGETS
} from '~constants/simulations';

export const ICONS = {
  MONEY: Money,
  FLOW: Flow,
  TERMS: Terms
};

export const DEFAULT_AMOUNTS = {
  TOTAL_VALUE: 3000000,
  DOWNPAYMENT: 400000,
  TERMS: 18,
  MONTHLY_PAYMENT: 166000
};

export const BASE_TYPES = {
  [SIMULATION_TYPES_NAMES.PERSON]: {
    name: CREDIT_TYPES_NAMES.PERSON,
    picture: SIMULATION_IMAGE_LINKS[SIMULATION_TYPES_TARGETS.PERSON]
  },
  [SIMULATION_TYPES_NAMES.MOTORCYCLE]: {
    name: CREDIT_TYPES_NAMES.MOTORCYCLE,
    picture: SIMULATION_IMAGE_LINKS[SIMULATION_TYPES_TARGETS.MOTORCYCLE]
  },
  [SIMULATION_TYPES_NAMES.CAR]: {
    name: CREDIT_TYPES_NAMES.CAR,
    picture: SIMULATION_IMAGE_LINKS[SIMULATION_TYPES_TARGETS.CAR]
  },
  [SIMULATION_TYPES_NAMES.EMOBILE]: {
    name: CREDIT_TYPES_NAMES.EMOBILE,
    picture: SIMULATION_IMAGE_LINKS[SIMULATION_TYPES_TARGETS.EMOBILE]
  },
  [SIMULATION_TYPES_NAMES.CELLPHONE]: {
    name: CREDIT_TYPES_NAMES.CELLPHONE,
    picture: SIMULATION_IMAGE_LINKS[SIMULATION_TYPES_TARGETS.CELLPHONE]
  },
  [SIMULATION_TYPES_NAMES.MOTORCYCLE_DEALER]: {
    name: CREDIT_TYPES_NAMES.MOTORCYCLE,
    picture: SIMULATION_IMAGE_LINKS[SIMULATION_TYPES_TARGETS.MOTORCYCLE_DEALER]
  },
  [SIMULATION_TYPES_NAMES.CAR_DEALER]: {
    name: CREDIT_TYPES_NAMES.CAR,
    picture: SIMULATION_IMAGE_LINKS[SIMULATION_TYPES_TARGETS.CAR_DEALER]
  },
  [SIMULATION_TYPES_NAMES.CAR_DEALER_AMICAR]: {
    name: CREDIT_TYPES_NAMES.CAR,
    picture: SIMULATION_IMAGE_LINKS[SIMULATION_TYPES_TARGETS.CAR_DEALER_AMICAR]
  }
};

export const SIMULATION_TYPES = Object.keys(BASE_TARGETS).reduce((prevValues, actualValue) => {
  const actualTypeName = SIMULATION_TYPES_NAMES[actualValue];
  const baseType = BASE_TYPES[actualTypeName];
  return {
    ...prevValues,
    [actualTypeName]: baseType,
    [actualTypeName.concat('_sin_aval')]: baseType
  };
}, {});

export const DEFAULT_PICTURE = SIMULATION_IMAGE_LINKS[SIMULATION_TYPES_TARGETS.PERSON];
