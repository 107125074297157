import i18next from 'i18next';

import { INCOMES_DOCUMENTATION } from '~constants/documentation';
import { TARGET_FILES_NAMES } from '~constants/fileTargets';
import { MODALS } from '~redux/Modal/constants';

import { isDependent } from './employmentSituation';
import { isManualChoice } from './flowChoice';

export const getPersonalEarningsDocs = incumbentTargets => [
  {
    name: 'personalEarningsPdf',
    modalTarget: MODALS.FILE_UPLOADER_EARNINGS,
    receivedTarget: incumbentTargets?.earningsReceivedTarget,
    successTarget: incumbentTargets?.earningsReceivedStateTarget,
    type: TARGET_FILES_NAMES.PERSONAL_EARNINGS
  }
];

export const getPersonalEarnings = (incumbentTargets, employmentSituation, flowChoice) => ({
  id: INCOMES_DOCUMENTATION.PERSONAL_EARNINGS,
  title: i18next.t('PersonalDocumentationForm:personalEarnings'),
  documents: getPersonalEarningsDocs(incumbentTargets),
  show: isDependent(employmentSituation) || (flowChoice && !isManualChoice(flowChoice)),
  warningTarget: incumbentTargets?.earningsWarningTarget,
  warningStateTarget: incumbentTargets?.earningsWarningStateTarget
});
