import { useHistory, useParams } from 'react-router';

import { INCUMBENT_TYPES } from '~constants/incumbent';
import { buildMainPath } from '~utils/path';

import { useIsView } from './useIsView';

export const useRedirect = params => {
  const history = useHistory();
  const { quickCode, kind } = useParams();
  const isGuarantorView = useIsView(INCUMBENT_TYPES.GUARANTOR);
  return ({ customStepName, customKind, postAction }) =>
    () => {
      history.push(
        params?.mainPath ||
          buildMainPath(
            params?.otherQuickCode || quickCode,
            customStepName,
            customKind || kind,
            !params?.withoutParams && isGuarantorView
          )
      );
      postAction?.();
    };
};
