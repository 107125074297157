export const SIMULATION_QUERY_PARAMS = {
  AMOUNT: 'amount',
  DOWNPAYMENT: 'downpayment',
  ITEM_NAME: 'itemName',
  OWNER: 'owner',
  SRC: 'src',
  REFERRAL_CODE: 'referralCode',
  NAME: 'name',
  RUT: 'rut',
  EMAIL: 'email',
  PHONE: 'phone',
  BRAND: 'brand',
  MODEL: 'model',
  PRODUCT_CONDITION: 'productCondition',
  PAYMENT_TYPE: 'paymentType',
  EMAIL_DEALER: 'emailDealer'
};

export const UTM_QUERY_PARAMS = {
  UTM_SOURCE: 'utmSource',
  UTM_CAMPAIGN: 'utmCampaign',
  UTM_MEDIUM: 'utmMedium'
};

export const EXPECTED_QUERY_PARAMS = {
  [SIMULATION_QUERY_PARAMS.AMOUNT]: 'amount',
  [SIMULATION_QUERY_PARAMS.DOWNPAYMENT]: 'downpayment',
  [SIMULATION_QUERY_PARAMS.ITEM_NAME]: 'item_name',
  [SIMULATION_QUERY_PARAMS.OWNER]: 'ref',
  [SIMULATION_QUERY_PARAMS.SRC]: 'src',
  [SIMULATION_QUERY_PARAMS.REFERRAL_CODE]: 'referralCode',
  [SIMULATION_QUERY_PARAMS.NAME]: 'name',
  [SIMULATION_QUERY_PARAMS.RUT]: 'rut',
  [SIMULATION_QUERY_PARAMS.EMAIL]: 'email',
  [SIMULATION_QUERY_PARAMS.PHONE]: 'phone',
  [SIMULATION_QUERY_PARAMS.BRAND]: 'brand',
  [SIMULATION_QUERY_PARAMS.MODEL]: 'model',
  [SIMULATION_QUERY_PARAMS.PRODUCT_CONDITION]: 'product_condition',
  [SIMULATION_QUERY_PARAMS.PAYMENT_TYPE]: 'paymentType',
  [SIMULATION_QUERY_PARAMS.EMAIL_DEALER]: 'emailDealer',
  [UTM_QUERY_PARAMS.UTM_SOURCE]: 'utm_source',
  [UTM_QUERY_PARAMS.UTM_CAMPAIGN]: 'utm_campaign',
  [UTM_QUERY_PARAMS.UTM_MEDIUM]: 'utm_medium'
};
