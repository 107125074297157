import { useDispatch, useSelector } from 'react-redux';

import SimulationActions from '~redux/Simulation/actions';
import { getScrapersTargets } from '~utils/scrapers';
import { MODAL_INFO_KEYS, MODAL_TYPES, SCRAPERS, SCRAPERS_HANDLERS } from '~constants/modals';

const mapHandlers = ({ targets, handleReceived, handleWarning, resetScraper, resetWarning }) =>
  SCRAPERS.reduce((map, scraper) => {
    const { RECEIVED, WARNING } = MODAL_INFO_KEYS(scraper);
    return {
      ...map,
      [MODAL_TYPES[scraper]]: {
        [SCRAPERS_HANDLERS.HANDLE_RECEIVED]: handleReceived(targets[RECEIVED]),
        [SCRAPERS_HANDLERS.HANDLE_WARNING]: handleWarning(targets[WARNING]),
        [SCRAPERS_HANDLERS.HANDLE_RESET]: resetScraper(targets[RECEIVED], targets[WARNING]),
        [SCRAPERS_HANDLERS.HANDLE_RESET_WARNING]: resetWarning(targets[WARNING])
      }
    };
  }, {});

export const useScrapersInfo = prefix => {
  const dispatch = useDispatch();
  const simulation = useSelector(state => state.simulation);
  const targets = getScrapersTargets(prefix);
  const handleReceived = target => () => dispatch(SimulationActions.setSimulationInfo(target, true));
  const handleWarning = target => () =>
    dispatch(SimulationActions.setSimulationInfo(target, !simulation[target]));
  const resetWarning = targetWarning => () => {
    dispatch(SimulationActions.setSimulationInfo(targetWarning, false));
  };
  const resetScraper = (targetReceived, targetWarning) => () => {
    dispatch(SimulationActions.setSimulationInfo(targetReceived, false));
    resetWarning(targetWarning);
  };
  return mapHandlers({ targets, handleReceived, handleWarning, resetScraper, resetWarning });
};
