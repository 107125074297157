import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { hotjar } from 'react-hotjar';

import store from '~redux/store';
import { setGTM } from '~utils/document';
import AnalyticsService from '~services/AnalyticsService';

import { getScripts } from '../scripts/gtm';
import '../scripts/sentry';

import Routes from './components/Routes';
import styles from './styles.module.scss';

const App = () => {
  useEffect(() => {
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SV);
    setGTM(getScripts(process.env.REACT_APP_GTM_ID));
    AnalyticsService.setUp();
  }, []);
  return (
    <Provider store={store}>
      <ToastContainer className={styles.alert} />
      <Routes />
    </Provider>
  );
};

export default hot(App);
