import { arrayOf, node, string } from 'prop-types';
import React from 'react';

import Card from '../Card';
import CheckList from '../CheckList';

import styles from './styles.module.scss';

function TransitionCard({
  icon: Icon,
  TitleComponent,
  title,
  items,
  className,
  iconClassName,
  source,
  labelClassName
}) {
  return (
    <Card cardClassName={`${styles.container} ${className}`}>
      <Icon className={`${styles.transitionCardIcon} ${iconClassName}`} />
      {TitleComponent ? (
        <TitleComponent />
      ) : (
        title && <span className={styles.transitionCardTitle}>{title}</span>
      )}
      <CheckList source={source} items={items} labelClassName={labelClassName} />
    </Card>
  );
}

TransitionCard.propTypes = {
  className: string,
  icon: node,
  iconClassName: string,
  items: arrayOf(string),
  labelClassName: string,
  source: string,
  title: string,
  TitleComponent: node
};

export default TransitionCard;
