import i18next from 'i18next';
import React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { func } from 'prop-types';

import CustomModal from '~app/components/CustomModal';
import PrimaryAndSecondaryButtons from '~app/components/PrimaryAndSecondaryButtons';
import { useModal } from '~app/hooks/useModal';
import { MODALS } from '~redux/Modal/constants';
import CreditHeader from '~app/components/CreditHeader';
import { useRedirect } from '~app/hooks/useRedirect';
import { ROUTES } from '~constants/routes';

import styles from './styles.module.scss';

function PastSimulationModal({ createNewSimulation }) {
  const { handleCloseModal } = useModal();
  const isOpen = useSelector(state => state.modal.pastSimulation);
  const { lastSimulation, createSimulationLoading } = useSelector(state => state.simulation);
  const { kind } = useParams();
  const handleRedirect = useRedirect({ otherQuickCode: lastSimulation?.quickCode });
  const handlePastSimulation = () => {
    handleCloseModal(MODALS.PAST_SIMULATION)();
    handleRedirect({ customStepName: ROUTES.STATUS_PAGE.stepName })();
  };
  const handleCreateNewSimulation = () => createNewSimulation(handleCloseModal(MODALS.PAST_SIMULATION))();
  return (
    <CustomModal
      className={styles.modal}
      isOpen={isOpen}
      onClose={handleCloseModal(MODALS.PAST_SIMULATION)}
      hideCloseButton
      shouldCloseOnOverlayClick={false}>
      <div className={styles.container}>
        <div className={styles.titlesContainer}>
          <span className={styles.pastTitle}>{i18next.t('PastSimulationModal:title')}</span>
          <span className={styles.pastSubtitle}>{i18next.t('PastSimulationModal:subtitle')}</span>
        </div>
        <CreditHeader
          className={styles.creditHeaderContainer}
          pictureClassName={styles.picture}
          kind={kind}
          term={Math.round(lastSimulation?.installmentsAmount)}
          monthlyPayment={lastSimulation?.installmentValue}
          amount={lastSimulation?.amount}
          downpayment={lastSimulation?.downpayment}
        />
        <span className={styles.pastQuestion}>{i18next.t('PastSimulationModal:question')}</span>
        <PrimaryAndSecondaryButtons
          className={styles.buttonsContainer}
          primaryText={i18next.t('PastSimulationModal:continue')}
          secondaryText={i18next.t('PastSimulationModal:createNew')}
          handlePrimaryAction={handlePastSimulation}
          handleSecondaryAction={handleCreateNewSimulation}
          loading={createSimulationLoading}
        />
      </div>
    </CustomModal>
  );
}

PastSimulationModal.propTypes = {
  createNewSimulation: func
};

export default PastSimulationModal;
