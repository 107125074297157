import * as Yup from 'yup';

import { INFORMATION_VALIDATION_SCHEMA } from '~constants/validations';
import { addPrefix, getValueOrEmptyString } from '~utils/string';
import { mapStepMetaInfo } from '~redux/Simulation/mappers';

import { BIRTH_REMAINING_KEYS, BIRTHDAY_FIELDS } from './constants';

export const VALIDATION_SCHEMA = Yup.object().shape(INFORMATION_VALIDATION_SCHEMA);

const mapIdentityInitialValues = (prevValues, actualValue, queryParams, func) => ({
  ...prevValues,
  [actualValue]: func(queryParams[actualValue])
});

export const getIdentityInitialValues = queryParams => {
  const values = [...BIRTH_REMAINING_KEYS];
  return values.reduce(
    (prevValues, actualValue) =>
      mapIdentityInitialValues(prevValues, actualValue, queryParams, getValueOrEmptyString),
    {}
  );
};

export const mapInitialValues = ({ gender, birthday, nationality }) => ({
  gender: gender || undefined,
  birthday: birthday || '',
  nationality: nationality || undefined
});

export const getFormConfiguration = {
  validationSchema: VALIDATION_SCHEMA
};

export const getIncumbentValues = ({ prefix, values }) =>
  Object.keys(BIRTHDAY_FIELDS).map(key => ({
    kind: addPrefix(prefix, `_${BIRTHDAY_FIELDS[key]}`),
    value: values[BIRTHDAY_FIELDS[key]]
  }));

export const postBirthInfoMapper = (values, incumbent, stepTarget) => [
  ...getIncumbentValues({ prefix: incumbent, values }),
  ...mapStepMetaInfo(stepTarget)
];
