import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Select from '~components/Select';
import { useInstallmentsInfo } from '~hooks/useInstallmentsInfo';

import InstallmentBoard from './components/InstallmentBoard';
import { setCurrentTerm } from './utils';
import { LABEL } from './constants';

function InstallmentsInfo() {
  const dispatch = useDispatch();
  const { options, defaultOption } = useInstallmentsInfo();
  const [installment, setInstallment] = useState(defaultOption);
  useEffect(() => {
    dispatch(setCurrentTerm(installment));
  }, [dispatch]);
  const handleSelectChange = value => {
    setInstallment(value);
    dispatch(setCurrentTerm(value));
  };
  return (
    <>
      <Select options={[LABEL, ...options]} defaultValue={defaultOption} onChange={handleSelectChange} />
      <InstallmentBoard installment={installment} />
    </>
  );
}

export default InstallmentsInfo;
