import React from 'react';
import i18next from 'i18next';
import { bool, func, string } from 'prop-types';
import cn from 'classnames';

import { useRedirect } from '~app/hooks/useRedirect';
import { ROUTES } from '~constants/routes';

import ButtonLoading from '../ButtonLoading';

import styles from './styles.module.scss';

function PrimaryAndSecondaryButtons({
  loading,
  handlePrimaryAction,
  handleSecondaryAction,
  className,
  primaryClassName,
  secondaryClassName,
  primaryText = i18next.t('PrimaryAndSecondaryButtons:continue'),
  secondaryText = i18next.t('PrimaryAndSecondaryButtons:goToStatusPage'),
  disableButtons,
  hideSecondary,
  secondaryUnderlineButton,
  secondaryInfoButton,
  primaryType = 'button',
  secondaryType = 'button'
}) {
  const handleRedirect = useRedirect();
  return (
    <div className={cn(styles.container, className)}>
      <ButtonLoading
        primary
        onClick={handlePrimaryAction}
        className={cn(
          {
            [styles.primaryButton]: !hideSecondary,
            [styles.primaryButtonWithSecondaryUnderlined]: secondaryUnderlineButton
          },
          primaryClassName
        )}
        loading={loading}
        disabled={disableButtons}
        type={primaryType}>
        {primaryText}
      </ButtonLoading>
      {!hideSecondary && (
        <ButtonLoading
          className={secondaryClassName}
          secondary={!secondaryUnderlineButton}
          borderPrimary={!secondaryUnderlineButton}
          underline={secondaryUnderlineButton}
          info={secondaryInfoButton}
          lowercase={secondaryUnderlineButton}
          disabled={disableButtons}
          onClick={handleSecondaryAction || handleRedirect({ customStepName: ROUTES.STATUS_PAGE.stepName })}
          loading={loading}
          type={secondaryType}>
          {secondaryText}
        </ButtonLoading>
      )}
    </div>
  );
}

PrimaryAndSecondaryButtons.propTypes = {
  className: string,
  disableButtons: bool,
  handlePrimaryAction: func,
  handleSecondaryAction: func,
  hideSecondary: string,
  loading: bool,
  primaryClassName: string,
  primaryText: string,
  primaryType: string,
  secondaryClassName: string,
  secondaryInfoButton: string,
  secondaryText: string,
  secondaryType: string,
  secondaryUnderlineButton: string
};

export default PrimaryAndSecondaryButtons;
