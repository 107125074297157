import { CLIENT_DOCUMENTATION_TARGETS, GUARANTOR_DOCS_TARGETS } from '~constants/documentation';

export const DOCS_TARGETS = {
  ...CLIENT_DOCUMENTATION_TARGETS,
  ...GUARANTOR_DOCS_TARGETS
};

export const DOCS_DEFAULT_STATE = {
  [DOCS_TARGETS.CLIENT_ID_FRONT_RECEIVED]: false,
  [DOCS_TARGETS.CLIENT_ID_BACK_RECEIVED]: false,
  [DOCS_TARGETS.CLIENT_IMMIGRATION_RECEIVED]: false,
  [DOCS_TARGETS.CLIENT_TITLE_RECEIVED]: false,
  [DOCS_TARGETS.CLIENT_ID_WARNING]: false,
  [DOCS_TARGETS.CLIENT_TITLE_WARNING]: false,
  [DOCS_TARGETS.CLIENT_IMMIGRATION_WARNING]: false,
  [DOCS_TARGETS.CLIENT_PERSONAL_DOCUMENTATION]: null,
  [DOCS_TARGETS.CLIENT_EARNINGS_RECEIVED]: false,
  [DOCS_TARGETS.CLIENT_EARNINGS_WARNING]: false,
  [DOCS_TARGETS.GUARANTOR_ID_FRONT_RECEIVED]: false,
  [DOCS_TARGETS.GUARANTOR_ID_BACK_RECEIVED]: false,
  [DOCS_TARGETS.GUARANTOR_IMMIGRATION_RECEIVED]: false,
  [DOCS_TARGETS.GUARANTOR_TITLE_RECEIVED]: false,
  [DOCS_TARGETS.GUARANTOR_ID_WARNING]: false,
  [DOCS_TARGETS.GUARANTOR_TITLE_WARNING]: false,
  [DOCS_TARGETS.GUARANTOR_IMMIGRATION_WARNING]: false,
  [DOCS_TARGETS.GUARANTOR_PERSONAL_DOCUMENTATION]: null,
  [DOCS_TARGETS.GUARANTOR_EARNINGS_RECEIVED]: false,
  [DOCS_TARGETS.GUARANTOR_EARNINGS_WARNING]: false
};

export const PERSONAL_DOCS_RECEIVED = [
  { docTarget: '_ID_FRONT_RECEIVED', requirementTarget: 'FrontRutFile' },
  { docTarget: '_ID_BACK_RECEIVED', requirementTarget: 'BackRutFile' },
  { docTarget: '_TITLE_RECEIVED', requirementTarget: 'CollegeDegreeFile' },
  { docTarget: '_IMMIGRATION_RECEIVED', requirementTarget: 'VisaDocumentFile' },
  { docTarget: '_EARNINGS_RECEIVED', requirementTarget: 'PersonalEarningsFile' }
];

export const PERSONAL_DOCS_WARNING = [
  { docTarget: '_ID_WARNING', requirementTarget: 'RutDocumentUnknownMeta' },
  { docTarget: '_TITLE_WARNING', requirementTarget: 'CollegeDegreeUnknownMeta' },
  { docTarget: '_IMMIGRATION_WARNING', requirementTarget: 'VisaDocumentUnknownMeta' },
  { docTarget: '_EARNINGS_WARNING', requirementTarget: 'PersonalEarningsUnknownMeta' }
];
