import i18next from 'i18next';

import Input from '~app/components/Input';

export const FIELDS = [
  {
    component: Input,
    name: 'name',
    label: i18next.t('IdentityFields:name')
  },
  {
    component: Input,
    name: 'email',
    label: i18next.t('IdentityFields:email')
  },
  {
    component: Input,
    name: 'phone',
    label: i18next.t('IdentityFields:phone')
  },
  {
    component: Input,
    name: 'rut',
    label: i18next.t('IdentityFields:rut')
  }
];
