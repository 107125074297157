import React from 'react';
import cn from 'classnames';
import { bool, string } from 'prop-types';
import i18next from 'i18next';

import { CONTACTS } from './constants';
import styles from './styles.module.scss';
import MigranteContact from './MigranteContact';

const MigranteContacts = ({ className, show = false }) => (
  <div className={cn(styles.container, className)}>
    {show && (
      <span className={styles.messageInfo}>
        {i18next.t('MigranteContacts:contactText')}
        <MigranteContact {...CONTACTS.PHONE} />
      </span>
    )}
  </div>
);

MigranteContacts.propTypes = {
  className: string,
  show: bool
};

export default MigranteContacts;
