import { AFPS } from './components/UniqueKeyForm/components/AfpOptions/constants';

export const FIELD_NAMES = {
  AFP_TYPE: 'afpType',
  RUT: 'rut',
  UNIQUE_KEY: 'uniqueKey'
};

export const INITIAL_VALUES = {
  [FIELD_NAMES.AFP_TYPE]: AFPS.UNO,
  [FIELD_NAMES.RUT]: '',
  [FIELD_NAMES.UNIQUE_KEY]: ''
};
