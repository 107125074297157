import React from 'react';

import SelectOption from '~app/components/SelectOption';
import { capitalize } from '~utils/string';

import { AFPS_OPTIONS } from './constants';

export const getAfpOptions = () =>
  AFPS_OPTIONS.map(({ value, image }) => ({
    label: (
      <SelectOption
        message={capitalize(value)}
        Image={<img className="image" height={38} alt={value} src={image} />}
      />
    ),
    value
  }));
