import { addPrefix } from '~utils/string';

export const MODAL_TYPES = {
  AFP: 'AFP',
  SII: 'SII',
  CMF: 'CMF'
};

export const SCRAPERS = [MODAL_TYPES.AFP, MODAL_TYPES.SII, MODAL_TYPES.CMF];

export const MODAL_TYPES_TARGETS = {
  [MODAL_TYPES.AFP]: 'afp',
  [MODAL_TYPES.SII]: 'sii',
  [MODAL_TYPES.CMF]: 'cmf'
};

export const MIDDLE_MODAL_TYPES_TARGETS = {
  [MODAL_TYPES.AFP]: 'Afp',
  [MODAL_TYPES.SII]: 'Sii',
  [MODAL_TYPES.CMF]: 'Cmf'
};

export const MODAL_INFO = {
  RECEIVED: 'RECEIVED',
  WARNING: 'WARNING',
  RECEIVED_WITHOUT_WARNING: 'RECEIVED_WITHOUT_WARNING'
};

export const MODAL_INFO_TARGETS = {
  [MODAL_INFO.RECEIVED]: 'Received',
  [MODAL_INFO.WARNING]: 'Warning',
  [MODAL_INFO.RECEIVED_WITHOUT_WARNING]: 'ReceivedAndWithoutWarning'
};

export const MODAL_INFO_KEYS = modalType => ({
  [MODAL_INFO.RECEIVED]: addPrefix(MODAL_TYPES_TARGETS[modalType], MODAL_INFO_TARGETS.RECEIVED),
  [MODAL_INFO.WARNING]: addPrefix(MODAL_TYPES_TARGETS[modalType], MODAL_INFO_TARGETS.WARNING),
  [MODAL_INFO.RECEIVED_WITHOUT_WARNING]: addPrefix(
    MODAL_TYPES_TARGETS[modalType],
    MODAL_INFO_TARGETS.RECEIVED_WITHOUT_WARNING
  )
});

export const SCRAPERS_HANDLERS = {
  HANDLE_RECEIVED: 'handleReceived',
  HANDLE_WARNING: 'handleWarning',
  HANDLE_RESET: 'handleReset',
  HANDLE_RESET_WARNING: 'handleResetWarning'
};

export const ERROR_MODALS = {
  REMEMBER: 'REMEMBER',
  NO_GUARANTOR: 'NO_GUARANTOR'
};
