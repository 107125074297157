import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import InstallmentOption from '~components/InstallmentOption';
import { getValidCurrentTerm } from '~models/simulations';
import { formatMoney } from '~utils/format';
import { getMoneyValue } from '~utils/money';

const getInstallmentsMessage = installments => installments.toString().concat('x');

export const useInstallmentsInfo = () => {
  const { terms, currentTerm, loanDraft } = useSelector(state => state.simulation);
  const { options, defaultOption } = useMemo(() => {
    const currentOptions = terms?.map(({ term, monthlyPayment, rate, apr, minRequiredSalary }) => {
      const money = Math.round(parseFloat(monthlyPayment));
      return {
        label: <InstallmentOption value={formatMoney(money)} message={getInstallmentsMessage(term)} />,
        money,
        value: term,
        rate: getMoneyValue(rate),
        guarantee: getMoneyValue(apr),
        minRequiredSalary: getMoneyValue(minRequiredSalary)
      };
    });
    const term = getValidCurrentTerm(currentTerm, loanDraft);
    const currentDefaultOption = currentOptions?.find(option => option.value === term);
    return {
      options: currentOptions || [],
      defaultOption: currentDefaultOption
    };
  }, [terms, currentTerm, loanDraft]);
  return { options, defaultOption };
};
