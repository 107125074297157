import { hasBeenSubmitted } from '~utils/formik';

export const hasError = ({
  optional,
  hasAllRequiredDocuments,
  submitCount,
  documentation,
  warningStateTarget,
  knowsStatusPage
}) =>
  !optional &&
  !hasAllRequiredDocuments &&
  ((hasBeenSubmitted(submitCount) && !documentation[warningStateTarget]) || knowsStatusPage);
