import i18next from 'i18next';
import { int, bool, string, shape } from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Card from '~app/components/Card';
import RangeSlider from '~app/components/RangeSlider';
import { formatNumber } from '~utils/format';
import { SIMULATION_TYPES } from '~constants/simulations';
import SimulationActions from '~redux/Simulation/actions';
import { ReactComponent as Edit } from '~assets/edit.svg';
import { MODALS } from '~redux/Modal/constants';
import { useModal } from '~app/hooks/useModal';
import { SIMULATION_TARGETS } from '~redux/Simulation/constants';

import AmountModal from '../AmountModal';

import { DEFAULT_HEADER_TYPE, DEFAULT_STEP } from './constants';
import styles from './styles.module.scss';

function CreditValue({ kind, currentValue, isConsumer, loanDraft, productType }) {
  const dispatch = useDispatch();
  const handleCalculateTerms = () => {
    dispatch(
      SimulationActions.getTerms({
        amount: currentValue,
        productType,
        kind
      })
    );
  };
  const handleRangeChange = e => {
    dispatch(SimulationActions.setSimulationInfo(SIMULATION_TARGETS.AMOUNT, e.target.value));
  };
  const canEditAmount = useSelector(state => state.simulation.canEditAmount);
  const { handleOpenModal } = useModal();
  return (
    <Card
      header={i18next.t(`CreditSimulation:${SIMULATION_TYPES[kind ? kind : DEFAULT_HEADER_TYPE]}`)}
      cardClassName={cn(styles.cardContainer, {
        [styles.cardContainerConsumer]: isConsumer,
        [styles.cardContainerNotConsumer]: !isConsumer
      })}>
      <div className={styles.container}>
        <span className={styles.valueLabel}>
          {i18next.t(`CreditSimulation:${isConsumer ? 'label' : 'totalValue'}`)}
        </span>
        <div
          className={cn(styles.valueContainer, {
            [styles.valueContainerConsumer]: isConsumer,
            [styles.valueContainerPointer]: canEditAmount
          })}
          onClick={canEditAmount && handleOpenModal(MODALS.AMOUNT)}>
          <span className={styles.priceSign}>$</span>
          <span className={styles.priceValue}>{formatNumber(currentValue)}</span>
          {canEditAmount && <Edit className={styles.editButton} />}
        </div>
        {isConsumer && (
          <div className={styles.rangeSlider}>
            <RangeSlider
              min={loanDraft?.loanConfig?.minimumAmount}
              max={loanDraft?.loanConfig?.maximumAmount}
              step={DEFAULT_STEP}
              money
              handleChange={handleRangeChange}
              handleDrop={handleCalculateTerms}
              value={currentValue}
            />
          </div>
        )}
      </div>
      <AmountModal />
    </Card>
  );
}

CreditValue.propTypes = {
  currentValue: int,
  isConsumer: bool,
  kind: string,
  loanDraft: shape({ productType: string }),
  productType: string
};

export default CreditValue;
