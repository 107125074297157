import i18next from 'i18next';

import Input from '~app/components/Input';
import Select from '~app/components/Select';

import { getGendersOptions, getNationalitiesOptions } from './utils';

export const GENDERS = {
  MALE: 'male',
  FEMALE: 'female'
};

export const GENDERS_OPTIONS = [{ value: GENDERS.MALE }, { value: GENDERS.FEMALE }];

export const NATIONALITIES_OPTIONS = [
  { value: 'VE' },
  { value: 'CL' },
  { value: 'CO' },
  { value: 'PE' },
  { value: 'HT' },
  { value: 'DO' },
  { value: 'EC' },
  { value: 'BO' },
  { value: 'CU' },
  { value: 'BR' },
  { value: 'AR' },
  { value: 'other' }
];

export const FIELDS = [
  {
    component: Select,
    name: 'gender',
    options: getGendersOptions(),
    label: i18next.t('BirthInformationFields:gender'),
    placeholder: i18next.t('Common:selectPlaceholder')
  },
  {
    component: Input,
    name: 'birthday',
    label: i18next.t('BirthInformationFields:birthday'),
    placeholder: 'DD/MM/AAAA'
  },
  {
    component: Select,
    name: 'nationality',
    options: getNationalitiesOptions(),
    label: i18next.t('BirthInformationFields:nationality'),
    placeholder: i18next.t('Common:selectPlaceholder')
  }
];
