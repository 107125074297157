import i18next from 'i18next';

import { ReactComponent as ProfessionalTitle } from '~assets/professional-title.svg';
import { ReactComponent as FrontRut } from '~assets/front-rut.svg';
import { ReactComponent as BackRut } from '~assets/back-rut.svg';
import { ReactComponent as Visa } from '~assets/visa.svg';
import { ReactComponent as AFP } from '~assets/afp.svg';
import { ReactComponent as SII } from '~assets/sii.svg';
import { ReactComponent as PersonalEarnings } from '~assets/personal-earnings.svg';

import { TARGET_FILES_NAMES } from './fileTargets';

export const RUT_SIDES = {
  FRONT: 'frente',
  BACK: 'dorso'
};

export const FILE_UPLOADER_MODAL_ITEM = (boldText, infoText) => ({
  boldText,
  infoText
});

export const MAIN_INFO = fileInfo =>
  FILE_UPLOADER_MODAL_ITEM(i18next.t('FileUploaderModal:imageOrPDF'), fileInfo);

export const FILE_UPLOADER_MODAL_INFO = {
  [TARGET_FILES_NAMES.AFP]: {
    title: i18next.t('FileUploaderModal:afpTitle'),
    picture: AFP,
    fileInfoTexts: [MAIN_INFO(i18next.t('FileUploaderModal:afpInfo'))],
    needsToBeUpdated: true
  },
  [TARGET_FILES_NAMES.SII]: {
    title: i18next.t('FileUploaderModal:siiTitle'),
    picture: SII,
    fileInfoTexts: [MAIN_INFO(i18next.t('FileUploaderModal:siiInfo'))],
    needsToBeUpdated: true
  },
  [TARGET_FILES_NAMES.FRONT_RUT]: {
    title: i18next.t('FileUploaderModal:rutTitle', { side: RUT_SIDES.FRONT }),
    picture: FrontRut,
    fileInfoTexts: [
      MAIN_INFO(i18next.t('FileUploaderModal:rutPrimaryInfo')),
      FILE_UPLOADER_MODAL_ITEM(RUT_SIDES.FRONT, i18next.t('FileUploaderModal:rutSecondaryInfo'))
    ]
  },
  [TARGET_FILES_NAMES.BACK_RUT]: {
    title: i18next.t('FileUploaderModal:rutTitle', { side: RUT_SIDES.BACK }),
    picture: BackRut,
    fileInfoTexts: [
      MAIN_INFO(i18next.t('FileUploaderModal:rutPrimaryInfo')),
      FILE_UPLOADER_MODAL_ITEM(RUT_SIDES.BACK, i18next.t('FileUploaderModal:rutSecondaryInfo'))
    ]
  },
  [TARGET_FILES_NAMES.VISA_DOCUMENT]: {
    title: i18next.t('FileUploaderModal:visaTitle'),
    picture: Visa,
    fileInfoTexts: [MAIN_INFO(i18next.t('FileUploaderModal:visaInfo'))]
  },
  [TARGET_FILES_NAMES.COLLEGE_DEGREE]: {
    title: i18next.t('FileUploaderModal:professionalTitle'),
    picture: ProfessionalTitle,
    fileInfoTexts: [MAIN_INFO(i18next.t('FileUploaderModal:professionalInfo'))]
  },
  [TARGET_FILES_NAMES.CMF]: {
    title: i18next.t('FileUploaderModal:cmfTitle')
  },
  [TARGET_FILES_NAMES.PERSONAL_EARNINGS]: {
    title: i18next.t('FileUploaderModal:personalEarningsTitle'),
    picture: PersonalEarnings,
    fileInfoTexts: [MAIN_INFO(i18next.t('FileUploaderModal:personalEarningsInfo'))]
  }
};
