import { setDatalayer } from '~utils/document';

export const addPageView = data => {
  setDatalayer({
    dataLayerName: 'dataLayer',
    dataLayer: {
      event: 'page-update',
      ...data
    }
  });
  return Promise.resolve({ ok: true, data: {} });
};
