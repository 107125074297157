import i18next from 'i18next';
import * as Yup from 'yup';

import { TARGET_FILES } from '~constants/fileTargets';
import { RUT_VALIDATION } from '~constants/validations';
import { addPrefix } from '~utils/string';

export const VALIDATION_SCHEMA = Yup.object().shape({
  rut: RUT_VALIDATION,
  uniqueKey: Yup.string().required(i18next.t('UniqueKeyScraper:required'))
});

export const getIncumbentValues = incumbent => [
  {
    value: 'pending',
    kind: addPrefix(incumbent, TARGET_FILES.CMF.kind),
    context: addPrefix(incumbent, TARGET_FILES.CMF.context)
  }
];
