import i18next from 'i18next';
import React from 'react';

import { ReactComponent as Unique } from '~assets/unique.svg';
import { ReactComponent as Lock } from '~assets/lock.svg';

import styles from './styles.module.scss';

function UniqueKeyHeader() {
  return (
    <div className={styles.container}>
      <Lock />
      <Unique className={styles.logo} />
      <span className={styles.uniqueKeySubtitle}>{i18next.t('UniqueKeyScraper:subtitle')}</span>
    </div>
  );
}

export default UniqueKeyHeader;
