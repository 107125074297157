import cn from 'classnames';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import React from 'react';

import LabelWithSelect from '~components/LabelWithSelect';
import { mapOptions } from '~utils/select';

import DocumentationCard from '../DocumentationCard';

import styles from './styles.module.scss';

function SelectWithDocumentationCard({
  target,
  name,
  label,
  selectLabel,
  placeholder,
  options,
  documentation,
  values,
  incumbent,
  submitCount,
  knowsStatusPage,
  ...props
}) {
  return (
    <div className={cn(styles.container)}>
      <LabelWithSelect
        name={name}
        label={label}
        selectLabel={selectLabel}
        placeholder={placeholder}
        options={mapOptions(options, target)}
        values={values}
        containerClassName={styles.labelWithSelectContainer}
        {...props}
      />
      {documentation.map(({ id, show, ...documentationProps }) => (
        <DocumentationCard
          key={id}
          target={target}
          show={show(values[name])}
          incumbent={incumbent}
          submitCount={submitCount}
          knowsStatusPage={knowsStatusPage}
          {...documentationProps}
        />
      ))}
    </div>
  );
}

SelectWithDocumentationCard.propTypes = {
  documentation: shape,
  errors: shape,
  incumbent: string,
  knowsStatusPage: bool,
  label: string,
  name: string,
  options: arrayOf(string),
  placeholder: string,
  selectLabel: string,
  setFieldTouched: func,
  setFieldValue: func,
  submitCount: number,
  target: string,
  touched: shape,
  values: shape
};

export default SelectWithDocumentationCard;
