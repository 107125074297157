import i18next from 'i18next';
import { number } from 'prop-types';
import React from 'react';
import { string } from 'yup/lib/locale';
import cn from 'classnames';

import { isConsumerSimulation } from '~models/simulations';
import { isWithoutDownpayment } from '~utils/simulation';

import Downpayment from './components/Downpayment';
import LabelAmount from './components/LabelAmount';
import { DEFAULT_AMOUNTS, DEFAULT_PICTURE, ICONS, SIMULATION_TYPES } from './constants';
import styles from './styles.module.scss';

function CreditHeader({ className, kind, term, monthlyPayment, amount, downpayment, pictureClassName }) {
  const isConsumer = isConsumerSimulation(kind) || !kind;
  return (
    <div className={`row space-between middle full-width ${styles.container} ${className}`}>
      <div className={`column ${styles.background}`} />
      <div className={`column charcoal label ${styles.content}`}>
        <LabelAmount
          icon={ICONS.MONEY}
          label={
            isConsumer
              ? i18next.t('CreditHeader:person')
              : i18next.t('CreditHeader:for', { type: SIMULATION_TYPES[kind]?.name })
          }
          amount={amount}
          defaultAmount={DEFAULT_AMOUNTS.TOTAL_VALUE}
          className="m-bottom-2"
        />
        {!isWithoutDownpayment(kind) && (
          <Downpayment amount={downpayment} defaultAmount={DEFAULT_AMOUNTS.DOWNPAYMENT} />
        )}
        <LabelAmount
          icon={ICONS.TERMS}
          label={i18next.t('CreditHeader:terms', {
            terms: term || DEFAULT_AMOUNTS.TERMS
          })}
          amount={monthlyPayment}
          defaultAmount={DEFAULT_AMOUNTS.MONTHLY_PAYMENT}
        />
      </div>
      <div className={`column m-left-4 ${styles.product}`}>
        <img
          src={isConsumer ? DEFAULT_PICTURE : SIMULATION_TYPES[kind]?.picture}
          href="product"
          className={cn(styles.picture, pictureClassName)}
        />
      </div>
    </div>
  );
}

CreditHeader.propTypes = {
  amount: number,
  className: string,
  downpayment: number,
  kind: string,
  monthlyPayment: number,
  pictureClassName: string,
  term: number
};

export default CreditHeader;
