import capitalImage from '~assets/afp/capital.png';
import modeloImage from '~assets/afp/modelo.png';
import habitatImage from '~assets/afp/habitat.png';
import providaImage from '~assets/afp/provida.png';
import planvitalImage from '~assets/afp/planvital.png';
import cuprumImage from '~assets/afp/cuprum.png';
import unoImage from '~assets/afp/uno.png';

export const AFPS = {
  UNO: 'uno',
  CAPITAL: 'capital',
  MODELO: 'modelo',
  PROVIDA: 'provida',
  PLANVITAL: 'planvital',
  CUPRUM: 'cuprum',
  HABITAT: 'habitat'
};

export const AFPS_OPTIONS = [
  { value: AFPS.UNO, image: unoImage },
  { value: AFPS.CAPITAL, image: capitalImage },
  { value: AFPS.MODELO, image: modeloImage },
  { value: AFPS.PROVIDA, image: providaImage },
  { value: AFPS.PLANVITAL, image: planvitalImage },
  { value: AFPS.CUPRUM, image: cuprumImage },
  { value: AFPS.HABITAT, image: habitatImage }
];
