import { createTypes, completeTypes, withPostSuccess, withPostFailure, withPreFetch } from 'redux-recompose';
import { push } from 'connected-react-router';

import * as SimulationService from '~services/SimulationService';
import StepsActions from '~redux/Steps/actions';
import LocalStorageService from '~services/LocalStorageService';
import { STEPS_PROGRESS_TARGETS, STEPS_STATES, STEPS_TARGETS } from '~constants/steps';
import { ROUTES } from '~constants/routes';
import { buildMainPath } from '~utils/path';
import { SIMULATION_NEEDS_GUARANTOR } from '~constants/simulations';
import { isCompleted } from '~utils/steps';
import { addPrefix } from '~utils/string';
import { META_VALUES } from '~constants/metaValues';
import { META_BOOLEANS_VALUES } from '~constants/metaInfo';
import ModalActions from '~redux/Modal/actions';
import { MODALS } from '~redux/Modal/constants';
import { sendAnalyticsEvents } from '~models/analytics';
import { FB_PIXEL_ACTIONS, FB_PIXEL_EVENTS } from '~constants/fbPixel';
import { PAYMENT_TYPES } from '~constants/paymentTypes';
import SegmentService from '~services/SegmentService';

import {
  changeIncumbentMapper,
  createSimulationMapper,
  mapIncumbentValue,
  postCreateMapper,
  postUpdateMapper,
  simulationMapper
} from './mappers';
import { saveFetchedSimulation } from './utils';
import { SIMULATION_TARGETS } from './constants';

export const actions = createTypes(
  completeTypes(
    [
      'GET_LOAN_DRAFT',
      'GET_TERMS',
      'CREATE_SIMULATION',
      'CHANGE_INCUMBENT',
      'UPDATE_SIMULATION',
      'GET_STEPS_PROGRESS',
      'FETCH_SIMULATION',
      'FIND_LAST_SIMULATION'
    ],
    ['SET_SIMULATION_INFO']
  ),
  '@@SIMULATION'
);

export const actionCreators = {
  setSimulationInfo: (target, payload) => ({
    type: actions.SET_SIMULATION_INFO,
    target,
    payload
  }),
  getLoanDraft: kind => ({
    type: actions.GET_LOAN_DRAFT,
    service: SimulationService.getLoanDraft,
    target: SIMULATION_TARGETS.LOAN_DRAFT,
    payload: kind,
    injections: [
      withPostSuccess(dispatch => {
        dispatch(actionCreators.setSimulationInfo(SIMULATION_TARGETS.KIND, kind));
        dispatch(
          actionCreators.setSimulationInfo(
            SIMULATION_TARGETS.NEEDS_GUARANTOR,
            SIMULATION_NEEDS_GUARANTOR[kind]
          )
        );
      }),
      withPostFailure(dispatch => {
        dispatch(push(ROUTES.ERROR.path));
      })
    ]
  }),
  getTerms: values => ({
    type: actions.GET_TERMS,
    service: SimulationService.getTerms,
    target: SIMULATION_TARGETS.TERMS,
    payload: values,
    injections: [
      withPostFailure(dispatch => {
        dispatch(push(ROUTES.FAILED_LOADING_TERMS.path));
      })
    ]
  }),
  createSimulation: (
    { kind, nextStep = ROUTES.CLIENT_BIRTH_INFORMATION.stepName, ...values },
    postSuccessAction
  ) => ({
    type: actions.CREATE_SIMULATION,
    service: SimulationService.createSimulation,
    target: SIMULATION_TARGETS.CREATE_SIMULATION,
    payload: () =>
      createSimulationMapper({ kind, ...values, anonymousId: SegmentService.currentAnonymousId() }),
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(
          actionCreators.changeIncumbent({
            quickCode: response.data.quickCode,
            values: postCreateMapper({ kind, ...values })
          })
        );
        dispatch(
          actionCreators.setSimulationInfo(SIMULATION_TARGETS.PRODUCT_TYPE, response.data.productTypeSlug)
        );
        dispatch(
          StepsActions.setStepState(STEPS_TARGETS.CLIENT_PERSONAL_INFORMATION, STEPS_STATES.COMPLETED)
        );
        dispatch(actionCreators.setSimulationInfo(SIMULATION_TARGETS.KIND, kind));
        dispatch(
          actionCreators.setSimulationInfo(
            SIMULATION_TARGETS.DECRYPTED_QUICK_CODE,
            response.data?.decryptedQuickCode
          )
        );
        if (postSuccessAction) {
          dispatch(postSuccessAction);
        }
        window.fbq(FB_PIXEL_ACTIONS.TRACK, FB_PIXEL_EVENTS.LEAD_GENERATION);
        dispatch(push(buildMainPath(response.data.quickCode, nextStep, kind)));
        LocalStorageService.setAnalyticsEvents({
          quickCode: response.data.quickCode
        });
      }),
      withPostFailure(dispatch => {
        dispatch(push(ROUTES.ERROR.path));
      })
    ]
  }),
  changeIncumbent: ({ quickCode, values, postSuccessAction }) => ({
    type: actions.CHANGE_INCUMBENT,
    service: SimulationService.changeIncumbent,
    target: SIMULATION_TARGETS.CHANGE_INCUMBENT,
    payload: () => changeIncumbentMapper(quickCode, values),
    injections: [
      withPostSuccess((dispatch, response, state) => {
        sendAnalyticsEvents(response, state);
        if (postSuccessAction) {
          dispatch(postSuccessAction);
        }
      }),
      withPostFailure(dispatch => {
        dispatch(push(ROUTES.ERROR.path));
      })
    ]
  }),
  updateSimulation: (quickCode, { kind, amount, downpayment, isFinanced, ...values }, postSuccessAction) => ({
    type: actions.UPDATE_SIMULATION,
    service: SimulationService.updateSimulation,
    target: SIMULATION_TARGETS.UPDATE_SIMULATION,
    payload: () => ({ quickCode, values: simulationMapper({ kind, amount, downpayment, ...values }) }),
    injections: [
      withPostSuccess(dispatch => {
        const paymentType = isFinanced ? PAYMENT_TYPES.QUOTES : PAYMENT_TYPES.CASH;
        dispatch(StepsActions.setStepState(STEPS_TARGETS.CLIENT_QUOTES_INFORMATION, STEPS_STATES.COMPLETED));
        dispatch(
          actionCreators.changeIncumbent({
            quickCode,
            values: postUpdateMapper(STEPS_TARGETS.CLIENT_QUOTES_INFORMATION, paymentType),
            postSuccessAction
          })
        );
      }),
      withPostFailure(dispatch => {
        dispatch(push(ROUTES.ERROR.path));
      })
    ]
  }),
  getStepsProgress: (quickCode, postSuccessAction) => ({
    type: actions.GET_STEPS_PROGRESS,
    service: SimulationService.getStepsProgress,
    target: SIMULATION_TARGETS.STEPS_PROGRESS,
    payload: quickCode,
    injections: [
      withPostSuccess((dispatch, response) => {
        STEPS_PROGRESS_TARGETS.forEach(({ id, target, incumbent }) => {
          dispatch(StepsActions.setStepState(target, isCompleted(response.data[incumbent][id]?.complete)));
        });
        dispatch(postSuccessAction);
      })
    ]
  }),
  fetchSimulation: quickCode => ({
    type: actions.FETCH_SIMULATION,
    service: SimulationService.fetchSimulation,
    target: SIMULATION_TARGETS.CURRENT_SIMULATION,
    payload: quickCode,
    injections: [
      withPreFetch(dispatch => {
        dispatch(actionCreators.setSimulationInfo(SIMULATION_TARGETS.SAVE_FETCHED_SIM_LOADING, true));
      }),
      withPostSuccess((dispatch, response) => {
        saveFetchedSimulation(quickCode, dispatch, actionCreators, response);
      })
    ]
  }),
  findLastSimulation: (values, postErrorAction) => ({
    type: actions.FIND_LAST_SIMULATION,
    service: SimulationService.findLastSimulation,
    target: SIMULATION_TARGETS.LAST_SIMULATION,
    payload: values,
    injections: [
      withPostSuccess(dispatch => {
        dispatch(ModalActions.openModal(MODALS.PAST_SIMULATION));
      }),
      withPostFailure(dispatch => {
        if (postErrorAction) {
          dispatch(postErrorAction);
        }
      })
    ]
  }),
  setSimulationUp: quickCode => dispatch => {
    dispatch(actionCreators.getStepsProgress(quickCode, actionCreators.fetchSimulation(quickCode)));
  },
  setAmountAndDownpayment: (amount, downpayment) => dispatch => {
    dispatch(actionCreators.setSimulationInfo(SIMULATION_TARGETS.AMOUNT, amount));
    dispatch(actionCreators.setSimulationInfo(SIMULATION_TARGETS.DOWNPAYMENT, downpayment));
  },
  setInfoReceivedAndWarning: (targetReceived, targetWarning, received, warning) => dispatch => {
    dispatch(actionCreators.setSimulationInfo(targetReceived, received));
    dispatch(actionCreators.setSimulationInfo(targetWarning, warning));
  },
  setEmailConfirmed: (quickCode, incumbent, emailConfirmedTarget) => dispatch => {
    dispatch(actionCreators.setSimulationInfo(emailConfirmedTarget, true));
    dispatch(
      actionCreators.changeIncumbent({
        quickCode,
        values: mapIncumbentValue(
          addPrefix(incumbent, META_VALUES.CONFIRMED_EMAIL_META),
          META_BOOLEANS_VALUES.TRUE
        )
      })
    );
  }
};

export default actionCreators;
