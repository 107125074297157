export const SNAKE_CASE_REGEX = /(_\w)/g;

export const CAPITAL_LETTERS_REGEX = /[A-Z]/g;

export const BLANK_REGEX = /^\s*$/;

export const NUMBERS_REGEX = /\d+/g;

export const LETTERS_REGEX = /\D+/g;

export const SPACES_REGEX = /\s/g;

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const DATE_REGEX = /(^0[1-9]|[12][0-9]|3[01])(\/)(0[1-9]|1[0-2])(\/)(\d{4}$)/;
