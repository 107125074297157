import React from 'react';
import { bool, func, string } from 'prop-types';
import i18next from 'i18next';
import cn from 'classnames';

import { ReactComponent as FileUpload } from '~assets/fileupload.svg';

import ButtonLoading from '../ButtonLoading';

import styles from './styles.module.scss';
import { COUNT, FILES_ACCEPTED } from './constants';

function FileUploader({
  handleFiles,
  loading,
  buttonClassName,
  buttonTextClassName,
  customText,
  targetsMultipleFiles
}) {
  const hiddenFileInput = React.useRef(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const handleChange = event => {
    handleFiles(event?.target?.files);
    event.target.value = '';
  };
  return (
    <>
      <ButtonLoading
        className={cn(styles.uploadFilesButton, buttonClassName)}
        lowercase
        onClick={handleClick}
        loading={loading}>
        <span className={cn(styles.uploadFilesButtonText, buttonTextClassName)}>
          {customText ||
            i18next.t('FileUploader:uploadFile', {
              count: targetsMultipleFiles ? COUNT.PLURAL : COUNT.SINGLE
            })}
        </span>
        <FileUpload />
      </ButtonLoading>
      <input
        type="file"
        accept={FILES_ACCEPTED}
        ref={hiddenFileInput}
        onChange={handleChange}
        className={styles.fileInput}
        multiple
      />
    </>
  );
}

FileUploader.propTypes = {
  buttonClassName: string,
  buttonTextClassName: string,
  customText: string,
  handleFiles: func,
  loading: bool,
  targetsMultipleFiles: bool
};

export default FileUploader;
