import i18next from 'i18next';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SimulationLoadingWrapper from '~components/SimulationLoadingWrapper';
import { INCUMBENT_TYPES } from '~constants/incumbent';
import { CLIENT_STEPS_TARGETS, STEPS_TARGETS } from '~constants/steps';
import SimulationActions from '~redux/Simulation/actions';
import { SIMULATION_TARGETS } from '~redux/Simulation/constants';

import EndingConfirmation from '../EndingConfirmation';

function ClientConfirmation() {
  const dispatch = useDispatch();
  const { needsGuarantor, currentClient, clientConfirmedEmail } = useSelector(state => state.simulation);
  const setCurrentClientEmail = newEmail => {
    dispatch(
      SimulationActions.setSimulationInfo(SIMULATION_TARGETS.CURRENT_CLIENT, {
        ...currentClient,
        email: newEmail
      })
    );
  };
  return (
    <SimulationLoadingWrapper loading={!currentClient}>
      <EndingConfirmation
        prefix={INCUMBENT_TYPES.CLIENT}
        title={i18next.t(`ClientConfirmation:${needsGuarantor ? 'veryGood' : 'titleWithoutGuarantor'}`)}
        firstText={i18next.t('ClientConfirmation:sendEmailWithoutGuarantor')}
        secondText={i18next.t('ClientConfirmation:confirmInfoWithoutGuarantor')}
        email={currentClient?.email}
        previousStep={CLIENT_STEPS_TARGETS.CLIENT_FINANCIAL_INFORMATION}
        target={STEPS_TARGETS.CLIENT_CONFIRMATION}
        setCurrentIncumbent={setCurrentClientEmail}
        confirmedEmail={clientConfirmedEmail}
        confirmedEmailTarget={SIMULATION_TARGETS.CLIENT_CONFIRMED_EMAIL}
      />
    </SimulationLoadingWrapper>
  );
}

export default ClientConfirmation;
