import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, func, string } from 'prop-types';
import { useParams } from 'react-router';

import { MODALS } from '~redux/Modal/constants';
import ModalActions from '~redux/Modal/actions';
import CustomModal from '~app/components/CustomModal';
import FormWrapper from '~app/components/FormWrapper';
import SimulationActions from '~redux/Simulation/actions';
import { mapIncumbentValue } from '~redux/Simulation/mappers';
import { addPrefix } from '~utils/string';
import VerificationsActions from '~redux/Verifications/actions';

import styles from './styles.module.scss';
import { hasChanged, VALIDATION_SCHEMA } from './utils';
import EmailForm from './components/EmailForm';

function EmailModal({
  incumbent,
  email,
  handleEmailChange,
  hideCloseButton,
  shouldCloseOnOverlayClick,
  customTitle,
  confirmedEmail,
  confirmedEmailTarget
}) {
  const dispatch = useDispatch();
  const { quickCode } = useParams();
  const open = useSelector(state => state.modal[MODALS.EMAIL]);
  const closeModal = () => dispatch(ModalActions.closeModal(MODALS.EMAIL));
  const handleSubmit = values => {
    if (hasChanged(email, values.email)) {
      dispatch(
        VerificationsActions.triggerCheckout({
          quickCode,
          incumbent,
          value: values.email,
          field: addPrefix(incumbent, '_email')
        })
      );
      dispatch(
        SimulationActions.changeIncumbent({
          quickCode,
          values: mapIncumbentValue(addPrefix(incumbent, '_email'), values.email)
        })
      );
      if (handleEmailChange) {
        handleEmailChange(values.email);
      }
    }
    if (!confirmedEmail) {
      dispatch(SimulationActions.setEmailConfirmed(quickCode, incumbent, confirmedEmailTarget));
    }
    closeModal();
  };
  return (
    <CustomModal
      className={styles.modal}
      modal={MODALS.ADVANCE_PAYMENT}
      onClose={closeModal}
      isOpen={open}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      hideCloseButton={hideCloseButton}>
      <FormWrapper
        form={EmailForm}
        onSubmit={handleSubmit}
        customTitle={customTitle}
        initialValues={{ email }}
        validationSchema={VALIDATION_SCHEMA}
      />
    </CustomModal>
  );
}

EmailModal.propTypes = {
  confirmedEmail: bool,
  confirmedEmailTarget: string,
  customTitle: string,
  email: string,
  handleEmailChange: func,
  hideCloseButton: bool,
  incumbent: string,
  shouldCloseOnOverlayClick: bool
};

export default EmailModal;
