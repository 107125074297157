import React from 'react';
import { func, shape, string } from 'prop-types';
import i18next from 'i18next';

import Button from '~app/components/Button';
import Input from '~app/components/Input';

import styles from './styles.module.scss';

function EmailForm({
  errors,
  touched,
  values,
  onChange,
  onBlur,
  customTitle = i18next.t('EmailModal:editEmail')
}) {
  return (
    <div className={`column center middle full-width full-height ${styles.container}`}>
      <div className={styles.sectionTitle}>{customTitle}</div>
      <div className={`full-width ${styles.input}`}>
        <Input
          label={i18next.t('EmailModal:email')}
          name="email"
          value={values.email}
          error={errors.email}
          touched={touched.email}
          handleChange={onChange}
          handleBlur={onBlur}
        />
      </div>
      <Button type="submit" primary className={styles.button}>
        {i18next.t('EmailModal:confirm')}
      </Button>
    </div>
  );
}

EmailForm.propTypes = {
  customTitle: string,
  errors: shape,
  touched: shape,
  values: shape,
  onBlur: func,
  onChange: func
};

export default EmailForm;
