import i18next from 'i18next';
import React from 'react';

import TransitionScreen from '~components/TransitionScreen';
import { ReactComponent as LetsStartIcon } from '~assets/lets-start.svg';
import { NEEDED_LABOR_DOCS } from '~constants/documentation';
import { ROUTES } from '~constants/routes';

function LetsStart() {
  return (
    <TransitionScreen
      title={i18next.t('LetsStart:title')}
      transitionCardIcon={LetsStartIcon}
      transitionCardTitle={i18next.t('LetsStart:laboralDocuments')}
      transitionCardSource="LetsStart"
      transitionCardContent={NEEDED_LABOR_DOCS}
      customStepName={ROUTES.CLIENT_INCOMES.stepName}
    />
  );
}

export default LetsStart;
