import i18next from 'i18next';
import { toast } from 'react-toastify';

import { ReactComponent as Link } from '~assets/link.svg';
import { ReactComponent as Whastapp } from '~assets/whatsapp.svg';
import { WHATSAPP_SHARE_LINK } from '~constants/urls';

export const SHAREABLES = [
  {
    id: 'clipboard',
    Icon: Link,
    onClick: link => {
      navigator.clipboard.writeText(link);
      toast.success(i18next.t('ShareLinkModal:linkCopied'));
    }
  },
  {
    id: 'whatsapp',
    Icon: Whastapp,
    onClick: (link, target = 'GuarantorShareableLink') => {
      try {
        return window
          .open(
            `${WHATSAPP_SHARE_LINK}${i18next.t(`${target}:link`, {
              link
            })}`
          )
          .focus();
      } catch (error) {
        return error;
      }
    }
  }
];
