import { ROUTES } from '~constants/routes';
import { GUARANTOR_STEPS_TARGETS, STEPS_TARGETS } from '~constants/steps';
import { mapStepMetaInfo } from '~redux/Simulation/mappers';

export const mapIncumbentValues = ({ name, phone, rut, dv, email }) => [
  {
    kind: 'simulation_guarantor',
    value: JSON.stringify({
      name,
      phone,
      rut,
      dv,
      email
    })
  },
  ...mapStepMetaInfo(STEPS_TARGETS.GUARANTOR_PERSONAL_INFORMATION)
];

export const getStepperActions = (continueAction, submitForm) => ({
  [GUARANTOR_STEPS_TARGETS.GUARANTOR_FINANCIAL_INFORMATION]: submitForm(
    continueAction(ROUTES.GUARANTOR_INCOMES.stepName)
  ),
  [GUARANTOR_STEPS_TARGETS.GUARANTOR_PERSONAL_DOCUMENTATION]: submitForm(
    continueAction(ROUTES.GUARANTOR_PERSONAL_DOCS.stepName)
  )
});
