import { createTypes, completeTypes } from 'redux-recompose';

import { STEPS_TARGETS } from './constants';

export const actions = createTypes(completeTypes([], ['SET_STEP_STATE']), '@@STEPS');

export const actionCreators = {
  setStepState: (step, state) => ({
    type: actions.SET_STEP_STATE,
    target: STEPS_TARGETS[step],
    payload: state
  })
};

export default actionCreators;
