import SimulationActions from '~redux/Simulation/actions';
import { SIMULATION_TARGETS } from '~redux/Simulation/constants';

export const setCurrentTerm = term =>
  SimulationActions.setSimulationInfo(SIMULATION_TARGETS.CURRENT_TERM, {
    term: term.value,
    monthlyPayment: term.money,
    rate: term.rate,
    apr: term.guarantee,
    minRequiredSalary: term.minRequiredSalary
  });
