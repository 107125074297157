import React from 'react';
import { arrayOf, string } from 'prop-types';
import i18next from 'i18next';
import cn from 'classnames';

import logo from '~assets/checklist/logo.png';

import styles from './styles.module.scss';

function CheckList({ source, customLogo = logo, items, className, logoClassName, labelClassName }) {
  return (
    <div className={cn(styles.container, className)}>
      {items.map(item => (
        <div className={styles.transitionItem} key={item}>
          <img src={customLogo} className={cn(styles.logo, logoClassName)} />
          <span className={cn(styles.transitionLabel, labelClassName)}>{i18next.t(`${source}:${item}`)}</span>
        </div>
      ))}
    </div>
  );
}

CheckList.propTypes = {
  className: string,
  customLogo: string,
  items: arrayOf(string),
  labelClassName: string,
  logoClassName: string,
  source: string
};

export default CheckList;
