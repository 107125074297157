import React from 'react';
import { string, bool, shape } from 'prop-types';
import cn from 'classnames';

import { stepsProptype } from '~propTypes/steps';
import { getPreviousSteps } from '~utils/steps';

import styles from './styles.module.scss';
import Step from './components/Step';
import { getSelectedIndex, isLastStep } from './utils';
import { getBarStyle } from './constants';

const Stepper = ({
  steps,
  selectedStep,
  className,
  customDisabled,
  stepperActions,
  disableLast,
  customBarStyle
}) => {
  const selectedStepIndex = getSelectedIndex(steps, selectedStep);
  const barStyle = getBarStyle(steps.length);
  return (
    <div className={`row full-width center middle space-evenly ${styles.container} ${className}`}>
      <div className={cn(styles.bar, barStyle, customBarStyle)} />
      {steps.map(({ icon, title, step, state, stepName }, index) => {
        const previousSteps = getPreviousSteps(steps, index);
        const isLast = isLastStep(steps, index);
        return (
          <Step
            key={title}
            icon={icon}
            title={title}
            step={step}
            state={state}
            number={index}
            selectedStep={selectedStepIndex}
            stepName={stepName}
            previousSteps={previousSteps}
            stepperActions={stepperActions}
            customDisabled={customDisabled || (disableLast && isLast)}
          />
        );
      })}
    </div>
  );
};
Stepper.propTypes = {
  className: string,
  customBarStyle: string,
  customDisabled: bool,
  disableLast: bool,
  selectedStep: string,
  stepperActions: shape,
  steps: stepsProptype
};

export default Stepper;
