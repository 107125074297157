import React, { useState } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { useShowStatusPageButton } from '~hooks/useShowStatusPageButton';
import { useRedirect } from '~hooks/useRedirect';
import { getValidCurrentTerm, hasChangedStepState, isConsumerSimulation } from '~models/simulations';
import SimulationActions from '~redux/Simulation/actions';
import { ROUTES } from '~constants/routes';
import PrimaryAndSecondaryButtons from '~components/PrimaryAndSecondaryButtons';
import MigranteContacts from '~components/MigranteContacts';

import { getStepperActions } from '../../utils';
import { DEFAULT_OPTION } from '../InstallmentsSelect/constants';
import { isFinanced } from '../InstallmentsSelect/utils';
import {
  getHaveDownpaymentOptions,
  getWhenBuyOptions
} from '../InstallmentsSelect/components/FinancedPayment/utils';

import styles from './styles.module.scss';
import { getCreditSimulationState, postDownpaymentInfoMapper } from './utils';
import SimulationHeader from './components/SimulationHeader';
import SimulationValueInfo from './components/SimulationValueInfo';

function CreditSimulationInfo() {
  const dispatch = useDispatch();
  const { quickCode, kind } = useParams();
  const {
    currentTerm,
    productType,
    updateSimulationLoading,
    changeIncumbentLoading,
    loanDraft,
    amount,
    downpayment,
    termsLoading,
    hasDownpayment: currentHasDownpayment,
    whenBuy: currentWhenBuy
  } = useSelector(state => state.simulation);
  const handleRedirect = useRedirect();
  const isConsumer = isConsumerSimulation(kind);
  const [previousState] = useState(
    getCreditSimulationState({ amount, downpayment, currentTerm, isConsumer })
  );
  const [paymentOption, setPaymentOption] = useState(DEFAULT_OPTION);
  const [hasDownpayment, setHasDownpayment] = useState({
    value: getHaveDownpaymentOptions().find(option => option.value === currentHasDownpayment) || ''
  });
  const [whenBuy, setWhenBuy] = useState({
    value: getWhenBuyOptions().find(option => option.value === currentWhenBuy) || ''
  });
  const setFields = (nameField, field) => {
    dispatch(SimulationActions.setSimulationInfo(nameField, field.value));
  };
  const handleUpdateSimulation = customStepName => () => {
    if (!hasDownpayment.value || !whenBuy.value) {
      if (!hasDownpayment.value) {
        setHasDownpayment({ ...hasDownpayment, error: i18next.t('Common:required') });
      }
      if (!whenBuy.value) {
        setWhenBuy({ ...whenBuy, error: i18next.t('Common:required') });
      }
      return;
    }

    const postAction = handleRedirect({
      customStepName: isFinanced(paymentOption) ? customStepName : ROUTES.CREDIT_CASH_SUCCESS.stepName
    });
    const actualState = getCreditSimulationState({ amount, downpayment, currentTerm, isConsumer });

    setFields('hasDownpayment', hasDownpayment.value);
    setFields('whenBuy', whenBuy.value);
    dispatch(
      SimulationActions.changeIncumbent({
        quickCode,
        values: postDownpaymentInfoMapper(hasDownpayment.value, whenBuy.value)
      })
    );

    if (hasChangedStepState(previousState, actualState)) {
      dispatch(
        SimulationActions.updateSimulation(
          quickCode,
          {
            kind,
            productType,
            ...actualState,
            term: getValidCurrentTerm(currentTerm, loanDraft),
            isFinanced: isFinanced(paymentOption)
          },
          postAction
        )
      );
    } else {
      postAction();
    }
  };
  const stepperActions = getStepperActions(handleUpdateSimulation);
  const showStatusPageButton = useShowStatusPageButton();
  return (
    <>
      <div className={styles.contentContainer}>
        <SimulationHeader kind={kind} stepperActions={stepperActions} />
        <SimulationValueInfo
          kind={kind}
          paymentOption={paymentOption}
          handlePaymentOption={setPaymentOption}
          hasDownpayment={hasDownpayment}
          setHasDownpayment={setHasDownpayment}
          whenBuy={whenBuy}
          setWhenBuy={setWhenBuy}
        />
      </div>
      <div className={styles.buttonsContactContainer}>
        <PrimaryAndSecondaryButtons
          loading={updateSimulationLoading || changeIncumbentLoading}
          disableButtons={termsLoading}
          handlePrimaryAction={handleUpdateSimulation(ROUTES.CLIENT_FLOW_CHOICE.stepName)}
          continueText={i18next.t('CreditSimulation:button')}
          hideSecondary={!showStatusPageButton}
          handleSecondaryAction={handleUpdateSimulation(ROUTES.STATUS_PAGE.stepName)}
          className={styles.kayakWidth}
        />
        <MigranteContacts className={styles.contacts} />
      </div>
    </>
  );
}

export default CreditSimulationInfo;
