import i18next from 'i18next';
import React from 'react';

import { ReactComponent as Retry } from '~assets/retry.svg';

import ErrorScreen from '../ErrorScreen';

function UniqueKeyError() {
  // TODO: Add handlePrimaryAction and enable button when integrated
  return (
    <ErrorScreen
      title={i18next.t('UniqueKeyError:title')}
      subtitle={i18next.t('UniqueKeyError:subtitle')}
      primaryButtonText={i18next.t('UniqueKeyError:button')}
      picture={Retry}
      hideSecondary
      disableButtons
    />
  );
}

export default UniqueKeyError;
