import { CLIENT_NEEDED_STEPS, GUARANTOR_NEEDED_STEPS } from '../../constants';
import { hasCompletedNeededSteps } from '../../utils';

export const hasCompletedInformation = (steps, needsGuarantor, isGuarantorView) =>
  hasCompletedNeededSteps({
    needsGuarantor,
    clientSections: isGuarantorView ? [] : CLIENT_NEEDED_STEPS,
    guarantorSections: GUARANTOR_NEEDED_STEPS,
    steps
  });

export const completeInformationMessage = isGuarantorView =>
  isGuarantorView ? 'guarantorInfoCompletedMessage' : 'infoCompletedMessage';
