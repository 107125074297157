import React from 'react';
import { useSelector } from 'react-redux';

import { DEFAULT_UK_ERROR_MESSAGES, UNIQUE_KEY_ERROR_MESSAGES, AFP_ERROR_MESSAGES } from './constants';
import styles from './styles.module.scss';

function UniqueKeyFormError() {
  const {
    scraperCmfDataError,
    scraperSiiDataError,
    scraperAfpDataError,
    scraperAllDataError,
    scraperStatusCodeError
  } = useSelector(state => state.scrapers);
  const primaryErrorMessages = scraperAfpDataError
    ? AFP_ERROR_MESSAGES[scraperStatusCodeError]
    : UNIQUE_KEY_ERROR_MESSAGES[scraperStatusCodeError];
  const errosMessages = primaryErrorMessages || DEFAULT_UK_ERROR_MESSAGES;

  return scraperCmfDataError || scraperSiiDataError || scraperAfpDataError || scraperAllDataError ? (
    <div className={styles.errorContainer}>
      <span className={styles.mainErrorTitle}>{errosMessages.MAIN_TEXT}</span>
      <span className={styles.secondaryErrorTitle}>{errosMessages.SECONDARY_TEXT}</span>
    </div>
  ) : null;
}

export default UniqueKeyFormError;
