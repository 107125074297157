import i18next from 'i18next';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SimulationLoadingWrapper from '~app/components/SimulationLoadingWrapper';
import { useIsView } from '~hooks/useIsView';
import { INCUMBENT_TYPES } from '~constants/incumbent';
import { GUARANTOR_STEPS_TARGETS, STEPS_TARGETS } from '~constants/steps';
import SimulationActions from '~redux/Simulation/actions';
import { SIMULATION_TARGETS } from '~redux/Simulation/constants';

import EndingConfirmation from '../EndingConfirmation';

function GuarantorConfirmation() {
  const dispatch = useDispatch();
  const { currentGuarantor, guarantorConfirmedEmail } = useSelector(state => state.simulation);
  const isGuarantorView = useIsView(INCUMBENT_TYPES.GUARANTOR);
  const setCurrentGuarantorEmail = newEmail => {
    dispatch(
      SimulationActions.setSimulationInfo(SIMULATION_TARGETS.CURRENT_GUARANTOR, {
        ...currentGuarantor,
        email: newEmail
      })
    );
  };
  return (
    <SimulationLoadingWrapper loading={!currentGuarantor}>
      <EndingConfirmation
        prefix={INCUMBENT_TYPES.GUARANTOR}
        title={i18next.t(`GuarantorConfirmation:${isGuarantorView ? 'thanks' : 'yes'}`)}
        firstText={i18next.t(`GuarantorConfirmation:${isGuarantorView ? 'sendEmailGuarantor' : 'sendEmail'}`)}
        secondText={i18next.t(
          `GuarantorConfirmation:${isGuarantorView ? 'confirmInfoGuarantor' : 'confirmInfo'}`
        )}
        email={currentGuarantor?.email}
        modifyEmailText={i18next.t(
          `GuarantorConfirmation:${isGuarantorView ? 'modify' : 'notGuarantorEmail'}`
        )}
        previousStep={GUARANTOR_STEPS_TARGETS.GUARANTOR_FINANCIAL_INFORMATION}
        target={STEPS_TARGETS.GUARANTOR_CONFIRMATION}
        setCurrentIncumbent={setCurrentGuarantorEmail}
        confirmedEmail={guarantorConfirmedEmail}
        confirmedEmailTarget={SIMULATION_TARGETS.GUARANTOR_CONFIRMED_EMAIL}
      />
    </SimulationLoadingWrapper>
  );
}

export default GuarantorConfirmation;
