/* eslint-disable max-lines */
import i18next from 'i18next';

import { SIMULATION_TYPES_TARGETS } from '~constants/simulations';
import { TITLE_VALUES } from '~constants/title';

i18next.addResources('es', 'Common', {
  required: 'Este campo es requerido',
  documentation: 'Documentación',
  shareLink: 'Compartir link con tu Aval',
  expired: 'Si, pero está vencida',
  university: 'Universitario',
  tertiary: 'Técnico superior / Terciario',
  intermediate: 'Bachiller / Medio',
  noDegree: 'No cuento con estudios formales',
  personalInformation: 'Personal',
  incomes: 'Ingresos y certificados AFP/SII',
  success: '¡Si, terminamos!',
  withoutInfo: 'Actualmente no cuento con esta información',
  goHome: 'Ir a Inicio',
  motorcycle: 'CRÉDITO MOTO',
  motorcycleWithoutGuarantee: 'CRÉDITO MOTO SIN AVAL',
  car: 'CRÉDITO AUTO',
  carWithoutGuarantee: 'CRÉDITO AUTO SIN AVAL',
  client: 'Titular',
  guarantor: 'Aval',
  continue: 'Continuar',
  dependent: 'Dependiente',
  independent: 'Independiente',
  dependentAndIndependent: 'Dependiente e Independiente',
  unemployed: 'No cuento con empleo formal',
  completeInfo: 'Completar datos del aval',
  IDontHaveGuarantor: 'Continuar sin Aval',
  personalEarnings: 'Liquidación de sueldo',
  personalEarningsPdf: 'PDF de la liquidación de sueldo',
  person: 'CRÉDITO PERSONAL - Emprendimiento',
  titleHeader: 'Título profesional',
  titlePdf: 'PDF del título',
  titlePdfTitle: 'PDF del título profesional',
  titlePdfDescription: 'Sube un PDF del título profesional.',
  sii: 'Servicio de Impuestos Internos',
  unemployment: 'Seguro de cesantía',
  debtFree: 'Certificado de libre deuda',
  understand: 'Entiendo',
  selectPlaceholder: 'Selecciona una opción'
});

i18next.addResources('es', 'CreditSimulation', {
  [SIMULATION_TYPES_TARGETS.PERSON]: i18next.getResource('es', 'Common', 'person'),
  [SIMULATION_TYPES_TARGETS.PERSON_WITHOUT_GUARANTEE]: i18next.getResource('es', 'Common', 'person'),
  [SIMULATION_TYPES_TARGETS.MOTORCYCLE]: i18next.getResource('es', 'Common', 'motorcycle'),
  [SIMULATION_TYPES_TARGETS.MOTORCYCLE_WITHOUT_GUARANTEE]: i18next.getResource(
    'es',
    'Common',
    'motorcycleWithoutGuarantee'
  ),
  [SIMULATION_TYPES_TARGETS.CAR]: i18next.getResource('es', 'Common', 'car'),
  [SIMULATION_TYPES_TARGETS.CAR_WITHOUT_GUARANTEE]: i18next.getResource(
    'es',
    'Common',
    'carWithoutGuarantee'
  ),
  [SIMULATION_TYPES_TARGETS.EMOBILE]: 'CRÉDITO PANEL SOLAR',
  [SIMULATION_TYPES_TARGETS.EMOBILE_WITHOUT_GUARANTEE]: 'CRÉDITO PANEL SOLAR SIN AVAL',
  [SIMULATION_TYPES_TARGETS.CELLPHONE]: 'CRÉDITO CELULAR',
  [SIMULATION_TYPES_TARGETS.CELLPHONE_WITHOUT_GUARANTEE]: 'CRÉDITO CELULAR SIN AVAL',
  [SIMULATION_TYPES_TARGETS.MOTORCYCLE_DEALER]: i18next.getResource('es', 'Common', 'motorcycle'),
  [SIMULATION_TYPES_TARGETS.MOTORCYCLE_DEALER_WITHOUT_GUARANTEE]: i18next.getResource(
    'es',
    'Common',
    'motorcycleWithoutGuarantee'
  ),
  [SIMULATION_TYPES_TARGETS.CAR_DEALER]: i18next.getResource('es', 'Common', 'car'),
  [SIMULATION_TYPES_TARGETS.CAR_DEALER_WITHOUT_GUARANTEE]: i18next.getResource('es', 'Common', 'car'),
  [SIMULATION_TYPES_TARGETS.CAR_DEALER_AMICAR]: i18next.getResource('es', 'Common', 'car'),
  [SIMULATION_TYPES_TARGETS.CAR_DEALER_AMICAR_WITHOUT_GUARANTEE]: i18next.getResource('es', 'Common', 'car'),
  label: 'Crédito por:',
  question: '¿Cómo lo pagarás?',
  installments: '{{total}} cuotas de',
  rate: 'Tasa de',
  guarantee: 'CAE de',
  button: 'Me interesa',
  quantity: 'Seleccione cantidad de cuotas',
  foot: 'Pie de {{mount}}',
  editFoot: 'Editar pie',
  editAmount: 'Editar valor total',
  confirm: 'Confirmar',
  totalValue: 'Valor total:',
  noExtraCost: 'El precio del producto puede sufrir algún cambio',
  review: 'Pie sujeto a evaluación de Galgo',
  financed: 'En cuotas',
  cash: 'Al contado',
  hasDownpaymentLabel: '¿Cuentas con el pie?',
  thisMonth: 'Este mes',
  nextMonth: 'El próximo mes',
  later: 'Más adelante',
  whenBuyLabel: '¿Cuándo quieres comprar tu producto?',
  oneWeek: 'En máximo 1 semana',
  betweenOneWeekAndOneMonth: 'Entre 1 semana y 1 mes',
  moreThanOneMonth: 'Más de 1 mes'
});

i18next.addResources('es', 'ClientIdentity', {
  aboutYou: 'Acerca de ti',
  message: 'El crédito no se efectivizará sin tu consentimiento y firma',
  emailDealer: 'Correo electrónico del referente',
  button: '¡Simula tu crédito!',
  continue: i18next.getResource('es', 'Common', 'continue'),
  hasErrors: 'Hay un problema con la información,\npor favor actualice la página y vuelva a cargarlos.',
  aboutClient: 'Acerca del cliente',
  aboutYouDealer: 'Acerca de ti (vendedor)',
  welcome: '¡Bienvenidos!',
  toForm: 'Al formulario',
  migranteEvaluation: 'de evaluación Galgo',
  startWith: 'Comienza con',
  firstStep: 'el primer paso',
  toObtain: 'para conseguir\ntu Auto o Moto.',
  aboutSimulation: '¿Tienes dudas?',
  completeForm: 'Completar formulario',
  stepOne: 'Completa el formulario para simular tu crédito',
  makeEasy: 'Hazlo fácil con tu',
  speedUp: 'para acelerar tu evaluación',
  stepThree: '¡Te contactaremos en muy poco tiempo!'
});

i18next.addResources('es', 'BirthInformation', {
  title: 'Tus datos personales',
  message: 'Recuerda que tus datos deben coincidir con tu documento de identidad.',
  hasErrors: 'Hay un problema con la información,\npor favor actualice la página y vuelva a cargarlos.',
  button: 'Continuar'
});

i18next.addResources('es', 'LaborInformation', {
  title: 'Información laboral',
  hasErrors: 'Hay un problema con la información,\npor favor actualice la página y vuelva a cargarlos.',
  button: 'Continuar'
});

i18next.addResources('es', 'LaborInformationMessage', {
  dependent: ', debes ingresar la fecha de ingreso a tu empleo actual.',
  independent: ', debes ingresar la fecha desde que estás generando boletas.',
  deliveryWorkerApp: ', debes ingresar la fecha desde que estás haciendo entregas con tu APP delivery.',
  dependentAndIndependent:
    ', debes ingresar la fecha de inicio y el ingreso mensual del trabajo que represente tu mayor fuente de ingresos',
  unemployed: ', lamentamos saber eso, te invitamos a continuar avanzando en el proceso.'
});

i18next.addResources('es', 'LaborInformationMessageTitle', {
  dependent: 'Dependiente',
  independent: 'Independiente',
  deliveryWorkerApp: 'Delivery',
  dependentAndIndependent: 'Mixto',
  unemployed: 'Desempleado'
});

i18next.addResources('es', 'IdentityFields', {
  name: 'Nombre y apellido',
  email: 'Correo electrónico',
  phone: 'Teléfono',
  rut: 'RUT'
});

i18next.addResources('es', 'IdentityFormErrors', {
  required: i18next.getResource('es', 'Common', 'required'),
  invalid: 'El {{field}} ingresado es inválido',
  invalidPhoneFormat: 'El formato del teléfono debe ser 56 9 XX XXX XXX',
  repeatedRut: 'El RUT ingresado debe ser distinto al del titular',
  invalidLength: 'La longitud del RUT es inválida'
});

i18next.addResources('es', 'BirthInformationFields', {
  gender: 'Sexo',
  birthday: 'Fecha de Nacimiento (DD/MM/AAAA)',
  nationality: 'Nacionalidad',
  female: 'Femenino',
  male: 'Masculino',
  VE: 'Venezuela',
  CL: 'Chile',
  CO: 'Colombia',
  PE: 'Perú',
  HT: 'Haití',
  DO: 'Rep. Dominicana',
  EC: 'Ecuador',
  BO: 'Bolivia',
  CU: 'Cuba',
  BR: 'Brasil',
  AR: 'Argentina',
  other: 'Otro'
});

i18next.addResources('es', 'LaborInformationFields', {
  select: 'Selecciona una opción',
  laborSituation: '¿Cuál es tu situación laboral?',
  emittedInvoicesLastYear: 'Boletas emitidas el último año',
  monthlyIncomes: 'Ingreso mensual líquido',
  jobStartDate: 'Antigüedad laboral (DD/MM/AAAA)',
  dependent: 'Dependiente',
  independent: 'Independiente',
  deliveryWorkerApp: 'Delivery (Trabajador APP)',
  dependentAndIndependent: 'Mixto (Dependiente e independiente)',
  unemployed: 'Desempleado',
  lessThanThree: 'Menos de 3',
  lessThanSix: 'Menos de 6',
  moreThanSix: 'Más de 6',
  none: 'No emito boletas'
});

i18next.addResources('es', 'LaborInformationJobStartDate', {
  dependent: 'Fecha de ingreso a empleo actual',
  independent: 'Fecha de inicio de actividades',
  deliveryWorkerApp: 'Fecha de inicio laboral en aplicaciones',
  dependentAndIndependent: 'Fecha de inicio en trabajo principal',
  unemployed: 'Antigüedad laboral (DD/MM/AAAA)',
  nan: 'Antigüedad laboral (DD/MM/AAAA)'
});

i18next.addResources('es', 'BirthInformationFormErrors', {
  required: i18next.getResource('es', 'Common', 'required'),
  invalid: 'La fecha ingresada es inválida',
  invalidBirthday: 'La fecha ingresada es inválida'
});

i18next.addResources('es', 'LaborInformationFormErrors', {
  required: i18next.getResource('es', 'Common', 'required'),
  invalidDate: 'La fecha ingresada es inválida',
  min: 'Los ingresos mensuales deben ser mayores a cero.'
});

i18next.addResources('es', 'AdvancePaymentFormErrors', {
  required: i18next.getResource('es', 'Common', 'required'),
  max: 'El pie no debe superar el 80% del valor total',
  min: 'El pie debe ser mayor al {{value}} del valor total'
});

i18next.addResources('es', 'AmountFormErrors', {
  required: i18next.getResource('es', 'Common', 'required'),
  max: 'El valor total no puede ser mayor a {{value}}',
  min: 'El valor total no puede ser menor a {{value}}'
});

i18next.addResources('es', 'CreditHeader', {
  person: 'Crédito Personal:',
  for: '{{type}} por:',
  foot: 'Pie de ',
  terms: '{{terms}} cuotas de:'
});

i18next.addResources('es', 'IncomesForm', {
  incomes: '¿Cuáles son tus ingresos mensuales?',
  guarantorIncomes: '¿Cuáles son sus ingresos mensuales?',
  infoLaborSituation1: 'Como trabajador ',
  infoLaborSituation2: ' necesitaremos la siguiente información:',
  employmentSituation: '¿Cuál es tu situación laboral?',
  guarantorEmploymentSituation: '¿Cuál es su situación laboral?',
  employmentSituationPlaceholder: 'Situación laboral',
  dependent: i18next.getResource('es', 'Common', 'dependent'),
  independent: i18next.getResource('es', 'Common', 'independent'),
  dependentAndIndependent: i18next.getResource('es', 'Common', 'dependentAndIndependent'),
  unemployed: i18next.getResource('es', 'Common', 'unemployed'),
  selectOne: 'Selecciona una',
  remember: 'Recordatorio',
  missingInfo: 'Falta completar parte de la información pero no te preocupes, ',
  complete: 'puedes continuar y completarla luego.',
  understand: i18next.getResource('es', 'Common', 'understand')
});

i18next.addResources('es', 'IncomesFormErrors', {
  required: i18next.getResource('es', 'Common', 'required'),
  min: 'Los ingresos mensuales deben ser mayores a cero.'
});

i18next.addResources('es', 'PrimaryAndSecondaryButtons', {
  continue: i18next.getResource('es', 'Common', 'continue'),
  back: 'Atrás',
  goToStatusPage: 'Ver mi información'
});

i18next.addResources('es', 'CertificateCard', {
  title: 'Necesitaremos {{certificate}}',
  titleAFP: 'el certificado de tu AFP',
  titleSII: 'tus boletas de SII',
  titleCMF: 'el certificado CMF',
  firstInfo: 'Para agilizar el proceso, puedes ',
  info: 'conectarte con {{scraper}}',
  infoAFP: 'tu AFP',
  infoSII: 'el SII',
  infoCMF: 'Clave Única',
  secondInfo: ' de forma segura.',
  connect: 'Conectar con {{scraper}}',
  manually: 'Cargar manualmente',
  withoutInfo: i18next.getResource('es', 'Common', 'withoutInfo'),
  perfect: 'Perfecto, hemos recibido la información, puedes continuar con el proceso.',
  resendFiles: 'Volver a subir archivos',
  firstManualInfo: 'Debes cargar ',
  manualInfoSII: 'tus boletas',
  manualInfoCMF: 'tu certificado',
  secondManualInfo: ' manualmente, ya que superaste el límite de intentos de tu Clave Única.'
});

i18next.addResources('es', 'ThankYou', {
  redirect: 'Redirigiendo...'
});

i18next.addResources('es', 'EmailModal', {
  editEmail: 'Modificar mail',
  email: 'Mail',
  confirm: 'Confirmar'
});

i18next.addResources('es', 'EndingConfirmation', {
  yes: i18next.getResource('es', 'Common', 'success'),
  sendEmail: 'Enviaremos el resumen al mail ',
  confirmInfo: 'para que confirmes la información.',
  continue: i18next.getResource('es', 'Common', 'continue'),
  notMyEmail: 'No es mi mail'
});

i18next.addResources('es', 'EndingAlert', {
  titleFirstPart: '¡Ahora somos Galgo! ',
  descriptionFirstPart: 'Cambiamos nuestro nombre de ',
  descriptionSecondtPart: 'Migrante a galgo.',
  descriptionThirdPart:
    'No te preocupes, seguimos siendo los mismos, solo que ahora nos llamamos de otra manera.',
  descriptionFourPart: '¡Nos movemos contigo!'
});

i18next.addResources('es', 'Steps', {
  clientSimulator: 'Crédito',
  clientIncomes: 'Ingresos',
  clientPersonalDocumentation: i18next.getResource('es', 'Common', 'documentation'),
  clientConfirmation: 'Aprobación',
  guarantorIdentity: 'Identidad',
  guarantorIncomes: 'Ingresos',
  guarantorPersonalDocumentation: i18next.getResource('es', 'Common', 'documentation'),
  guarantorConfirmation: 'Confirmación',
  clientBankAccount: 'Bancarios'
});

i18next.addResources('es', 'FileUploaderModal', {
  save: 'Guardar',
  error: 'Hubo un error en la carga de archivos,\npor favor intenta subirlo nuevamente.',
  unableToUploadFilesError: 'Alguno de los archivos falló al cargar,\nintenta subirlos nuevamente.',
  imageOrPDF: 'Imagen o PDF',
  updated: 'Actualizado de los últimos 24 meses',
  maxSize: 'Peso máximo de 10 MB',
  professionalTitle: 'Título profesional',
  professionalInfo: ' de tu título profesional',
  rutTitle: '{{side}} de cédula de identidad',
  rutPrimaryInfo: ' del ',
  rutSecondaryInfo: ' de tu cédula.',
  visaTitle: 'Trámite migratorio',
  visaInfo: ' de tu trámite migratorio',
  afpTitle: 'Carga manual de tu AFP',
  afpInfo: ' del certificado de cotizaciones de tu AFP',
  siiTitle: 'Carga manual del SII',
  siiInfo: ' de las boletas del SII',
  cmfTitle: 'Carga manual del certificado',
  maxSizeError: 'Algunos archivos son demasiado grandes,\nel peso máximo debe ser de 10 MB. ',
  personalEarningsTitle: i18next.getResource('es', 'Common', 'personalEarnings'),
  personalEarningsInfo: ' de la última liquidación.'
});

i18next.addResources('es', 'FileUploader', {
  uploadFile: 'Subir archivo',
  uploadFile_plural: 'Subir archivos'
});

i18next.addResources('es', 'DocumentationUploaderHeader', {
  limit: 'El archivo debe tener un límite de 10 MB.'
});

i18next.addResources('es', 'LetsStart', {
  title: '¡Has alcanzado el máximo de intentos con tu Clave Única!',
  laboralDocuments: 'Agiliza tu solicitud ingresando la información manualmente:',
  personalInformation: i18next.getResource('es', 'Common', 'personalInformation'),
  incomes: 'Información de ingresos',
  documentation: 'Documentos de identidad',
  siiOrAfp: 'Carga de certificado AFP/SII.',
  cmf: 'Carga de la CMF.',
  aditionalInformation: 'Infomación adicional.'
});

i18next.addResources('es', 'ClientConfirmation', {
  titleWithoutGuarantor: i18next.getResource('es', 'Common', 'success'),
  sendEmailWithoutGuarantor: 'Enviaremos el resumen al mail ',
  confirmInfoWithoutGuarantor: 'para que confirmes la información.',
  veryGood: '¡Muy bien!'
});

i18next.addResources('es', 'ContinueWithGuarantor', {
  letsContinue: '¡Sigamos!',
  preapproved: 'Tu crédito esta pre aprobado',
  almostThere: 'Ya falta poco',
  toContinue: 'Vamos a necesitar la siguiente\ninformación de tu ',
  guarantor: 'Aval',
  whatIsAGuarantor: '¿Qué es un Aval?',
  guarantorData: 'Documentación\n requerida del ',
  personalInformation: i18next.getResource('es', 'Common', 'personalInformation'),
  incomes: i18next.getResource('es', 'LetsStart', 'incomes'),
  documentation: i18next.getResource('es', 'LetsStart', 'documentation'),
  noGuarantorWarningTitle: 'Necesitas de un Aval',
  continueWithGuarantor: 'Completar información',
  IDontHaveGuarantor: i18next.getResource('es', 'Common', 'IDontHaveGuarantor'),
  firstNoGuarantorWarningInfo: '¡El ',
  secondNoGuarantorWarningInfo: ' es de vital importancia a la hora de aprobar una solicitud de crédito!',
  noGuarantorWarningButton: 'Finalizar proceso sin aval',
  completeIncomes: 'Completar ingresos',
  emailModalTitle: 'Confirma tu mail',
  shareLink: i18next.getResource('es', 'Common', 'shareLink'),
  shareModalTitle: 'Compartir con Aval',
  shareModalSubtitle: 'Enviale el link a tu Aval para que termine de completar su información personal.',
  yes: '¡Si!',
  named: 'Te han nombrado Aval',
  neededInfo: 'Para completar la solicitud del crédito Galgo, vamos a necesitar:',
  complete: 'Completar'
});

i18next.addResources('es', 'GuarantorInfoModal', {
  title: '¿Qué es un Aval?',
  info: 'Es la persona que garantizará el pago del crédito en caso de que el titular no pueda pagarlo.'
});

i18next.addResources('es', 'Error', {
  mainTitle: 'Problemas en el servidor',
  mainSubtitle: 'La información no pudo actualizarse correctamente,\nvuelve a intentarlo más tarde.',
  reload: 'Volver a cargar',
  goHome: i18next.getResource('es', 'Common', 'goHome')
});

i18next.addResources('es', 'GuarantorConfirmation', {
  yes: i18next.getResource('es', 'Common', 'success'),
  thanks: '¡Gracias!',
  sendEmailGuarantor: 'Enviaremos la información al titular y a tu mail ',
  sendEmail: 'Enviaremos un resumen al mail de tu Aval ',
  confirmInfo: 'para que confirme su información.',
  confirmInfoGuarantor: 'para corroborar los datos.',
  goHome: i18next.getResource('es', 'Common', 'goHome'),
  modify: 'Modificar mail',
  notGuarantorEmail: 'No es el mail de mi Aval'
});

i18next.addResources('es', 'GuarantorIdentityForm', {
  title: 'Información personal del',
  specialText: 'Aval'
});

i18next.addResources('es', 'GuarantorIdentityFormErrors', {
  repeatedRut: 'El RUT ingresado debe ser distinto al del titular'
});

i18next.addResources('es', 'GuarantorShareableLink', {
  info: 'Puedes compartirle este link a tu Aval para que complete el siguiente formulario.',
  button: i18next.getResource('es', 'Common', 'shareLink'),
  link: 'Puedes ayudarme a sacar mi Crédito Galgo completando tu información como Aval en este link: {{link}}'
});

i18next.addResources('es', 'ShareLinkButton', {
  button: i18next.getResource('es', 'Common', 'shareLink')
});

i18next.addResources('es', 'ShareLinkModal', {
  modalTitle: 'Compartir con Aval',
  modalSubtitle: 'Enviale el link a tu Aval para que termine de completar su información personal.',
  clipboard: 'Copiar vínculo',
  whatsapp: 'WhatsApp',
  linkCopied: 'Vínculo copiado'
});

i18next.addResources('es', 'GuarantorIncomes', {
  title: 'Ingresos del',
  specialText: 'Aval'
});

i18next.addResources('es', 'StatusPage', {
  title: 'Crédito Galgo',
  goHome: i18next.getResource('es', 'Common', 'goHome'),
  client: i18next.getResource('es', 'Common', 'client'),
  guarantor: i18next.getResource('es', 'Common', 'guarantor'),
  clientQuotesInformation: 'Cuotas',
  clientBirthInformation: 'Datos personales',
  clientLaborInformation: 'Información Laboral',
  clientFinancialInformation: 'Ingresos',
  clientPersonalDocumentation: i18next.getResource('es', 'Common', 'documentation'),
  guarantorPersonalInformation: 'Información personal',
  guarantorBirthInformation: 'Datos personales',
  guarantorLaborInformation: 'Información Laboral',
  guarantorFinancialInformation: 'Ingresos',
  message: '¡Descarga la app y sigue el avance de\ntu crédito en tiempo real!',
  guarantorPersonalDocumentation: i18next.getResource('es', 'Common', 'documentation'),
  documentationButton: 'Completar información',
  editDocumentationButton: 'Editar documentación',
  emailMessage: 'Revisa tu correo para finalizar la solicitud',
  boldEmailMessage: 'o complétala ahora mismo.',
  editYourDocsHere: 'Puedes editar tu documentación personal aquí:',
  completeGuarantor: 'Falta completar información de tu ',
  shareLinkButton: i18next.getResource('es', 'Common', 'shareLink'),
  completeInfo: i18next.getResource('es', 'Common', 'completeInfo'),
  shareLinkTitle: 'Enviar el formulario al Aval',
  shareLinkInfo: 'Comparte este enlace con tu Aval\npara que complete su información.',
  modalTitle: 'Estado de tu crédito',
  modalTitleGuarantor: 'Estado del crédito',
  modalInfo: 'Aquí podrás ver el estado\nde tu crédito, la información\ncargada y la faltante.',
  modalInfoGuarantor: 'Aquí podrás ver y modificar\n las secciones completadas.',
  modalButton: i18next.getResource('es', 'Common', 'understand'),
  infoCompletedMessage: '¡Gracias por completar tu información!\nUn ejecutivo se contactará contigo.',
  guarantorInfoCompletedMessage:
    '¡Gracias por completar tu información!\nTe contactaremos solo de ser necesario.',
  infoNotCompletedMessage: 'Acelera el proceso\ncompletando la información faltante.'
});

i18next.addResources('es', 'NotFoundScreen', {
  title: '¡Recalculando!',
  subtitle: 'No encontramos la página que buscas,\npuedes ir al inicio y obtener tu crédito Galgo.'
});

i18next.addResources('es', 'ProductNotAvailable', {
  title: 'Lo sentimos,\nproducto no disponible',
  subtitle: 'Revisa nuestro catálogo\ny encuentra lo que necesitas',
  button: 'Ir a catálogo'
});

i18next.addResources('es', 'ContinueWithDocumentation', {
  title: '¡Sigamos!',
  subtitle: 'Obtén tu crédito más rápido\ncompletando los siguientes datos:',
  identity: 'Cédula de identidad o trámite migratorio.',
  professional: 'Título profesional.',
  address: 'Comprobante de domicilio.',
  bankAccount: 'Cuenta bancaria.',
  button: 'Empezar'
});

i18next.addResources('es', 'DocumentationWrapper', {
  subtitle: 'Tu información será de uso interno,\ny nunca será revelada a terceros.'
});

i18next.addResources('es', 'PersonalDocumentationForm', {
  select: 'Elige una opción',
  identityQuestion: '¿Tienes cédula de identidad?',
  identity: 'Cédula de Identidad',
  yes: 'Si',
  no: 'No',
  expired: i18next.getResource('es', 'Common', 'expired'),
  front: 'Foto de frente de la cédula',
  back: 'Foto dorso de la cédula',
  immigration: 'Trámite Migratorio',
  immigrationPdf: 'PDF del trámite',
  frontTitle: 'Cédula de identidad frente',
  frontDescription: 'Sube una imagen del frente de la cédula de identidad.',
  backTitle: 'Cédula de identidad dorso',
  backDescription: 'Sube una imagen del dorso de la cédula de identidad.',
  immigrationPdfTitle: 'PDF del trámite migratorio',
  immigrationPdfDescription: 'Sube un PDF del trámite migratorio.',
  titleQuestion: 'Puedes indicarnos tu nivel de estudio',
  title: 'Nivel de estudios realizados',
  [TITLE_VALUES.NO_DEGREE]: 'Básico / Sin estudios',
  [TITLE_VALUES.INTERMEDIATE]: i18next.getResource('es', 'Common', 'intermediate'),
  [TITLE_VALUES.TERTIARY]: i18next.getResource('es', 'Common', 'tertiary'),
  [TITLE_VALUES.UNIVERSITY]: i18next.getResource('es', 'Common', 'university'),
  titleHeader: i18next.getResource('es', 'Common', 'titleHeader'),
  titlePdf: i18next.getResource('es', 'Common', 'titlePdf'),
  titlePdfTitle: i18next.getResource('es', 'Common', 'titlePdfTitle'),
  titlePdfDescription: i18next.getResource('es', 'Common', 'titlePdfDescription'),
  personalEarnings: i18next.getResource('es', 'Common', 'personalEarnings'),
  personalEarningsPdf: i18next.getResource('es', 'Common', 'personalEarningsPdf'),
  personalEarningsPdfTitle: i18next.getResource('es', 'Common', 'personalEarningsPdf'),
  personalEarningsPdfDescription: 'Sube un PDF de la liquidación de sueldo.'
});

i18next.addResources('es', 'GuarantorPersonalDocumentationForm', {
  select: 'Elige una opción',
  identityQuestion: '¿Tiene cédula de identidad?',
  identity: 'Cédula de Identidad',
  yes: 'Si',
  no: 'No',
  expired: i18next.getResource('es', 'Common', 'expired'),
  front: 'Foto de frente de la cédula',
  back: 'Foto dorso de la cédula',
  immigration: 'Trámite Migratorio',
  immigrationPdf: 'PDF del trámite',
  frontTitle: 'Cédula de identidad frente',
  frontDescription: 'Sube una imagen del frente de la cédula de identidad.',
  backTitle: 'Cédula de identidad dorso',
  backDescription: 'Sube una imagen del dorso de la cédula de identidad.',
  immigrationPdfTitle: 'PDF del trámite migratorio',
  immigrationPdfDescription: 'Sube un PDF del trámite migratorio.',
  titleQuestion: 'Puedes indicarnos su nivel de estudio',
  title: 'Nivel de estudios realizados',
  [TITLE_VALUES.NO_DEGREE]: 'Básico / Sin estudios',
  [TITLE_VALUES.INTERMEDIATE]: i18next.getResource('es', 'Common', 'intermediate'),
  [TITLE_VALUES.TERTIARY]: i18next.getResource('es', 'Common', 'tertiary'),
  [TITLE_VALUES.UNIVERSITY]: i18next.getResource('es', 'Common', 'university'),
  titleHeader: i18next.getResource('es', 'Common', 'titleHeader'),
  titlePdf: i18next.getResource('es', 'Common', 'titlePdf'),
  titlePdfTitle: i18next.getResource('es', 'Common', 'titlePdfTitle'),
  titlePdfDescription: i18next.getResource('es', 'Common', 'titlePdfDescription'),
  personalEarningsPdf: i18next.getResource('es', 'Common', 'personalEarningsPdf')
});

i18next.addResources('es', 'ClientPersonalDocumentationErrors', {
  required: i18next.getResource('es', 'Common', 'required')
});

i18next.addResources('es', 'GuarantorPersonalDocumentationErrors', {
  required: i18next.getResource('es', 'Common', 'required')
});

i18next.addResources('es', 'DocumentationCard', {
  upload: 'Subir',
  withoutInfo: i18next.getResource('es', 'Common', 'withoutInfo'),
  select: 'Subir archivos',
  error: 'El archivo no se subió correctamente',
  optional: '(Opcional)'
});

i18next.addResources('es', 'ConfidentialInfoForm', {
  addressLabel: '¿Dónde resides en Chile?',
  addressInputLabel: 'Ingresa tu dirección',
  floor: 'Agrega piso y departamento',
  required: i18next.getResource('es', 'Common', 'required'),
  select: 'Elige una opción',
  continue: i18next.getResource('es', 'Common', 'continue'),
  civilStatusQuestion: '¿Cúal es tu estado civil?',
  civilStatus: 'Estado civil',
  single: 'Soltero/a',
  married: 'Casado/a',
  divorced: 'Divorciado/a',
  widower: 'Viudo/a'
});

i18next.addResources('es', 'ClientConfidentialInfoErrors', {
  required: i18next.getResource('es', 'Common', 'required')
});

i18next.addResources('es', 'MigranteContacts', {
  contactText: 'Puedes contactarte con nuestros asesores llamando al',
  whatsAppMessage: 'Si tienes algún inconveniente, contáctate con nuestros asesores haciendo',
  whatsAppButtonText: 'WhatsApp',
  whatsappAfterText: 'o llama al',
  phoneMessage: 'También puedes llamar al',
  phoneButtonText: '{{phone}}'
});

i18next.addResources('es', 'MoreInfoModal', {
  continue: i18next.getResource('es', 'Common', 'continue')
});

i18next.addResources('es', 'ContinueGuarantorDocsButtons', {
  shareLink: i18next.getResource('es', 'Common', 'shareLink'),
  completeLater: 'Completar más tarde',
  shareTitle: 'Compartir con Aval',
  shareSubtitle: 'Enviale el link a tu Aval para que termine de\ncompletar su informacion.',
  link: '¡Falta muy poco! Necesito que completes la siguiente información: {{link}}'
});

i18next.addResources('es', 'ContinueWithGuarantorDocs', {
  title: '¡Muy bien!',
  subtitle: 'Tu contrato ya está casi listo',
  info: 'Solo te queda completar\nlos datos de tu aval',
  titleGuarantor: '¡Último paso!',
  subtitleGuarantor: 'El contrato ya está casi listo',
  infoGuarantor: 'Solo faltan completar\nalgunos datos personales'
});

i18next.addResources('es', 'GuarantorDocsInformation', {
  subtitle: 'A continuación vamos a necesitar\ninformación de tu aval:',
  subtitleGuarantor: 'A continuación vamos a necesitar:',
  continueButtonTextGuarantor: 'Completar',
  identity: 'Cédula de identidad o trámite migratorio',
  professional: 'Título profesional',
  address: 'Comprobante de domicilio',
  errorTitle: 'Recordatorio',
  errorInfo:
    'Puedes terminar de completar la ficha más tarde. Pero recuerda que es esencial para obtener tu crédito.',
  errorButtonText: i18next.getResource('es', 'Common', 'understand')
});

i18next.addResources('es', 'ClientBankAccount', {
  holder: 'Acerca del titular de la cuenta',
  name: 'Nombre y apellido',
  rut: 'RUT',
  bank: 'Banco',
  bankEntity: 'Entidad bancaria',
  bankName: 'Nombre del Banco',
  selectOne: 'Elige uno',
  accountType: 'Tipo de cuenta',
  accountNumber: 'Número de la cuenta',
  currentAccount: 'Cuenta Corriente',
  rutAccount: 'Cuenta Rut/Vista',
  savingsAccount: 'Cuenta Ahorro',
  chile: 'Banco de Chile',
  international: 'Banco Internacional',
  state: 'Banco de Estado',
  other: 'Otro'
});

i18next.addResources('es', 'ClientBankAccountFormErrors', {
  required: i18next.getResource('es', 'Common', 'required')
});

i18next.addResources('es', 'ContinueWithBankAccount', {
  title: '¡Último paso!',
  info: 'Solicitaremos información de tu cuenta\nbancaría para la acreditación del dinero.'
});

i18next.addResources('es', 'EndingDocumentation', {
  title: i18next.getResource('es', 'Common', 'success'),
  subtitle: '¡Felicidades, un ejecutivo se contactará\ncontigo para continuar con el proceso!',
  goHome: i18next.getResource('es', 'Common', 'goHome')
});

i18next.addResources('es', 'GuarantorPersonalDocumentation', {
  title: 'Documentación del',
  specialText: 'Aval'
});

i18next.addResources('es', 'ErrorModal', {
  continue: i18next.getResource('es', 'Common', 'continue')
});

i18next.addResources('es', 'PastSimulationModal', {
  title: '¡Hemos encontrado una\nsolicitud de crédito para este RUT!',
  subtitle: 'Detalle de la solicitud:',
  continue: 'Continuar simulación',
  createNew: 'Crear una nueva',
  question: '¿Qué deseas hacer?'
});

i18next.addResources('es', 'GoogleAnalytics', {
  withGuarantor: 'Con aval',
  withoutGuarantor: 'Sin aval'
});

i18next.addResources('es', 'FailedLoadingTerms', {
  title: 'Carga de datos fallida',
  subtitle: 'Estamos procesando muchos créditos,\ninténtalo nuevamente.'
});

i18next.addResources('es', 'DebtFreeCertificate', {
  title: 'Certificado de libre deuda',
  itemText: ' de tu certificado CMF',
  link: 'Descarga tu certificado de la CMF '
});

i18next.addResources('es', 'FileUploaderModalItem', {
  here: 'aquí'
});

i18next.addResources('es', 'FlowChoice', {
  complete: 'Completar solicitud',
  with: 'con',
  manually: 'manualmente',
  timeLabel: '{{time}} min.',
  differences: 'Conoce las diferencias',
  differencesTitle: 'Diferencias',
  understand: i18next.getResource('es', 'Common', 'understand'),
  rut: 'Ingresas tu RUT y clave única',
  avoid: 'Evitas subida de archivos',
  protect: 'Nos encargamos de recopilar y proteger tus datos',
  afpSii: 'Subida de certificado AFP/SII.',
  afp: 'Certificado AFP',
  cmf: 'Informe CFM',
  identity: 'Cédula de identidad',
  unique: 'Completar con',
  manual: 'Completar manualmente',
  mustUpload: 'Deberás subir',
  needed: 'Necesitarás el respaldo de un ',
  guarantor: 'Aval'
});

i18next.addResources('es', 'ClientFlowChoice', {
  title: '¡Vamos por tu {{product}}!'
});

i18next.addResources('es', 'GuarantorFlowChoice', {
  title: 'Necesitaremos más datos'
});

i18next.addResources('es', 'NavBar', {
  guarantor: 'Información Aval'
});

i18next.addResources('es', 'CreditEndingStatus', {
  summaryButton: 'Ver resumen'
});

i18next.addResources('es', 'CreditApproved', {
  title: '¡Tu crédito está aprobado!',
  info: 'Te enviaremos el resumen\na tu WhatsApp'
});

i18next.addResources('es', 'CreditPending', {
  title: 'Tu crédito está pendiente\nde aprobación',
  info: 'A continuación puedes ver\nel detalle del crédito'
});

i18next.addResources('es', 'CreditCashSuccess', {
  title: '¡Listo!\nTu solicitud fue enviada',
  info: 'Nos estaremos contactando\na la brevedad.',
  summaryButton: 'Volver a web Galgo'
});

i18next.addResources('es', 'UniqueKeyError', {
  title: 'Hubo un problema',
  subtitle: 'La verificación de tus datos falló,\npor favor intenta nuevamente.',
  button: 'Volver a intentar'
});

i18next.addResources('es', 'UniqueKeyLoading', {
  title: '¡Excelente!',
  subtitle: 'Aguarda mientras verificamos\ntu información...'
});

i18next.addResources('es', 'UniqueKeyScraper', {
  subtitle: 'Su información está protegida y la usaremos\núnicamente para la solicitud del crédito.',
  afpSectionTitle: 'Seleccione AFP',
  rutLabel: 'RUT',
  passwordLabel: 'Contraseña',
  rutSectionTitle: 'Ingrese su RUT',
  passwordSectionTitle: 'Ingrese su clave única',
  passwordAlternativeTitle: 'Ingrese su clave',
  forgotPassword: '¿Has olvidado tu contraseña?',
  continue: i18next.getResource('es', 'Common', 'continue'),
  required: i18next.getResource('es', 'Common', 'required'),
  invalidCredentials: 'El RUT y/o la contraseña no coinciden.',
  retry: 'Verifica los datos e inténtalo nuevamente',
  connection: 'Parece que hemos tenido un problema de conexión.',
  afpBlocked: 'La AFP ingresada se encuentra bloqueada.',
  afpNotFound: 'Parece que no te encuentras registrado en esta AFP.',
  retryLater: 'Inténtalo más tarde'
});

i18next.addResources('es', 'PrivacyPolicies', {
  firstPart: 'Al continuar estoy aceptando las ',
  privacyPolicies: 'políticas de privacidad',
  secondPart: ' de Galgo y autorizo el uso de mi clave única, con el fin de ser evaluado comercialmente. ',
  evaluationPolicies: 'Criterios de evaluación'
});

i18next.addResources('es', 'CretificateScraper', {
  title: 'Conecta con tu AFP',
  subtitle: 'Ingrese sus datos',
  passwordSectionTitle: 'Ingrese su contraseña'
});

i18next.addResources('es', 'ContinueWithGuarantorUniqueKey', {
  title: '¡Muy bien!\nAhora nos toca a nosotros',
  firstText: 'Necesitarás de un ',
  secondText: ' para finalizar el proceso',
  guarantor: i18next.getResource('es', 'Common', 'guarantor'),
  shareLink: i18next.getResource('es', 'Common', 'shareLink'),
  completeInfo: i18next.getResource('es', 'Common', 'completeInfo'),
  IDontHaveGuarantor: i18next.getResource('es', 'Common', 'IDontHaveGuarantor')
});

i18next.addResources('es', 'Maintenance', {
  title: 'Estamos realizando\nun mantenimiento',
  subtitle: '¡Muy pronto estaremos contigo!',
  button: 'Ir al inicio'
});

i18next.addResources('es', 'FlowChanger', {
  go: 'Ir'
});

i18next.addResources('es', 'ManualToAutomatic', {
  title: 'Ahorra tiempo ingresando\n',
  withYour: 'con tu'
});

i18next.addResources('es', 'AutomaticToManual', {
  title: 'Puedes ingresar toda tu\ninformación manualmente.'
});

i18next.addResources('es', 'ClientUniqueKeySuccess', {
  title: '¡Genial, falta muy poco!',
  transitionTitle: 'Hemos verificado\nla siguiente información:',
  sii: i18next.getResource('es', 'Common', 'sii'),
  unemployment: i18next.getResource('es', 'Common', 'unemployment'),
  debtFree: i18next.getResource('es', 'Common', 'debtFree')
});
