import {
  CLIENT_DOC_STEPS_TARGETS,
  CLIENT_STEPS_TARGETS,
  GUARANTOR_DOC_STEPS_TARGETS,
  GUARANTOR_STEPS_TARGETS,
  STEPS_STATES
} from '~constants/steps';

export const STEPS_TARGETS = {
  ...CLIENT_STEPS_TARGETS,
  ...GUARANTOR_STEPS_TARGETS,
  ...CLIENT_DOC_STEPS_TARGETS,
  ...GUARANTOR_DOC_STEPS_TARGETS
};

export const STEPS_DEFAULT_STATE = {
  [STEPS_TARGETS.CLIENT_PERSONAL_INFORMATION]: STEPS_STATES.UNCOMPLETED,
  [STEPS_TARGETS.CLIENT_BIRTH_INFORMATION]: STEPS_STATES.UNCOMPLETED,
  [STEPS_TARGETS.CLIENT_LABOR_INFORMATION]: STEPS_STATES.UNCOMPLETED,
  [STEPS_TARGETS.CLIENT_QUOTES_INFORMATION]: STEPS_STATES.UNCOMPLETED,
  [STEPS_TARGETS.CLIENT_FINANCIAL_INFORMATION]: STEPS_STATES.UNCOMPLETED,
  [STEPS_TARGETS.CLIENT_PERSONAL_DOCUMENTATION]: STEPS_STATES.UNCOMPLETED,
  [STEPS_TARGETS.GUARANTOR_PERSONAL_INFORMATION]: STEPS_STATES.UNCOMPLETED,
  [STEPS_TARGETS.GUARANTOR_BIRTH_INFORMATION]: STEPS_STATES.UNCOMPLETED,
  [STEPS_TARGETS.GUARANTOR_LABOR_INFORMATION]: STEPS_STATES.UNCOMPLETED,
  [STEPS_TARGETS.GUARANTOR_FINANCIAL_INFORMATION]: STEPS_STATES.UNCOMPLETED,
  [STEPS_TARGETS.GUARANTOR_PERSONAL_DOCUMENTATION]: STEPS_STATES.UNCOMPLETED,
  [STEPS_TARGETS.CLIENT_CONFIDENTIAL_INFO]: STEPS_STATES.UNCOMPLETED,
  [STEPS_TARGETS.CLIENT_BANK_ACCOUNT]: STEPS_STATES.UNCOMPLETED,
  [STEPS_TARGETS.GUARANTOR_CONFIDENTIAL_INFO]: STEPS_STATES.UNCOMPLETED
};
