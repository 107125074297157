import { INCUMBENT_IDENTITY_TARGETS, INCUMBENT_TYPES, INCUMBENT_TYPES_NAMES } from '~constants/incumbent';
import { isTrue } from '~utils/boolean';
import { addPrefix, snakeToCamelCase } from '~utils/string';
import DocumentationActions from '~redux/Documentation/actions';
import { SIMULATION_NEEDS_GUARANTOR } from '~constants/simulations';
import { META_ACTION_TARGETS, META_VALUES_TARGETS } from '~constants/metaValues';
import { getDownpaymentByKind } from '~utils/simulation';
import { EMPLOYMENT_CONVERTED_STATUS } from '~constants/employmentSituation';
import { setFinancialStepProgress } from '~utils/steps';
import { getScrapersBooleans, getScrapersReceived, setScrapersInfo } from '~utils/scrapers';
import { setPersonalDocsReceived, setPersonalDocsWarning } from '~models/documentation';
import { FLOW_CHOICE_OPTIONS, FLOW_CHOICE_TARGET } from '~constants/flowChoice';

import { SIMULATION_TARGETS } from './constants';

const getCurrentValue = (prefix, target, requirements) => requirements[addPrefix(prefix, target)]?.value;

export const getPersonalDocumentation = (prefix, requirements) => ({
  identityCard: getCurrentValue(prefix, 'HasPersonalDocMeta', requirements)
});

const getCurrentInformation = (quickCode, prefix, requirements) =>
  INCUMBENT_IDENTITY_TARGETS.reduce(
    (properties, { fieldName, valueTarget, fixer }) => ({
      ...properties,
      [valueTarget]: fixer
        ? fixer({ quickCode, value: getCurrentValue(prefix, fieldName, requirements) })
        : getCurrentValue(prefix, fieldName, requirements)
    }),
    {}
  );

const setFinancialInfo = ({ dispatch, actionCreators, prefix, requirements, incumbentName, ...params }) => {
  const isTargetValue = (metaTarget, targetValue) =>
    requirements?.[addPrefix(prefix, metaTarget)]?.value === targetValue;
  const isAutomatic = isTargetValue(META_VALUES_TARGETS.FLOW_META, FLOW_CHOICE_OPTIONS.AUTOMATIC);
  const isSubmitted = isTargetValue(META_VALUES_TARGETS.UC_SUBMITTED_META, 'completed');
  const { afpReceivedAndWithoutWarning, siiReceivedAndWithoutWarning, cmfReceivedAndWithoutWarning } =
    getScrapersBooleans({ prefix, requirements, ...params });
  const employmentSituation =
    EMPLOYMENT_CONVERTED_STATUS[requirements[addPrefix(prefix, 'LaborSituationMeta')]?.value] || '';
  dispatch(
    actionCreators.setSimulationInfo(addPrefix(prefix, 'FinancialInfo'), {
      incomes: requirements[addPrefix(prefix, 'MonthlyIncomesMeta')]?.value || '',
      emittedInvoicesLastYear: requirements[addPrefix(prefix, 'EmittedInvoicesLastYearMeta')]?.value || '',
      jobStartDate: requirements[addPrefix(prefix, 'JobStartDateMeta')]?.value || '',
      employmentSituation
    })
  );
  setFinancialStepProgress({
    dispatch,
    employmentSituation,
    afpReceivedAndWithoutWarning,
    siiReceivedAndWithoutWarning,
    cmfReceivedAndWithoutWarning,
    isAutomatic,
    isSubmitted,
    incumbentName
  });
};

const setSimulationBoolInfo = ({ actionTarget, dispatch, actionCreators, currentValue }) =>
  dispatch(actionCreators.setSimulationInfo(actionTarget, isTrue(currentValue)));

const setClientBoolInfo = ({ requirements, actionTarget, requirementTarget, ...params }) =>
  setSimulationBoolInfo({
    ...params,
    actionTarget,
    currentValue: requirements[requirementTarget]?.value
  });

const setIncumbentBoolInfo = ({
  incumbentName,
  metaActionTarget,
  metaValueTarget,
  requirements,
  ...params
}) => {
  setSimulationBoolInfo({
    ...params,
    actionTarget: SIMULATION_TARGETS[addPrefix(incumbentName, metaActionTarget)],
    currentValue: getCurrentValue(INCUMBENT_TYPES[incumbentName], metaValueTarget, requirements)
  });
};

const setIncumenbentInfo = ({
  dispatch,
  actionCreators,
  simulationTarget,
  quickCode,
  requirements,
  incumbent,
  incumbentName,
  setPersonalDocumentation
}) => {
  const scrapersReceived = getScrapersReceived(incumbent, requirements);
  const infoParams = {
    dispatch,
    actionCreators,
    prefix: incumbent,
    requirements,
    ...scrapersReceived,
    incumbentName
  };
  dispatch(
    actionCreators.setSimulationInfo(
      simulationTarget,
      getCurrentInformation(quickCode, incumbent, requirements)
    )
  );
  setScrapersInfo(infoParams);
  setFinancialInfo(infoParams);
  dispatch(setPersonalDocumentation(getPersonalDocumentation(incumbent, requirements)));
  dispatch(
    actionCreators.setSimulationInfo(
      'hasDownpayment',
      snakeToCamelCase(requirements.simulationHasDownpaymentMeta?.value || '')
    )
  );
  dispatch(
    actionCreators.setSimulationInfo(
      'whenBuy',
      snakeToCamelCase(requirements.simulationWhenBuyMeta?.value || '')
    )
  );
  const personalDocsData = { dispatch, prefix: incumbentName, requirements };
  setPersonalDocsReceived(personalDocsData);
  setPersonalDocsWarning(personalDocsData);
  setIncumbentBoolInfo({
    dispatch,
    actionCreators,
    incumbentName,
    requirements,
    metaActionTarget: META_ACTION_TARGETS.STATUS_PAGE,
    metaValueTarget: META_VALUES_TARGETS.KNOWS_STATUS_PAGE_META
  });
};

const saveFlowChoice = ({ incumbent, dispatch, requirements, actionCreators }) => {
  dispatch(
    actionCreators.setSimulationInfo(
      addPrefix(incumbent, FLOW_CHOICE_TARGET),
      requirements?.[addPrefix(incumbent, META_VALUES_TARGETS.FLOW_META)]?.value
    )
  );
};

export const saveFetchedSimulation = (quickCode, dispatch, actionCreators, response) => {
  const {
    kind,
    amount,
    downpayment,
    installmentsAmount,
    installmentValue,
    requirements,
    decryptedQuickCode,
    interestedIn,
    productTypeSlug
  } = response.data;
  dispatch(actionCreators.setSimulationInfo(SIMULATION_TARGETS.DECRYPTED_QUICK_CODE, decryptedQuickCode));
  dispatch(actionCreators.getLoanDraft(requirements?.simulationKindMeta?.value || kind));
  dispatch(actionCreators.setSimulationInfo(SIMULATION_TARGETS.ITEM_NAME, interestedIn));
  dispatch(actionCreators.setSimulationInfo(SIMULATION_TARGETS.PRODUCT_TYPE, productTypeSlug));
  dispatch(
    actionCreators.setAmountAndDownpayment(amount, getDownpaymentByKind(kind, Math.round(downpayment)))
  );
  saveFlowChoice({ incumbent: INCUMBENT_TYPES.CLIENT, dispatch, requirements, actionCreators });
  dispatch(
    actionCreators.setSimulationInfo(SIMULATION_TARGETS.CURRENT_TERM, {
      term: Math.round(installmentsAmount),
      monthlyPayment: installmentValue
    })
  );
  setClientBoolInfo({
    dispatch,
    actionCreators,
    requirements,
    actionTarget: SIMULATION_TARGETS.CLIENT_KNOWS_STATUS_SHARE_LINK,
    requirementTarget: META_VALUES_TARGETS.CLIENT_KNOWS_SHARE_LINK_META
  });
  setClientBoolInfo({
    dispatch,
    actionCreators,
    requirements,
    actionTarget: SIMULATION_TARGETS.CAN_EDIT_AMOUNT,
    requirementTarget: META_VALUES_TARGETS.CLIENT_CAN_EDIT_AMOUNT_META
  });
  setIncumbentBoolInfo({
    dispatch,
    actionCreators,
    incumbentName: INCUMBENT_TYPES_NAMES.CLIENT,
    requirements,
    metaActionTarget: META_ACTION_TARGETS.CONFIRMED_EMAIL,
    metaValueTarget: META_VALUES_TARGETS.CONFIRMED_EMAIL_META
  });
  setIncumenbentInfo({
    dispatch,
    actionCreators,
    simulationTarget: SIMULATION_TARGETS.CURRENT_CLIENT,
    quickCode,
    requirements,
    incumbent: INCUMBENT_TYPES.CLIENT,
    incumbentName: INCUMBENT_TYPES_NAMES.CLIENT,
    setPersonalDocumentation: DocumentationActions.setClientPersonalDocumentation
  });
  if (SIMULATION_NEEDS_GUARANTOR[kind]) {
    setIncumenbentInfo({
      dispatch,
      actionCreators,
      simulationTarget: SIMULATION_TARGETS.CURRENT_GUARANTOR,
      quickCode,
      requirements,
      incumbent: INCUMBENT_TYPES.GUARANTOR,
      incumbentName: INCUMBENT_TYPES_NAMES.GUARANTOR,
      setPersonalDocumentation: DocumentationActions.setGuarantorPersonalDocs
    });
  }
  dispatch(actionCreators.setSimulationInfo(SIMULATION_TARGETS.SAVE_FETCHED_SIM_LOADING, false));
};
