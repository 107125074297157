import i18next from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import { bool, string } from 'prop-types';
import cn from 'classnames';

import Button from '~app/components/Button';
import PrimaryAndSecondaryButtons from '~app/components/PrimaryAndSecondaryButtons';
import { useIsView } from '~app/hooks/useIsView';
import { INCUMBENT_TYPES } from '~constants/incumbent';
import { useContinueWithGuarantor } from '~hooks/useContinueWithGuarantor';
import GuarantorInfoModal from '~components/GuarantorInfoModal';

import styles from './styles.module.scss';

function ContinueWithGuarantorButtons({
  containerClassName,
  underlinedButtonText,
  primaryText,
  secondaryText,
  hideSecondary,
  moreInfoClassName
}) {
  const { handleOpenInfoModal, handleOpenWarning, handleShareLink, handleContinue } =
    useContinueWithGuarantor();
  const isGuarantorView = useIsView(INCUMBENT_TYPES.GUARANTOR);
  const changeIncumbentLoading = useSelector(state => state.simulation.changeIncumbentLoading);
  const withoutSecondaryClassName = hideSecondary && styles.withoutBackButton;
  return (
    <div className={containerClassName}>
      <PrimaryAndSecondaryButtons
        className={cn(styles.continueBackButtons, withoutSecondaryClassName)}
        primaryClassName={withoutSecondaryClassName}
        primaryText={isGuarantorView ? primaryText : secondaryText}
        secondaryText={i18next.t('ContinueWithGuarantor:continueWithGuarantor')}
        hideSecondary={hideSecondary}
        handlePrimaryAction={isGuarantorView ? handleContinue : handleShareLink}
        handleSecondaryAction={handleContinue}
        loading={changeIncumbentLoading}
      />
      <Button
        className={moreInfoClassName}
        lowercase
        underline
        onClick={isGuarantorView ? handleOpenInfoModal : handleOpenWarning}>
        {underlinedButtonText || i18next.t('ContinueWithGuarantor:IDontHaveGuarantor')}
      </Button>
      <GuarantorInfoModal />
    </div>
  );
}

ContinueWithGuarantorButtons.propTypes = {
  containerClassName: string,
  hideSecondary: bool,
  moreInfoClassName: string,
  primaryText: string,
  secondaryText: string,
  underlinedButtonText: string
};

export default ContinueWithGuarantorButtons;
