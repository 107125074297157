import i18next from 'i18next';
import { func, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import cn from 'classnames';

import SimulationActions from '~redux/Simulation/actions';
import { IDENTITY_CARD_FIELD, IDENTITY_CARD_OPTIONS } from '~constants/identityCard';
import DocumentationActions from '~redux/Documentation/actions';
import StepsActions from '~redux/Steps/actions';
import { STEPS_STATES } from '~constants/steps';
import { canContinuePersonalDoc } from '~models/documentation';
import { usePersonalDocumentation } from '~app/hooks/usePersonalDocumentation';
import { PERSONAL_DOCUMENTATION_FORM } from '~constants/personalDocumentation';
import { useRedirect } from '~app/hooks/useRedirect';
import { useShowStatusPageButton } from '~app/hooks/useShowStatusPageButton';
import { ROUTES } from '~constants/routes';
import { hasChangedStepState } from '~models/simulations';
import { getPersonalEarnings } from '~utils/incomes';

import PrimaryAndSecondaryButtons from '../PrimaryAndSecondaryButtons';
import SelectWithDocumentationCard from '../SelectWithDocumentationCard';
import MigranteContacts from '../MigranteContacts';
import DocumentationCard from '../DocumentationCard';

import styles from './styles.module.scss';
import { mapIncumbentValues } from './utils';
import { getReceivedStateInfo } from './models';

function PersonalDocumentationForm({
  values,
  setPersonalDocumentation,
  stepTarget,
  nextStepName,
  incumbentTargets,
  incumbent,
  customHandleRedirect,
  textTarget = PERSONAL_DOCUMENTATION_FORM,
  handleSubmit,
  employmentSituation,
  flowChoice,
  ...props
}) {
  const showStatusPageButton = useShowStatusPageButton();
  const dispatch = useDispatch();
  const { quickCode } = useParams();
  const documentation = useSelector(state => state.documentation);
  const {
    hasAllRequiredDocuments,
    requiredDocsAndWarnings,
    idDocumentation,
    documentationInfo,
    earningsReceived,
    earningsWarning,
    ...morePersonalDocsInfo
  } = usePersonalDocumentation(incumbentTargets, values, employmentSituation);
  const handleRedirect = useRedirect();
  const changeIncumbentLoading = useSelector(state => state.simulation.changeIncumbentLoading);
  const [previousState] = useState({
    ...values,
    ...morePersonalDocsInfo,
    earningsReceived,
    earningsWarning,
    ...getReceivedStateInfo(incumbent, documentation)
  });
  useEffect(() => {
    requiredDocsAndWarnings.forEach(({ requiredDocsAndWarning, warningTarget }) => {
      if (requiredDocsAndWarning) {
        dispatch(DocumentationActions.setDocumentationWarning(warningTarget, false));
      }
    });
  }, [requiredDocsAndWarnings]);
  const handleRedirectWithCustomStep = customStepName => () => {
    handleRedirect({ customStepName })();
  };
  const handleContinue = continueStepName => () => {
    handleSubmit();
    if (canContinuePersonalDoc({ values, documentationInfo, employmentSituation, ...props })) {
      setPersonalDocumentation(values);
      dispatch(
        StepsActions.setStepState(
          stepTarget,
          hasAllRequiredDocuments ? STEPS_STATES.COMPLETED : STEPS_STATES.UNCOMPLETED
        )
      );
      const actualStateWithoutValues = {
        earningsReceived,
        earningsWarning,
        ...morePersonalDocsInfo,
        ...getReceivedStateInfo(incumbent, documentation)
      };
      const postAction = customHandleRedirect || handleRedirectWithCustomStep(continueStepName);
      if (hasChangedStepState(previousState, { ...actualStateWithoutValues, ...values })) {
        dispatch(
          SimulationActions.changeIncumbent({
            quickCode,
            values: mapIncumbentValues({
              incumbent,
              values,
              earningsReceived,
              earningsWarning,
              ...actualStateWithoutValues
            }),
            postSuccessAction: postAction
          })
        );
      } else {
        postAction();
      }
    }
  };
  return (
    <div className={styles.container}>
      <div className={cn(styles.innerContainer)}>
        <SelectWithDocumentationCard
          target={textTarget}
          name={IDENTITY_CARD_FIELD}
          options={IDENTITY_CARD_OPTIONS}
          documentation={idDocumentation}
          label={i18next.t(`${textTarget}:identityQuestion`)}
          selectLabel={i18next.t(`${textTarget}:identity`)}
          placeholder={i18next.t(`${textTarget}:select`)}
          values={values}
          incumbent={incumbent}
          {...props}
        />
        <DocumentationCard
          incumbent={incumbent}
          className={styles.documentationCard}
          target={textTarget}
          optional
          {...props}
          {...getPersonalEarnings(incumbentTargets.earnings, employmentSituation, flowChoice)}
        />
      </div>
      <div className={styles.buttonsContainer}>
        <PrimaryAndSecondaryButtons
          className={styles.buttonsContentContainer}
          handlePrimaryAction={handleContinue(nextStepName)}
          hideSecondary={!showStatusPageButton}
          loading={changeIncumbentLoading}
          handleSecondaryAction={handleContinue(ROUTES.STATUS_PAGE.stepName)}
        />
        <MigranteContacts />
      </div>
    </div>
  );
}

PersonalDocumentationForm.propTypes = {
  customHandleRedirect: func,
  employmentSituation: string,
  errors: shape,
  flowChoice: string,
  handleSubmit: func,
  incumbent: string,
  incumbentTargets: shape,
  nextStepName: string,
  previousStepName: string,
  setFieldTouched: func,
  setFieldValue: func,
  setPersonalDocumentation: func,
  stepTarget: string,
  textTarget: string,
  touched: shape,
  values: shape
};

export default PersonalDocumentationForm;
