import React from 'react';
import i18next from 'i18next';
import { func, number, string } from 'prop-types';

import styles from './styles.module.scss';
import TimeLabel from './components/TimeLabel';

function ChoiceCard({ subtitle, Image, time, onClick }) {
  return (
    <div className={styles.choiceCardContainer} onClick={onClick}>
      <div className={styles.choiceCardInfo}>
        <span className={styles.choiceTitle}>{i18next.t('FlowChoice:complete')}</span>
        <div className={styles.subtitleContainer}>
          <span className={styles.choiceSubtitle}>{subtitle}</span>
          {Image && <Image className={styles.image} />}
        </div>
      </div>
      <TimeLabel time={time} />
    </div>
  );
}

ChoiceCard.propTypes = {
  Image: string,
  subtitle: string,
  time: number,
  onClick: func
};

export default ChoiceCard;
