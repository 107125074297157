import { createTypes, completeTypes, withPostSuccess } from 'redux-recompose';

import * as VerificationsService from '~services/VerificationsService';

import { VERIFICATIONS_TARGETS } from './constants';

export const actions = createTypes(completeTypes(['TRIGGER_CHECKOUT'], []), '@@VERIFICATIONS');

export const actionCreators = {
  triggerCheckout: (values, postSuccessAction) => ({
    type: actions.TRIGGER_CHECKOUT,
    service: VerificationsService.triggerCheckout,
    target: VERIFICATIONS_TARGETS.CHECKOUT,
    payload: values,
    injections: [
      withPostSuccess(dispatch => {
        if (postSuccessAction) {
          dispatch(postSuccessAction);
        }
      })
    ]
  })
};

export default actionCreators;
