export const NEEDED_LABOR_DOCS = ['siiOrAfp', 'cmf', 'aditionalInformation'];

// TODO: Delete when integration is completed
export const REDUCED_REQUIRED_DOCS = ['identity', 'professional'];

export const REQUIRED_DOCUMENTATION_ITEMS = [...REDUCED_REQUIRED_DOCS, 'address'];

export const CLIENT_REQUIRED_DOCS = [...REQUIRED_DOCUMENTATION_ITEMS, 'bankAccount'];

export const DOCUMENTATION_TARGETS = {
  CLIENT_CONFIDENTIAL_INFO: 'CLIENT_CONFIDENTIAL_INFO',
  CLIENT_ID_FRONT_RECEIVED: 'CLIENT_ID_FRONT_RECEIVED',
  CLIENT_ID_BACK_RECEIVED: 'CLIENT_ID_BACK_RECEIVED',
  CLIENT_IMMIGRATION_RECEIVED: 'CLIENT_IMMIGRATION_RECEIVED',
  CLIENT_TITLE_RECEIVED: 'CLIENT_TITLE_RECEIVED',
  CLIENT_ID_WARNING: 'CLIENT_ID_WARNING',
  CLIENT_IMMIGRATION_WARNING: 'CLIENT_IMMIGRATION_WARNING',
  CLIENT_TITLE_WARNING: 'CLIENT_TITLE_WARNING',
  CLIENT_PERSONAL_DOCUMENTATION: 'CLIENT_PERSONAL_DOCUMENTATION',
  CLIENT_BANK_ACCOUNT: 'CLIENT_BANK_ACCOUNT',
  CLIENT_EARNINGS_RECEIVED: 'CLIENT_EARNINGS_RECEIVED',
  CLIENT_EARNINGS_WARNING: 'CLIENT_EARNINGS_WARNING',
  GUARANTOR_ID_FRONT_RECEIVED: 'GUARANTOR_ID_FRONT_RECEIVED',
  GUARANTOR_ID_BACK_RECEIVED: 'GUARANTOR_ID_BACK_RECEIVED',
  GUARANTOR_IMMIGRATION_RECEIVED: 'GUARANTOR_IMMIGRATION_RECEIVED',
  GUARANTOR_TITLE_RECEIVED: 'GUARANTOR_TITLE_RECEIVED',
  GUARANTOR_ID_WARNING: 'GUARANTOR_ID_WARNING',
  GUARANTOR_IMMIGRATION_WARNING: 'GUARANTOR_IMMIGRATION_WARNING',
  GUARANTOR_TITLE_WARNING: 'GUARANTOR_TITLE_WARNING',
  GUARANTOR_PERSONAL_DOCS: 'GUARANTOR_PERSONAL_DOCUMENTATION',
  GUARANTOR_EARNINGS_RECEIVED: 'GUARANTOR_EARNINGS_RECEIVED',
  GUARANTOR_EARNINGS_WARNING: 'GUARANTOR_EARNINGS_WARNING'
};

export const CLIENT_DOCUMENTATION_TARGETS = {
  [DOCUMENTATION_TARGETS.CLIENT_CONFIDENTIAL_INFO]: 'clientConfidentialInfo',
  [DOCUMENTATION_TARGETS.CLIENT_ID_FRONT_RECEIVED]: 'clientIdFrontReceived',
  [DOCUMENTATION_TARGETS.CLIENT_ID_BACK_RECEIVED]: 'clientIdBackReceived',
  [DOCUMENTATION_TARGETS.CLIENT_IMMIGRATION_RECEIVED]: 'clientImmigrationReceived',
  [DOCUMENTATION_TARGETS.CLIENT_TITLE_RECEIVED]: 'clientTitleReceived',
  [DOCUMENTATION_TARGETS.CLIENT_ID_WARNING]: 'clientIdWarning',
  [DOCUMENTATION_TARGETS.CLIENT_TITLE_WARNING]: 'clientTitleWarning',
  [DOCUMENTATION_TARGETS.CLIENT_IMMIGRATION_WARNING]: 'clientImmigrationWarning',
  [DOCUMENTATION_TARGETS.CLIENT_PERSONAL_DOCUMENTATION]: 'clientPersonalDocumentation',
  [DOCUMENTATION_TARGETS.CLIENT_BANK_ACCOUNT]: 'clientBankAccount',
  [DOCUMENTATION_TARGETS.CLIENT_EARNINGS_RECEIVED]: 'clientEarningsReceived',
  [DOCUMENTATION_TARGETS.CLIENT_EARNINGS_WARNING]: 'clientEarningsWarning'
};

export const GUARANTOR_DOCS_TARGETS = {
  [DOCUMENTATION_TARGETS.GUARANTOR_ID_FRONT_RECEIVED]: 'guarantorIdFrontReceived',
  [DOCUMENTATION_TARGETS.GUARANTOR_ID_BACK_RECEIVED]: 'guarantorIdBackReceived',
  [DOCUMENTATION_TARGETS.GUARANTOR_IMMIGRATION_RECEIVED]: 'guarantorImmigrationReceived',
  [DOCUMENTATION_TARGETS.GUARANTOR_TITLE_RECEIVED]: 'guarantorTitleReceived',
  [DOCUMENTATION_TARGETS.GUARANTOR_ID_WARNING]: 'guarantorIdWarning',
  [DOCUMENTATION_TARGETS.GUARANTOR_TITLE_WARNING]: 'guarantorTitleWarning',
  [DOCUMENTATION_TARGETS.GUARANTOR_IMMIGRATION_WARNING]: 'guarantorImmigrationWarning',
  [DOCUMENTATION_TARGETS.GUARANTOR_PERSONAL_DOCS]: 'guarantorPersonalDocumentation',
  [DOCUMENTATION_TARGETS.GUARANTOR_EARNINGS_RECEIVED]: 'guarantorEarningsReceived',
  [DOCUMENTATION_TARGETS.GUARANTOR_EARNINGS_WARNING]: 'guarantorEarningsWarning'
};

export const COMMON_DOCUMENTATION_TYPES = {
  VISA_DOCUMENT: 'VISA_DOCUMENT',
  CMF_CERTIFICATE: 'CMF_CERTIFICATE',
  PERSONAL_EARNINGS: 'PERSONAL_EARNINGS'
};

export const DOCUMENTATION_TYPES = {
  RECEIVED: {
    FRONT_RUT: 'FRONT_RUT',
    BACK_RUT: 'BACK_RUT',
    ...COMMON_DOCUMENTATION_TYPES
  },
  WARNING: {
    RUT_FILE: 'RUT_FILE',
    ...COMMON_DOCUMENTATION_TYPES
  }
};

export const INCOMES_DOCUMENTATION = {
  PERSONAL_EARNINGS: 'personalEarnings'
};

export const ID_DOCUMENTATION = {
  IDENTITY: 'identity',
  IMMIGRATION: 'immigration'
};

export const DOCUMENTATION_RECEIVED_TARGETS = {
  ID_FRONT_RECEIVED: 'IdFrontReceived',
  ID_BACK_RECEIVED: 'IdBackReceived',
  IMMIGRATION_RECEIVED: 'ImmigrationReceived',
  TITLE_RECEIVED: 'TitleReceived'
};
