import i18next from 'i18next';
import React from 'react';

import Button from '~components/Button';
import PrimaryAndSecondaryButtons from '~components/PrimaryAndSecondaryButtons';
import { useModal } from '~hooks/useModal';
import { MODALS } from '~redux/Modal/constants';

import styles from './styles.module.scss';

function ContinueButtons() {
  const { handleOpenModal } = useModal();
  return (
    <div className={styles.innerContainer}>
      <PrimaryAndSecondaryButtons
        className={styles.mainButtons}
        primaryText={i18next.t('ContinueWithGuarantorUniqueKey:shareLink')}
        secondaryText={i18next.t('ContinueWithGuarantorUniqueKey:completeInfo')}
        handlePrimaryAction={handleOpenModal(MODALS.SHARE_LINK)}
      />
      <Button info underline lowercase className={styles.withoutGuarantorButton}>
        {i18next.t('ContinueWithGuarantorUniqueKey:IDontHaveGuarantor')}
      </Button>
    </div>
  );
}

export default ContinueButtons;
