import i18next from 'i18next';
import { string } from 'prop-types';
import React from 'react';

import styles from './styles.module.scss';

function GuarantorInfoText({ firstInfo, secondInfo }) {
  return (
    <div className={styles.container}>
      <span className={styles.infoContainer}>
        {firstInfo}
        <b className={styles.specialTextGuarantor}>{i18next.t('ContinueWithGuarantor:guarantor')}</b>
        {secondInfo}
      </span>
    </div>
  );
}

GuarantorInfoText.propTypes = {
  firstInfo: string,
  secondInfo: string
};

export default GuarantorInfoText;
