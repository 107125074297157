import React from 'react';
import { string } from 'prop-types';

import styles from './styles.module.scss';

function Card({ header, children, cardClassName }) {
  return (
    <div
      className={`column ${styles.cardContainer} ${
        header && styles.cardContainerWithHeader
      } ${cardClassName}`}>
      {header && (
        <span className={`row full-width center small-title grizzle-gray medium m-bottom-3 ${styles.header}`}>
          {header}
        </span>
      )}
      {children}
    </div>
  );
}

Card.propTypes = {
  cardClassName: string,
  header: string
};

export default Card;
