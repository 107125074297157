import i18next from 'i18next';
import React from 'react';
import { bool, string } from 'prop-types';

import Button from '~components/Button';
import { ReactComponent as Share } from '~assets/share.svg';
import { MODALS } from '~redux/Modal/constants';
import ShareLinkModal from '~components/ShareLinkModal';
import { useModal } from '~app/hooks/useModal';

import styles from './styles.module.scss';

function ShareLinkButton({ link, disabled }) {
  const { handleOpenModal } = useModal();
  return (
    <div className={styles.container}>
      <Button lowercase className={styles.shareButton} onClick={handleOpenModal(MODALS.SHARE_LINK)}>
        <span className={styles.shareButtonText}>{i18next.t('ShareLinkButton:button')}</span>
        <Share />
      </Button>
      <ShareLinkModal link={link} disabled={disabled} />
    </div>
  );
}

ShareLinkButton.propTypes = {
  disabled: bool,
  link: string
};

export default ShareLinkButton;
