import React, { useState } from 'react';
import { func, bool, node, number, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { useScrapersInfo } from '~hooks/useScrapersInfo';
import { MODALS } from '~redux/Modal/constants';
import ModalActions from '~redux/Modal/actions';
import StepsActions from '~redux/Steps/actions';
import { STEPS_STATES } from '~constants/steps';
import { MODAL_TYPES } from '~constants/modals';
import SimulationActions from '~redux/Simulation/actions';
import { addPrefix, camelToSnakeCase } from '~utils/string';
import { stepsProptype } from '~propTypes/steps';
import { hasEmploymentAndWarning } from '~utils/employmentSituation';
import { useRedirect } from '~app/hooks/useRedirect';
import { useShowStatusPageButton } from '~app/hooks/useShowStatusPageButton';
import { ROUTES } from '~constants/routes';
import { hasChangedStepState } from '~models/simulations';
import { useCMF } from '~app/hooks/useCMF';
import {
  incomesErrorsProptypes,
  incomesTouchedProptypes,
  incomesValuesProptypes,
  personalEarningsProptype
} from '~propTypes/incomes';
import { useIncomes } from '~app/hooks/useIncomes';

import PrimaryAndSecondaryButtons from '../PrimaryAndSecondaryButtons';
import MigranteContacts from '../MigranteContacts';
import FlowChanger from '../FlowChanger';

import { canContinue, getIncumbentValues, getStepperActions, mapWarningInfo } from './utils';
import styles from './styles.module.scss';
import FormContent from './components/FormContent';
import IncomesModals from './components/IncomesModals';
import { FIELDS, FINANCIAL_INFO } from './constants';
import ManualToAutomatic from './components/ManualToAutomaticTitle';

function IncomesForm({
  className,
  prefix,
  values,
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  nextStepName,
  previousStepName,
  previousStepTarget,
  currentStepTarget,
  nextStepTarget,
  wrapper: Wrapper,
  steps,
  selectedStep,
  incumbentTargets,
  flowChoice,
  currentStepName,
  showFlowChanger = false,
  ...props
}) {
  const showChoosePath = false;
  const showStatusPageButton = useShowStatusPageButton();
  const dispatch = useDispatch();
  const handleRedirect = useRedirect();
  const cmfProps = useCMF(prefix);
  const [stepNameToRedirect, setStepNameToRedirect] = useState(nextStepName);
  const { quickCode } = useParams();
  const { changeIncumbentLoading, ...incomesState } = useIncomes({
    values,
    incumbentTargets,
    incumbent: prefix
  });
  const [previousState] = useState({
    ...incomesState,
    ...values,
    ...cmfProps
  });
  const warningInfo = mapWarningInfo(incomesState?.afpWarning, incomesState?.siiWarning);
  const { incomes, employmentSituation } = values;
  const [modalType, setModalType] = useState(MODAL_TYPES.AFP);
  const handleEmploymentChange = e => setFieldValue('employmentSituation', e.value);
  const rememberAction = () => dispatch(ModalActions.openModal(MODALS.REMEMBER_ERROR));
  const handleConfirm = customStepName => () => {
    handleRedirect({ customStepName })();
    dispatch(ModalActions.closeModal(MODALS.REMEMBER_ERROR));
  };
  const typeHandlers = useScrapersInfo(prefix);
  const continueAction = customStepName => () => {
    if (
      hasEmploymentAndWarning(employmentSituation, incomesState?.afpWarning, incomesState?.siiWarning) ||
      cmfProps.cmfWarning
    ) {
      rememberAction();
      dispatch(StepsActions.setStepState(currentStepTarget, STEPS_STATES.UNCOMPLETED));
    } else {
      dispatch(StepsActions.setStepState(currentStepTarget, STEPS_STATES.COMPLETED));
      handleRedirect({ customStepName })();
    }
  };
  const canContinueOrGoBack = canContinue({ incomes, employmentSituation, ...incomesState });
  const onSubmit = action => () => {
    handleSubmit();
    setFieldTouched(FIELDS.EMPLOYMENT_SITUATION, true, true);
    setFieldTouched(FIELDS.INCOMES, true, true);
    if (canContinueOrGoBack) {
      action();
    }
  };
  const submitForm = postSuccessAction => () => {
    if (hasChangedStepState(previousState, { ...incomesState, ...values, ...cmfProps })) {
      const incumbentValues = getIncumbentValues({
        prefix,
        incomes,
        employmentSituation: camelToSnakeCase(employmentSituation),
        ...incomesState,
        ...cmfProps
      });
      dispatch(
        SimulationActions.setSimulationInfo(addPrefix(prefix, FINANCIAL_INFO), {
          incomes,
          employmentSituation
        })
      );
      dispatch(
        SimulationActions.changeIncumbent({
          quickCode,
          values: incumbentValues,
          postSuccessAction
        })
      );
    } else {
      postSuccessAction();
    }
  };
  const handleContinue = continueStepName => () => {
    setStepNameToRedirect(continueStepName);
    submitForm(continueAction(continueStepName))();
  };
  const stepperActions = getStepperActions({
    prefix,
    previousStepTarget,
    nextStepTarget,
    handleBack: handleRedirect({ customStepName: previousStepName }),
    handleContinue: handleContinue(nextStepName)
  });

  return (
    <Wrapper
      steps={steps}
      selectedStep={selectedStep}
      stepperActions={stepperActions}
      disableLast={!canContinueOrGoBack}
      previousStepName={previousStepName}
      {...props}>
      <div className={`${styles.container} ${className}`}>
        <FormContent
          values={values}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          handleEmploymentChange={handleEmploymentChange}
          setModalType={setModalType}
          typeHandlers={typeHandlers}
          incumbent={prefix}
          incumbentTargets={incumbentTargets}
          {...cmfProps}
          {...incomesState}
          {...props}
        />
        <div className={styles.buttonsContainer}>
          <PrimaryAndSecondaryButtons
            handlePrimaryAction={onSubmit(handleContinue(nextStepName))}
            className={styles.continueBackButtons}
            loading={changeIncumbentLoading}
            hideSecondary={!showStatusPageButton}
            handleSecondaryAction={onSubmit(handleContinue(ROUTES.STATUS_PAGE.stepName))}
          />
          <MigranteContacts className={styles.buttons} />
        </div>
        {showChoosePath && (
          <FlowChanger
            show={showFlowChanger}
            incumbent={prefix}
            Title={ManualToAutomatic}
            customStepName={currentStepName}
            currentFlowChoice={flowChoice}
          />
        )}
        <IncomesModals
          modalType={modalType}
          prefix={prefix}
          values={values}
          typeHandlers={typeHandlers}
          handleConfirm={handleConfirm(stepNameToRedirect)}
          warningInfo={warningInfo}
        />
      </div>
    </Wrapper>
  );
}

IncomesForm.propTypes = {
  className: string,
  currentStepName: string,
  currentStepTarget: string,
  errors: incomesErrorsProptypes,
  flowChoice: string,
  formClassName: string,
  handleSubmit: func,
  incumbentTargets: personalEarningsProptype,
  knowsStatusPage: bool,
  nextStepName: string,
  nextStepTarget: string,
  prefix: string,
  previousStepName: string,
  previousStepTarget: string,
  selectedStep: string,
  setFieldTouched: func,
  setFieldValue: func,
  showFlowChanger: bool,
  steps: stepsProptype,
  submitCount: number,
  touched: incomesTouchedProptypes,
  values: incomesValuesProptypes,
  wrapper: node,
  onChange: func
};

export default IncomesForm;
