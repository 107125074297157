import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, string } from 'prop-types';
import { useParams } from 'react-router';

import { MODALS } from '~redux/Modal/constants';
import ModalActions from '~redux/Modal/actions';
import CustomModal from '~app/components/CustomModal';

import styles from './styles.module.scss';
import { getUrls, useIframeEventHandler } from './utils';

function ScraperModal({ rut, type, typeHandlers, prefix, employmentSituation, warning }) {
  const dispatch = useDispatch();
  const { quickCode, kind } = useParams();
  const needsGuarantor = useSelector(state => state.simulation.needsGuarantor);
  const open = useSelector(state => state.modal[MODALS.SCRAPER]);
  const closeModal = () => dispatch(ModalActions.closeModal(MODALS.SCRAPER));
  const urls = getUrls(rut, quickCode, prefix);
  useEffect(() => {
    const useHandler = event =>
      useIframeEventHandler({
        quickCode,
        kind,
        needsGuarantor,
        dispatch,
        event,
        closeModal,
        type,
        typeHandlers,
        prefix,
        employmentSituation,
        warning
      });
    window.addEventListener('message', useHandler, false);
    return () => window.removeEventListener('message', useHandler);
  }, [typeHandlers]);
  return (
    <CustomModal className={styles.scraperModal} modal={MODALS.SCRAPER} onClose={closeModal} isOpen={open}>
      <iframe className={styles.frame} src={urls[type]} frameBorder="0" data-hj-allow-iframe="" />
    </CustomModal>
  );
}

ScraperModal.propTypes = {
  employmentSituation: string,
  prefix: string,
  rut: string,
  type: string,
  typeHandlers: string,
  warning: bool
};

export default ScraperModal;
