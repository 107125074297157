export const HAS_DOWNPAYMENT = {
  THIS_MONTH: 'thisMonth',
  NEXT_MONTH: 'nextMonth',
  LATER: 'later'
};

export const HAS_DOWNPAYMENT_OPTIONS = [
  { value: HAS_DOWNPAYMENT.THIS_MONTH },
  { value: HAS_DOWNPAYMENT.NEXT_MONTH },
  { value: HAS_DOWNPAYMENT.LATER }
];

export const WHEN_BUY = {
  ONE_WEEK: 'oneWeek',
  BETWEEN_ONE_WEEK_AND_ONE_MONTH: 'betweenOneWeekAndOneMonth',
  MORE_THAN_ON_MONTH: 'moreThanOneMonth'
};

export const WHEN_BUY_OPTIONS = [
  { value: WHEN_BUY.ONE_WEEK },
  { value: WHEN_BUY.BETWEEN_ONE_WEEK_AND_ONE_MONTH },
  { value: WHEN_BUY.MORE_THAN_ON_MONTH }
];
