import { IDENTITY_CARD_FIELD } from '~constants/identityCard';
import { META_VALUES } from '~constants/metaValues';
import { NEXT_STEP_STATES, SIMULATION_NEXT_STEP, STEP_SUBMITTED_META } from '~constants/steps';
import { getCompleteMetaInfo } from '~utils/metaInfo';
import { addPrefix } from '~utils/string';

import { DOCUMENTATION_TARGETS } from './constants';

export const mapIncumbentValues = ({ incumbent, values, ...params }) => [
  {
    kind: addPrefix(incumbent, '_has_personal_doc_meta'),
    value: values[IDENTITY_CARD_FIELD]
  },
  {
    value: NEXT_STEP_STATES[addPrefix(incumbent, META_VALUES.FINANCIAL_INFORMATION)],
    kind: SIMULATION_NEXT_STEP
  },
  { kind: STEP_SUBMITTED_META, value: addPrefix(incumbent, META_VALUES.PERSONAL_DOCUMENTATION) },
  ...getCompleteMetaInfo({
    incumbent,
    documentationTargets: DOCUMENTATION_TARGETS,
    ...params
  })
];

export const getDocumentationReceived = (incumbent, documentation, target) =>
  documentation[addPrefix(incumbent, target)];
