import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import SimulationActions from '~redux/Simulation/actions';
import { getCompleteRut } from '~utils/rut';
import { mapCreationValues } from '~models/simulations';
import { SIMULATION_TARGETS } from '~redux/Simulation/constants';

import { useSimulationQueryParams } from './useSimulationQueryParams';

export const useCreateSimulation = () => {
  const dispatch = useDispatch();
  const { kind } = useParams();
  const queryParams = useSimulationQueryParams();
  const loanDraft = useSelector(state => state.simulation.loanDraft);
  return values => postSuccessAction => () => {
    const { currentClient, editAmount, initialSimulationValues, rut, dv } = mapCreationValues({
      values,
      queryParams,
      kind,
      loanDraft
    });
    dispatch(SimulationActions.setSimulationInfo(SIMULATION_TARGETS.CURRENT_CLIENT, currentClient));
    dispatch(SimulationActions.setSimulationInfo(SIMULATION_TARGETS.CAN_EDIT_AMOUNT, editAmount));
    dispatch(
      SimulationActions.setAmountAndDownpayment(
        initialSimulationValues.amount,
        initialSimulationValues.downpayment
      )
    );
    if (queryParams?.itemName) {
      dispatch(SimulationActions.setSimulationInfo(SIMULATION_TARGETS.ITEM_NAME, queryParams?.itemName));
    }
    dispatch(
      SimulationActions.createSimulation(
        {
          kind,
          canEditAmount: editAmount,
          simulationEmailDealerMeta: values?.emailDealer,
          ...queryParams,
          ...currentClient,
          ...initialSimulationValues,
          referralCode: values?.emailDealer || queryParams?.referralCode,
          completeRutAndDv: getCompleteRut(rut, dv)
        },
        postSuccessAction
      )
    );
  };
};
