import i18next from 'i18next';
import { arrayOf, bool, func, number, shape } from 'prop-types';
import React from 'react';
import { useParams } from 'react-router';

import ButtonLoading from '~app/components/ButtonLoading';
import IdentityFields from '~app/components/IdentityFields';
import { field } from '~propTypes/field';
import Error from '~app/components/Error';
import { hasBeenSubmitted, hasErrorsAndIsTouched } from '~utils/formik';
import { isMotorcycleSimulation, needsDealer } from '~models/simulations';
import { ReactComponent as Exclamation } from '~assets/circle-exclamation.svg';
import { MODALS } from '~redux/Modal/constants';
import { useModal } from '~app/hooks/useModal';
import Button from '~app/components/Button';
import { IDENTITY_BANNERS } from '~constants/banners';
import { useKind } from '~app/hooks/useKind';
import Banner from '~app/components/Banner';

import PastSimulationModal from '../PastSimulationModal';

import styles from './styles.module.scss';
import StepsInfoModal from './components/StepsInfoModal';

const IdentityForm = ({
  values,
  onChange,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  buttonLoading,
  createNewSimulation,
  additionalFields,
  submitCount
}) => {
  const kind = useKind();
  const { kind: creditType } = useParams();
  const params = useParams();
  const isADeal = needsDealer(params.kind);
  const { handleOpenModal } = useModal();
  const infoModal = React.createRef();
  const timeAnim = setTimeout(() => {
    infoModal.current?.classList.toggle('slide-out-right');
  }, 5000);
  const handleClick = () => {
    infoModal.current?.classList.toggle('slide-out-right');
    handleOpenModal(MODALS.STEPS_INFO)();
    clearInterval(timeAnim);
  };
  const header = () => (
    <span className={styles.sectionTitle}>
      {i18next.t(`ClientIdentity:${isADeal ? 'aboutClient' : 'aboutYou'}`)}
      <section className={styles.infoContent}>
        <div ref={infoModal} className={styles.infoModal} onClick={handleClick}>
          <span>{i18next.t('ClientIdentity:aboutSimulation')}</span>
        </div>
        <Button className={styles.infoButton} onClick={handleOpenModal(MODALS.STEPS_INFO)} secondary>
          <Exclamation />
        </Button>
      </section>
    </span>
  );
  return (
    <div className={styles.formContainer}>
      <Banner src={IDENTITY_BANNERS[kind]} />
      <div className={styles.container}>
        <IdentityFields
          values={values}
          onChange={onChange}
          errors={errors}
          setFieldTouched={setFieldTouched}
          touched={touched}
          setFieldValue={setFieldValue}
          header={header}
          additionalFields={additionalFields}
          formClassName={styles.formFieldsContainer}
        />
        <div className={styles.messageContainer}>
          <Error
            error={i18next.t('ClientIdentity:hasErrors')}
            show={hasBeenSubmitted(submitCount) && hasErrorsAndIsTouched(errors, touched)}
            errorClassName={styles.errorMessage}
          />
          {!isADeal && <span className={styles.message}>{i18next.t('ClientIdentity:message')}</span>}
          <ButtonLoading type="submit" primary loading={buttonLoading}>
            {i18next.t(
              `ClientIdentity:${isADeal || isMotorcycleSimulation(creditType) ? 'continue' : 'button'}`
            )}
          </ButtonLoading>
        </div>
      </div>
      <StepsInfoModal />
      <PastSimulationModal createNewSimulation={createNewSimulation(values)} />
    </div>
  );
};

IdentityForm.propTypes = {
  additionalFields: arrayOf(field),
  buttonLoading: bool,
  createNewSimulation: func,
  errors: shape,
  setFieldTouched: func,
  setFieldValue: func,
  submitCount: number,
  touched: shape,
  values: shape,
  onChange: func
};

export default IdentityForm;
