export const TITLE_FIELD = 'title';

export const TITLE_VALUES = {
  UNIVERSITY: 'university',
  TERTIARY: 'tertiary',
  INTERMEDIATE: 'intermediate',
  NO_DEGREE: 'no_degree'
};

export const TITLE_OPTIONS = Object.values(TITLE_VALUES);

export const TITLE_REQUIRED_VALUES = [
  TITLE_VALUES.INTERMEDIATE,
  TITLE_VALUES.TERTIARY,
  TITLE_VALUES.UNIVERSITY
];
