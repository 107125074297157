import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getIncomesValues } from '~models/incomes';

export const useIncomes = ({ incumbent }) => {
  const simulation = useSelector(state => state.simulation);
  const { changeIncumbentLoading, afpReceived, siiReceived, afpWarning, siiWarning } = getIncomesValues(
    simulation,
    incumbent
  );
  const receivedAndWarningState = useMemo(
    () => ({
      afpReceived,
      siiReceived,
      afpWarning,
      siiWarning
    }),
    [afpReceived, siiReceived, afpWarning, siiWarning]
  );
  return { changeIncumbentLoading, ...receivedAndWarningState };
};
