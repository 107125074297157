import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

import { MODALS } from '~redux/Modal/constants';
import ModalActions from '~redux/Modal/actions';
import CustomModal from '~components/CustomModal';
import Button from '~components/Button';
import { ReactComponent as Status } from '~assets/status.svg';
import { useStatusPageModal } from '~hooks/useStatusPageModal';
import { useIsView } from '~app/hooks/useIsView';
import { INCUMBENT_TYPES, INCUMBENT_TYPES_NAMES } from '~constants/incumbent';

import styles from './styles.module.scss';
import { STATUS_PAGE_MODAL_CONFIG } from './constants';

function StatusPageModal() {
  const dispatch = useDispatch();
  const { handleKnowsStatusPage } = useStatusPageModal();
  const open = useSelector(state => state.modal[MODALS.STATUS_PAGE]);
  const isGuarantorView = useIsView(INCUMBENT_TYPES.GUARANTOR);
  const closeModal = () => {
    handleKnowsStatusPage();
    dispatch(ModalActions.closeModal(MODALS.STATUS_PAGE));
  };
  const incumbentName = isGuarantorView ? INCUMBENT_TYPES_NAMES.GUARANTOR : INCUMBENT_TYPES_NAMES.CLIENT;
  const { title, info } = STATUS_PAGE_MODAL_CONFIG[incumbentName];
  return (
    <CustomModal className={styles.modal} modal={MODALS.STATUS_PAGE} onClose={closeModal} isOpen={open}>
      <div className={styles.container}>
        <Status />
        <span className={styles.statusModalTitle}>{i18next.t(`StatusPage:${title}`)}</span>
        <span className={styles.statusModalInfo}>{i18next.t(`StatusPage:${info}`)}</span>
        <Button primary onClick={closeModal}>
          {i18next.t('StatusPage:modalButton')}
        </Button>
      </div>
    </CustomModal>
  );
}

export default StatusPageModal;
