import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { int, func } from 'prop-types';
import i18next from 'i18next';

import { MODALS } from '~redux/Modal/constants';
import ModalActions from '~redux/Modal/actions';
import SimulationActions from '~redux/Simulation/actions';
import PriceModal from '~app/components/PriceModal';
import { SIMULATION_TARGETS } from '~redux/Simulation/constants';
import { isUndefined } from '~utils/validations';
import { calculateMinimumDownpayment } from '~models/simulations';

import { getValidationSchema } from './utils';
import { FIELDS } from './constants';

function AdvancePaymentModal({ currentValue, advancePayment, handleAdvancePaymentChange }) {
  const dispatch = useDispatch();
  const loanDraft = useSelector(state => state.simulation.loanDraft);
  const closeModal = () => dispatch(ModalActions.closeModal(MODALS.ADVANCE_PAYMENT));
  const handleSubmit = values => {
    handleAdvancePaymentChange(values.advancePayment);
    dispatch(SimulationActions.setSimulationInfo(SIMULATION_TARGETS.DOWNPAYMENT, values.advancePayment));
    closeModal();
  };
  const customDisabled = (fieldName, values) => isUndefined(values[fieldName]) || values[fieldName] < 0;
  const showMessage = (fieldName, values) =>
    isUndefined(values[fieldName]) ||
    values[fieldName] <
      calculateMinimumDownpayment(loanDraft?.loanConfig.defaultDownpaymentPercentage, currentValue);
  return (
    <PriceModal
      title={i18next.t('CreditSimulation:editFoot')}
      modalName={MODALS.ADVANCE_PAYMENT}
      fieldName={FIELDS.ADVANCE_PAYMENT}
      initialValues={{ advancePayment }}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(currentValue, loanDraft?.loanConfig.minimumDownpaymentPercentage)}
      customDisabled={customDisabled}
      message={i18next.t('CreditSimulation:review')}
      showMessage={showMessage}
    />
  );
}

AdvancePaymentModal.propTypes = {
  advancePayment: int,
  currentValue: int,
  handleAdvancePaymentChange: func
};

export default AdvancePaymentModal;
