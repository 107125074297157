import Immutable from 'seamless-immutable';
import { createReducer, onReadValue } from 'redux-recompose';

import { actions } from './actions';
import { DOCS_DEFAULT_STATE } from './constants';

const reducerDescription = {
  [actions.SET_DOCUMENTATION_RECEIVED]: onReadValue(),
  [actions.SET_DOCUMENTATION_WARNING]: onReadValue(),
  [actions.SET_CLIENT_CONFIDENTIAL_INFO]: onReadValue(),
  [actions.SET_CLIENT_PERSONAL_DOCUMENTATION]: onReadValue(),
  [actions.SET_GUARANTOR_PERSONAL_DOCUMENTATION]: onReadValue()
};

const reducer = createReducer(new Immutable(DOCS_DEFAULT_STATE), reducerDescription);

export default reducer;
