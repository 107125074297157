import React from 'react';

import NavBarWrapper from '~app/components/NavBarWrapper';
import UniqueKeyScraper from '~components/UniqueKeyScraper';

function GuarantorUniqueKey() {
  return (
    <NavBarWrapper isGuarantorView disabled>
      <UniqueKeyScraper />
    </NavBarWrapper>
  );
}

export default GuarantorUniqueKey;
