import React from 'react';
import { bool, func, string } from 'prop-types';
import NumberFormat from 'react-number-format';
import cn from 'classnames';

import { BACKSPACE } from '~constants/charcodes';
import { NOT_NUMBER_REGEX } from '~constants/regex';

import Input from '../Input';
import styles from '../Input/styles.module.scss';

import { unmaskMoney } from './utils';
import { PRICE_MAX_LENGTH } from './constants';

function PriceInput({ name, value, setFieldValue, setFieldTouched, disabled = false, ...props }) {
  const handleKeyPress = event => {
    if (NOT_NUMBER_REGEX.test(event.key) && event.keyCode !== BACKSPACE) {
      event.preventDefault();
    }
  };

  const handlePriceChange = e => {
    setFieldValue(name, unmaskMoney(e.target.value));
  };

  const handlePriceBlur = e => {
    setFieldTouched(name, unmaskMoney(e.target.value), true);
  };
  return (
    <Input shouldTrim={false} hideLabel name={name} value={value} disabled={disabled} {...props}>
      <span className="grizzle-gray m-left-3">$</span>
      <NumberFormat
        value={value}
        className={cn(styles.input, styles.inputMoney, styles.inputWithoutLabel, disabled && styles.disabled)}
        thousandSeparator="."
        decimalSeparator=","
        allowNegative={false}
        type="tel"
        renderText={val => val}
        name={name}
        disabled={disabled}
        onKeyDown={handleKeyPress}
        onBlur={handlePriceBlur}
        onChange={handlePriceChange}
        maxLength={PRICE_MAX_LENGTH}
      />
    </Input>
  );
}

PriceInput.propTypes = {
  disabled: bool,
  name: string,
  setFieldTouched: func,
  setFieldValue: func,
  value: string
};

export default PriceInput;
