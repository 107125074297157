import React from 'react';
import { bool, func, int, string } from 'prop-types';

import styles from './styles.module.scss';
import EdgeLabel from './components/EdgeLabel';

function RangeSlider({
  min,
  max,
  step,
  containerClassName,
  className,
  money,
  handleChange,
  value,
  handleDrop,
  labelClassName
}) {
  return (
    <div className={`column full-width ${containerClassName}`}>
      <div className="row m-bottom-4">
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          className={`${styles.slider} ${className}`}
          onChange={handleChange}
          onMouseUp={handleDrop}
          onTouchEnd={handleDrop}
          value={value}
        />
      </div>
      <div className="row space-between">
        <EdgeLabel value={min} money={money} className={labelClassName} />
        <EdgeLabel value={max} money={money} className={labelClassName} />
      </div>
    </div>
  );
}

RangeSlider.propTypes = {
  className: string,
  containerClassName: string,
  handleChange: func,
  handleDrop: func,
  labelClassName: string,
  max: int,
  min: int,
  money: bool,
  step: int,
  value: int
};

export default RangeSlider;
