import i18next from 'i18next';

import { ID_DOCUMENTATION } from '~constants/documentation';
import {
  IDENTITY_CARD_FIELD,
  IDENTITY_CARD_VALUES,
  ID_REQUIRED_VALUES,
  IMMIGRATION_VALUES
} from '~constants/identityCard';
import { TITLE_REQUIRED_VALUES } from '~constants/title';
import { hasRequiredDocsAndWarning } from '~models/documentation';
import { MODALS } from '~redux/Modal/constants';

import { TARGET_FILES_NAMES } from '../constants/fileTargets';

export const mapRequiredDocsAndWarnings = ({
  idDocumentationInfo,
  idRequiredDocs,
  visaDocumentationInfo,
  visaRequiredDocs
}) => [
  {
    requiredDocsAndWarning: hasRequiredDocsAndWarning(idDocumentationInfo),
    warningTarget: idRequiredDocs.warningTarget
  },
  {
    requiredDocsAndWarning: hasRequiredDocsAndWarning(visaDocumentationInfo),
    warningTarget: visaRequiredDocs.warningTarget
  }
];

export const requiresID = value => ID_REQUIRED_VALUES.includes(value);

export const doesNotHaveID = value => IMMIGRATION_VALUES.includes(value);

export const requiresTitle = value => TITLE_REQUIRED_VALUES.includes(value);

export const getIdRequiredDocuments = incumbentTargets => [
  {
    name: 'titlePdf',
    modalTarget: MODALS.FILE_UPLOADER_TITLE,
    receivedTarget: incumbentTargets.titleReceivedTarget,
    successTarget: incumbentTargets.titleReceivedStateTarget
  }
];

export const getIdRequiredDocs = incumbentTargets => [
  {
    name: 'front',
    modalTarget: MODALS.FILE_UPLOADER_ID_FRONT,
    receivedTarget: incumbentTargets.idFrontReceivedTarget,
    successTarget: incumbentTargets.idFrontReceivedStateTarget,
    type: TARGET_FILES_NAMES.FRONT_RUT
  },
  {
    name: 'back',
    modalTarget: MODALS.FILE_UPLOADER_ID_BACK,
    receivedTarget: incumbentTargets.idBackReceivedTarget,
    successTarget: incumbentTargets.idBackReceivedStateTarget,
    type: TARGET_FILES_NAMES.BACK_RUT
  }
];

export const getImmigrationRequiredDocs = incumbentTargets => [
  {
    name: 'immigrationPdf',
    modalTarget: MODALS.FILE_UPLOADER_IMMIGRATION,
    receivedTarget: incumbentTargets.immigrationReceivedTarget,
    successTarget: incumbentTargets.immigrationReceivedStateTarget,
    type: TARGET_FILES_NAMES.VISA_DOCUMENT
  }
];

export const getIdDocumentation = incumbentTargets => [
  {
    id: ID_DOCUMENTATION.IDENTITY,
    title: i18next.t('PersonalDocumentationForm:identity'),
    documents: getIdRequiredDocs(incumbentTargets),
    show: requiresID,
    warningTarget: incumbentTargets.idWarningTarget,
    warningStateTarget: incumbentTargets.idWarningStateTarget
  },
  {
    id: ID_DOCUMENTATION.IMMIGRATION,
    title: i18next.t('PersonalDocumentationForm:immigration'),
    documents: getImmigrationRequiredDocs(incumbentTargets),
    show: doesNotHaveID,
    warningTarget: incumbentTargets.immigrationWarningTarget,
    warningStateTarget: incumbentTargets.immigrationWarningStateTarget
  }
];

export const hasNoId = values => values[IDENTITY_CARD_FIELD] === IDENTITY_CARD_VALUES.NO;

export const hasExpiredId = values => values[IDENTITY_CARD_FIELD] === IDENTITY_CARD_VALUES.EXPIRED;
