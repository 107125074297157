import React from 'react';
import i18next from 'i18next';
import { func } from 'prop-types';

import Button from '~app/components/Button';
import SuccessMessage from '~app/components/SuccessMessage';

import styles from './styles.module.scss';

function PerfectMessage({ resetInfo }) {
  return (
    <>
      <SuccessMessage successText={i18next.t('CertificateCard:perfect')} />
      <div className={styles.perfectButtonContainer}>
        <Button lowercase underline className={styles.manualButton} onClick={resetInfo}>
          {i18next.t('CertificateCard:resendFiles')}
        </Button>
      </div>
    </>
  );
}

PerfectMessage.propTypes = {
  resetInfo: func
};

export default PerfectMessage;
