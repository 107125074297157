export const setStyleProperty = (property, value) =>
  document.documentElement.style.setProperty(property, value);

export const getStyleProperty = property =>
  getComputedStyle(document.documentElement).getPropertyValue(property);

const createScript = (js, name) => {
  const script = document.createElement(name);
  script.innerHTML = js;
  return script;
};

export const setGTM = ({ script, noScript }) => {
  document.head.insertBefore(createScript(script, 'script'), document.head.childNodes[0]);
  document.body.insertBefore(createScript(noScript, 'noscript'), document.body.childNodes[0]);
};

// eslint-disable-next-line consistent-return
export const setDatalayer = ({ dataLayer, dataLayerName, script }) => {
  if (window[dataLayerName]) {
    return window[dataLayerName].push(dataLayer);
  }
  document.head.insertBefore(createScript(script, 'script'), document.head.childNodes[0]);
};
