import { bool, shape, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import CreditHeader from '~app/components/CreditHeader';
import Stepper from '~app/components/Stepper';
import { stepsProptype } from '~propTypes/steps';

import NavBar from '../NavBar';

import styles from './styles.module.scss';

function StepWrapper({ children, previousStepName, customPreviousNavBarStepName, ...stepperProps }) {
  const { kind, currentTerm, amount, downpayment } = useSelector(state => state.simulation);
  return (
    <div className={styles.container}>
      <NavBar previousStepName={customPreviousNavBarStepName || previousStepName} />
      <Stepper customBarStyle={styles.stepperBar} {...stepperProps} />
      <CreditHeader
        kind={kind}
        term={currentTerm?.term}
        monthlyPayment={currentTerm?.monthlyPayment}
        amount={amount}
        downpayment={downpayment}
      />
      {children}
    </div>
  );
}

StepWrapper.propTypes = {
  customDisabled: bool,
  customPreviousNavBarStepName: string,
  disableLast: bool,
  previousStepName: string,
  selectedStep: string,
  stepperActions: shape,
  steps: stepsProptype
};

export default StepWrapper;
