import { IDENTITY_CARD_VALUES } from '~constants/identityCard';
import { getRequiredDocuments, mapPersonalDocumentationInfo } from '~utils/documentation';
import { getIdDocumentation, mapRequiredDocsAndWarnings } from '~utils/identityCard';
import { getPersonalEarnings } from '~utils/incomes';

import { useDocumentationInfo } from './useDocumentationInfo';

export const usePersonalDocumentation = (incumbentTargets, values, employmentSituation) => {
  const idDocumentation = getIdDocumentation(incumbentTargets.id);
  const personalEarnings = getPersonalEarnings(incumbentTargets.earnings, employmentSituation);
  const idRequiredDocs = getRequiredDocuments(idDocumentation, IDENTITY_CARD_VALUES.YES);
  const visaRequiredDocs = getRequiredDocuments(idDocumentation, IDENTITY_CARD_VALUES.NO);
  const { hasAllRequiredDocuments: earningsReceived, hasWarning: earningsWarning } = useDocumentationInfo(
    personalEarnings.documents,
    personalEarnings.warningStateTarget
  );
  const idDocumentationInfo = useDocumentationInfo(
    idRequiredDocs.documents,
    idRequiredDocs.warningStateTarget
  );
  const visaDocumentationInfo = useDocumentationInfo(
    visaRequiredDocs.documents,
    visaRequiredDocs.warningStateTarget
  );
  const documentationInfo = mapPersonalDocumentationInfo({
    values,
    idDocumentationInfo,
    visaDocumentationInfo
  });
  const requiredDocsAndWarnings = mapRequiredDocsAndWarnings({
    idDocumentationInfo,
    idRequiredDocs,
    visaDocumentationInfo,
    visaRequiredDocs
  });
  const hasAllRequiredDocuments = documentationInfo.every(documents => documents.hasAllRequiredDocuments);
  return {
    hasAllRequiredDocuments,
    requiredDocsAndWarnings,
    idDocumentation,
    documentationInfo,
    idWarning: idDocumentationInfo.hasWarning,
    visaWarning: visaDocumentationInfo.hasWarning,
    earningsReceived,
    earningsWarning
  };
};
