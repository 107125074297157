import Immutable from 'seamless-immutable';
import { completeState, completeReducer, createReducer, onReadValue } from 'redux-recompose';

import { actions } from './actions';
import { SIMULATION_DEFAULT_STATE, SIMULATION_LOCAL_STATE_TARGETS } from './constants';

const defaultState = completeState(SIMULATION_DEFAULT_STATE, SIMULATION_LOCAL_STATE_TARGETS);

const reducerDescription = {
  primaryActions: [
    actions.GET_LOAN_DRAFT,
    actions.GET_TERMS,
    actions.CREATE_SIMULATION,
    actions.CHANGE_INCUMBENT,
    actions.UPDATE_SIMULATION,
    actions.GET_STEPS_PROGRESS,
    actions.FETCH_SIMULATION,
    actions.FIND_LAST_SIMULATION
  ],
  override: {
    [actions.SET_SIMULATION_INFO]: onReadValue()
  }
};

const reducer = createReducer(new Immutable(defaultState), completeReducer(reducerDescription));

export default reducer;
