import i18next from 'i18next';
import { int, bool } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import Button from '~app/components/Button';
import { formatMoney } from '~utils/format';
import { ReactComponent as Edit } from '~assets/edit.svg';
import ModalActions from '~redux/Modal/actions';
import { MODALS } from '~redux/Modal/constants';

import styles from './styles.module.scss';

function AdvancePaymentButton({ advancePayment, isConsumer }) {
  const dispatch = useDispatch();
  const openModal = () => dispatch(ModalActions.openModal(MODALS.ADVANCE_PAYMENT));
  return (
    !isConsumer && (
      <Button lowercase primary className={styles.container} onClick={openModal}>
        <div className="row full-width center middle">
          <span className="m-right-2">
            {i18next.t('CreditSimulation:foot', { mount: formatMoney(advancePayment) })}
          </span>
          <Edit className={styles.editButton} />
        </div>
      </Button>
    )
  );
}

AdvancePaymentButton.propTypes = {
  advancePayment: int,
  isConsumer: bool
};

export default AdvancePaymentButton;
