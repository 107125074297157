export const SCRAPERS_TARGETS = {
  AFP: {
    RECEIVED: 'AfpReceived',
    WARNING: 'AfpWarning',
    MANUAL_FILE: 'RetirementFundCertificateFile',
    SCRAPER_FILE: 'ScraperFile',
    UNKNOWN_META: 'RetirementFundCertificateUnknownMeta'
  },
  SII: {
    RECEIVED: 'SiiReceived',
    WARNING: 'SiiWarning',
    MANUAL_FILE: 'PersonalInvoiceFile',
    SCRAPER_FILE: 'SiiCertificateFile',
    UNKNOWN_META: 'PersonalInvoiceUnknownMeta'
  },
  CMF: {
    RECEIVED: 'CmfReceived',
    WARNING: 'CmfWarning',
    MANUAL_FILE: 'DebtCertificateFile',
    SCRAPER_FILE: 'DebtCertificateFile',
    UNKNOWN_META: 'DebtCertificateUnknownMeta'
  }
};
