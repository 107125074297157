import React from 'react';
import { Formik } from 'formik';
import { func, shape, string } from 'prop-types';

import styles from './styles.module.scss';

const FormWrapper = ({
  initialValues,
  form: Children,
  onSubmit,
  validationSchema,
  autoComplete,
  ...props
}) => (
  <Formik
    initialValues={initialValues}
    enableReinitialize
    onSubmit={onSubmit}
    validateOnChange
    validationSchema={validationSchema}>
    {({
      handleChange,
      handleSubmit,
      handleBlur,
      touched,
      values,
      errors,
      setFieldValue,
      setFieldTouched,
      submitCount
    }) => (
      <form className={styles.container} autoComplete={autoComplete} onSubmit={handleSubmit}>
        <Children
          values={values}
          onChange={handleChange}
          errors={errors}
          setFieldValue={setFieldValue}
          submitCount={submitCount}
          onBlur={handleBlur}
          touched={touched}
          handleSubmit={handleSubmit}
          setFieldTouched={setFieldTouched}
          {...props}
        />
      </form>
    )}
  </Formik>
);

FormWrapper.propTypes = {
  autoComplete: string,
  form: shape,
  initialValues: shape,
  validationSchema: shape,
  onSubmit: func
};

export default FormWrapper;
