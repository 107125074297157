import i18next from 'i18next';
import * as Yup from 'yup';

import { IDENTITY_VALIDATION_SCHEMA, RUT_VALIDATION } from '~constants/validations';
import { getCurrentRutAndDv } from '~utils/rut';

export const VALIDATION_SCHEMA = userRut =>
  Yup.object().shape({
    ...IDENTITY_VALIDATION_SCHEMA,
    rut: RUT_VALIDATION.test({
      name: 'repeatedRutValidation',
      message: i18next.t('IdentityFormErrors:repeatedRut'),
      test() {
        return getCurrentRutAndDv(this.originalValue?.trim()).rut !== userRut.toString();
      }
    })
  });
