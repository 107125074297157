import i18next from 'i18next';

export const AFP_STATUS_CODES = {
  INVALID_CREDENTIALS: 400,
  ACCOUNT_NOT_FOUND: 404,
  LOCKED_ACCOUNT: 423
};

export const AFP_ERROR_MESSAGES = {
  [AFP_STATUS_CODES.INVALID_CREDENTIALS]: {
    MAIN_TEXT: i18next.t('UniqueKeyScraper:invalidCredentials'),
    SECONDARY_TEXT: i18next.t('UniqueKeyScraper:retry')
  },
  [AFP_STATUS_CODES.ACCOUNT_NOT_FOUND]: {
    MAIN_TEXT: i18next.t('UniqueKeyScraper:afpNotFound'),
    SECONDARY_TEXT: i18next.t('UniqueKeyScraper:retry')
  },
  [AFP_STATUS_CODES.LOCKED_ACCOUNT]: {
    MAIN_TEXT: i18next.t('UniqueKeyScraper:afpBlocked'),
    SECONDARY_TEXT: i18next.t('UniqueKeyScraper:retry')
  }
};

export const DEFAULT_UK_ERROR_MESSAGES = {
  MAIN_TEXT: i18next.t('UniqueKeyScraper:connection'),
  SECONDARY_TEXT: i18next.t('UniqueKeyScraper:retryLater')
};

export const UNIQUE_KEY_ERROR_MESSAGES = {
  401: {
    MAIN_TEXT: i18next.t('UniqueKeyScraper:invalidCredentials'),
    SECONDARY_TEXT: i18next.t('UniqueKeyScraper:retry')
  }
};
