import i18next from 'i18next';
import { arrayOf, bool, func, number, shape } from 'prop-types';
import React from 'react';

import ButtonLoading from '~app/components/ButtonLoading';
import { field } from '~propTypes/field';
import Error from '~app/components/Error';
import { hasBeenSubmitted, hasErrorsAndIsTouched } from '~utils/formik';
import { useKind } from '~app/hooks/useKind';
import { IDENTITY_BANNERS } from '~constants/banners';
import Banner from '~app/components/Banner';

import styles from './styles.module.scss';
import BirthFields from './BirthFields';

const BirthForm = ({
  values,
  onChange,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  buttonLoading,
  additionalFields,
  submitCount
}) => {
  const kind = useKind();
  const header = () => (
    <div>
      <span className={styles.sectionTitle}>{i18next.t('BirthInformation:title')}</span>
      <span className={styles.message}>{i18next.t('BirthInformation:message')}</span>
    </div>
  );

  return (
    <div className={styles.formContainer}>
      <Banner src={IDENTITY_BANNERS[kind]} />
      <div className={styles.container}>
        <BirthFields
          values={values}
          onChange={onChange}
          errors={errors}
          setFieldTouched={setFieldTouched}
          touched={touched}
          setFieldValue={setFieldValue}
          header={header}
          additionalFields={additionalFields}
          formClassName={styles.formFieldsContainer}
        />
        <div className={styles.messageContainer}>
          <Error
            error={i18next.t('BirthInformation:hasErrors')}
            show={hasBeenSubmitted(submitCount) && hasErrorsAndIsTouched(errors, touched)}
            errorClassName={styles.errorMessage}
          />
          <ButtonLoading type="submit" primary loading={buttonLoading}>
            {i18next.t('BirthInformation:button')}
          </ButtonLoading>
        </div>
      </div>
    </div>
  );
};

BirthForm.propTypes = {
  additionalFields: arrayOf(field),
  buttonLoading: bool,
  errors: shape,
  setFieldTouched: func,
  setFieldValue: func,
  submitCount: number,
  touched: shape,
  values: shape,
  onChange: func
};

export default BirthForm;
