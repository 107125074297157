import i18next from 'i18next';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { useDocumentationInfo } from '~app/hooks/useDocumentationInfo';
import { ReactComponent as MoreInfo } from '~assets/more-info.svg';
import { ReactComponent as Error } from '~assets/error.svg';

import Card from '../Card';

import DocumentationCardInfo from './components/DocumentationCardInfo';
import WithoutInfoCheckbox from './components/WithoutInfoCheckbox';
import styles from './styles.module.scss';
import { hasError } from './utils';

function DocumentationCard({
  className,
  show,
  title,
  documents,
  target,
  warningStateTarget,
  optional = false,
  extraInfo,
  incumbent,
  submitCount,
  knowsStatusPage,
  ...props
}) {
  const dispatch = useDispatch();
  const documentation = useSelector(state => state.documentation);
  const { hasAllRequiredDocuments } = useDocumentationInfo(documents, warningStateTarget);
  const handleMoreInfoClick = () => {
    dispatch(extraInfo?.action);
  };
  const error = hasError({
    optional,
    hasAllRequiredDocuments,
    submitCount,
    documentation,
    warningStateTarget,
    knowsStatusPage
  });
  return show ? (
    <Card cardClassName={cn(styles.container, className, { [styles.fullWidthCardError]: error })}>
      <div className={styles.documentationCardTitleContainer}>
        <div className={styles.documentationCardTitle}>{title}</div>
        {optional && (
          <div className={styles.documentationCardOptional}>{i18next.t('DocumentationCard:optional')}</div>
        )}
        {error && <Error className={styles.errorSign} />}
        {extraInfo?.needed && (
          <div className={styles.moreInfoContainer} onClick={handleMoreInfoClick}>
            <MoreInfo />
          </div>
        )}
      </div>
      {documents?.map(({ name, ...documentsProps }, index) => (
        <DocumentationCardInfo
          key={name}
          name={name}
          target={target}
          index={index}
          incumbent={incumbent}
          {...documentsProps}
        />
      ))}
      {!hasAllRequiredDocuments && !optional && (
        <WithoutInfoCheckbox
          className={styles.checkboxContainer}
          warningStateTarget={warningStateTarget}
          hasError={error}
          {...props}
        />
      )}
    </Card>
  ) : null;
}

DocumentationCard.propTypes = {
  checkboxText: string,
  className: string,
  documents: arrayOf(shape),
  extraInfo: shape({ needed: bool, action: func }),
  incumbent: string,
  knowsStatusPage: bool,
  optional: bool,
  show: bool,
  submitCount: number,
  target: string,
  title: string,
  warningStateTarget: string,
  warningTarget: string
};

export default DocumentationCard;
