import { POSSIBLE_STEP_STATES } from './steps';

export const EVENT_ACTIONS = {
  NEW_LEAD: 'nuevo_lead',
  CREDIT_SIMULATION: 'modelado_del_credito',
  CLIENT_INCOMES: 'ingreso_titular_ok',
  CLIENT_DOCUMENTATION: 'documentacion_titular_ok',
  CLIENT_FLOW: 'recorrido_titular_ok',
  GUARANTOR_IDENTITY: 'informacion_aval_ok',
  GUARANTOR_INCOMES: 'ingreso_aval_ok',
  GUARANTOR_DOCUMENTATION: 'documentacion_aval_ok',
  GUARANTOR_FLOW: 'recorrido_aval_ok',
  INFO_AFP: 'infoafp_ok',
  INFO_SII: 'infosii_ok',
  CLIENT_FLOW_COMPLETED: 'flujo_titular_ok',
  GUARANTOR_FLOW_COMPLETED: 'flujo_aval_ok',
  TOTAL_FLOW_COMPLETED: 'flujo_total_ok',
  INTENTION_UNIQUE_KEY: 'intencion_clave_unica'
};

export const EVENT_TO_ACTION = {
  [POSSIBLE_STEP_STATES.CLIENT_PERSONAL_INFORMATION]: EVENT_ACTIONS.NEW_LEAD,
  [POSSIBLE_STEP_STATES.CLIENT_QUOTES_INFORMATION]: EVENT_ACTIONS.CREDIT_SIMULATION,
  [POSSIBLE_STEP_STATES.CLIENT_FINANCIAL_INFORMATION]: EVENT_ACTIONS.CLIENT_INCOMES,
  [POSSIBLE_STEP_STATES.CLIENT_PERSONAL_DOCUMENTATION]: EVENT_ACTIONS.CLIENT_DOCUMENTATION,
  [POSSIBLE_STEP_STATES.CLIENT_CONFIRMATION]: EVENT_ACTIONS.CLIENT_FLOW,
  [POSSIBLE_STEP_STATES.GUARANTOR_PERSONAL_INFORMATION]: EVENT_ACTIONS.GUARANTOR_IDENTITY,
  [POSSIBLE_STEP_STATES.GUARANTOR_FINANCIAL_INFORMATION]: EVENT_ACTIONS.GUARANTOR_INCOMES,
  [POSSIBLE_STEP_STATES.GUARANTOR_PERSONAL_DOCUMENTATION]: EVENT_ACTIONS.GUARANTOR_DOCUMENTATION,
  [POSSIBLE_STEP_STATES.GUARANTOR_CONFIRMATION]: EVENT_ACTIONS.GUARANTOR_FLOW,
  [EVENT_ACTIONS.CLIENT_FLOW_COMPLETED]: EVENT_ACTIONS.CLIENT_FLOW_COMPLETED,
  [EVENT_ACTIONS.GUARANTOR_FLOW_COMPLETED]: EVENT_ACTIONS.GUARANTOR_FLOW_COMPLETED,
  [EVENT_ACTIONS.TOTAL_FLOW_COMPLETED]: EVENT_ACTIONS.TOTAL_FLOW_COMPLETED
};
