import { formatMoney } from './format';
import { isString } from './string';

export const getMoneyValue = money => {
  const actualMoney = isString(money) ? parseFloat(money) : money;
  return actualMoney.toFixed(2);
};

export const setMoneyAmount = (amount, defaultAmount) => formatMoney(amount || defaultAmount);

export const getHalfValue = (minValue, maxValue) => (minValue + maxValue) / 2;
