import i18next from 'i18next';
import { arrayOf, bool, node, shape, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import Card from '~app/components/Card';
import { isOdd } from '~utils/number';
import { getPreviousSteps, isAllowed } from '~utils/steps';
import { STEPS_STATES } from '~constants/steps';
import EditButton from '~components/EditButton';
import { useRedirect } from '~app/hooks/useRedirect';

import styles from './styles.module.scss';
import { STEPS_STATES_ICONS } from './constants';

function SectionsCard({ icon: Icon, owner, sections }) {
  const steps = useSelector(state => state.steps);
  const handleRedirect = useRedirect();
  const handleEditSection = (customStepName, allowed) => () => {
    if (allowed) {
      handleRedirect({ customStepName })();
    }
  };
  return (
    <Card cardClassName={styles.sectionsCard}>
      <div className={styles.container}>
        <div className={styles.ownerContainer}>
          <Icon />
          <div className={styles.ownerTitle}>{i18next.t(`StatusPage:${owner}`)}</div>
        </div>
        <div className={styles.sectionsRows}>
          {sections.map(({ step, stepName }, index) => {
            const Status = STEPS_STATES_ICONS[steps[step]];
            const previousSteps = getPreviousSteps(sections, index);
            const isUncompleted = steps[step] === STEPS_STATES.UNCOMPLETED;
            const allowed = isAllowed(steps, previousSteps);
            return (
              <div
                key={step}
                className={cn(styles.sectionRow, {
                  [styles.sectionRowBackground]: isOdd(index),
                  [styles.sectionRowUncompleted]: isUncompleted
                })}
                onClick={handleEditSection(stepName, allowed)}>
                <div className={styles.sectionTitleWithIcon}>
                  <Status className={cn(styles.sectionIcon, { [styles.errorIcon]: isUncompleted })} />
                  <span className={styles.statusSectionTitle}>{i18next.t(`StatusPage:${step}`)}</span>
                </div>
                {allowed && <EditButton />}
              </div>
            );
          })}
        </div>
      </div>
    </Card>
  );
}
SectionsCard.propTypes = {
  icon: node,
  owner: string,
  sections: arrayOf(shape({ id: string, path: string, needsKind: bool }))
};

export default SectionsCard;
