import { EVENTS } from '~constants/events';
import SimulationActions from '~redux/Simulation/actions';
import { getCorrectEmploymentSituation } from '~models/simulations';
import { addPrefix } from '~utils/string';
import { TARGET_FILES } from '~constants/fileTargets';
import { MODAL_TYPES } from '~constants/modals';
import { META_VALUES } from '~constants/metaValues';
import AnalyticsService from '~services/AnalyticsService';
import { EVENT_ACTIONS } from '~constants/analytics';

import { DEVTOOLS_EVENT } from './constants';

export const getUrls = (rut, quickCode, incumbent) => {
  const path = `?rut=${rut}&quickCode=${quickCode}-${incumbent}&redirectUrl=${process.env.REACT_APP_URL}/thank-you`;
  return {
    [MODAL_TYPES.AFP]: `${process.env.REACT_APP_AFP_SCRAPER}/${path}`,
    [MODAL_TYPES.SII]: `${process.env.REACT_APP_SII_SCRAPER}/${path}`
  };
};

const getIncumbentValues = (prefix, type, employmentSituation) => [
  {
    value: getCorrectEmploymentSituation(employmentSituation),
    kind: addPrefix(prefix, META_VALUES.LABOR_SITUATION)
  },
  {
    value: 'pending',
    kind: addPrefix(prefix, TARGET_FILES[type].kind),
    context: addPrefix(prefix, TARGET_FILES[type].context)
  }
];

const isInvalidEvent = event =>
  event.origin !== process.env.REACT_APP_URL ||
  event.data.source === DEVTOOLS_EVENT ||
  event.data !== EVENTS.TRANSACTION_FINISHED;

export const useIframeEventHandler = ({
  dispatch,
  quickCode,
  kind,
  needsGuarantor,
  event,
  closeModal,
  typeHandlers,
  prefix,
  employmentSituation,
  type,
  warning
}) => {
  if (!isInvalidEvent(event)) {
    const incumbentValues = getIncumbentValues(prefix, type, employmentSituation);
    dispatch(SimulationActions.changeIncumbent({ quickCode, values: incumbentValues }));
    typeHandlers.handleReceived();
    if (warning) {
      typeHandlers.handleWarning();
    }
    AnalyticsService.event({
      category: kind,
      action: EVENT_ACTIONS[addPrefix('INFO_', type)],
      needsGuarantor
    });
    closeModal();
  }
};
