import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PersonalDocumentationForm from '~components/PersonalDocumentationForm';
import FormWrapper from '~components/FormWrapper';
import { INCUMBENT_TYPES } from '~constants/incumbent';
import { ROUTES } from '~constants/routes';
import { CLIENT_ROUTES, ROUTES_NAMES, STEPS_TARGETS } from '~constants/steps';
import { getSteps } from '~utils/steps';
import DocumentationActions from '~redux/Documentation/actions';
import StepWrapper from '~components/StepWrapper';
import SimulationLoadingWrapper from '~components/SimulationLoadingWrapper';

import { INCUMBENT_TARGETS, INITIAL_VALUES } from './constants';
import { VALIDATION_SCHEMA } from './utils';

function ClientPersonalDocumentation() {
  const dispatch = useDispatch();
  const { needsGuarantor, clientKnowsStatusPage, clientFinancialInfo, clientFlowChoice } = useSelector(
    state => state.simulation
  );
  const clientPersonalDocumentation = useSelector(state => state.documentation.clientPersonalDocumentation);
  const setPersonalDocumentation = values =>
    dispatch(DocumentationActions.setClientPersonalDocumentation(values));
  return (
    <SimulationLoadingWrapper>
      <StepWrapper
        steps={getSteps(CLIENT_ROUTES)}
        selectedStep={ROUTES_NAMES.CLIENT.DOCUMENTATION}
        previousStepName={ROUTES.CLIENT_INCOMES.stepName}>
        <FormWrapper
          form={PersonalDocumentationForm}
          incumbent={INCUMBENT_TYPES.CLIENT}
          initialValues={clientPersonalDocumentation || INITIAL_VALUES}
          validationSchema={VALIDATION_SCHEMA}
          incumbentTargets={INCUMBENT_TARGETS}
          setPersonalDocumentation={setPersonalDocumentation}
          stepTarget={STEPS_TARGETS.CLIENT_PERSONAL_DOCUMENTATION}
          nextStepName={
            needsGuarantor ? ROUTES.CONTINUE_WITH_GUARANTOR.stepName : ROUTES.CLIENT_CONFIRMATION.stepName
          }
          knowsStatusPage={clientKnowsStatusPage}
          employmentSituation={clientFinancialInfo?.employmentSituation}
          flowChoice={clientFlowChoice}
        />
      </StepWrapper>
    </SimulationLoadingWrapper>
  );
}

export default ClientPersonalDocumentation;
