import { STEPS_STATES } from '~constants/steps';

import {
  CLIENT_DOCS_NEEDED_STEPS,
  CLIENT_NEEDED_STEPS,
  GUARANTOR_DOCS_NEEDED_STEPS,
  GUARANTOR_NEEDED_STEPS
} from './constants';

export const getAllNeededSteps = ({ needsGuarantor, clientSections, guarantorSections }) => [
  ...clientSections,
  ...(needsGuarantor ? guarantorSections : [])
];

export const hasCompletedNeededSteps = ({
  needsGuarantor = false,
  clientSections = [],
  guarantorSections = [],
  steps
}) => {
  const neededSteps = getAllNeededSteps({
    needsGuarantor,
    clientSections,
    guarantorSections
  });
  return neededSteps.every(step => steps[step] === STEPS_STATES.COMPLETED);
};

export const hasCompletedInformation = (steps, needsGuarantor) =>
  hasCompletedNeededSteps({
    needsGuarantor,
    clientSections: CLIENT_NEEDED_STEPS,
    guarantorSections: GUARANTOR_NEEDED_STEPS,
    steps
  });

export const hasCompletedDocumentation = (steps, needsGuarantor) =>
  hasCompletedNeededSteps({
    needsGuarantor,
    clientSections: CLIENT_DOCS_NEEDED_STEPS,
    guarantorSections: GUARANTOR_DOCS_NEEDED_STEPS,
    steps
  });

export const hasCompletedGuarantorFlow = (steps, needsGuarantor) =>
  hasCompletedNeededSteps({ steps, needsGuarantor, guarantorSections: GUARANTOR_NEEDED_STEPS });

export const isClientAndKnowsShareLink = (clientKnowsStatusPageShareLink, isGuarantorView) =>
  clientKnowsStatusPageShareLink && !isGuarantorView;

export const hideShareLinkButton = ({ clientAndKnowsShareLink, completedGuarantorFlow }) =>
  !clientAndKnowsShareLink || completedGuarantorFlow;

export const clientHasCompletedGuarantor = (isGuarantorView, completedGuarantorFlow) =>
  !isGuarantorView && completedGuarantorFlow;

export const showDownloadAppButtons = ({ isAppView, needsGuarantor, clientAndHasCompletedGuarantor }) =>
  !isAppView && (!needsGuarantor || clientAndHasCompletedGuarantor);

export const showGuarantorSectionCard = (
  isGuarantorView,
  clientAndKnowsShareLink,
  clientAndHasCompletedGuarantor
) => isGuarantorView || clientAndKnowsShareLink || clientAndHasCompletedGuarantor;
