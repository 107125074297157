import { func, string } from 'prop-types';
import React from 'react';
import cn from 'classnames';

import { ReactComponent as Edit } from '~assets/edit-section.svg';

import styles from './styles.module.scss';

function EditButton({ onClick, className, editIconClassName }) {
  return (
    <div className={cn(styles.editButton, className)} onClick={onClick}>
      <Edit className={cn(styles.editIcon, editIconClassName)} />
    </div>
  );
}

EditButton.propTypes = {
  className: string,
  editIconClassName: string,
  onClick: func
};

export default EditButton;
