import i18next from 'i18next';
import Lottie from 'react-lottie';

import { TIMES } from '~models/flowChoice';
import { ReactComponent as Unique } from '~assets/unique.svg';
import welcome from '~assets/lotties/welcome.json';
import { getLottieOptions } from '~utils/lottie';

import styles from './styles.module.scss';

export const HEADERS = Header => [
  { id: 'header', Component: Header, containerClassName: styles.innerContainer },
  {
    id: 'salesGirl',
    Component: Lottie,
    options: getLottieOptions({
      loop: false,
      autoplay: true,
      animationData: welcome
    }),
    containerClassName: styles.salesContainer
  }
];

export const CHOICE_CARDS = (handleUniqueKeyChoice, handleManualChoice) => [
  {
    id: 'with',
    subtitle: i18next.t('FlowChoice:with'),
    time: TIMES.UNIQUE,
    Image: Unique,
    onClick: handleUniqueKeyChoice
  },
  {
    id: 'manually',
    subtitle: i18next.t('FlowChoice:manually'),
    time: TIMES.MANUAL,
    onClick: handleManualChoice
  }
];

// TODO: AB test sin flujo manual https://app.clickup.com/t/2z02k60
export const MANUALLY = 'manually';
