import i18next from 'i18next';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import VerificationsActions from '~redux/Verifications/actions';
import CreditEndingStatus from '~components/CreditEndingStatus';
import { ReactComponent as Pending } from '~assets/credit-pending.svg';
import { ROUTES } from '~constants/routes';
import { INCUMBENT_TYPES } from '~constants/incumbent';
import SimulationNewFlowWrapper from '~app/components/SimulationNewFlowWrapper';
import { useRedirect } from '~app/hooks/useRedirect';
import { addPrefix } from '~utils/string';

function CreditPending() {
  const { quickCode } = useParams();
  const dispatch = useDispatch();
  const handleRedirect = useRedirect();
  const currentClient = useSelector(state => state.simulation.currentClient);
  const checkoutLoading = useSelector(state => state.verifications.checkoutLoading);
  const handleCheckout = () => {
    dispatch(
      VerificationsActions.triggerCheckout(
        {
          quickCode,
          incumbent: INCUMBENT_TYPES.CLIENT,
          value: currentClient?.email,
          field: addPrefix(INCUMBENT_TYPES.CLIENT, '_email')
        },
        handleRedirect({ customStepName: ROUTES.STATUS_PAGE.stepName })
      )
    );
  };
  return (
    <SimulationNewFlowWrapper previousStepName={ROUTES.CLIENT_UNIQUE_KEY.stepName} disabled={checkoutLoading}>
      <CreditEndingStatus
        title={i18next.t('CreditPending:title')}
        info={i18next.t('CreditPending:info')}
        Image={Pending}
        loading={checkoutLoading}
        handleCheckout={handleCheckout}
      />
    </SimulationNewFlowWrapper>
  );
}

export default CreditPending;
