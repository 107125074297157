import { EMPLOYMENT_STATUS } from '~constants/employmentSituation';
import { isEmployed } from '~utils/employmentSituation';
import { getScrapersTargets } from '~utils/scrapers';

export const hasWork = employmentSituation => employmentSituation !== EMPLOYMENT_STATUS.UNEMPLOYED;

export const hasExpectedIncomes = (employmentSituation, incomes) =>
  isEmployed(employmentSituation) ? incomes > 0 : incomes === '0';

export const getIncomesValues = (simulation, prefix) => {
  const targets = getScrapersTargets(prefix);
  return {
    changeIncumbentLoading: simulation.changeIncumbentLoading,
    afpReceived: simulation[targets.afpReceived],
    siiReceived: simulation[targets.siiReceived],
    afpWarning: simulation[targets.afpWarning],
    siiWarning: simulation[targets.siiWarning]
  };
};
