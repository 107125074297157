/* eslint-disable react/button-has-type */
import { func, oneOf, string, boolean } from 'prop-types';
import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

function Button({
  children,
  onClick,
  type = 'button',
  className,
  primary,
  secondary,
  lowercase,
  borderPrimary,
  borderSecondary,
  disabled,
  underline,
  info
}) {
  return (
    <button
      type={type}
      className={cn(
        'semi-bold',
        styles.container,
        {
          [styles.primary]: primary,
          [styles.secondary]: secondary,
          [styles.uppercase]: !lowercase,
          [styles.border]: borderPrimary || borderSecondary,
          [styles.borderPrimary]: borderPrimary,
          [styles.borderSecondary]: borderSecondary,
          [styles.disabled]: disabled,
          [styles.underline]: underline,
          [styles.info]: info
        },
        className
      )}
      onClick={onClick}
      disabled={disabled}>
      {children}
    </button>
  );
}

Button.propTypes = {
  borderPrimary: boolean,
  borderSecondary: boolean,
  className: string,
  disabled: boolean,
  info: boolean,
  lowercase: boolean,
  primary: boolean,
  secondary: boolean,
  type: oneOf(['button', 'submit', 'reset']),
  underline: boolean,
  onClick: func
};

export default Button;
