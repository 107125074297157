import { DOCUMENTATION_RECEIVED_TARGETS } from '~constants/documentation';

import { getDocumentationReceived } from './utils';

export const getReceivedStateInfo = (incumbent, documentation) => ({
  idFrontReceived: getDocumentationReceived(
    incumbent,
    documentation,
    DOCUMENTATION_RECEIVED_TARGETS.ID_FRONT_RECEIVED
  ),
  idBackReceived: getDocumentationReceived(
    incumbent,
    documentation,
    DOCUMENTATION_RECEIVED_TARGETS.ID_BACK_RECEIVED
  ),
  visaReceived: getDocumentationReceived(
    incumbent,
    documentation,
    DOCUMENTATION_RECEIVED_TARGETS.IMMIGRATION_RECEIVED
  )
});
