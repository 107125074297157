import { DOCUMENTATION_TARGETS, GUARANTOR_DOCS_TARGETS } from '~constants/documentation';

export const FIELDS = {
  IDENTITY_CARD: 'identityCard',
  TITLE: 'title'
};

export const INITIAL_VALUES = {
  [FIELDS.IDENTITY_CARD]: '',
  [FIELDS.TITLE]: ''
};

export const GUARANTOR_ID_TARGETS = {
  idFrontReceivedTarget: DOCUMENTATION_TARGETS.GUARANTOR_ID_FRONT_RECEIVED,
  idFrontReceivedStateTarget: GUARANTOR_DOCS_TARGETS.GUARANTOR_ID_FRONT_RECEIVED,
  idBackReceivedTarget: DOCUMENTATION_TARGETS.GUARANTOR_ID_BACK_RECEIVED,
  idBackReceivedStateTarget: GUARANTOR_DOCS_TARGETS.GUARANTOR_ID_BACK_RECEIVED,
  immigrationReceivedTarget: DOCUMENTATION_TARGETS.GUARANTOR_IMMIGRATION_RECEIVED,
  immigrationReceivedStateTarget: GUARANTOR_DOCS_TARGETS.GUARANTOR_IMMIGRATION_RECEIVED,
  idWarningTarget: DOCUMENTATION_TARGETS.GUARANTOR_ID_WARNING,
  idWarningStateTarget: GUARANTOR_DOCS_TARGETS.GUARANTOR_ID_WARNING,
  immigrationWarningTarget: DOCUMENTATION_TARGETS.GUARANTOR_IMMIGRATION_WARNING,
  immigrationWarningStateTarget: GUARANTOR_DOCS_TARGETS.GUARANTOR_IMMIGRATION_WARNING
};

export const GUARANTOR_EARNINGS_TARGETS = {
  earningsReceivedTarget: DOCUMENTATION_TARGETS.GUARANTOR_EARNINGS_RECEIVED,
  earningsReceivedStateTarget: GUARANTOR_DOCS_TARGETS.GUARANTOR_EARNINGS_RECEIVED,
  earningsWarningTarget: DOCUMENTATION_TARGETS.GUARANTOR_EARNINGS_WARNING,
  earningsWarningStateTarget: GUARANTOR_DOCS_TARGETS.GUARANTOR_EARNINGS_WARNING
};

export const INCUMBENT_TARGETS = {
  id: GUARANTOR_ID_TARGETS,
  earnings: GUARANTOR_EARNINGS_TARGETS
};
