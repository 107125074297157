import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { META_VALUES } from '~constants/metaValues';
import { mapIncumbentValue } from '~redux/Simulation/mappers';
import SimulationActions from '~redux/Simulation/actions';
import { addPrefix } from '~utils/string';
import { FLOW_CHOICE_OPTIONS, FLOW_CHOICE_TARGET } from '~constants/flowChoice';
import ScrapersActions from '~redux/Scrapers/actions';
import { ROUTES } from '~constants/routes';

import { useRedirect } from './useRedirect';

export const useFlowChoice = ({ incumbent, customStepName, currentFlowChoice, getStepNameByFlowChoice }) => {
  const handleRedirect = useRedirect();
  const { quickCode } = useParams();
  const dispatch = useDispatch();
  const getTarget = target => addPrefix(incumbent, target);
  const handleChoice = flowChoice => () => {
    const actualFlow = flowChoice === 'start' ? 'manual' : flowChoice;
    const postSuccessAction = () => {
      dispatch(SimulationActions.setSimulationInfo(getTarget(FLOW_CHOICE_TARGET), actualFlow));
      handleRedirect({
        customStepName: getStepNameByFlowChoice?.(flowChoice) || customStepName
      })();
    };
    dispatch(ScrapersActions.resetErrors());
    if (actualFlow === currentFlowChoice) {
      postSuccessAction();
    } else {
      dispatch(
        SimulationActions.changeIncumbent({
          quickCode,
          values: mapIncumbentValue(getTarget(META_VALUES.FLOW_META), actualFlow),
          postSuccessAction
        })
      );
    }
  };
  return {
    handleUniqueKeyChoice: handleChoice(FLOW_CHOICE_OPTIONS.AUTOMATIC),
    handleManualChoice: handleChoice(FLOW_CHOICE_OPTIONS.MANUAL),
    handleStartManualChoice: handleChoice(ROUTES.LETS_START.stepName)
  };
};
