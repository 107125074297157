import i18next from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';

import { MODALS } from '~redux/Modal/constants';
import { ReactComponent as GuarantorInfo } from '~assets/guarantor-info.svg';
import CustomModal from '~components/CustomModal';
import { useModal } from '~app/hooks/useModal';

import styles from './styles.module.scss';

function GuarantorInfoModal() {
  const { handleCloseModal } = useModal();
  const isOpen = useSelector(state => state.modal[MODALS.GUARANTOR_INFO]);
  return (
    <CustomModal
      className={styles.guarantorModal}
      modal={MODALS.GUARANTOR_INFO}
      isOpen={isOpen}
      onClose={handleCloseModal(MODALS.GUARANTOR_INFO)}>
      <div className={styles.guarantorModalContainer}>
        <GuarantorInfo />
        <div className={styles.guarantorInfoTitle}>{i18next.t('GuarantorInfoModal:title')}</div>
        <div className={styles.guarantorInfoText}>{i18next.t('GuarantorInfoModal:info')}</div>
      </div>
    </CustomModal>
  );
}

export default GuarantorInfoModal;
