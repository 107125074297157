import { string } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useModal } from '~app/hooks/useModal';
import { useScrapersInfo } from '~app/hooks/useScrapersInfo';
import { MODAL_TYPES } from '~constants/modals';
import { MODALS } from '~redux/Modal/constants';
import ScrapersActions from '~redux/Scrapers/actions';
import { TARGET_FILES } from '~constants/fileTargets';

import CustomModal from '../CustomModal';
import UniqueKeyScraper from '../UniqueKeyScraper';

import styles from './styles.module.scss';
import { mapIncumbentValues } from './utils';

function UniqueKeyScraperModal({
  incumbent,
  modal = MODALS.UNIQUE_KEY_CMF,
  type = MODAL_TYPES.CMF,
  targetFiles = TARGET_FILES.CMF,
  ...props
}) {
  const dispatch = useDispatch();
  const { handleCloseModal } = useModal();
  const isOpen = useSelector(state => state.modal[modal]);
  const scrapersInfo = useScrapersInfo(incumbent)[type];
  const handleCloseUniqueKeyModal = () => {
    dispatch(ScrapersActions.resetErrors());
    handleCloseModal(modal)();
  };
  return (
    <CustomModal
      className={styles.scraperModal}
      modal={modal}
      onClose={handleCloseUniqueKeyModal}
      isOpen={isOpen}>
      <UniqueKeyScraper
        incumbent={incumbent}
        type={type}
        customPostSuccessAction={handleCloseUniqueKeyModal}
        mapIncumbentValues={mapIncumbentValues(targetFiles)}
        {...scrapersInfo}
        {...props}
      />
    </CustomModal>
  );
}

UniqueKeyScraperModal.propTypes = {
  incumbent: string,
  modal: string,
  targetFiles: string,
  type: string
};

export default UniqueKeyScraperModal;
