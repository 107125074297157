import i18next from 'i18next';
import React, { useState } from 'react';
import { bool, func, string } from 'prop-types';
import cn from 'classnames';

import { ReactComponent as WhiteArrow } from '~assets/white-arrow.svg';
import { ReactComponent as Cross } from '~assets/cross.svg';
import { useFlowChoice } from '~app/hooks/useFlowChoice';
import { isManualChoice } from '~utils/flowChoice';

import styles from './styles.module.scss';

function FlowChanger({ show = false, incumbent, customStepName, currentFlowChoice, Title }) {
  const { handleManualChoice, handleUniqueKeyChoice } = useFlowChoice({
    incumbent,
    customStepName,
    currentFlowChoice
  });
  const [showChanger, setShowChanger] = useState(true);
  const handleClose = () => setShowChanger(false);
  const handleMainClick = isManualChoice(currentFlowChoice) ? handleUniqueKeyChoice : handleManualChoice;
  return show ? (
    <div className={cn(styles.container, { [styles.hideContainer]: !showChanger })}>
      <div className={styles.buttonContainer}>
        <div className={styles.closeButton} onClick={handleClose}>
          <Cross />
        </div>
        <div className={styles.mainButtonContainer} onClick={handleMainClick}>
          <Title className={styles.titleContainer} />
          <div className={styles.goButtonContainer}>
            {i18next.t('FlowChanger:go')}
            <WhiteArrow className={styles.arrow} />
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

FlowChanger.propTypes = {
  currentFlowChoice: string,
  customStepName: string,
  incumbent: string,
  show: bool,
  Title: func
};

export default FlowChanger;
