import React from 'react';
import { useSelector } from 'react-redux';

import { ROUTES } from '~constants/routes';
import { INCUMBENT_TYPES } from '~constants/incumbent';
import { STEPS_TARGETS } from '~constants/steps';
import SimulationLoadingWrapper from '~app/components/SimulationLoadingWrapper';
import { SIMULATION_TARGETS } from '~redux/Simulation/constants';
import LaborInformation from '~app/components/LaborInformation';

function ClientLaborInformation() {
  const { clientFinancialInfo, loanDraftLoading } = useSelector(state => state.simulation);
  return (
    <SimulationLoadingWrapper loading={loanDraftLoading}>
      <LaborInformation
        loading={loanDraftLoading}
        incumbent={INCUMBENT_TYPES.CLIENT}
        currentInfo={clientFinancialInfo}
        currentTarget={SIMULATION_TARGETS.CURRENT_CLIENT}
        nextStep={ROUTES.CREDIT_SIMULATION.stepName}
        stepTarget={STEPS_TARGETS.CLIENT_LABOR_INFORMATION}
      />
    </SimulationLoadingWrapper>
  );
}

export default ClientLaborInformation;
