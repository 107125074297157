import React from 'react';
import { useSelector } from 'react-redux';

import { ROUTES } from '~constants/routes';
import { INCUMBENT_TYPES } from '~constants/incumbent';
import BirthInformation from '~app/components/BirthInformation';
import { STEPS_TARGETS } from '~constants/steps';
import SimulationLoadingWrapper from '~app/components/SimulationLoadingWrapper';
import { SIMULATION_TARGETS } from '~redux/Simulation/constants';

function ClientBirthInformation() {
  const { currentClient, loanDraftLoading } = useSelector(state => state.simulation);
  return (
    <SimulationLoadingWrapper loading={loanDraftLoading}>
      <BirthInformation
        loading={loanDraftLoading}
        incumbent={INCUMBENT_TYPES.CLIENT}
        currentInfo={currentClient}
        currentTarget={SIMULATION_TARGETS.CURRENT_CLIENT}
        nextStep={ROUTES.CLIENT_LABOR_INFORMATION.stepName}
        stepTarget={STEPS_TARGETS.CLIENT_BIRTH_INFORMATION}
      />
    </SimulationLoadingWrapper>
  );
}

export default ClientBirthInformation;
