import {
  CUSTOM_STEPS_TARGETS,
  NEXT_STEP_STATES,
  SIMULATION_NEXT_STEP,
  STEP_SUBMITTED_META
} from '~constants/steps';
import { getCorrectEmploymentSituation } from '~models/simulations';
import { addPrefix, isEmptyString } from '~utils/string';
import { isUndefined } from '~utils/validations';
import { META_VALUES } from '~constants/metaValues';
import { EMPLOYMENT_STATUS } from '~constants/employmentSituation';
import { getCompleteMetaInfo } from '~utils/metaInfo';
import { hasExpectedIncomes } from '~models/incomes';

import { DOCUMENTATION_TARGETS } from './constants';

const getContinueCondition = ({ afpReceived, siiReceived, afpWarning, siiWarning }) => {
  const siiFulfilled = siiReceived || siiWarning;
  const afpFulfilled = afpReceived || afpWarning;
  return {
    [EMPLOYMENT_STATUS.DEPENDENT]: afpFulfilled,
    [EMPLOYMENT_STATUS.INDEPENDENT]: siiFulfilled,
    [EMPLOYMENT_STATUS.DEPENDENT_AND_INDEPENDENT]: afpFulfilled && siiFulfilled,
    [EMPLOYMENT_STATUS.UNEMPLOYED]: true,
    [EMPLOYMENT_STATUS.DELIVERY_WORKER_APP]: siiFulfilled
  };
};

export const canContinue = ({
  incomes,
  employmentSituation,
  afpReceived,
  siiReceived,
  afpWarning,
  siiWarning
}) =>
  !isUndefined(incomes) &&
  !isEmptyString(incomes) &&
  employmentSituation &&
  hasExpectedIncomes(employmentSituation, incomes) &&
  getContinueCondition({
    afpReceived,
    siiReceived,
    afpWarning,
    siiWarning
  })[employmentSituation];

export const getIncumbentValues = ({ prefix, incomes, employmentSituation, ...params }) => {
  const currentStep = addPrefix(prefix, META_VALUES.FINANCIAL_INFORMATION);
  return [
    {
      value: getCorrectEmploymentSituation(employmentSituation),
      kind: addPrefix(prefix, META_VALUES.LABOR_SITUATION)
    },
    {
      value: incomes,
      kind: addPrefix(prefix, META_VALUES.MONTHLY_INCOMES)
    },
    ...getCompleteMetaInfo({
      incumbent: prefix,
      documentationTargets: DOCUMENTATION_TARGETS,
      ...params
    }),
    {
      value: NEXT_STEP_STATES[addPrefix(prefix, META_VALUES.FINANCIAL_INFORMATION)],
      kind: SIMULATION_NEXT_STEP
    },
    {
      value: currentStep,
      kind: STEP_SUBMITTED_META
    }
  ];
};
export const getStepperActions = ({
  prefix,
  previousStepTarget,
  nextStepTarget,
  handleBack,
  handleContinue
}) => {
  const targets = CUSTOM_STEPS_TARGETS[prefix];
  return {
    [targets[previousStepTarget]]: handleBack,
    [targets[nextStepTarget]]: handleContinue
  };
};

export const mapWarningInfo = (afpWarning, siiWarning) => ({ AFP: afpWarning, SII: siiWarning });
