/* eslint-disable import/no-named-as-default-member */
import ReactGA from 'react-ga4';
import i18next from 'i18next';

import { history } from '~redux/store';

const KAYAK_GA_IDS = process.env.REACT_APP_GOOGLE_ANALYTICS_IDS.split(', ');

const setPageView = page => {
  ReactGA.set({ page });
  ReactGA.send({ hitType: 'pageview', page });
};

const setUp = () => {
  ReactGA.initialize(
    KAYAK_GA_IDS.map(id => ({ trackingId: id })),
    { debug: false }
  );
  setPageView(window.location.pathname);
  history.listen(location => {
    setPageView(location.pathname);
  });
};

const event = ({ category, action, needsGuarantor, value }) => {
  ReactGA.event({
    category,
    action,
    label: i18next.t(`GoogleAnalytics:${needsGuarantor ? 'withGuarantor' : 'withoutGuarantor'}`),
    value
  });
};

export default { setUp, event };
