import i18next from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';

import CustomModal from '~app/components/CustomModal';
import Button from '~app/components/Button';
import { useModal } from '~app/hooks/useModal';
import { MODALS } from '~redux/Modal/constants';

import styles from './styles.module.scss';
import { STEPS } from './constants';

function StepsInfoModal() {
  const open = useSelector(state => state.modal[MODALS.STEPS_INFO]);
  const { handleCloseModal } = useModal();
  return (
    <CustomModal modal={MODALS.STEPS_INFO} isOpen={open} className={styles.modal} hideCloseButton>
      <div className={styles.container}>
        <section className={styles.steps}>
          {STEPS.map(({ step, text }) => (
            <div className={styles.step} key={step}>
              <span className={styles.numberStep}>{step}</span>
              <span className={styles.textStep}>{text}</span>
            </div>
          ))}
        </section>
        <Button primary border onClick={handleCloseModal(MODALS.STEPS_INFO)}>
          {i18next.t('ClientIdentity:completeForm')}
        </Button>
      </div>
    </CustomModal>
  );
}

export default StepsInfoModal;
