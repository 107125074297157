import { getKayakAssetsURL } from '~utils/image';

export const BASE_TARGETS = {
  PERSON: 'PERSON',
  MOTORCYCLE: 'MOTORCYCLE',
  CAR: 'CAR',
  EMOBILE: 'EMOBILE',
  CELLPHONE: 'CELLPHONE',
  MOTORCYCLE_DEALER: 'MOTORCYCLE_DEALER',
  CAR_DEALER: 'CAR_DEALER',
  CAR_DEALER_AMICAR: 'CAR_DEALER_AMICAR'
};

export const SIMULATION_TARGETS = Object.values(BASE_TARGETS).reduce((prevValues, actualValue) => {
  const withoutGuarantee = actualValue.concat('_WITHOUT_GUARANTEE');
  return { ...prevValues, [actualValue]: actualValue, [withoutGuarantee]: withoutGuarantee };
}, {});

export const BASE_TYPES_NAMES = {
  [SIMULATION_TARGETS.PERSON]: 'persona',
  [SIMULATION_TARGETS.MOTORCYCLE]: 'moto',
  [SIMULATION_TARGETS.CAR]: 'automotriz_privado',
  [SIMULATION_TARGETS.EMOBILE]: 'electromovilidad',
  [SIMULATION_TARGETS.CELLPHONE]: 'celular',
  [SIMULATION_TARGETS.MOTORCYCLE_DEALER]: 'moto_dealer',
  [SIMULATION_TARGETS.CAR_DEALER]: 'auto_dealer',
  [SIMULATION_TARGETS.CAR_DEALER_AMICAR]: 'auto_amicar'
};

export const BASE_TYPES_TARGETS = {
  [SIMULATION_TARGETS.PERSON]: 'person',
  [SIMULATION_TARGETS.MOTORCYCLE]: 'motorcycle',
  [SIMULATION_TARGETS.CAR]: 'car',
  [SIMULATION_TARGETS.EMOBILE]: 'electromobile',
  [SIMULATION_TARGETS.CELLPHONE]: 'cellphone',
  [SIMULATION_TARGETS.MOTORCYCLE_DEALER]: 'motorcycleDealer',
  [SIMULATION_TARGETS.CAR_DEALER]: 'carDealer',
  [SIMULATION_TARGETS.CAR_DEALER_AMICAR]: 'carDealerAmicar'
};

export const SIMULATION_IMAGE_LINK_META = 'simulation_image_link_meta';

export const SIMULATION_IMAGE_PATHS = {
  [SIMULATION_TARGETS.PERSON]: '/images/consumo.png',
  [SIMULATION_TARGETS.CAR]: '/images/automotriz.png',
  [SIMULATION_TARGETS.MOTORCYCLE]: '/images/moto.png',
  [SIMULATION_TARGETS.EMOBILE]: '/images/consumo.png',
  [SIMULATION_TARGETS.CELLPHONE]: '/images/celular.png'
};

export const SIMULATION_TYPES_MAPPER = (baseTypes, suffix, initialValues) =>
  Object.keys(BASE_TARGETS).reduce((prevValues, actualValue) => {
    const baseType = baseTypes[actualValue];
    return {
      ...prevValues,
      [actualValue]: baseType,
      [actualValue.concat('_WITHOUT_GUARANTEE')]: baseType.concat(suffix)
    };
  }, initialValues);

export const SIMULATION_TYPES_NAMES = SIMULATION_TYPES_MAPPER(BASE_TYPES_NAMES, '_sin_aval', {});

export const SIMULATION_TYPES_TARGETS = SIMULATION_TYPES_MAPPER(BASE_TYPES_TARGETS, 'WithoutGuarantee', {});

export const SIMULATION_TYPES = Object.keys(SIMULATION_TARGETS).reduce(
  (prevValues, actualValue) => ({
    ...prevValues,
    [SIMULATION_TYPES_NAMES[actualValue]]: SIMULATION_TYPES_TARGETS[actualValue]
  }),
  {}
);

export const SIMULATION_NEEDS_GUARANTOR = Object.keys(SIMULATION_TARGETS).reduce(
  (prevValues, actualValue) => ({
    ...prevValues,
    [SIMULATION_TYPES_NAMES[actualValue]]: !actualValue.includes('_WITHOUT_GUARANTEE')
  }),
  {}
);

export const BASE_IMAGE_LINKS = {
  [SIMULATION_TYPES_TARGETS.PERSON]: getKayakAssetsURL(SIMULATION_IMAGE_PATHS.PERSON),
  [SIMULATION_TYPES_TARGETS.CAR]: getKayakAssetsURL(SIMULATION_IMAGE_PATHS.CAR),
  [SIMULATION_TYPES_TARGETS.MOTORCYCLE]: getKayakAssetsURL(SIMULATION_IMAGE_PATHS.MOTORCYCLE),
  [SIMULATION_TYPES_TARGETS.EMOBILE]: getKayakAssetsURL(SIMULATION_IMAGE_PATHS.EMOBILE),
  [SIMULATION_TYPES_TARGETS.CELLPHONE]: getKayakAssetsURL(SIMULATION_IMAGE_PATHS.CELLPHONE),
  [SIMULATION_TYPES_TARGETS.MOTORCYCLE_DEALER]: getKayakAssetsURL(SIMULATION_IMAGE_PATHS.MOTORCYCLE),
  [SIMULATION_TYPES_TARGETS.CAR_DEALER]: getKayakAssetsURL(SIMULATION_IMAGE_PATHS.CAR),
  [SIMULATION_TYPES_TARGETS.CAR_DEALER_AMICAR]: getKayakAssetsURL(SIMULATION_IMAGE_PATHS.CAR)
};

export const SIMULATION_IMAGE_LINKS = Object.keys(BASE_TARGETS).reduce((prevValues, actualValue) => {
  const actualTarget = SIMULATION_TYPES_TARGETS[actualValue];
  const imageLink = BASE_IMAGE_LINKS[actualTarget];
  return {
    ...prevValues,
    [actualTarget]: imageLink,
    [SIMULATION_TYPES_TARGETS[actualValue.concat('_WITHOUT_GUARANTEE')]]: imageLink
  };
}, {});

export const SIMULATION_CLIENT_META = 'simulation_client_meta';

export const SIMULATION_DEAL_FIELD = 'simulation_deal_field';

export const SIMULATION_DEAL_FIELDS = {
  BRAND: 'product_brand',
  MODEL: 'product_model'
};

export const CONSUMER_SIMULATION_TYPES = [
  SIMULATION_TYPES_NAMES.PERSON,
  SIMULATION_TYPES_NAMES.PERSON_WITHOUT_GUARANTEE
];

export const MOTORCYCLE_TYPES = [
  SIMULATION_TYPES_NAMES.MOTORCYCLE,
  SIMULATION_TYPES_NAMES.MOTORCYCLE_WITHOUT_GUARANTEE,
  SIMULATION_TYPES_NAMES.MOTORCYCLE_DEALER,
  SIMULATION_TYPES_NAMES.MOTORCYCLE_DEALER_WITHOUT_GUARANTEE
];

export const VEHICLE_CREDIT_TYPES = [
  SIMULATION_TYPES_NAMES.CAR,
  SIMULATION_TYPES_NAMES.CAR_WITHOUT_GUARANTEE,
  SIMULATION_TYPES_NAMES.MOTORCYCLE,
  SIMULATION_TYPES_NAMES.MOTORCYCLE_WITHOUT_GUARANTEE,
  SIMULATION_TYPES_NAMES.MOTORCYCLE_DEALER,
  SIMULATION_TYPES_NAMES.MOTORCYCLE_DEALER_WITHOUT_GUARANTEE,
  SIMULATION_TYPES_NAMES.CAR_DEALER,
  SIMULATION_TYPES_NAMES.CAR_DEALER_WITHOUT_GUARANTEE,
  SIMULATION_TYPES_NAMES.CAR_DEALER_AMICAR,
  SIMULATION_TYPES_NAMES.CAR_DEALER_AMICAR_WITHOUT_GUARANTEE
];

export const WITH_DEALER = Object.keys(SIMULATION_TARGETS).reduce(
  (prevValues, actualValue) => [
    ...prevValues,
    ...(actualValue.includes('_DEALER') ? [SIMULATION_TYPES_NAMES[actualValue]] : [])
  ],
  []
);

export const WITHOUT_DOWNPAYMENT = [
  SIMULATION_TYPES_NAMES.PERSON,
  SIMULATION_TYPES_NAMES.PERSON_WITHOUT_GUARANTEE,
  SIMULATION_TYPES_NAMES.CELLPHONE,
  SIMULATION_TYPES_NAMES.CELLPHONE_WITHOUT_GUARANTEE
];
