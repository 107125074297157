import SimulationActions from '~redux/Simulation/actions';
import DocumentationActions from '~redux/Documentation/actions';
import { DOCUMENTATION_FILES, TARGET_FILES_NAMES } from '~constants/fileTargets';
import { SCRAPERS_TARGETS } from '~constants/scrapers';
import { addPrefix } from '~utils/string';
import { SCRAPERS } from '~constants/modals';

export const mapDocumentation = targetReceived => ({
  actionReceived: DocumentationActions.setDocumentationReceived,
  targetReceived
});

export const mapScrapersFiles = (prefix, targetReceived, targetWarning) => ({
  actionReceived: SimulationActions.setSimulationInfo,
  actionWarning: SimulationActions.setSimulationInfo,
  targetReceived: addPrefix(prefix, targetReceived),
  targetWarning: addPrefix(prefix, targetWarning)
});

const mapScrapersActions = prefix =>
  SCRAPERS.reduce(
    (map, scraper) => ({
      ...map,
      [TARGET_FILES_NAMES[scraper]]: mapScrapersFiles(
        prefix,
        SCRAPERS_TARGETS[scraper].RECEIVED,
        SCRAPERS_TARGETS[scraper].WARNING
      )
    }),
    {}
  );

const mapDocumentationActions = targetReceived =>
  DOCUMENTATION_FILES.reduce(
    (map, documentation) => ({
      ...map,
      [TARGET_FILES_NAMES[documentation]]: mapDocumentation(targetReceived)
    }),
    {}
  );

export const getUploadAction = (prefix, targetReceived = '') => ({
  ...mapScrapersActions(prefix),
  ...mapDocumentationActions(targetReceived)
});

export const dispatchWhenActionExists = (dispatch, action, target, value) => {
  if (action) {
    dispatch(action(target, value));
  }
};

export const getUploadedFiles = (filesToUpload, errors) =>
  filesToUpload.filter(file => !errors.includes(file.name));
