import { DOCUMENTATION_TYPES } from '~constants/documentation';
import { EMPLOYMENT_STATUS } from '~constants/employmentSituation';
import { MODAL_TYPES } from '~constants/modals';

export const FIELDS = {
  EMPLOYMENT_SITUATION: 'employmentSituation',
  INCOMES: 'incomes'
};

export const AFP_STATUS = [EMPLOYMENT_STATUS.DEPENDENT, EMPLOYMENT_STATUS.DEPENDENT_AND_INDEPENDENT];

export const SII_STATUS = [
  EMPLOYMENT_STATUS.INDEPENDENT,
  EMPLOYMENT_STATUS.DEPENDENT_AND_INDEPENDENT,
  EMPLOYMENT_STATUS.DELIVERY_WORKER_APP
];

export const FINANCIAL_INFO = 'FinancialInfo';

export const DOCUMENTATION_TARGETS = ({
  afpReceived,
  siiReceived,
  afpWarning,
  siiWarning,
  cmfWarning,
  cmfReceived
}) => ({
  WARNING_METAS: [
    {
      boolean: afpWarning,
      docType: MODAL_TYPES.AFP
    },
    {
      boolean: siiWarning,
      docType: MODAL_TYPES.SII
    },
    {
      boolean: cmfWarning,
      docType: DOCUMENTATION_TYPES.WARNING.CMF_CERTIFICATE
    }
  ],
  RECEIVED_METAS: [
    {
      boolean: afpReceived,
      docType: MODAL_TYPES.AFP
    },
    {
      boolean: siiReceived,
      docType: MODAL_TYPES.SII
    },
    {
      boolean: cmfReceived,
      docType: DOCUMENTATION_TYPES.RECEIVED.CMF_CERTIFICATE
    }
  ]
});
