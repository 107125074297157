import i18next from 'i18next';
import { bool, string } from 'prop-types';
import React from 'react';
import { useParams } from 'react-router';

import FileUploaderModal from '~components/FileUploaderModal';
import { useFiles } from '~hooks/useFiles';
import CertificateCard from '~components/CertificateCard';
import { EVENT_ACTIONS } from '~constants/analytics';
import { FILE_UPLOADER_MODAL_INFO, MAIN_INFO } from '~constants/fileUploader';
import { MODAL_TYPES } from '~constants/modals';
import { TARGET_FILES_NAMES } from '~constants/fileTargets';
import AnalyticsService from '~services/AnalyticsService';
import { useScrapersInfo } from '~hooks/useScrapersInfo';
import { ReactComponent as CMF } from '~assets/cmf.svg';
import { useModal } from '~hooks/useModal';
import { MODALS } from '~redux/Modal/constants';
import UniqueKeyScraperModal from '~components/UniqueKeyScraperModal';

import styles from './styles.module.scss';
import { CMF_LINK } from './constants';

function DebtFreeCertificate({ incumbent, needsGuarantor, cmfWarning, cmfReceived, ...props }) {
  const { kind, quickCode } = useParams();
  const { handleOpenModal } = useModal();
  const filesProps = useFiles(quickCode, incumbent, TARGET_FILES_NAMES.CMF);
  const handleConnectAction = () => {
    handleOpenModal(MODALS.UNIQUE_KEY_CMF)();
    AnalyticsService.event({
      category: kind,
      action: EVENT_ACTIONS.INTENTION_UNIQUE_KEY,
      needsGuarantor
    });
  };
  const { handleWarning, handleReset } = useScrapersInfo(incumbent)[MODAL_TYPES.CMF];
  return (
    <div className={styles.container}>
      <CertificateCard
        show
        type={MODAL_TYPES.CMF}
        infoReceived={cmfReceived}
        warning={cmfWarning}
        onWarning={handleWarning}
        onResetInfo={handleReset}
        customConnectAction={handleConnectAction}
        certificateCardClassName={styles.debtCertificateContainer}
        customUploadFileAction={handleOpenModal(MODALS.FILE_UPLOADER_CMF)}
        {...props}
      />
      <UniqueKeyScraperModal incumbent={incumbent} />
      <FileUploaderModal
        prefix={incumbent}
        picture={CMF}
        fileInfoTexts={[MAIN_INFO(i18next.t('DebtFreeCertificate:itemText'))]}
        targetsMultipleFiles={false}
        link={CMF_LINK}
        linkText={i18next.t('DebtFreeCertificate:link')}
        modalTarget={MODALS.FILE_UPLOADER_CMF}
        {...filesProps}
        {...FILE_UPLOADER_MODAL_INFO[MODAL_TYPES.CMF]}
      />
    </div>
  );
}

DebtFreeCertificate.propTypes = {
  cmfReceived: bool,
  cmfWarning: bool,
  incumbent: string,
  needsGuarantor: bool
};

export default DebtFreeCertificate;
