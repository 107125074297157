import React, { useEffect } from 'react';
import { arrayOf, func, node, shape, string } from 'prop-types';
import cn from 'classnames';

import { field } from '~propTypes/field';
import { handleDateChange } from '~utils/date/regex';

import { LABOR_FIELDS } from '../../constants';

import styles from './styles.module.scss';
import { FIELDS, hasEmittedInvoicesLastYear, isUnemployed } from './constants';
import { getLaborSituationOptions, getEmittedInvoicesOptions } from './utils';

function LaborFields({
  header: Header,
  values,
  onChange,
  setFieldTouched,
  errors,
  touched,
  setFieldValue,
  setLaborSituation,
  fieldsClassName,
  formClassName
}) {
  const selectedOption = (options, selected) => options.find(option => option.value === selected);
  const mapValueField = fieldName => {
    switch (fieldName) {
      case LABOR_FIELDS.LABOR_SITUATION:
        return selectedOption(getLaborSituationOptions(), values.laborSituation);
      case LABOR_FIELDS.EMITTED_INVOICES_LAST_YEAR:
        return selectedOption(getEmittedInvoicesOptions(), values.emittedInvoicesLastYear);
      default:
        return values[fieldName];
    }
  };
  const resetInput = name => {
    setFieldValue(name, '');
    setFieldTouched(name, '', false);
  };
  const handleChangeSituationSelect = (name, option) => {
    setLaborSituation(option.value);
    if (isUnemployed(option.value)) {
      resetInput(LABOR_FIELDS.EMITTED_INVOICES_LAST_YEAR);
      resetInput(LABOR_FIELDS.MONTHLY_INCOMES);
      resetInput(LABOR_FIELDS.JOB_START_DATE);
    } else if (!hasEmittedInvoicesLastYear(option.value)) {
      resetInput(LABOR_FIELDS.EMITTED_INVOICES_LAST_YEAR);
    }

    setFieldValue(name, option.value);
  };
  const handleChangeSelect = (name, option) => {
    setFieldValue(name, option.value);
  };
  const handleChangeDate = (name, option) => {
    setFieldValue(name, handleDateChange(option.target.value));
  };
  const mapChangeField = (fieldName, option) => {
    switch (fieldName) {
      case LABOR_FIELDS.LABOR_SITUATION:
        return handleChangeSituationSelect(fieldName, option);
      case LABOR_FIELDS.EMITTED_INVOICES_LAST_YEAR:
        return handleChangeSelect(fieldName, option);
      case LABOR_FIELDS.JOB_START_DATE:
        return handleChangeDate(fieldName, option);
      case LABOR_FIELDS.MONTHLY_INCOMES:
        return handleChangeDate(fieldName, option);
      default:
        return onChange;
    }
  };
  const mapErrorField = fieldName => {
    switch (fieldName) {
      case LABOR_FIELDS.LABOR_SITUATION:
        return !values[fieldName] && touched[fieldName] && errors[fieldName];
      case LABOR_FIELDS.EMITTED_INVOICES_LAST_YEAR:
        return !values[fieldName] && touched[fieldName] && errors[fieldName];
      default:
        return errors[fieldName];
    }
  };
  useEffect(() => {
    handleChangeSituationSelect(LABOR_FIELDS.LABOR_SITUATION, {
      value: values[LABOR_FIELDS.LABOR_SITUATION]
    });
  }, [values[LABOR_FIELDS.LABOR_SITUATION]]);

  return (
    <div className={cn(styles.formContainer, formClassName)}>
      <Header />
      <div className={cn(styles.fields, fieldsClassName)}>
        {FIELDS(values.laborSituation).map(({ component: Field, name, label, ...props }) => {
          const handleBlur = e => {
            const { value } = e.target;
            setFieldValue(name, value.trim());
            setFieldTouched(name, value, true);
          };
          return (
            <div key={name}>
              <div className={styles.sectionLabel}>{label}</div>
              <Field
                className="column full-width m-bottom-2"
                value={mapValueField(name)}
                name={name}
                error={mapErrorField(name)}
                handleChange={onChange}
                onChange={option => mapChangeField(name, option)}
                touched={touched[name]}
                onBlur={() => setFieldTouched(name, true, true)}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                autocomplete={name}
                setFieldTouched={() => setFieldTouched(name, true, true)}
                hideLabel
                {...props}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

LaborFields.propTypes = {
  additionalFields: arrayOf(field),
  errors: shape,
  fieldsClassName: string,
  formClassName: string,
  header: node,
  setFieldTouched: func,
  setFieldValue: func,
  setLaborSituation: func,
  touched: shape,
  values: shape,
  onChange: func
};

export default LaborFields;
