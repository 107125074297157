import React from 'react';
import { useSelector } from 'react-redux';

import FormWrapper from '~app/components/FormWrapper';
import IncomesForm from '~app/components/IncomesForm';
import SimulationLoadingWrapper from '~app/components/SimulationLoadingWrapper';
import { INCUMBENT_TYPES } from '~constants/incumbent';
import { ROUTES } from '~constants/routes';
import { GUARANTOR_ROUTES, ROUTES_NAMES, STEPS_TARGETS } from '~constants/steps';
import { VALIDATION_SCHEMA_INCOMES } from '~constants/validations';
import { getSteps } from '~utils/steps';

import GuarantorIncomesWrapper from './components/GuarantorIncomesWrapper';
import { INITIAL_VALUES } from './constants';

function GuarantorIncomes() {
  const { guarantorFinancialInfo, guarantorKnowsStatusPage } = useSelector(state => state.simulation);
  return (
    <SimulationLoadingWrapper withInitialLoading>
      <FormWrapper
        form={IncomesForm}
        initialValues={guarantorFinancialInfo || INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA_INCOMES}
        prefix={INCUMBENT_TYPES.GUARANTOR}
        nextStepName={ROUTES.GUARANTOR_PERSONAL_DOCS.stepName}
        previousStepName={ROUTES.GUARANTOR_IDENTITY.stepName}
        previousStepTarget={STEPS_TARGETS.GUARANTOR_PERSONAL_INFORMATION}
        currentStepTarget={STEPS_TARGETS.GUARANTOR_FINANCIAL_INFO}
        nextStepTarget={STEPS_TARGETS.GUARANTOR_PERSONAL_DOCS}
        wrapper={GuarantorIncomesWrapper}
        steps={getSteps(GUARANTOR_ROUTES)}
        selectedStep={ROUTES_NAMES.GUARANTOR.INCOMES}
        knowsStatusPage={guarantorKnowsStatusPage}
      />
    </SimulationLoadingWrapper>
  );
}

export default GuarantorIncomes;
