import i18next from 'i18next';

export const DEFAULT_OPTION = {
  label: i18next.t('CreditSimulation:financed'),
  value: 'financed',
  name: 'paymentOption'
};

export const OPTIONS = [
  DEFAULT_OPTION,
  {
    label: i18next.t('CreditSimulation:cash'),
    value: 'cash',
    name: 'paymentOption'
  }
];
