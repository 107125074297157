import React from 'react';
import i18next from 'i18next';
import { number } from 'prop-types';

import { ReactComponent as Clock } from '~assets/clock.svg';
import { ReactComponent as Ray } from '~assets/ray.svg';
import { TIMES } from '~models/flowChoice';

import styles from './styles.module.scss';

function TimeLabel({ time }) {
  return (
    <div className={time === TIMES.UNIQUE ? styles.containerPrimary : styles.containerSecondary}>
      <span className={styles.label}>{i18next.t('FlowChoice:timeLabel', { time })}</span>
      {time === TIMES.UNIQUE ? <Ray className={styles.icon} /> : <Clock className={styles.icon} />}
    </div>
  );
}

TimeLabel.propTypes = {
  time: number
};

export default TimeLabel;
