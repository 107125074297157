import { string } from 'prop-types';
import React from 'react';
import cn from 'classnames';

import { useIsView } from '~app/hooks/useIsView';
import { INCUMBENT_TYPES } from '~constants/incumbent';

import GuarantorShareableLink from '../GuarantorShareableLink';

import styles from './styles.module.scss';

const GuarantorHeader = ({ title, specialText, customLink, className }) => {
  const isGuarantorView = useIsView(INCUMBENT_TYPES.GUARANTOR);
  return (
    <div className={cn(styles.container, className, { [styles.guarantorContainer]: isGuarantorView })}>
      <div className={cn(styles.sectionTitle, styles.guarantorHeader)}>
        <span className={styles.guarantorTitle}>{title}</span>
        <span className={styles.specialTextGuarantor}>{specialText}</span>
      </div>
      <GuarantorShareableLink customLink={customLink} />
    </div>
  );
};

GuarantorHeader.propTypes = { className: string, customLink: string, specialText: string, title: string };

export default GuarantorHeader;
