export const FIELDS = {
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone',
  RUT: 'rut'
};

export const INITIAL_VALUES = {
  [FIELDS.NAME]: '',
  [FIELDS.EMAIL]: '',
  [FIELDS.PHONE]: '',
  [FIELDS.RUT]: ''
};
