import i18next from 'i18next';
import { string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import GuarantorInfoModal from '~app/components/GuarantorInfoModal';
import { useModal } from '~app/hooks/useModal';
import { ReactComponent as Guarantor } from '~assets/guarantor.svg';
import { INCUMBENT_TYPES } from '~constants/incumbent';
import { MODALS } from '~redux/Modal/constants';

import styles from './styles.module.scss';

function GuarantorInformation({ incumbent }) {
  const needsGuarantor = useSelector(state => state.simulation.needsGuarantor);
  const { handleOpenModal } = useModal();
  return incumbent === INCUMBENT_TYPES.CLIENT && needsGuarantor ? (
    <div className={styles.guarantorInformationContainer}>
      <div className={styles.guarantorIconContainer}>
        <Guarantor />
      </div>
      <span className={styles.guarantorNeeded}>{i18next.t('FlowChoice:needed')}</span>
      <span className={styles.guarantorInfo} onClick={handleOpenModal(MODALS.GUARANTOR_INFO)}>
        {i18next.t('FlowChoice:guarantor')}
      </span>
      <GuarantorInfoModal />
    </div>
  ) : null;
}

GuarantorInformation.propTypes = { incumbent: string };

export default GuarantorInformation;
