import React from 'react';
import { shape, string } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import ErrorBoundary from '~app/components/ErrorBoundary';
import Helmet from '~app/components/Helmet';
import { isActualPathHome } from '~utils/routes';
import { DEFAULT_PATH, PATHS_BY_STATE, ROUTES } from '~constants/routes';
import ErrorFallback from '~app/components/ErrorFallback';

function AuthenticatedRoute({ title, description, path, component: Component, componentProps, ...props }) {
  const dispatch = useDispatch();
  // TODO: Remove this login of PATHS_BY_STATE and integrate with backend maintenance status code
  const defaultPathByState = PATHS_BY_STATE[process.env.REACT_APP_STATE];
  const hasStateAndDefaultPathByState = defaultPathByState && defaultPathByState !== path;
  return hasStateAndDefaultPathByState || isActualPathHome() ? (
    <Redirect to={defaultPathByState || DEFAULT_PATH} />
  ) : (
    <>
      <Helmet title={title} description={description} />
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          dispatch(push(ROUTES.ERROR.path));
        }}>
        <Route path={path} render={() => <Component {...componentProps} />} {...props} />
      </ErrorBoundary>
    </>
  );
}

AuthenticatedRoute.propTypes = {
  path: string.isRequired,
  alternativePath: string,
  component: shape,
  componentProps: shape,
  description: string,
  title: string
};

export default withRouter(AuthenticatedRoute);
