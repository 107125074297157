import { func, node, string } from 'prop-types';
import React from 'react';

import FileUploaderModalItem from './components/FileUploaderModalItem';
import { FILE_UPLOADER_ITEMS } from './constants';
import styles from './styles.module.scss';

function FileUploaderModalHeader({ title, picture: Picture, CustomHeaderInfo, ...props }) {
  return (
    <div className={styles.container}>
      <span className={styles.fileUploaderTitle}>{title}</span>
      {CustomHeaderInfo ? (
        <CustomHeaderInfo {...props} />
      ) : (
        <div className={styles.infoContainer}>
          <div className={styles.pictureContainer}>
            <Picture className={styles.picture} />
          </div>
          <div className={styles.itemsContainer}>
            {FILE_UPLOADER_ITEMS(props).map(({ key, ...itemProps }) => (
              <FileUploaderModalItem key={key} {...itemProps} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

FileUploaderModalHeader.propTypes = {
  CustomHeaderInfo: func,
  picture: node,
  title: string
};

export default FileUploaderModalHeader;
