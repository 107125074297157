import i18next from 'i18next';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import ErrorModal from '~app/components/ErrorModal';
import { MODALS } from '~redux/Modal/constants';
import ModalActions from '~redux/Modal/actions';
import { ROUTES } from '~constants/routes';
import ShareLinkModal from '~components/ShareLinkModal';
import { INCUMBENT_TYPES } from '~constants/incumbent';
import EmailModal from '~components/EmailModal';
import SimulationActions from '~redux/Simulation/actions';
import { getGuarantorShareLink } from '~utils/shareLink';
import { useRedirect } from '~app/hooks/useRedirect';
import { SIMULATION_TARGETS } from '~redux/Simulation/constants';
import { useContinueWithGuarantor } from '~app/hooks/useContinueWithGuarantor';
import { ERROR_MODALS } from '~constants/modals';

import GuarantorInfoText from '../GuarantorInfoText';

import styles from './styles.module.scss';

function ContinueWithGuarantorModals() {
  const dispatch = useDispatch();
  const { quickCode, kind } = useParams();
  const { sendClientConfirmation } = useContinueWithGuarantor();
  const handleRedirect = useRedirect({ withoutParams: true });
  const { clientConfirmedEmail, currentClient, changeIncumbentLoading } = useSelector(
    state => state.simulation
  );
  const handleRedirectWithoutGuarantor = () => {
    dispatch(ModalActions.closeModal(MODALS.NO_GUARANTOR_ERROR));
    handleRedirect({ customStepName: ROUTES.STATUS_PAGE.stepName })();
  };
  const handleContinueWithoutGuarantor = () => {
    sendClientConfirmation(false, handleRedirectWithoutGuarantor)();
  };
  const setCurrentClientEmail = newEmail => {
    dispatch(
      SimulationActions.setSimulationInfo(SIMULATION_TARGETS.CURRENT_CLIENT, {
        ...currentClient,
        email: newEmail
      })
    );
  };
  const guarantorInfoText = () => (
    <GuarantorInfoText
      firstInfo={i18next.t('ContinueWithGuarantor:firstNoGuarantorWarningInfo')}
      secondInfo={i18next.t('ContinueWithGuarantor:secondNoGuarantorWarningInfo')}
    />
  );
  return (
    <>
      <ErrorModal
        type={ERROR_MODALS.NO_GUARANTOR}
        title={i18next.t('ContinueWithGuarantor:noGuarantorWarningTitle')}
        InfoComponent={guarantorInfoText}
        buttonText={i18next.t('ContinueWithGuarantor:noGuarantorWarningButton')}
        handleClick={handleContinueWithoutGuarantor}
        buttonClassName={styles.errorModalButton}
        loading={changeIncumbentLoading}
      />
      <ShareLinkModal
        title={i18next.t('ContinueWithGuarantor:shareModalTitle')}
        subtitle={i18next.t('ContinueWithGuarantor:shareModalSubtitle')}
        link={getGuarantorShareLink(quickCode, kind)}
        customHandleRedirectAction={sendClientConfirmation(
          true,
          handleRedirect({ customStepName: ROUTES.STATUS_PAGE.stepName })
        )}
      />
      <EmailModal
        email={currentClient?.email}
        prefix={INCUMBENT_TYPES.CLIENT}
        incumbent={INCUMBENT_TYPES.CLIENT}
        shouldCloseOnOverlayClick={false}
        hideCloseButton
        customTitle={i18next.t('ContinueWithGuarantor:emailModalTitle')}
        handleEmailChange={setCurrentClientEmail}
        confirmedEmail={clientConfirmedEmail}
        confirmedEmailTarget={SIMULATION_TARGETS.CLIENT_CONFIRMED_EMAIL}
      />
    </>
  );
}

export default ContinueWithGuarantorModals;
