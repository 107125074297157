import { INCUMBENT_TYPES_NAMES } from '~constants/incumbent';
import {
  getIncumbentScrapersTargets,
  getScrapersDefaultState,
  getScrapersLocalStateTargets
} from '~utils/simulation';

export const SIMULATION_TARGETS = {
  KIND: 'kind',
  CURRENT_CLIENT: 'currentClient',
  LOAN_DRAFT: 'loanDraft',
  PRODUCT_TYPE: 'productType',
  CURRENT_TERM: 'currentTerm',
  TERMS: 'terms',
  CREATE_SIMULATION: 'createSimulation',
  DOWNPAYMENT: 'downpayment',
  AMOUNT: 'amount',
  HAS_DOWNPAYMENT: 'hasDownpayment',
  WHEN_BUY: 'whenBuy',
  CHANGE_INCUMBENT: 'changeIncumbent',
  UPDATE_SIMULATION: 'updateSimulation',
  CLIENT_FINANCIAL_INFO: 'clientFinancialInfo',
  GUARANTOR_FINANCIAL_INFO: 'guarantorFinancialInfo',
  NEEDS_GUARANTOR: 'needsGuarantor',
  CURRENT_GUARANTOR: 'currentGuarantor',
  ...getIncumbentScrapersTargets(INCUMBENT_TYPES_NAMES.CLIENT),
  ...getIncumbentScrapersTargets(INCUMBENT_TYPES_NAMES.GUARANTOR),
  STEPS_PROGRESS: 'stepsProgress',
  CURRENT_SIMULATION: 'currentSimulation',
  ITEM_NAME: 'itemName',
  CLIENT_KNOWS_STATUS_SHARE_LINK: 'clientKnowsStatusPageShareLink',
  CLIENT_KNOWS_STATUS_PAGE: 'clientKnowsStatusPage',
  CLIENT_CONFIRMED_EMAIL: 'clientConfirmedEmail',
  GUARANTOR_CONFIRMED_EMAIL: 'guarantorConfirmedEmail',
  GUARANTOR_KNOWS_STATUS_PAGE: 'guarantorKnowsStatusPage',
  CAN_EDIT_AMOUNT: 'canEditAmount',
  DECRYPTED_QUICK_CODE: 'decryptedQuickCode',
  LAST_SIMULATION: 'lastSimulation',
  SAVE_FETCHED_SIM_LOADING: 'saveFetchedSimulationLoading',
  CLIENT_FLOW_CHOICE: 'clientFlowChoice'
};

export const SIMULATION_DEFAULT_STATE = {
  [SIMULATION_TARGETS.KIND]: null,
  [SIMULATION_TARGETS.CURRENT_CLIENT]: null,
  [SIMULATION_TARGETS.LOAN_DRAFT]: null,
  [SIMULATION_TARGETS.PRODUCT_TYPE]: null,
  [SIMULATION_TARGETS.TERMS]: null,
  [SIMULATION_TARGETS.CREATE_SIMULATION]: null,
  [SIMULATION_TARGETS.CURRENT_TERM]: null,
  [SIMULATION_TARGETS.AMOUNT]: null,
  [SIMULATION_TARGETS.DOWNPAYMENT]: null,
  [SIMULATION_TARGETS.HAS_DOWNPAYMENT]: null,
  [SIMULATION_TARGETS.WHEN_BUY]: null,
  [SIMULATION_TARGETS.CHANGE_INCUMBENT]: null,
  [SIMULATION_TARGETS.UPDATE_SIMULATION]: null,
  ...getScrapersDefaultState(INCUMBENT_TYPES_NAMES.CLIENT),
  ...getScrapersDefaultState(INCUMBENT_TYPES_NAMES.GUARANTOR),
  [SIMULATION_TARGETS.CLIENT_FINANCIAL_INFO]: null,
  [SIMULATION_TARGETS.NEEDS_GUARANTOR]: false,
  [SIMULATION_TARGETS.CURRENT_GUARANTOR]: null,
  [SIMULATION_TARGETS.GUARANTOR_FINANCIAL_INFO]: null,
  [SIMULATION_TARGETS.STEPS_PROGRESS]: null,
  [SIMULATION_TARGETS.CURRENT_SIMULATION]: null,
  [SIMULATION_TARGETS.ITEM_NAME]: null,
  [SIMULATION_TARGETS.CLIENT_KNOWS_STATUS_SHARE_LINK]: false,
  [SIMULATION_TARGETS.CLIENT_KNOWS_STATUS_PAGE]: false,
  [SIMULATION_TARGETS.CLIENT_CONFIRMED_EMAIL]: false,
  [SIMULATION_TARGETS.GUARANTOR_CONFIRMED_EMAIL]: false,
  [SIMULATION_TARGETS.GUARANTOR_KNOWS_STATUS_PAGE]: false,
  [SIMULATION_TARGETS.CAN_EDIT_AMOUNT]: false,
  [SIMULATION_TARGETS.DECRYPTED_QUICK_CODE]: null,
  [SIMULATION_TARGETS.LAST_SIMULATION]: null,
  [SIMULATION_TARGETS.SAVE_FETCHED_SIM_LOADING]: false,
  [SIMULATION_TARGETS.CLIENT_FLOW_CHOICE]: null
};

export const SIMULATION_LOCAL_STATE_TARGETS = [
  SIMULATION_TARGETS.KIND,
  SIMULATION_TARGETS.PRODUCT_TYPE,
  SIMULATION_TARGETS.CURRENT_CLIENT,
  SIMULATION_TARGETS.CURRENT_TERM,
  SIMULATION_TARGETS.AMOUNT,
  SIMULATION_TARGETS.DOWNPAYMENT,
  SIMULATION_TARGETS.HAS_DOWNPAYMENT,
  SIMULATION_TARGETS.WHEN_BUY,
  ...getScrapersLocalStateTargets(INCUMBENT_TYPES_NAMES.CLIENT),
  ...getScrapersLocalStateTargets(INCUMBENT_TYPES_NAMES.GUARANTOR),
  SIMULATION_TARGETS.CLIENT_FINANCIAL_INFO,
  SIMULATION_TARGETS.NEEDS_GUARANTOR,
  SIMULATION_TARGETS.CURRENT_GUARANTOR,
  SIMULATION_TARGETS.GUARANTOR_FINANCIAL_INFO,
  SIMULATION_TARGETS.ITEM_NAME,
  SIMULATION_TARGETS.CLIENT_KNOWS_STATUS_SHARE_LINK,
  SIMULATION_TARGETS.CLIENT_KNOWS_STATUS_PAGE,
  SIMULATION_TARGETS.CLIENT_CONFIRMED_EMAIL,
  SIMULATION_TARGETS.GUARANTOR_CONFIRMED_EMAIL,
  SIMULATION_TARGETS.CAN_EDIT_AMOUNT,
  SIMULATION_TARGETS.DECRYPTED_QUICK_CODE,
  SIMULATION_TARGETS.SAVE_FETCHED_SIM_LOADING,
  SIMULATION_TARGETS.CLIENT_FLOW_CHOICE
];
