import React from 'react';
import { node, string } from 'prop-types';

import styles from './styles.module.scss';

function IconRow({ icon: Icon, children, className }) {
  return (
    <div className={`row full-width m-left-4 ${styles.container} ${className}`}>
      <div className="column m-right-1">
        <Icon />
      </div>
      <div className="column">{children}</div>
    </div>
  );
}

IconRow.propTypes = { className: string, icon: node };

export default IconRow;
