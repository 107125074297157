import React from 'react';
import i18next from 'i18next';
import { bool, number, string } from 'prop-types';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import Button from '~components/Button';
import { ReactComponent as Upload } from '~assets/upload-white.svg';
import { ReactComponent as Check } from '~assets/check.svg';
import { ReactComponent as Error } from '~assets/error.svg';
import { isEven } from '~utils/number';
import ModalActions from '~redux/Modal/actions';
import EditButton from '~components/EditButton';

import styles from './styles.module.scss';

function DocumentationCardRow({
  target,
  name,
  index,
  modalTarget,
  success,
  error,
  contentContainer,
  contentContainerBackground
}) {
  const dispatch = useDispatch();
  const handleOpenUploader = () => {
    dispatch(ModalActions.openModal(modalTarget));
  };
  const hasSuccessOrError = success || error;
  return (
    <div
      className={cn(contentContainer, { [contentContainerBackground]: isEven(index) })}
      onClick={handleOpenUploader}>
      <div className={styles.documentationInfoContainer}>
        <div className={styles.documentationInfo}>
          {hasSuccessOrError && (
            <div className={styles.documentationStatusSign}>
              {success ? <Check /> : <Error className={styles.errorIcon} />}
            </div>
          )}
          <div className={styles.documentationInfoTextContainer}>
            <span className={styles.documentationInfoText}>{i18next.t(`${target}:${name}`)}</span>
            {error && (
              <span className={styles.documentationInfoError}>{i18next.t('DocumentationCard:error')}</span>
            )}
          </div>
        </div>
        {hasSuccessOrError ? (
          <EditButton className={styles.editButton} editIconClassName={styles.editIcon} />
        ) : (
          <Button lowercase className={styles.uploadButton}>
            <div className={styles.uploadButtonText}>{i18next.t('DocumentationCard:upload')}</div>
            <Upload />
          </Button>
        )}
      </div>
    </div>
  );
}

DocumentationCardRow.propTypes = {
  contentContainer: string,
  contentContainerBackground: string,
  error: bool,
  index: number,
  modalTarget: string,
  name: string,
  success: bool,
  target: string
};

export default DocumentationCardRow;
