import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { bool, string } from 'prop-types';

import styles from './styles.module.scss';
import FileUploaded from './components/FileUploaded';

function FilesUploaded({ containerClassName, containerWithErrorClassName, hasMaxSizeError }) {
  const { filesToUpload, uploadFilesError } = useSelector(state => state.file);
  const hasError = !!uploadFilesError || hasMaxSizeError;
  return (
    <div
      className={cn(styles.container, containerClassName, {
        [styles.containerWithError]: hasError,
        [containerWithErrorClassName]: hasError
      })}>
      {filesToUpload.map(file => (
        <FileUploaded key={file.name} file={file} />
      ))}
    </div>
  );
}

FilesUploaded.propTypes = {
  containerClassName: string,
  containerWithErrorClassName: string,
  hasMaxSizeError: bool
};

export default FilesUploaded;
