import { APP_STATES } from './app';

export const DEFAULT_PATH = '/simulations/steps/client_personal_information/moto_sin_aval';

export const ROUTES = {
  CLIENT_IDENTITY: {
    path: '/simulations/steps/client_personal_information/:kind',
    basePath: '/simulations/steps/client_personal_information',
    publicRoute: true,
    exact: true,
    needsKind: true,
    stepName: 'client_personal_information'
  },
  CLIENT_BIRTH_INFORMATION: {
    path: '/simulations/steps/client_birth_information/:kind/:quickCode',
    basePath: '/simulations/steps/client_birth_information',
    publicRoute: true,
    exact: true,
    needsKind: true,
    stepName: 'client_birth_information'
  },
  CLIENT_LABOR_INFORMATION: {
    path: '/simulations/steps/client_labor_information/:kind/:quickCode',
    basePath: '/simulations/steps/client_labor_information',
    publicRoute: true,
    exact: true,
    needsKind: true,
    stepName: 'client_labor_information'
  },
  CREDIT_SIMULATION: {
    path: '/simulations/steps/client_quotes_information/:kind/:quickCode',
    basePath: '/simulations/steps/client_quotes_information',
    publicRoute: true,
    exact: true,
    stepName: 'client_quotes_information'
  },
  CREDIT_CASH_SUCCESS: {
    path: '/simulations/steps/credit_cash_success/:kind/:quickCode',
    basePath: '/simulations/steps/credit_cash_success',
    publicRoute: true,
    exact: true,
    stepName: 'credit_cash_success'
  },
  CLIENT_INCOMES: {
    path: '/simulations/steps/client_financial_information/:kind/:quickCode',
    basePath: '/simulations/steps/client_financial_information',
    publicRoute: true,
    exact: true,
    stepName: 'client_financial_information'
  },
  CLIENT_PERSONAL_DOCUMENTATION: {
    path: '/simulations/steps/client_personal_documentation/:kind/:quickCode',
    basePath: '/simulations/steps/client_personal_documentation',
    publicRoute: true,
    exact: true,
    stepName: 'client_personal_documentation'
  },
  CLIENT_CONFIRMATION: {
    path: '/simulations/steps/client_confirmation/:kind/:quickCode',
    basePath: '/simulations/steps/client_confirmation',
    publicRoute: true,
    exact: true,
    stepName: 'client_confirmation'
  },
  CONTINUE_WITH_GUARANTOR: {
    path: '/simulations/steps/continue/:kind/:quickCode',
    basePath: '/simulations/steps/continue',
    publicRoute: true,
    exact: true,
    stepName: 'continue'
  },
  THANK_YOU: {
    path: '/thank-you',
    basePath: '/thank-you',
    publicRoute: true,
    exact: true
  },
  LETS_START: {
    path: '/simulations/steps/start/:kind/:quickCode',
    basePath: '/simulations/steps/start',
    publicRoute: true,
    exact: true,
    stepName: 'start'
  },
  PRODUCT_NOT_AVAILABLE: {
    path: '/product_not_available',
    basePath: '/product_not_available',
    publicRoute: true,
    exact: true,
    stepName: 'product_not_available'
  },
  ERROR: {
    path: '/error/server',
    basePath: '/error',
    publicRoute: true,
    exact: true
  },
  FAILED_LOADING_TERMS: {
    path: '/error/terms',
    basePath: '/error',
    publicRoute: true,
    exact: true
  },
  GUARANTOR_IDENTITY: {
    path: '/simulations/steps/guarantor_personal_information/:kind/:quickCode',
    basePath: '/simulations/steps/guarantor_personal_information',
    publicRoute: true,
    exact: true,
    stepName: 'guarantor_personal_information'
  },
  GUARANTOR_BIRTH_INFORMATION: {
    path: '/simulations/steps/guarantor_birth_information/:kind/:quickCode',
    basePath: '/simulations/steps/guarantor_birth_information',
    publicRoute: true,
    exact: true,
    stepName: 'guarantor_birth_information'
  },
  GUARANTOR_LABOR_INFORMATION: {
    path: '/simulations/steps/guarantor_labor_information/:kind/:quickCode',
    basePath: '/simulations/steps/guarantor_labor_information',
    publicRoute: true,
    exact: true,
    stepName: 'guarantor_labor_information'
  },
  GUARANTOR_INCOMES: {
    path: '/simulations/steps/guarantor_financial_information/:kind/:quickCode',
    basePath: '/simulations/steps/guarantor_financial_information',
    publicRoute: true,
    exact: true,
    stepName: 'guarantor_financial_information'
  },
  GUARANTOR_PERSONAL_DOCS: {
    path: '/simulations/steps/guarantor_personal_documentation/:kind/:quickCode',
    basePath: '/simulations/steps/guarantor_personal_documentation',
    publicRoute: true,
    exact: true,
    stepName: 'guarantor_personal_documentation'
  },
  GUARANTOR_CONFIRMATION: {
    path: '/simulations/steps/guarantor_confirmation/:kind/:quickCode',
    basePath: '/simulations/steps/guarantor_confirmation',
    publicRoute: true,
    exact: true,
    stepName: 'guarantor_confirmation'
  },
  STATUS_PAGE: {
    path: '/simulations/steps/status/:kind/:quickCode',
    basePath: '/simulations/steps/status',
    publicRoute: true,
    exact: true,
    stepName: 'status'
  },
  MAINTENANCE: {
    path: '/maintenance',
    basePath: '/maintenance',
    publicRoute: true,
    exact: true
  },
  // New flow screens
  CLIENT_FLOW_CHOICE: {
    path: '/simulations/steps/client_flow_choice/:kind/:quickCode',
    basePath: '/simulations/steps/client_flow_choice',
    publicRoute: true,
    exact: true,
    stepName: 'client_flow_choice'
  },
  CLIENT_UNIQUE_KEY_SUCCESS: {
    path: '/simulations/steps/client_unique_key_success/:kind/:quickCode',
    basePath: '/simulations/steps/client_unique_key_success',
    publicRoute: true,
    exact: true,
    stepName: 'client_unique_key_success'
  },
  CLIENT_UNIQUE_KEY: {
    path: '/simulations/steps/client_unique_credential_information/:kind/:quickCode',
    basePath: '/simulations/steps/client_unique_credential_information',
    publicRoute: true,
    exact: true,
    stepName: 'client_unique_credential_information'
  },
  CREDIT_PENDING: {
    path: '/simulations/steps/credit_pending/:kind/:quickCode',
    basePath: '/simulations/steps/credit_pending',
    publicRoute: true,
    exact: true,
    stepName: 'credit_pending'
  },
  GUARANTOR_FLOW_CHOICE: {
    path: '/guarantor-flow-choice',
    basePath: '/guarantor-flow-choice',
    publicRoute: true,
    exact: true
  },
  CREDIT_APPROVED: {
    path: '/credit-approved',
    basePath: '/credit-approved',
    publicRoute: true,
    exact: true
  },

  UNIQUE_KEY_ERROR: {
    path: '/error/unique-key',
    basePath: '/error',
    publicRoute: true,
    exact: true
  },
  GUARANTOR_UNIQUE_KEY: {
    path: '/guarantor-unique-key',
    basePath: '/guarantor-unique-key',
    publicRoute: true,
    exact: true
  },
  CONTINUE_GUARANTOR_UNIQUE_KEY: {
    path: '/continue',
    basePath: '/continue',
    publicRoute: true,
    exact: true
  }
};

export const ROUTES_IDS = {
  CLIENT_IDENTITY: 'CLIENT_IDENTITY',
  CREDIT_SIMULATION: 'CREDIT_SIMULATION',
  CLIENT_INCOMES: 'CLIENT_INCOMES',
  CLIENT_PERSONAL_DOCUMENTATION: 'CLIENT_PERSONAL_DOCUMENTATION',
  CLIENT_CONFIRMATION: 'CLIENT_CONFIRMATION',
  CONTINUE_WITH_GUARANTOR: 'CONTINUE_WITH_GUARANTOR',
  GUARANTOR_IDENTITY: 'GUARANTOR_IDENTITY',
  GUARANTOR_INCOMES: 'GUARANTOR_INCOMES',
  GUARANTOR_PERSONAL_DOCS: 'GUARANTOR_PERSONAL_DOCS',
  GUARANTOR_CONFIRMATION: 'GUARANTOR_CONFIRMATION'
};

export const HOME_PATHS = [
  '/',
  '/drafts/new',
  '/steps/client_personal_information',
  '/steps/client_personal_information/persona',
  '/simulations/steps/client_personal_information'
];

export const PRODUCTS_NOT_AVAILABLE_PATHS = ['celular', 'celular_sin_aval', 'persona', 'persona_sin_aval'];

export const PATHS_BY_STATE = {
  [APP_STATES.MAINTENANCE]: ROUTES.MAINTENANCE.path
};
