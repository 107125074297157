import React from 'react';
import { int } from 'prop-types';
import i18next from 'i18next';

import { setMoneyAmount } from '~utils/money';

import IconRow from '../IconRow';
import { ICONS } from '../../constants';

function Downpayment({ amount, defaultAmount }) {
  return amount === 0 ? null : (
    <IconRow icon={ICONS.FLOW} className="middle m-bottom-2">
      <div className="row full-width">
        <div className="medium m-right-1">{i18next.t('CreditHeader:foot')}</div>
        <div className="bold">{setMoneyAmount(amount, defaultAmount)}</div>
      </div>
    </IconRow>
  );
}

Downpayment.propTypes = {
  amount: int,
  defaultAmount: int
};

export default Downpayment;
