import { hasExpiredId, hasNoId } from './identityCard';

export const getRequiredDocuments = (documentation, value) =>
  documentation.find(doc => doc.show(value)) || {
    documents: []
  };

export const mapPersonalDocumentationInfo = ({ values, idDocumentationInfo, visaDocumentationInfo }) => [
  hasNoId(values) ? visaDocumentationInfo : idDocumentationInfo,
  ...(hasExpiredId(values) ? [visaDocumentationInfo] : [])
];
