import i18next from 'i18next';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, string } from 'prop-types';

import Checkbox from '~app/components/Checkbox';
import DocumentationActions from '~redux/Documentation/actions';

import styles from './styles.module.scss';

function WithoutInfoCheckbox({
  checkboxText = i18next.t('DocumentationCard:withoutInfo'),
  className,
  warningTarget,
  warningStateTarget,
  hasError
}) {
  const documentation = useSelector(state => state.documentation);
  const dispatch = useDispatch();
  const handleChange = e => {
    dispatch(DocumentationActions.setDocumentationWarning(warningTarget, e.target.checked));
  };
  return (
    <div className={className}>
      <Checkbox
        containerClassName={styles.checkboxContainer}
        onChange={handleChange}
        checked={documentation[warningStateTarget]}
        hasError={hasError}>
        {checkboxText}
      </Checkbox>
    </div>
  );
}

WithoutInfoCheckbox.propTypes = {
  checkboxText: string,
  className: string,
  hasError: bool,
  warningStateTarget: string,
  warningTarget: string
};

export default WithoutInfoCheckbox;
