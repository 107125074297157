import * as Yup from 'yup';

import { LABOR_INFORMATION_VALIDATION } from '~constants/validations';
import { addPrefix, camelToSnakeCase, getValueOrEmptyString, snakeToCamelCase } from '~utils/string';
import { mapStepMetaInfo } from '~redux/Simulation/mappers';

import { LABOR_REMAINING_KEYS, LABOR_FIELDS } from './constants';

export const VALIDATION_SCHEMA = laborSituation =>
  Yup.object().shape(LABOR_INFORMATION_VALIDATION(laborSituation));

const mapIdentityInitialValues = (prevValues, actualValue, queryParams, func) => ({
  ...prevValues,
  [actualValue]: func(queryParams[actualValue])
});

export const getIdentityInitialValues = queryParams => {
  const values = [...LABOR_REMAINING_KEYS];
  return values.reduce(
    (prevValues, actualValue) =>
      mapIdentityInitialValues(prevValues, actualValue, queryParams, getValueOrEmptyString),
    {}
  );
};

export const mapInitialValues = ({
  employmentSituation,
  emittedInvoicesLastYear,
  incomes,
  jobStartDate
}) => ({
  laborSituation: snakeToCamelCase(employmentSituation),
  emittedInvoicesLastYear: emittedInvoicesLastYear ? snakeToCamelCase(emittedInvoicesLastYear) : undefined,
  monthlyIncomes: incomes || '',
  jobStartDate: jobStartDate || ''
});

export const getFormConfiguration = {
  validationSchema: laborSituation => VALIDATION_SCHEMA(laborSituation)
};

export const getIncumbentValues = ({ prefix, values }) =>
  Object.keys(LABOR_FIELDS).map(key => ({
    kind: addPrefix(prefix, `_${camelToSnakeCase(LABOR_FIELDS[key])}_meta`),
    value:
      typeof values[LABOR_FIELDS[key]] === 'string'
        ? camelToSnakeCase(values[LABOR_FIELDS[key]])
        : values[LABOR_FIELDS[key]] || null
  }));

export const postLaborInfoMapper = (values, incumbent, stepTarget) => [
  ...getIncumbentValues({ prefix: incumbent, values }),
  ...mapStepMetaInfo(stepTarget)
];
