import { bool } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import SimulationActions from '~redux/Simulation/actions';

import LoadingWrapper from '../LoadingWrapper';

function SimulationLoadingWrapper({ children, loading, withInitialLoading }) {
  const dispatch = useDispatch();
  const { quickCode } = useParams();
  const {
    createSimulation,
    stepsProgress,
    currentSimulation,
    currentSimulationLoading,
    stepsProgressLoading,
    saveFetchedSimulationLoading,
    loanDraftLoading
  } = useSelector(state => state.simulation);
  useEffect(() => {
    if (withInitialLoading || (!createSimulation && !currentSimulation && !stepsProgress)) {
      dispatch(SimulationActions.setSimulationUp(quickCode));
    }
  }, [dispatch]);
  return (
    <LoadingWrapper
      withInitialLoading={withInitialLoading || (!createSimulation && !currentSimulation && !stepsProgress)}
      loading={
        loading ||
        stepsProgressLoading ||
        currentSimulationLoading ||
        loanDraftLoading ||
        saveFetchedSimulationLoading
      }>
      {children}
    </LoadingWrapper>
  );
}

SimulationLoadingWrapper.propTypes = {
  loading: bool,
  withInitialLoading: bool
};

export default SimulationLoadingWrapper;
