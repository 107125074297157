import i18next from 'i18next';
import React from 'react';

import TransitionScreen from '~components/TransitionScreen';
import { ReactComponent as CheckGuy } from '~assets/check-guy.svg';
import { ROUTES } from '~constants/routes';
import { UNIQUE_KEY_ITEMS } from '~constants/uniqueKey';

import styles from './styles.module.scss';

function ClientUniqueKeySuccess() {
  return (
    <TransitionScreen
      title={i18next.t('ClientUniqueKeySuccess:title')}
      transitionCardIcon={CheckGuy}
      transitionCardIconClassName={styles.icon}
      transitionCardTitle={i18next.t('ClientUniqueKeySuccess:transitionTitle')}
      transitionCardSource="ClientUniqueKeySuccess"
      transitionCardContent={UNIQUE_KEY_ITEMS}
      customStepName={ROUTES.CLIENT_PERSONAL_DOCUMENTATION.stepName}
    />
  );
}

export default ClientUniqueKeySuccess;
