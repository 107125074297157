import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { useParams } from 'react-router';

import { MODALS } from '~redux/Modal/constants';
import PriceModal from '~app/components/PriceModal';
import SimulationActions from '~redux/Simulation/actions';
import { calculateDownpayment, isConsumerSimulation } from '~models/simulations';
import { useModal } from '~app/hooks/useModal';
import { getDownpaymentByKind } from '~utils/simulation';

import { mapSimulationValues } from '../../mappers';

import { getValidationSchema } from './utils';
import { FIELDS } from './constants';

function AmountModal() {
  const dispatch = useDispatch();
  const { kind } = useParams();
  const { amount, loanDraft, productType } = useSelector(state => state.simulation);
  const { handleCloseModal } = useModal();
  const isConsumer = isConsumerSimulation(kind);
  const handleSubmit = values => {
    const newDownpayment = getDownpaymentByKind(
      kind,
      calculateDownpayment(loanDraft.loanConfig, values.amount, 0)
    );
    dispatch(SimulationActions.setAmountAndDownpayment(values.amount, newDownpayment));
    dispatch(
      SimulationActions.getTerms(
        mapSimulationValues({
          currentValue: values.amount,
          productType,
          kind,
          isConsumer,
          advancePayment: newDownpayment
        })
      )
    );
    handleCloseModal(MODALS.AMOUNT)();
  };
  return (
    <PriceModal
      title={i18next.t('CreditSimulation:editAmount')}
      modalName={MODALS.AMOUNT}
      fieldName={FIELDS.AMOUNT}
      initialValues={{ amount: Math.floor(amount) }}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(
        loanDraft?.loanConfig.minimumAmount,
        loanDraft?.loanConfig.maximumAmount
      )}
    />
  );
}

export default AmountModal;
