import React from 'react';
import { arrayOf, func, node, shape, string } from 'prop-types';
import cn from 'classnames';

import { field } from '~propTypes/field';

import styles from './styles.module.scss';
import { getIdentityFields } from './utils';

function IdentityFields({
  header: Header,
  values,
  onChange,
  setFieldTouched,
  errors,
  touched,
  setFieldValue,
  fieldsClassName,
  additionalFields = [],
  formClassName
}) {
  const identityFields = getIdentityFields(additionalFields);
  return (
    <div className={cn(styles.formContainer, formClassName)}>
      <Header />
      <div className={cn(styles.fields, fieldsClassName)}>
        {identityFields.map(({ component: Field, name, ...props }) => {
          const handleBlur = e => {
            const { value } = e.target;
            setFieldValue(name, value.trim());
            setFieldTouched(name, value, true);
          };
          return (
            <Field
              key={name}
              value={values[name]}
              name={name}
              error={errors[name]}
              handleChange={onChange}
              touched={touched[name]}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              autocomplete={name}
              {...props}
            />
          );
        })}
      </div>
    </div>
  );
}

IdentityFields.propTypes = {
  additionalFields: arrayOf(field),
  errors: shape,
  fieldsClassName: string,
  formClassName: string,
  header: node,
  setFieldTouched: func,
  setFieldValue: func,
  touched: shape,
  values: shape,
  onChange: func
};

export default IdentityFields;
