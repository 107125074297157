import { useSelector } from 'react-redux';

import { useIsView } from '~app/hooks/useIsView';
import { INCUMBENT_TYPES } from '~constants/incumbent';

export const useShowStatusPageButton = () => {
  const isGuarantorView = useIsView(INCUMBENT_TYPES.GUARANTOR);
  const { clientKnowsStatusPage, guarantorKnowsStatusPage } = useSelector(state => state.simulation);
  return isGuarantorView ? guarantorKnowsStatusPage : clientKnowsStatusPage;
};
