import React from 'react';
import { useSelector } from 'react-redux';

import LaborInformation from '~app/components/LaborInformation';
import SimulationLoadingWrapper from '~app/components/SimulationLoadingWrapper';
import { INCUMBENT_TYPES } from '~constants/incumbent';
import { ROUTES } from '~constants/routes';
import { STEPS_TARGETS } from '~constants/steps';
import { SIMULATION_TARGETS } from '~redux/Simulation/constants';

function GuarantorLaborInformation() {
  const { guarantorFinancialInfo, loanDraftLoading } = useSelector(state => state.simulation);
  return (
    <SimulationLoadingWrapper loading={loanDraftLoading}>
      <LaborInformation
        loading={loanDraftLoading}
        incumbent={INCUMBENT_TYPES.GUARANTOR}
        currentInfo={guarantorFinancialInfo}
        currentTarget={SIMULATION_TARGETS.CURRENT_GUARANTOR}
        nextStep={ROUTES.GUARANTOR_INCOMES.stepName}
        stepTarget={STEPS_TARGETS.GUARANTOR_LABOR_INFORMATION}
      />
    </SimulationLoadingWrapper>
  );
}

export default GuarantorLaborInformation;
