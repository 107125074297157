import { ROUTES } from '~constants/routes';
import {
  CLIENT_DOC_STEPS_TARGETS,
  CLIENT_STEPS_TARGETS,
  GUARANTOR_DOC_STEPS_TARGETS,
  GUARANTOR_STEPS_TARGETS
} from '~constants/steps';

export const CLIENT_SECTIONS = [
  {
    step: CLIENT_STEPS_TARGETS.CLIENT_BIRTH_INFORMATION,
    stepName: ROUTES.CLIENT_BIRTH_INFORMATION.stepName
  },
  {
    step: CLIENT_STEPS_TARGETS.CLIENT_LABOR_INFORMATION,
    stepName: ROUTES.CLIENT_LABOR_INFORMATION.stepName
  },
  {
    step: CLIENT_STEPS_TARGETS.CLIENT_QUOTES_INFORMATION,
    stepName: ROUTES.CREDIT_SIMULATION.stepName
  },
  {
    step: CLIENT_STEPS_TARGETS.CLIENT_FINANCIAL_INFORMATION,
    stepName: ROUTES.CLIENT_INCOMES.stepName
  },
  {
    step: CLIENT_STEPS_TARGETS.CLIENT_PERSONAL_DOCUMENTATION,
    stepName: ROUTES.CLIENT_PERSONAL_DOCUMENTATION.stepName
  }
];

export const CLIENT_NEEDED_STEPS = [
  CLIENT_STEPS_TARGETS.CLIENT_PERSONAL_INFORMATION,
  CLIENT_STEPS_TARGETS.CLIENT_BIRTH_INFORMATION,
  CLIENT_STEPS_TARGETS.CLIENT_LABOR_INFORMATION,
  CLIENT_STEPS_TARGETS.CLIENT_QUOTES_INFORMATION,
  CLIENT_STEPS_TARGETS.CLIENT_FINANCIAL_INFORMATION,
  CLIENT_STEPS_TARGETS.CLIENT_PERSONAL_DOCUMENTATION
];

export const GUARANTOR_SECTIONS = [
  {
    step: GUARANTOR_STEPS_TARGETS.GUARANTOR_PERSONAL_INFORMATION,
    stepName: ROUTES.GUARANTOR_IDENTITY.stepName
  },
  {
    step: GUARANTOR_STEPS_TARGETS.GUARANTOR_BIRTH_INFORMATION,
    stepName: ROUTES.GUARANTOR_BIRTH_INFORMATION.stepName
  },
  {
    step: GUARANTOR_STEPS_TARGETS.GUARANTOR_LABOR_INFORMATION,
    stepName: ROUTES.GUARANTOR_LABOR_INFORMATION.stepName
  },
  {
    step: GUARANTOR_STEPS_TARGETS.GUARANTOR_FINANCIAL_INFORMATION,
    stepName: ROUTES.GUARANTOR_INCOMES.stepName
  },
  {
    step: GUARANTOR_STEPS_TARGETS.GUARANTOR_PERSONAL_DOCUMENTATION,
    stepName: ROUTES.GUARANTOR_PERSONAL_DOCS.stepName
  }
];

export const GUARANTOR_NEEDED_STEPS = [
  GUARANTOR_STEPS_TARGETS.GUARANTOR_PERSONAL_INFORMATION,
  GUARANTOR_STEPS_TARGETS.GUARANTOR_BIRTH_INFORMATION,
  GUARANTOR_STEPS_TARGETS.GUARANTOR_LABOR_INFORMATION,
  GUARANTOR_STEPS_TARGETS.GUARANTOR_FINANCIAL_INFORMATION,
  GUARANTOR_STEPS_TARGETS.GUARANTOR_PERSONAL_DOCUMENTATION
];

// TODO: Add remaining steps
export const CLIENT_DOCS_NEEDED_STEPS = [CLIENT_DOC_STEPS_TARGETS.CLIENT_PERSONAL_DOCUMENTATION];
export const GUARANTOR_DOCS_NEEDED_STEPS = [GUARANTOR_DOC_STEPS_TARGETS.GUARANTOR_PERSONAL_DOCUMENTATION];

export const APP_VIEW = 'app';
