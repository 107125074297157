import { EMPLOYMENT_STATUS } from '~constants/employmentSituation';
import { CONSUMER_SIMULATION_TYPES, MOTORCYCLE_TYPES, WITH_DEALER } from '~constants/simulations';
import { inRange } from '~utils/number';
import { getCurrentRutAndDv } from '~utils/rut';
import { canEditAmount, getDownpaymentByKind } from '~utils/simulation';
import { onlyNumbers } from '~utils/string';

export const isConsumerSimulation = kind => CONSUMER_SIMULATION_TYPES.includes(kind);

export const isMotorcycleSimulation = kind => MOTORCYCLE_TYPES.includes(kind);

export const getCorrectEmploymentSituation = employmentSituation =>
  employmentSituation === EMPLOYMENT_STATUS.DEPENDENT_AND_INDEPENDENT
    ? 'dependent_and_independent'
    : employmentSituation;

export const calculateMinimumDownpayment = (downpaymentPercentage, amount) =>
  (amount * downpaymentPercentage) / 100;

export const calculateMaximumDownpayment = amount => 0.8 * amount;

export const getInitialDownpayment = ({ defaultDownpaymentPercentage, amount, downpayment }) => {
  const minimumDownpayment = calculateMinimumDownpayment(defaultDownpaymentPercentage, amount);
  const maximumDownpayment = calculateMaximumDownpayment(amount);
  const actualDownpayment = downpayment && downpayment >= 0 ? downpayment : minimumDownpayment;
  return actualDownpayment >= maximumDownpayment ? maximumDownpayment : actualDownpayment;
};

export const calculateDownpayment = ({ defaultDownpaymentPercentage = 20 }, amount, downpayment) =>
  defaultDownpaymentPercentage
    ? getInitialDownpayment({ defaultDownpaymentPercentage, amount, downpayment })
    : null;

export const getInitialSimulationValues = ({ amount, downpayment, kind, productType, loanConfig }) => ({
  amount,
  productType,
  kind,
  downpayment: getDownpaymentByKind(kind, calculateDownpayment(loanConfig, amount, downpayment)),
  term: Math.min(...loanConfig.possibleTerms)
});

export const needsDealer = kind => WITH_DEALER.includes(kind);

export const hasChangedStepState = (previousState, actualState) =>
  !Object.keys(previousState).every(key => previousState[key] === actualState[key]);

export const getValidAmount = (amount, loanConfig) =>
  amount && inRange(amount, loanConfig.minimumAmount, loanConfig.maximumAmount)
    ? amount
    : loanConfig.defaultAmount;

export const getDefaultTerm = loanDraft =>
  loanDraft?.loanConfig?.defaultTerm || Math.min(...loanDraft?.loanConfig?.possibleTerms);

export const getValidCurrentTerm = (currentTerm, loanDraft) =>
  currentTerm ? currentTerm.term : getDefaultTerm(loanDraft);

export const mapCreationValues = ({ values, queryParams, kind, loanDraft }) => {
  const { rut, dv } = getCurrentRutAndDv(values.rut);
  const currentClient = {
    ...values,
    phone: onlyNumbers(values.phone),
    ...getCurrentRutAndDv(values.rut)
  };
  const amount = getValidAmount(queryParams.amount, loanDraft.loanConfig);
  const editAmount = canEditAmount(queryParams, kind);
  const initialSimulationValues = getInitialSimulationValues({
    amount,
    downpayment: queryParams.downpayment,
    kind,
    productType: loanDraft.productType,
    loanConfig: loanDraft.loanConfig
  });
  return { rut, dv, currentClient, amount, editAmount, initialSimulationValues };
};
