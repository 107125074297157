import { ZERO_FILE_SIZE, FILE_SIZES, MAX_SIZE } from '~constants/file';

export const readFileData = (file, handleLoad) => {
  const reader = new FileReader();
  reader.onload = handleLoad;
  reader.readAsDataURL(file);
};

export const parseXMLNodeValue = (xml, node) => {
  const parsedResponse = new DOMParser().parseFromString(xml, 'application/xml');

  return parsedResponse?.getElementsByTagName(node)?.[0]?.childNodes?.[0]?.nodeValue;
};

export const blobToFile = (theBlob, fileName) => {
  const file = theBlob;
  // A Blob() is almost a File() - it's just missing the two properties below which we will add
  file.lastModifiedDate = new Date();
  file.name = fileName;

  // Cast to a File() type
  return file;
};

export const downloadFile = (data, fileName) => {
  const blob = new Blob([data]);
  const blobUrl = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.download = fileName;
  a.href = blobUrl;
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  a.parentNode.removeChild(a);
};

export const addFilesToUpload = (newFiles, files) => {
  const filesToBeFiltered = [...newFiles];
  const actualFilesNames = files ? files.map(file => file?.name) : [];
  const filesToBeAdded = filesToBeFiltered.filter(file => !actualFilesNames.includes(file.name));
  return [...files, ...filesToBeAdded];
};

export const removeFileToUpload = (fileName, filesToBeFiltered) =>
  filesToBeFiltered.filter(file => file.name !== fileName);

export const removeFilesToUpload = (files, filesToBeFiltered) =>
  filesToBeFiltered.filter(file => !files.includes(file));

export const formatBytes = (bytes, decimals = 2, k = 1024) => {
  const pos = Math.floor(Math.log(bytes) / Math.log(k));
  return bytes === 0
    ? ZERO_FILE_SIZE
    : `${parseFloat((bytes / Math.pow(k, pos)).toFixed(Math.max(0, decimals)))} ${FILE_SIZES[pos]}`;
};

export const hasSurpassedMaxSize = file => file.size > MAX_SIZE;
