import i18next from 'i18next';
import { arrayOf, bool, shape, string } from 'prop-types';
import React from 'react';

import styles from './styles.module.scss';

function FileUploaderModalItem({ key, fileInfoTexts, linkText, link, show }) {
  const hasLink = linkText && link;

  return show ? (
    <div className={styles.itemContainer} key={key}>
      <div className={styles.dot} />
      <span className={styles.textContainer}>
        {fileInfoTexts?.map(({ boldText, infoText }) => (
          <>
            <b>{boldText}</b>
            {infoText}
          </>
        ))}
        {hasLink && (
          <div className={styles.linkContainer}>
            {linkText}
            <span className={styles.link} onClick={() => window.open(link, '_blank')}>
              {i18next.t('FileUploaderModalItem:here')}
            </span>
          </div>
        )}
      </span>
    </div>
  ) : null;
}

FileUploaderModalItem.propTypes = {
  fileInfoTexts: arrayOf(
    shape({
      boldText: string,
      infoText: string
    })
  ),
  key: string,
  link: string,
  linkText: string,
  show: bool
};

export default FileUploaderModalItem;
