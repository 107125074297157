import { getLastCharacter, removeCommas, removeDots, takeNCharacters } from '~utils/string';

export const getCompleteRut = (rut, dv) => (rut && dv ? [rut, '-', dv].join('') : '');

export const getCurrentRutAndDv = rut => {
  if (!rut) {
    return { rut, dv: null };
  }
  const charsToDelete = rut.includes('-') ? 2 : 1;
  const rutWithoutDv = takeNCharacters(rut.length - charsToDelete)?.(rut);
  const rutWithoutDvAndDots = removeDots(rutWithoutDv);
  const rutWithoutDvDotsAndCommas = removeCommas(rutWithoutDvAndDots);
  return {
    rut: rutWithoutDvDotsAndCommas,
    dv: getLastCharacter(rut)
  };
};

export const validateRut = value => {
  const { rut, dv } = getCurrentRutAndDv(value);
  let M = 0,
    S = 1;
  let newRut = rut;
  for (; newRut; newRut = Math.floor(newRut / 10)) {
    S = (S + (newRut % 10) * (9 - (M++ % 6))) % 11;
  }
  const newDv = S ? S - 1 : 'k';
  return newDv?.toString() === dv?.toLowerCase();
};

export const validateRutLength = value => {
  const { rut, dv } = getCurrentRutAndDv(value);
  return rut?.length <= 8 && dv?.length === 1;
};
