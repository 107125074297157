import i18next from 'i18next';
import { string } from 'prop-types';
import React from 'react';

import styles from './styles.module.scss';

function ContinueHeader({ containerClassName, title, subtitle }) {
  return (
    <div className={containerClassName}>
      <div className={styles.transitionHeader}>
        <span className={styles.continueHeaderTitle}>
          {title || i18next.t('ContinueWithGuarantor:letsContinue')}
        </span>
        <span className={styles.continueHeaderSubtitle}>{subtitle}</span>
      </div>
    </div>
  );
}

ContinueHeader.propTypes = {
  containerClassName: string,
  subtitle: string,
  title: string
};

export default ContinueHeader;
