import { number, string } from 'prop-types';
import React from 'react';
import cn from 'classnames';

import { ReactComponent as Success } from '~assets/success.svg';

import { ICON_SIZE } from './constants';
import styles from './styles.module.scss';

function SuccessMessage({ defaultIconSize = ICON_SIZE, successText, className, textClassName }) {
  return (
    <div className={cn(styles.successContainer, className)}>
      <Success width={defaultIconSize} height={defaultIconSize} />
      <span className={cn(styles.successText, textClassName)}>{successText}</span>
    </div>
  );
}

SuccessMessage.propTypes = {
  className: string,
  defaultIconSize: number,
  successText: string,
  textClassName: string
};

export default SuccessMessage;
