import React from 'react';
import { string } from 'prop-types';

import styles from './styles.module.scss';

function SelectOption({ message, Image }) {
  return (
    <div className={`column charcoal ${styles.option}`}>
      {Image} {message}
    </div>
  );
}

SelectOption.propTypes = {
  Image: string,
  message: string
};

export default SelectOption;
