import { getCompletePhone, getCorrectValue, getCorrenctName } from '~utils/client';

export const INCUMBENT_TYPES_NAMES = {
  CLIENT: 'CLIENT',
  GUARANTOR: 'GUARANTOR'
};

export const INCUMBENT_TYPES = {
  [INCUMBENT_TYPES_NAMES.CLIENT]: 'client',
  [INCUMBENT_TYPES_NAMES.GUARANTOR]: 'guarantor'
};

export const INCUMBENT_IDENTITY_FIELDS = {
  NAME: 'Name',
  PHONE: 'Phone',
  EMAIL: 'Email',
  RUT: 'Rut',
  DV: 'Dv'
};

export const INCUMBENT_INCOMES_FIELDS = {
  EMPLOYMENT_SITUATION: 'EmploymentSituation',
  INCOMES: 'Incomes',
  AFP: 'Afp',
  SII: 'Sii'
};

export const INCUMBENT_BIRTH_FIELDS = {
  GENDER: 'Gender',
  BIRTHDAY: 'Birthday',
  NATIONALITY: 'Nationality'
};

export const INCUMBENT_IDENTITY_TARGETS = [
  { fieldName: INCUMBENT_IDENTITY_FIELDS.NAME, valueTarget: 'name', fixer: getCorrenctName },
  { fieldName: INCUMBENT_IDENTITY_FIELDS.PHONE, valueTarget: 'phone', fixer: getCompletePhone },
  { fieldName: INCUMBENT_IDENTITY_FIELDS.EMAIL, valueTarget: 'email', fixer: getCorrectValue },
  { fieldName: INCUMBENT_IDENTITY_FIELDS.RUT, valueTarget: 'rut' },
  { fieldName: INCUMBENT_IDENTITY_FIELDS.DV, valueTarget: 'dv' },
  { fieldName: INCUMBENT_BIRTH_FIELDS.GENDER, valueTarget: 'gender' },
  { fieldName: INCUMBENT_BIRTH_FIELDS.BIRTHDAY, valueTarget: 'birthday' },
  { fieldName: INCUMBENT_BIRTH_FIELDS.NATIONALITY, valueTarget: 'nationality' }
];
