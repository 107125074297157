import { createTypes, completeTypes, withPostSuccess, withPostFailure } from 'redux-recompose';

import * as ScrapersService from '~services/ScrapersService';

import { SCRAPERS_TARGETS } from './constants';

export const actions = createTypes(
  completeTypes(
    ['SCRAPE_CMF_DATA', 'SCRAPE_SII_DATA', 'SCRAPE_AFP_DATA', 'SCRAPE_ALL_DATA'],
    ['SET_SCRAPER_INFO']
  ),
  '@@SCRAPERS'
);

export const actionCreators = {
  scrapeCmfData: (values, postSuccessAction, postErrorAction) => ({
    type: actions.SCRAPE_CMF_DATA,
    service: ScrapersService.scrapeCmfData,
    target: SCRAPERS_TARGETS.SCRAPER_CMF_DATA,
    payload: values,
    injections: [
      withPostSuccess(dispatch => {
        if (postSuccessAction) {
          dispatch(postSuccessAction);
        }
      }),
      withPostFailure((dispatch, response) => {
        if (postErrorAction) {
          dispatch(postErrorAction(response?.status));
        }
      })
    ]
  }),
  scrapeSiiData: (values, postSuccessAction, postErrorAction) => ({
    type: actions.SCRAPE_SII_DATA,
    service: ScrapersService.scrapeSiiData,
    target: SCRAPERS_TARGETS.SCRAPER_SII_DATA,
    payload: values,
    injections: [
      withPostSuccess(dispatch => {
        if (postSuccessAction) {
          dispatch(postSuccessAction);
        }
      }),
      withPostFailure((dispatch, response) => {
        if (postErrorAction) {
          dispatch(postErrorAction(response?.status));
        }
      })
    ]
  }),
  scrapeAfpData: (values, postSuccessAction, postErrorAction) => ({
    type: actions.SCRAPE_AFP_DATA,
    service: ScrapersService.scrapeAfpData,
    target: SCRAPERS_TARGETS.SCRAPER_AFP_DATA,
    payload: values,
    injections: [
      withPostSuccess(dispatch => {
        if (postSuccessAction) {
          dispatch(postSuccessAction);
        }
      }),
      withPostFailure((dispatch, response) => {
        if (postErrorAction) {
          dispatch(postErrorAction(response?.status));
        }
      })
    ]
  }),
  scrapeAllData: (values, postSuccessAction, postErrorAction) => ({
    type: actions.SCRAPE_ALL_DATA,
    service: ScrapersService.scrapeAllData,
    target: SCRAPERS_TARGETS.SCRAPER_ALL_DATA,
    payload: values,
    injections: [
      withPostSuccess(dispatch => {
        if (postSuccessAction) {
          dispatch(postSuccessAction);
        }
      }),
      withPostFailure((dispatch, response) => {
        if (postErrorAction) {
          dispatch(postErrorAction(response?.status));
        }
      })
    ]
  }),
  setScraperInfo: (target, payload) => ({
    type: actions.SET_SCRAPER_INFO,
    target,
    payload
  }),
  resetErrors: () => dispatch => {
    dispatch(actionCreators.setScraperInfo(SCRAPERS_TARGETS.SCRAPER_SUBMIT_COUNT, 0));
    dispatch(actionCreators.setScraperInfo(SCRAPERS_TARGETS.SCRAPER_STATUS_CODE_ERROR, null));
    dispatch(actionCreators.setScraperInfo(SCRAPERS_TARGETS.SCRAPER_CMF_DATA_ERROR, null));
    dispatch(actionCreators.setScraperInfo(SCRAPERS_TARGETS.SCRAPER_SII_DATA_ERROR, null));
    dispatch(actionCreators.setScraperInfo(SCRAPERS_TARGETS.SCRAPER_AFP_DATA_ERROR, null));
    dispatch(actionCreators.setScraperInfo(SCRAPERS_TARGETS.SCRAPER_ALL_DATA_ERROR, null));
  }
};

export default actionCreators;
