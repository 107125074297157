import i18next from 'i18next';
import * as Yup from 'yup';

import { formatMoney } from '~utils/format';

export const getValidationSchema = (minimumAmount, maximumAmount) =>
  Yup.object().shape({
    amount: Yup.number()
      .required(i18next.t('AmountFormErrors:required'))
      .max(maximumAmount, i18next.t('AmountFormErrors:max', { value: formatMoney(maximumAmount) }))
      .min(minimumAmount, i18next.t('AmountFormErrors:min', { value: formatMoney(minimumAmount) }))
  });
