import i18next from 'i18next';
import { bool, func, node, string } from 'prop-types';
import React from 'react';

import ButtonLoading from '../ButtonLoading';

import styles from './styles.module.scss';

function CreditEndingStatus({
  title,
  info,
  summaryButton = i18next.t('CreditEndingStatus:summaryButton'),
  Image,
  loading,
  handleCheckout
}) {
  return (
    <div className={styles.container}>
      <span className={styles.creditEndingTitle}>{title}</span>
      <Image />
      <span className={styles.creditEndingInfo}>{info}</span>
      <ButtonLoading onClick={handleCheckout} className={styles.summaryButton} loading={loading} secondary>
        {summaryButton}
      </ButtonLoading>
    </div>
  );
}

CreditEndingStatus.propTypes = {
  handleCheckout: func,
  Image: node,
  info: string,
  loading: bool,
  summaryButton: string,
  title: string
};

export default CreditEndingStatus;
