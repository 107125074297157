import React from 'react';
import i18next from 'i18next';

import SelectOption from '~app/components/SelectOption';

import { LABOR_SITUATION_OPTIONS, EMITTED_INVOICES_OPTIONS } from './constants';

export const getLaborSituationOptions = () =>
  LABOR_SITUATION_OPTIONS.map(({ value }) => ({
    label: <SelectOption message={i18next.t(`LaborInformationFields:${value}`)} />,
    value
  }));

export const getEmittedInvoicesOptions = () =>
  EMITTED_INVOICES_OPTIONS.map(({ value }) => ({
    label: <SelectOption message={i18next.t(`LaborInformationFields:${value}`)} />,
    value
  }));
