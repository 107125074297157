import React, { useEffect } from 'react';
import i18next from 'i18next';
import { func, number, string } from 'prop-types';
import cn from 'classnames';

import { isEmployed } from '~utils/employmentSituation';
import {
  incomesErrorsProptypes,
  incomesTouchedProptypes,
  incomesValuesProptypes,
  personalEarningsProptype
} from '~propTypes/incomes';

import { FIELDS } from '../../constants';

import CertificateCards from './components/CertificateCards';
import { formatIncomes } from './utils';
import styles from './styles.module.scss';
import DebtFreeCertificate from './components/DebtFreeCertificate';

function FormContent({
  formClassName,
  values,
  setFieldValue,
  incumbent,
  submitCount,
  ...certificateCardsProps
}) {
  useEffect(() => {
    setFieldValue(
      FIELDS.INCOMES,
      isEmployed(values.employmentSituation) ? formatIncomes(values.incomes) : '0'
    );
  }, [values.employmentSituation]);
  return (
    <div className={cn(styles.formContainer, formClassName)}>
      <div className={styles.employmentSituationField}>
        <div className={styles.sectionLaborTitle}>
          <span>
            {i18next.t('IncomesForm:infoLaborSituation1')}
            <b>{i18next.t(`LaborInformationMessageTitle:${values.employmentSituation}`)}</b>
            {i18next.t('IncomesForm:infoLaborSituation2')}
          </span>
        </div>
      </div>
      <CertificateCards
        incumbent={incumbent}
        values={values}
        submitCount={submitCount}
        {...certificateCardsProps}
      />
      <DebtFreeCertificate incumbent={incumbent} submitCount={submitCount} {...certificateCardsProps} />
    </div>
  );
}

FormContent.propTypes = {
  errors: incomesErrorsProptypes,
  formClassName: string,
  handleEmploymentChange: func,
  incumbent: string,
  incumbentTargets: personalEarningsProptype,
  setFieldTouched: func,
  setFieldValue: func,
  submitCount: number,
  touched: incomesTouchedProptypes,
  values: incomesValuesProptypes
};
export default FormContent;
