import i18next from 'i18next';
import React from 'react';

import SelectOption from '~app/components/SelectOption';
import { EMPLOYMENT_OPTIONS } from '~constants/employmentSituation';

export const getOptions = () =>
  EMPLOYMENT_OPTIONS.map(option => ({
    label: <SelectOption message={i18next.t(`IncomesForm:${option}`)} />,
    value: option
  }));

export const formatIncomes = incomes => (!incomes || incomes === '0' ? '' : incomes);
