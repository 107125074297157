import i18next from 'i18next';
import { bool, func, string } from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { ReactComponent as Guarantor } from '~assets/guarantor-white.svg';
import { useRedirect } from '~app/hooks/useRedirect';
import { ReactComponent as BackArrow } from '~assets/back-arrow.svg';

import styles from './styles.module.scss';

function NavBar({ mainPath, previousStepName, disabled = false, isGuarantorView = false, customAction }) {
  const dispatch = useDispatch();
  const handleRedirect = useRedirect({ mainPath });
  const handleClick = () => {
    if (customAction) {
      dispatch(customAction);
    }
    handleRedirect({ customStepName: previousStepName })();
  };
  return (
    <div className={styles.container}>
      <div
        className={cn(styles.backArrowContainer, { [styles.backArrowContainerDisabled]: disabled })}
        onClick={!disabled && handleClick}>
        <BackArrow className={styles.backArrow} />
      </div>
      {isGuarantorView && (
        <div className={styles.guarantorContainer}>
          <div className={styles.guarantorIconContainer}>
            <Guarantor className={styles.guarantorIcon} />
          </div>
          <span className={styles.guarantorTitle}>{i18next.t('NavBar:guarantor')}</span>
        </div>
      )}
    </div>
  );
}

NavBar.propTypes = {
  customAction: func,
  disabled: bool,
  isGuarantorView: bool,
  mainPath: string,
  previousStepName: string
};

export default NavBar;
