import { arrayOf, bool, func, string } from 'prop-types';
import React from 'react';
import SelectLib from 'react-select';
import cn from 'classnames';

import { option } from '~propTypes/select';
import { isUndefined } from '~utils/validations';

import Error from '../Error';

import { ERROR_STYLES, FOCUS_STYLES, SELECT_STYLES, THEME } from './styles';
import styles from './styles.module.scss';

function Select({
  name,
  options,
  onBlur,
  onChange,
  defaultValue,
  placeholder = '',
  error,
  value,
  disabled = false,
  showLabel = false,
  className
}) {
  const isSuccessful = !error && !isUndefined(value) && value;
  const floatLabel = isSuccessful && showLabel;

  return (
    <div className={className}>
      <div className={cn('column full-width m-bottom-1')}>
        <div className="row full-width middle">
          <div className="column full-width anchor">
            {floatLabel && (
              <label htmlFor={name} className={styles.kayakSelectLabel}>
                {placeholder}
              </label>
            )}
            <div className="row full-width center middle">
              <SelectLib
                className={floatLabel && 'float-label'}
                classNamePrefix="kayak-select"
                name={name}
                styles={{ ...SELECT_STYLES, ...(error && ERROR_STYLES), ...(value && FOCUS_STYLES) }}
                theme={THEME}
                options={options}
                onChange={onChange}
                onBlur={onBlur}
                value={value || defaultValue ? value : ''}
                defaultValue={defaultValue}
                isSearchable={false}
                isOptionDisabled={({ isDisabled }) => isDisabled}
                placeholder={placeholder}
                isDisabled={disabled}
              />
            </div>
          </div>
        </div>
      </div>
      <Error error={error} touched />
    </div>
  );
}

Select.propTypes = {
  options: arrayOf(option).isRequired,
  className: string,
  defaultValue: option,
  disabled: bool,
  error: bool,
  name: string,
  placeholder: string,
  showLabel: bool,
  value: string,
  onBlur: func,
  onChange: func
};

export default Select;
