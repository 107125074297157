import { useDispatch } from 'react-redux';

import ModalActions from '~redux/Modal/actions';

export const useModal = () => {
  const dispatch = useDispatch();
  const handleModalAction = action => modal => () => dispatch(action(modal));
  const handleOpenModal = handleModalAction(ModalActions.openModal);
  const handleCloseModal = handleModalAction(ModalActions.closeModal);
  return { handleOpenModal, handleCloseModal };
};
