import i18next from 'i18next';
import { bool, func, string } from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { useParams } from 'react-router';

import ShareLinkModal from '~app/components/ShareLinkModal';
import Button from '~components/Button';
import { MODALS } from '~redux/Modal/constants';
import { useModal } from '~app/hooks/useModal';
import { getGuarantorShareLink } from '~utils/shareLink';

import styles from './styles.module.scss';

function ShareLinkButton({ className, buttonClassName, handlePostAction, hide }) {
  const { handleOpenModal } = useModal();
  const { quickCode, kind } = useParams();
  return (
    !hide && (
      <div className={cn(styles.container, className)}>
        <Button
          primary
          className={cn(styles.shareLinkButton, buttonClassName)}
          onClick={handleOpenModal(MODALS.SHARE_LINK)}>
          {i18next.t('StatusPage:shareLinkButton')}
        </Button>
        <ShareLinkModal
          title={i18next.t('StatusPage:shareLinkTitle')}
          subtitle={i18next.t('StatusPage:shareLinkInfo')}
          customPostAction={handlePostAction}
          link={getGuarantorShareLink(quickCode, kind)}
        />
      </div>
    )
  );
}

ShareLinkButton.propTypes = {
  buttonClassName: string,
  className: string,
  handlePostAction: func,
  hide: bool
};

export default ShareLinkButton;
