import { EVENT_ACTIONS, EVENT_TO_ACTION } from '~constants/analytics';
import { POSSIBLE_STEP_STATES } from '~constants/steps';
import AnalyticsService from '~services/AnalyticsService';
import LocalStorageService from '~services/LocalStorageService';
import { returnOrUndefined } from '~utils/validations';

const shouldSendEvent = (sentAnalyticsEvents, event) => !sentAnalyticsEvents?.[EVENT_TO_ACTION[event]];

const setEvent = (sentAnalyticsEvents, event) => {
  if (sentAnalyticsEvents) {
    sentAnalyticsEvents[EVENT_TO_ACTION[event]] = true;
  }
};

export const sendCompletedAllSteps = ({
  sentAnalyticsEvents,
  category,
  needsGuarantor,
  completedAllStepsAndActions
}) => {
  completedAllStepsAndActions.forEach(({ completedAllSteps, action }) => {
    if (completedAllSteps && shouldSendEvent(sentAnalyticsEvents, action)) {
      AnalyticsService.event({
        category,
        action,
        needsGuarantor
      });
      setEvent(sentAnalyticsEvents, action);
    }
  });
};

export const sendAnalyticsEvents = (response, state) => {
  const {
    completedSteps = null,
    clientCompletedAllSteps = false,
    guarantorCompletedAllSteps = false,
    simulationAllStepsCompleted = false
  } = response.data;
  const { kind, needsGuarantor, amount } = state.simulation;
  const sentAnalyticsEvents = LocalStorageService.getAnalyticsEvents();
  if (completedSteps) {
    completedSteps.forEach(step => {
      if (shouldSendEvent(sentAnalyticsEvents, step)) {
        const isQuotesStep = step === POSSIBLE_STEP_STATES.CLIENT_QUOTES_INFORMATION;
        AnalyticsService.event({
          category: kind,
          action: EVENT_TO_ACTION[step],
          needsGuarantor,
          value: returnOrUndefined(parseInt(amount), isQuotesStep)
        });
        setEvent(sentAnalyticsEvents, step);
      }
    });
  }
  sendCompletedAllSteps({
    sentAnalyticsEvents,
    category: kind,
    needsGuarantor,
    completedAllStepsAndActions: [
      {
        completedAllSteps: clientCompletedAllSteps,
        action: EVENT_ACTIONS.CLIENT_FLOW_COMPLETED
      },
      {
        completedAllSteps: guarantorCompletedAllSteps,
        action: EVENT_ACTIONS.GUARANTOR_FLOW_COMPLETED
      },
      {
        completedAllSteps: simulationAllStepsCompleted,
        action: EVENT_ACTIONS.TOTAL_FLOW_COMPLETED
      }
    ]
  });
  LocalStorageService.setAnalyticsEvents(sentAnalyticsEvents);
};
