import { arrayOf, bool, node, shape, string } from 'prop-types';

export const stepsProptype = arrayOf(
  shape({
    basePath: string,
    icon: node,
    title: string,
    completed: bool,
    selected: bool,
    needsKind: bool
  })
);
