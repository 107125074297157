import { bool, shape, string } from 'prop-types';

export const personalEarningsProptype = shape({
  earningsReceivedTarget: string,
  earningsReceivedStateTarget: string,
  earningsWarningTarget: string,
  earningsWarningStateTarget: string
});

export const incomesValuesProptypes = shape({
  employmentSituation: string,
  incomes: string
});

export const incomesErrorsProptypes = shape({
  employmentSituation: string,
  incomes: string
});

export const incomesTouchedProptypes = shape({
  employmentSituation: bool,
  incomes: bool
});
