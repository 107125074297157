import React from 'react';
import { string, number } from 'prop-types';
import i18next from 'i18next';

import styles from './styles.module.scss';

function InformationRow({ label, value }) {
  return (
    <div className={`row ${styles.infoContainer}`}>
      <span className={`m-right-1 x-small-text ${styles.label}`}>
        {i18next.t(`CreditSimulation:${label}`)}
      </span>
      <span className="medium small-text">{value}%</span>
    </div>
  );
}

InformationRow.propTypes = {
  label: string,
  value: number
};

export default InformationRow;
