import React from 'react';
import { arrayOf, func, node, shape, string } from 'prop-types';
import cn from 'classnames';

import { field } from '~propTypes/field';
import { handleDateChange } from '~utils/date/regex';

import { BIRTHDAY_FIELDS } from '../../constants';

import styles from './styles.module.scss';
import { FIELDS } from './constants';
import { getGendersOptions, getNationalitiesOptions } from './utils';

function BirthFields({
  header: Header,
  values,
  onChange,
  setFieldTouched,
  errors,
  touched,
  setFieldValue,
  fieldsClassName,
  formClassName
}) {
  const genderSelectOption = getGendersOptions().find(option => option.value === values.gender);
  const nationalitySelectOption = getNationalitiesOptions().find(
    option => option.value === values.nationality
  );
  const mapValueField = fieldName => {
    switch (fieldName) {
      case BIRTHDAY_FIELDS.GENDER:
        return genderSelectOption;
      case BIRTHDAY_FIELDS.NATIONALITY:
        return nationalitySelectOption;
      default:
        return values[fieldName];
    }
  };
  const handleChangeGenderSelect = option => {
    setFieldValue(BIRTHDAY_FIELDS.GENDER, option.value);
  };
  const handleChangeNacionalitySelect = option => {
    setFieldValue(BIRTHDAY_FIELDS.NATIONALITY, option.value);
  };
  const handleChangeBirthdaySelect = option => {
    setFieldValue(BIRTHDAY_FIELDS.BIRTHDAY, handleDateChange(option.target.value));
  };
  const mapChangeField = fieldName => {
    switch (fieldName) {
      case BIRTHDAY_FIELDS.GENDER:
        return handleChangeGenderSelect;
      case BIRTHDAY_FIELDS.NATIONALITY:
        return handleChangeNacionalitySelect;
      case BIRTHDAY_FIELDS.BIRTHDAY:
        return handleChangeBirthdaySelect;
      default:
        return onChange;
    }
  };
  const mapErrorField = fieldName => {
    switch (fieldName) {
      case BIRTHDAY_FIELDS.GENDER:
        return !values[fieldName] && touched[fieldName] && errors[fieldName];
      case BIRTHDAY_FIELDS.NATIONALITY:
        return !values[fieldName] && touched[fieldName] && errors[fieldName];
      default:
        return errors[fieldName];
    }
  };

  return (
    <div className={cn(styles.formContainer, formClassName)}>
      <Header />
      <div className={cn(styles.fields, fieldsClassName)}>
        {FIELDS.map(({ component: Field, name, label, ...props }) => {
          const handleBlur = e => {
            const { value } = e.target;
            setFieldValue(name, value.trim());
            setFieldTouched(name, value, true);
          };
          return (
            <div key={name}>
              <div className={styles.sectionLabel}>{label}</div>
              <Field
                className="column full-width m-bottom-2"
                value={mapValueField(name)}
                name={name}
                error={mapErrorField(name)}
                handleChange={onChange}
                onChange={mapChangeField(name)}
                touched={touched[name]}
                onBlur={() => setFieldTouched(name, true, true)}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                autocomplete={name}
                hideLabel
                {...props}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

BirthFields.propTypes = {
  additionalFields: arrayOf(field),
  errors: shape,
  fieldsClassName: string,
  formClassName: string,
  header: node,
  setFieldTouched: func,
  setFieldValue: func,
  touched: shape,
  values: shape,
  onChange: func
};

export default BirthFields;
