import i18next from 'i18next';
import React, { useEffect } from 'react';

import { EVENTS } from '~constants/events';

function ThankYou() {
  useEffect(() => {
    window.parent.postMessage(EVENTS.TRANSACTION_FINISHED);
  }, []);
  return <div>{i18next.t('ThankYou:redirect')}</div>;
}

export default ThankYou;
