import React from 'react';
import { bool, string } from 'prop-types';

import { isUndefined } from '~utils/validations';

import styles from './styles.module.scss';

function Error({ error, touched, show, errorClassName }) {
  return (
    <div>
      {(show || (!isUndefined(error) && !isUndefined(touched) && !!error)) && (
        <span className={`${styles.error} ${errorClassName}`}>{error}</span>
      )}
    </div>
  );
}

Error.propTypes = {
  error: string,
  errorClassName: string,
  show: bool,
  touched: bool
};

export default Error;
