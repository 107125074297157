import React from 'react';
import { func, node, string } from 'prop-types';

import styles from './styles.module.scss';

function MigranteContact({ Logo, buttonText, handleClick, href }) {
  return (
    <span className={styles.contactButtonContainer}>
      <div className={styles.migranteContactContainer}>
        <div className={styles.logoContainer}>
          <Logo className={styles.logo} />
        </div>
        <div className={styles.messageInfo}>
          <a href={href} className={styles.messageLink} onClick={handleClick}>
            {buttonText}
          </a>
        </div>
      </div>
    </span>
  );
}

MigranteContact.propTypes = {
  buttonText: string,
  handleClick: func,
  href: string,
  Logo: node
};

export default MigranteContact;
