import React from 'react';
import i18next from 'i18next';
import { bool, func, number, string } from 'prop-types';
import cn from 'classnames';

import Card from '~app/components/Card';
import { hasBeenSubmitted } from '~utils/formik';
import { ReactComponent as Error } from '~assets/error.svg';

import styles from './styles.module.scss';
import PerfectMessage from './components/PerfectMessage';
import CertificateContent from './components/CertificateContent';

function CertificateCard({
  type,
  setModalType,
  infoReceived,
  warning,
  onWarning,
  onResetInfo,
  submitCount,
  knowsStatusPage,
  show = false,
  certificateCardClassName,
  ...customProps
}) {
  const hasError = !infoReceived && ((hasBeenSubmitted(submitCount) && !warning) || knowsStatusPage);
  return show ? (
    <Card
      cardClassName={cn(styles.certificateCardContainer, certificateCardClassName, {
        [styles.fullWidthCardError]: hasError
      })}>
      <div className={styles.certificateCardContentContainer}>
        <div className={styles.certificateTitleContainer}>
          <span className={styles.certificateTitle}>
            {i18next.t('CertificateCard:title', { certificate: i18next.t(`CertificateCard:title${type}`) })}
          </span>
          {hasError && <Error className={styles.errorSign} />}
        </div>
        <div className={styles.info}>
          {type === 'AFP' ? (
            <>
              {i18next.t('CertificateCard:firstInfo')}
              <b>{i18next.t('CertificateCard:info', { scraper: i18next.t('CertificateCard:infoAFP') })}</b>
              {i18next.t('CertificateCard:secondInfo')}
            </>
          ) : (
            <>
              {i18next.t('CertificateCard:firstManualInfo')}
              {i18next.t(`CertificateCard:manualInfo${type}`)}
              {i18next.t('CertificateCard:secondManualInfo')}
            </>
          )}
        </div>
        <div className={styles.content}>
          {infoReceived ? (
            <PerfectMessage resetInfo={onResetInfo} />
          ) : (
            <CertificateContent
              type={type}
              setModalType={setModalType}
              warning={warning}
              handleWarning={onWarning}
              hasError={hasError}
              {...customProps}
            />
          )}
        </div>
      </div>
    </Card>
  ) : null;
}

CertificateCard.propTypes = {
  certificateCardClassName: string,
  customConnectAction: func,
  customUploadFileAction: func,
  infoReceived: bool,
  knowsStatusPage: bool,
  setModalType: func,
  show: bool,
  submitCount: number,
  type: string,
  warning: bool,
  onResetInfo: func,
  onWarning: func
};

export default CertificateCard;
