import { ReactComponent as Installments } from '~assets/installments.svg';
import { ReactComponent as Incomes } from '~assets/incomes.svg';
import { ReactComponent as Confirmation } from '~assets/confirmation.svg';
import { ReactComponent as Personal } from '~assets/personal.svg';
import { ReactComponent as Identity } from '~assets/identity-card.svg';
import { ReactComponent as Bank } from '~assets/bank.svg';

import { ROUTES_IDS } from './routes';
import { INCUMBENT_TYPES } from './incumbent';

export const STEPS_TARGETS = {
  CLIENT_PERSONAL_INFORMATION: 'CLIENT_PERSONAL_INFORMATION',
  CLIENT_BIRTH_INFORMATION: 'CLIENT_BIRTH_INFORMATION',
  CLIENT_LABOR_INFORMATION: 'CLIENT_LABOR_INFORMATION',
  CLIENT_QUOTES_INFORMATION: 'CLIENT_QUOTES_INFORMATION',
  CLIENT_FINANCIAL_INFORMATION: 'CLIENT_FINANCIAL_INFORMATION',
  CLIENT_CONFIRMATION: 'CLIENT_CONFIRMATION',
  GUARANTOR_PERSONAL_INFORMATION: 'GUARANTOR_PERSONAL_INFORMATION',
  GUARANTOR_BIRTH_INFORMATION: 'GUARANTOR_BIRTH_INFORMATION',
  GUARANTOR_LABOR_INFORMATION: 'GUARANTOR_LABOR_INFORMATION',
  GUARANTOR_FINANCIAL_INFO: 'GUARANTOR_FINANCIAL_INFORMATION',
  GUARANTOR_CONFIRMATION: 'GUARANTOR_CONFIRMATION',
  CLIENT_PERSONAL_DOCUMENTATION: 'CLIENT_PERSONAL_DOCUMENTATION',
  CLIENT_CONFIDENTIAL_INFO: 'CLIENT_CONFIDENTIAL_INFO',
  CLIENT_BANK_ACCOUNT: 'CLIENT_BANK_ACCOUNT',
  GUARANTOR_PERSONAL_DOCS: 'GUARANTOR_PERSONAL_DOCUMENTATION',
  GUARANTOR_CONFIDENTIAL_INFO: 'GUARANTOR_CONFIDENTIAL_INFO'
};

export const CLIENT_STEPS_TARGETS = {
  [STEPS_TARGETS.CLIENT_PERSONAL_INFORMATION]: 'clientPersonalInformation',
  [STEPS_TARGETS.CLIENT_BIRTH_INFORMATION]: 'clientBirthInformation',
  [STEPS_TARGETS.CLIENT_LABOR_INFORMATION]: 'clientLaborInformation',
  [STEPS_TARGETS.CLIENT_QUOTES_INFORMATION]: 'clientQuotesInformation',
  [STEPS_TARGETS.CLIENT_FINANCIAL_INFORMATION]: 'clientFinancialInformation',
  [STEPS_TARGETS.CLIENT_PERSONAL_DOCUMENTATION]: 'clientPersonalDocumentation'
};

export const GUARANTOR_STEPS_TARGETS = {
  [STEPS_TARGETS.GUARANTOR_PERSONAL_INFORMATION]: 'guarantorPersonalInformation',
  [STEPS_TARGETS.GUARANTOR_BIRTH_INFORMATION]: 'guarantorBirthInformation',
  [STEPS_TARGETS.GUARANTOR_LABOR_INFORMATION]: 'guarantorLaborInformation',
  [STEPS_TARGETS.GUARANTOR_FINANCIAL_INFO]: 'guarantorFinancialInformation',
  [STEPS_TARGETS.GUARANTOR_PERSONAL_DOCS]: 'guarantorPersonalDocumentation'
};

export const CLIENT_DOC_STEPS_TARGETS = {
  [STEPS_TARGETS.CLIENT_CONFIDENTIAL_INFO]: 'clientConfidentialInfo',
  [STEPS_TARGETS.CLIENT_BANK_ACCOUNT]: 'clientBankAccount'
};

export const GUARANTOR_DOC_STEPS_TARGETS = {
  [STEPS_TARGETS.GUARANTOR_CONFIDENTIAL_INFO]: 'guarantorConfidentialInfo'
};

export const STEPS_STATES = {
  COMPLETED: 'completed',
  UNCOMPLETED: 'uncompleted'
};

export const ROUTES_NAMES = {
  CLIENT: {
    SIMULATOR: 'clientSimulator',
    INCOMES: 'clientIncomes',
    DOCUMENTATION: 'clientPersonalDocumentation'
  },
  GUARANTOR: {
    IDENTITY: 'guarantorIdentity',
    INCOMES: 'guarantorIncomes',
    DOCUMENTATION: 'guarantorPersonalDocumentation'
  }
};

export const CLIENT_DOC_ROUTES_NAMES = {
  CONFIDENTIAL_INFO: 'clientConfidentialInfo',
  BANK_ACCOUNT: 'clientBankAccount'
};

export const GUARANTOR_DOC_ROUTES_NAMES = {
  DOCUMENTATION: 'guarantorPersonalDocumentation',
  CONFIDENTIAL_INFO: 'guarantorConfidentialInfo'
};

export const CLIENT_ROUTES = [
  {
    name: ROUTES_NAMES.CLIENT.SIMULATOR,
    icon: Installments,
    step: CLIENT_STEPS_TARGETS.CLIENT_QUOTES_INFORMATION,
    route: ROUTES_IDS.CREDIT_SIMULATION
  },
  {
    name: ROUTES_NAMES.CLIENT.INCOMES,
    icon: Incomes,
    step: CLIENT_STEPS_TARGETS.CLIENT_FINANCIAL_INFORMATION,
    route: ROUTES_IDS.CLIENT_INCOMES
  },
  {
    name: ROUTES_NAMES.CLIENT.DOCUMENTATION,
    icon: Confirmation,
    step: CLIENT_STEPS_TARGETS.CLIENT_PERSONAL_DOCUMENTATION,
    route: ROUTES_IDS.CLIENT_PERSONAL_DOCUMENTATION
  }
];

export const GUARANTOR_ROUTES = [
  {
    name: ROUTES_NAMES.GUARANTOR.IDENTITY,
    icon: Personal,
    step: GUARANTOR_STEPS_TARGETS.GUARANTOR_PERSONAL_INFORMATION,
    route: ROUTES_IDS.GUARANTOR_IDENTITY
  },
  {
    name: ROUTES_NAMES.GUARANTOR.INCOMES,
    icon: Incomes,
    step: GUARANTOR_STEPS_TARGETS.GUARANTOR_FINANCIAL_INFORMATION,
    route: ROUTES_IDS.GUARANTOR_INCOMES
  },
  {
    name: ROUTES_NAMES.GUARANTOR.DOCUMENTATION,
    icon: Confirmation,
    step: GUARANTOR_STEPS_TARGETS.GUARANTOR_PERSONAL_DOCUMENTATION,
    route: ROUTES_IDS.GUARANTOR_PERSONAL_DOCS
  }
];

export const CLIENT_DOCUMENTATION_ROUTES = [
  {
    name: CLIENT_DOC_ROUTES_NAMES.DOCUMENTATION,
    icon: Identity,
    step: CLIENT_DOC_STEPS_TARGETS.CLIENT_PERSONAL_DOCUMENTATION,
    route: ROUTES_IDS.CLIENT_PERSONAL_DOCUMENTATION
  },
  {
    name: CLIENT_DOC_ROUTES_NAMES.CONFIDENTIAL_INFO,
    icon: Confirmation,
    step: CLIENT_DOC_STEPS_TARGETS.CLIENT_CONFIDENTIAL_INFO,
    route: ROUTES_IDS.CLIENT_CONFIDENTIAL_INFO
  },
  {
    name: CLIENT_DOC_ROUTES_NAMES.BANK_ACCOUNT,
    icon: Bank,
    step: CLIENT_DOC_STEPS_TARGETS.CLIENT_BANK_ACCOUNT,
    route: ROUTES_IDS.CLIENT_BANK_ACCOUNT
  }
];

export const GUARANTOR_DOCUMENTATION_ROUTES = [
  {
    name: GUARANTOR_DOC_ROUTES_NAMES.DOCUMENTATION,
    icon: Identity,
    step: GUARANTOR_DOC_STEPS_TARGETS.GUARANTOR_PERSONAL_DOCUMENTATION,
    route: ROUTES_IDS.GUARANTOR_PERSONAL_DOCS
  },
  {
    name: GUARANTOR_DOC_ROUTES_NAMES.CONFIDENTIAL_INFO,
    icon: Confirmation,
    step: GUARANTOR_DOC_STEPS_TARGETS.GUARANTOR_CONFIDENTIAL_INFO,
    route: ROUTES_IDS.GUARANTOR_CONFIDENTIAL_INFO
  }
];

export const ALLOWED_GOING_FORWARD_STATES = [STEPS_STATES.COMPLETED];

export const CUSTOM_STEPS_TARGETS = {
  client: CLIENT_STEPS_TARGETS,
  guarantor: GUARANTOR_STEPS_TARGETS
};

export const STEPS_PROGRESS_TARGETS = [
  {
    id: CLIENT_STEPS_TARGETS.CLIENT_PERSONAL_INFORMATION,
    target: STEPS_TARGETS.CLIENT_PERSONAL_INFORMATION,
    incumbent: INCUMBENT_TYPES.CLIENT
  },
  {
    id: CLIENT_STEPS_TARGETS.CLIENT_BIRTH_INFORMATION,
    target: STEPS_TARGETS.CLIENT_BIRTH_INFORMATION,
    incumbent: INCUMBENT_TYPES.CLIENT
  },
  {
    id: CLIENT_STEPS_TARGETS.CLIENT_LABOR_INFORMATION,
    target: STEPS_TARGETS.CLIENT_LABOR_INFORMATION,
    incumbent: INCUMBENT_TYPES.CLIENT
  },
  {
    id: CLIENT_STEPS_TARGETS.CLIENT_QUOTES_INFORMATION,
    target: STEPS_TARGETS.CLIENT_QUOTES_INFORMATION,
    incumbent: INCUMBENT_TYPES.CLIENT
  },
  {
    id: CLIENT_STEPS_TARGETS.CLIENT_FINANCIAL_INFORMATION,
    target: STEPS_TARGETS.CLIENT_FINANCIAL_INFORMATION,
    incumbent: INCUMBENT_TYPES.CLIENT
  },
  {
    id: CLIENT_STEPS_TARGETS.CLIENT_PERSONAL_DOCUMENTATION,
    target: STEPS_TARGETS.CLIENT_PERSONAL_DOCUMENTATION,
    incumbent: INCUMBENT_TYPES.CLIENT
  },
  {
    id: CLIENT_STEPS_TARGETS.CLIENT_CONFIRMATION,
    target: STEPS_TARGETS.CLIENT_CONFIRMATION,
    incumbent: INCUMBENT_TYPES.CLIENT
  },
  {
    id: GUARANTOR_STEPS_TARGETS.GUARANTOR_PERSONAL_INFORMATION,
    target: STEPS_TARGETS.GUARANTOR_PERSONAL_INFORMATION,
    incumbent: INCUMBENT_TYPES.GUARANTOR
  },
  {
    id: GUARANTOR_STEPS_TARGETS.GUARANTOR_BIRTH_INFORMATION,
    target: STEPS_TARGETS.GUARANTOR_BIRTH_INFORMATION,
    incumbent: INCUMBENT_TYPES.GUARANTOR
  },
  {
    id: GUARANTOR_STEPS_TARGETS.GUARANTOR_LABOR_INFORMATION,
    target: STEPS_TARGETS.GUARANTOR_LABOR_INFORMATION,
    incumbent: INCUMBENT_TYPES.GUARANTOR
  },
  {
    id: GUARANTOR_STEPS_TARGETS.GUARANTOR_FINANCIAL_INFORMATION,
    target: STEPS_TARGETS.GUARANTOR_FINANCIAL_INFO,
    incumbent: INCUMBENT_TYPES.GUARANTOR
  },
  {
    id: GUARANTOR_STEPS_TARGETS.GUARANTOR_PERSONAL_DOCUMENTATION,
    target: STEPS_TARGETS.GUARANTOR_PERSONAL_DOCS,
    incumbent: INCUMBENT_TYPES.GUARANTOR
  },
  {
    id: GUARANTOR_STEPS_TARGETS.GUARANTOR_CONFIRMATION,
    target: STEPS_TARGETS.GUARANTOR_CONFIRMATION,
    incumbent: INCUMBENT_TYPES.GUARANTOR
  }
];

export const SIMULATION_NEXT_STEP = 'simulation_next_step_meta';

export const POSSIBLE_STEP_STATES = {
  [STEPS_TARGETS.CLIENT_PERSONAL_INFORMATION]: 'client_personal_information',
  [STEPS_TARGETS.CLIENT_BIRTH_INFORMATION]: 'client_birth_information',
  [STEPS_TARGETS.CLIENT_LABOR_INFORMATION]: 'client_labor_information',
  [STEPS_TARGETS.CLIENT_QUOTES_INFORMATION]: 'client_quotes_information',
  [STEPS_TARGETS.CLIENT_FINANCIAL_INFORMATION]: 'client_financial_information',
  [STEPS_TARGETS.CLIENT_PERSONAL_DOCUMENTATION]: 'client_personal_documentation',
  [STEPS_TARGETS.CLIENT_CONFIRMATION]: 'client_confirmation',
  [STEPS_TARGETS.GUARANTOR_PERSONAL_INFORMATION]: 'guarantor_personal_information',
  [STEPS_TARGETS.GUARANTOR_BIRTH_INFORMATION]: 'guarantor_birth_information',
  [STEPS_TARGETS.GUARANTOR_LABOR_INFORMATION]: 'guarantor_labor_information',
  [STEPS_TARGETS.GUARANTOR_FINANCIAL_INFO]: 'guarantor_financial_information',
  [STEPS_TARGETS.GUARANTOR_PERSONAL_DOCS]: 'guarantor_personal_documentation',
  [STEPS_TARGETS.GUARANTOR_CONFIRMATION]: 'guarantor_confirmation'
};

export const NEXT_STEP_STATES = {
  [POSSIBLE_STEP_STATES.CLIENT_PERSONAL_INFORMATION]: POSSIBLE_STEP_STATES.CLIENT_BIRTH_INFORMATION,
  [POSSIBLE_STEP_STATES.CLIENT_BIRTH_INFORMATION]: POSSIBLE_STEP_STATES.CLIENT_LABOR_INFORMATION,
  [POSSIBLE_STEP_STATES.CLIENT_LABOR_INFORMATION]: POSSIBLE_STEP_STATES.CLIENT_QUOTES_INFORMATION,
  [POSSIBLE_STEP_STATES.CLIENT_QUOTES_INFORMATION]: POSSIBLE_STEP_STATES.CLIENT_FINANCIAL_INFORMATION,
  [POSSIBLE_STEP_STATES.CLIENT_FINANCIAL_INFORMATION]: POSSIBLE_STEP_STATES.CLIENT_PERSONAL_DOCUMENTATION,
  [POSSIBLE_STEP_STATES.CLIENT_PERSONAL_DOCUMENTATION]: POSSIBLE_STEP_STATES.CLIENT_CONFIRMATION,
  [POSSIBLE_STEP_STATES.CLIENT_CONFIRMATION]: POSSIBLE_STEP_STATES.GUARANTOR_PERSONAL_INFORMATION,
  [POSSIBLE_STEP_STATES.GUARANTOR_PERSONAL_INFORMATION]: POSSIBLE_STEP_STATES.GUARANTOR_BIRTH_INFORMATION,
  [POSSIBLE_STEP_STATES.GUARANTOR_BIRTH_INFORMATION]: POSSIBLE_STEP_STATES.GUARANTOR_LABOR_INFORMATION,
  [POSSIBLE_STEP_STATES.GUARANTOR_LABOR_INFORMATION]: POSSIBLE_STEP_STATES.GUARANTOR_FINANCIAL_INFORMATION,
  [POSSIBLE_STEP_STATES.GUARANTOR_FINANCIAL_INFORMATION]:
    POSSIBLE_STEP_STATES.GUARANTOR_PERSONAL_DOCUMENTATION,
  [POSSIBLE_STEP_STATES.GUARANTOR_PERSONAL_DOCUMENTATION]: POSSIBLE_STEP_STATES.GUARANTOR_CONFIRMATION
};

export const STEP_SUBMITTED_META = 'simulation_step_submitted_meta';
