import React from 'react';
import Lottie from 'react-lottie';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

import { getLottieOptions } from '~utils/lottie';
import cashApproved from '~assets/lotties/cash-approved.json';
import CreditEndingStatus from '~app/components/CreditEndingStatus';
import { HOME_PAGE } from '~constants/urls';
import SimulationLoadingWrapper from '~app/components/SimulationLoadingWrapper';

import styles from './styles.module.scss';

export default function CashSuccess() {
  const checkoutLoading = useSelector(state => state.verifications.checkoutLoading);
  const cashApprovedAnim = () => (
    <div className={styles.salesContainer}>
      <Lottie
        options={getLottieOptions({
          loop: true,
          autoplay: true,
          animationData: cashApproved
        })}
      />
    </div>
  );
  const handleCheckout = () => window.location.replace(HOME_PAGE);
  return (
    <SimulationLoadingWrapper>
      <CreditEndingStatus
        title={i18next.t('CreditCashSuccess:title')}
        info={i18next.t('CreditCashSuccess:info')}
        summaryButton={i18next.t('CreditCashSuccess:summaryButton')}
        Image={cashApprovedAnim}
        loading={checkoutLoading}
        handleCheckout={handleCheckout}
      />
    </SimulationLoadingWrapper>
  );
}
