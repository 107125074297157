import { func, oneOf, string, boolean, objectOf, bool } from 'prop-types';
import React from 'react';

import Button from '../Button';
import LoadingWrapper from '../LoadingWrapper';

import { BUTTON_LOADING, LOADING_SIZE } from './constants';

function ButtonLoading({
  children,
  onClick,
  type = 'button',
  className,
  disabled,
  loading,
  loadingClassName,
  loadingProps,
  loadingType = BUTTON_LOADING,
  ...props
}) {
  return (
    <Button type={type} className={className} onClick={onClick} disabled={loading || disabled} {...props}>
      <LoadingWrapper
        loading={loading}
        className={loadingClassName}
        loadingProps={loadingProps}
        type={loadingType}
        width={LOADING_SIZE}
        height={LOADING_SIZE}>
        {children}
      </LoadingWrapper>
    </Button>
  );
}

ButtonLoading.propTypes = {
  className: string,
  disabled: bool,
  loading: boolean,
  loadingClassName: string,
  loadingProps: objectOf(string),
  loadingType: string,
  type: oneOf(['button', 'submit', 'reset']),
  onClick: func
};

export default ButtonLoading;
