import Immutable from 'seamless-immutable';
import { createReducer, onReadValue } from 'redux-recompose';

import { actions } from './actions';
import { STEPS_DEFAULT_STATE } from './constants';

const reducerDescription = {
  [actions.SET_STEP_STATE]: onReadValue()
};

const reducer = createReducer(new Immutable(STEPS_DEFAULT_STATE), reducerDescription);

export default reducer;
