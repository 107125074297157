import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, func, string } from 'prop-types';
import i18next from 'i18next';

import { MODALS } from '~redux/Modal/constants';
import ModalActions from '~redux/Modal/actions';
import { ReactComponent as Share } from '~assets/share-purple.svg';
import { ROUTES } from '~constants/routes';
import { useRedirect } from '~hooks/useRedirect';

import CustomModal from '../CustomModal';

import styles from './styles.module.scss';
import { SHAREABLES } from './constants';

function ShareLinkModal({
  title = i18next.t('ShareLinkModal:modalTitle'),
  subtitle = i18next.t('ShareLinkModal:modalSubtitle'),
  link,
  target,
  customPostAction,
  customHandleRedirectAction,
  disabled
}) {
  const dispatch = useDispatch();
  const open = useSelector(state => state.modal[MODALS.SHARE_LINK]);
  const closeModal = () => dispatch(ModalActions.closeModal(MODALS.SHARE_LINK));
  const handlePostAction = () => {
    customPostAction?.();
    closeModal();
  };
  const handleRedirect = useRedirect();
  const handleClick = onClickAction => () => {
    onClickAction(link, target);
    if (customHandleRedirectAction) {
      customHandleRedirectAction();
      closeModal();
    } else {
      handleRedirect({ customStepName: ROUTES.STATUS_PAGE.stepName, postAction: handlePostAction })();
    }
  };
  return (
    <CustomModal className={styles.modal} modal={MODALS.SHARE_LINK} onClose={closeModal} isOpen={open}>
      <div className={styles.container}>
        <Share className={styles.share} />
        <span className={styles.title}>{title}</span>
        <span className={styles.subtitle}>{subtitle}</span>
        <div className={styles.divider} />
        <div className={styles.shareablesContainer}>
          {SHAREABLES.map(({ id, Icon, onClick }) => (
            <button
              type="button"
              key={id}
              className={styles.shareable}
              onClick={handleClick(onClick)}
              disabled={disabled}>
              <Icon className={styles.icon} />
              <span className={styles.subtitle}>{i18next.t(`ShareLinkModal:${id}`)}</span>
            </button>
          ))}
        </div>
      </div>
    </CustomModal>
  );
}

ShareLinkModal.propTypes = {
  link: string.isRequired,
  subtitle: string.isRequired,
  title: string.isRequired,
  customHandleRedirectAction: func,
  customPostAction: func,
  disabled: bool,
  target: string
};

export default ShareLinkModal;
