import React from 'react';
import { int } from 'prop-types';

import styles from './styles.module.scss';

function InstallmentOption({ message, value }) {
  return (
    <div className={`column charcoal ${styles.option}`}>
      {message && <span className="m-right-1 bold">{message}</span>}
      <span>{value}</span>
    </div>
  );
}

InstallmentOption.propTypes = {
  message: int,
  value: int
};

export default InstallmentOption;
