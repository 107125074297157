import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import cn from 'classnames';

import { ReactComponent as Cross } from '~assets/error.svg';
import { ReactComponent as Check } from '~assets/check.svg';
import { isUndefined } from '~utils/validations';

import Error from '../Error';

import styles from './styles.module.scss';

// eslint-disable-next-line complexity
function Input({
  name,
  label,
  hideLabel,
  handleChange,
  handleBlur,
  error,
  className,
  inputClassName,
  inputContainerClassName,
  labelClassName,
  errorClassName,
  signClassName,
  value,
  touched,
  inputRef,
  children,
  CustomIcon,
  disabled,
  ...inputProps
}) {
  const isSuccessful = !!value && !error && !isUndefined(touched) && !isUndefined(value);
  const hasError = error && !isUndefined(touched);
  return (
    <div className={`column full-width m-bottom-2 ${className}`}>
      <div
        className={cn('column full-width m-bottom-1', inputContainerClassName, styles.inputContainer, {
          [styles.inputContainerSuccess]: isSuccessful,
          [styles.inputContainerError]: hasError,
          [styles.inputDisabled]: disabled
        })}>
        <div className="row full-width middle">
          <div className={`column ${styles.contentContainer}`}>
            {!hideLabel && (
              <label htmlFor={name} className={cn(labelClassName, value && styles.inputLabel)}>
                {label}
              </label>
            )}
            <div className="row full-width center middle">
              {children || (
                <input
                  name={name}
                  className={cn(styles.input, inputClassName, {
                    [styles.inputWithoutLabel]: hideLabel,
                    [styles.inputDisabled]: disabled
                  })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={value}
                  placeholder=""
                  disabled={disabled}
                  ref={inputRef}
                  {...inputProps}
                />
              )}
            </div>
          </div>
          <div className={`column center ${styles.signContainer} ${signClassName}`}>
            {CustomIcon ? (
              CustomIcon()
            ) : (
              <>
                {isSuccessful && <Check />}
                {hasError && <Cross className={styles.errorIcon} />}
              </>
            )}
          </div>
        </div>
      </div>
      <Error error={error} touched={touched} errorClassName={errorClassName} />
    </div>
  );
}

Input.propTypes = {
  label: string.isRequired,
  name: string.isRequired,
  className: string,
  CustomIcon: func,
  disabled: bool,
  error: string,
  errorClassName: string,
  handleBlur: func,
  handleChange: func,
  hideLabel: bool,
  inputClassName: string,
  inputContainerClassName: string,
  inputRef: shape,
  isMoney: bool,
  labelClassName: string,
  signClassName: string,
  touched: bool,
  type: string,
  value: string
};

Input.defaultProps = {
  className: 'column'
};

export default Input;
