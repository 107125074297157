import { configNewApi, headers } from '~config/api';
import { isAfp } from '~utils/certificates';

export const uploadFile = ({ files, kind, quickCode, context = null, type }) => {
  const formData = new FormData();

  const api = configNewApi();

  api.setHeader(headers.CONTENT_TYPE, 'multipart/form-data');

  files.forEach(file => {
    formData.append('files[]', file);
  });

  formData.append('kind', kind);
  formData.append('simulation_ref', quickCode);

  if (context && !isAfp(type)) {
    formData.append('context', context);
  }

  return api.post('/documents', formData, {
    timeout: 50000
  });
};
