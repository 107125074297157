import { bool, string } from 'prop-types';
import React from 'react';

import NavBar from '../NavBar';

import styles from './styles.module.scss';

function NavBarWrapper({ children, disabled = false, show = true, ...props }) {
  return (
    <div className={styles.container}>
      {show && <NavBar disabled={disabled} {...props} />}
      {children}
    </div>
  );
}

NavBarWrapper.propTypes = {
  disabled: bool,
  isGuarantorView: bool,
  previousStepName: string,
  show: bool
};

export default NavBarWrapper;
