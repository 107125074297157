import React from 'react';
import i18next from 'i18next';

import SelectOption from '~app/components/SelectOption';

import { HAS_DOWNPAYMENT_OPTIONS, WHEN_BUY_OPTIONS } from './constants';

export const getHaveDownpaymentOptions = () =>
  HAS_DOWNPAYMENT_OPTIONS.map(({ value }) => ({
    label: <SelectOption message={i18next.t(`CreditSimulation:${value}`)} />,
    value
  }));

export const getWhenBuyOptions = () =>
  WHEN_BUY_OPTIONS.map(({ value }) => ({
    label: <SelectOption message={i18next.t(`CreditSimulation:${value}`)} />,
    value
  }));
