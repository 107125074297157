import { INCUMBENT_TYPES } from '~constants/incumbent';
import { PERSONAL_DOCS_RECEIVED, PERSONAL_DOCS_WARNING } from '~redux/Documentation/constants';
import { isTrue } from '~utils/boolean';
import { addPrefix } from '~utils/string';
import DocumentationActions from '~redux/Documentation/actions';

export const hasAllValues = values => values && Object.values(values).every(value => !!value);

export const hasNoErrorsAndhasBeenTouched = (errors, touched) =>
  errors && touched && Object.values(errors).length === 0 && Object.values(touched).length > 0;

export const canContinue = ({ values, errors, touched }) =>
  hasAllValues(values) || hasNoErrorsAndhasBeenTouched(errors, touched);

export const hasRequiredDocsAndWarning = info => info.hasAllRequiredDocuments && info.hasWarning;

export const hasRequiredDocumentsOrWarning = ({ hasAllRequiredDocuments, hasWarning }) =>
  hasAllRequiredDocuments || hasWarning;

export const canContinueWithDocumentation = ({
  values,
  hasAllRequiredDocuments,
  hasWarning,
  errors,
  touched
}) =>
  hasRequiredDocumentsOrWarning({ hasAllRequiredDocuments, hasWarning }) &&
  canContinue({ values, errors, touched });

export const canContinuePersonalDoc = ({ values, errors, touched, documentationInfo }) =>
  documentationInfo.every(({ hasAllRequiredDocuments, hasWarning }) =>
    hasRequiredDocumentsOrWarning({ hasAllRequiredDocuments, hasWarning })
  ) && canContinue({ values, errors, touched });

export const hasWarning = (prefix, requirements, target) =>
  requirements && isTrue(requirements[addPrefix(prefix, target)]?.value);

export const isReceivedWithoutWarning = (received, warning) => received && !warning;

const setDocumentationReceived = ({ dispatch, prefix, docTarget, requirementTarget, requirements }) => {
  const documentation = addPrefix(prefix, docTarget);
  const requirement = addPrefix(INCUMBENT_TYPES[prefix], requirementTarget);
  dispatch(DocumentationActions.setDocumentationReceived(documentation, !!requirements[requirement]?.value));
};

const setDocumentationWarning = ({ dispatch, prefix, docTarget, requirementTarget, requirements }) => {
  dispatch(
    DocumentationActions.setDocumentationWarning(
      addPrefix(prefix, docTarget),
      hasWarning(INCUMBENT_TYPES[prefix], requirements, requirementTarget)
    )
  );
};

const setPersonalDocs = (docs, values, personalAction) => {
  docs.forEach(documentation => {
    personalAction({
      ...documentation,
      ...values
    });
  });
};

export const setPersonalDocsReceived = values =>
  setPersonalDocs(PERSONAL_DOCS_RECEIVED, values, setDocumentationReceived);

export const setPersonalDocsWarning = values =>
  setPersonalDocs(PERSONAL_DOCS_WARNING, values, setDocumentationWarning);
