import { DOCUMENTATION_TYPES } from '~constants/documentation';

export const DOCUMENTATION_TARGETS = ({
  idWarning,
  visaWarning,
  earningsWarning,
  idFrontReceived,
  idBackReceived,
  visaReceived,
  earningsReceived
}) => ({
  WARNING_METAS: [
    {
      boolean: idWarning,
      docType: DOCUMENTATION_TYPES.WARNING.RUT_FILE
    },
    {
      boolean: visaWarning,
      docType: DOCUMENTATION_TYPES.WARNING.VISA_DOCUMENT
    },
    {
      boolean: earningsWarning,
      docType: DOCUMENTATION_TYPES.WARNING.PERSONAL_EARNINGS
    }
  ],
  RECEIVED_METAS: [
    {
      boolean: idFrontReceived,
      docType: DOCUMENTATION_TYPES.RECEIVED.FRONT_RUT
    },
    {
      boolean: idBackReceived,
      docType: DOCUMENTATION_TYPES.RECEIVED.BACK_RUT
    },
    {
      boolean: visaReceived,
      docType: DOCUMENTATION_TYPES.RECEIVED.VISA_DOCUMENT
    },
    {
      boolean: earningsReceived,
      docType: DOCUMENTATION_TYPES.RECEIVED.PERSONAL_EARNINGS
    }
  ]
});
