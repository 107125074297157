import i18next from 'i18next';

import PasswordInput from '~components/PasswordInput';
import Input from '~components/Input';
import Select from '~app/components/Select';

import { getAfpOptions } from '../AfpOptions/utils';

export const FIELDS = rutDisabled => [
  {
    component: Select,
    name: 'afpType',
    type: 'AFP',
    options: getAfpOptions(),
    placeholder: i18next.t('UniqueKeyScraper:afpSectionTitle'),
    sectionTitle: i18next.t('UniqueKeyScraper:afpSectionTitle')
  },
  {
    component: Input,
    name: 'rut',
    label: i18next.t('UniqueKeyScraper:rutLabel'),
    sectionTitle: i18next.t('UniqueKeyScraper:rutSectionTitle'),
    disabled: rutDisabled
  },
  {
    component: PasswordInput,
    name: 'uniqueKey',
    type: 'password',
    autoComplete: 'new-password',
    label: i18next.t('UniqueKeyScraper:passwordLabel'),
    sectionTitle: i18next.t('UniqueKeyScraper:passwordSectionTitle'),
    alternativeTitle: i18next.t('UniqueKeyScraper:passwordAlternativeTitle'),
    needsCustomError: true
  }
];
