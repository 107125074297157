import i18next from 'i18next';
import React from 'react';
import { bool } from 'prop-types';

import { stepsProptype } from '~propTypes/steps';
import SuccessMessage from '~app/components/SuccessMessage';

import styles from './styles.module.scss';
import { completeInformationMessage, hasCompletedInformation } from './utils';

function InformationStatusMessage({ steps, needsGuarantor, isGuarantorView }) {
  const informationIsComplete = hasCompletedInformation(steps, needsGuarantor, isGuarantorView);
  return informationIsComplete ? (
    <SuccessMessage
      className={styles.successMessageContainer}
      textClassName={styles.successText}
      successText={i18next.t(`StatusPage:${completeInformationMessage(isGuarantorView)}`)}
    />
  ) : (
    <span className={styles.guideMessage}>{i18next.t('StatusPage:infoNotCompletedMessage')}</span>
  );
}

InformationStatusMessage.propTypes = {
  isGuarantorView: bool,
  needsGuarantor: bool,
  steps: stepsProptype
};

export default InformationStatusMessage;
