import { number, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import FileUploaderModal from '~components/FileUploaderModal';
import { useFiles } from '~app/hooks/useFiles';
import { FILE_UPLOADER_MODAL_INFO } from '~constants/fileUploader';

import styles from './styles.module.scss';
import DocumentationCardRow from './components/DocumentationCardRow';

function DocumentationCardInfo({
  incumbent,
  target,
  name,
  index,
  modalTarget,
  receivedTarget,
  successTarget,
  type
}) {
  const { quickCode } = useParams();
  const documentation = useSelector(state => state.documentation);
  const filesProps = useFiles(quickCode, incumbent, type, receivedTarget);
  return (
    <div key={name} className={styles.container}>
      <DocumentationCardRow
        target={target}
        name={name}
        modalTarget={modalTarget}
        index={index}
        contentContainer={styles.contentContainer}
        contentContainerBackground={styles.contentContainerBackground}
        success={documentation[successTarget]}
      />
      <FileUploaderModal modalTarget={modalTarget} {...filesProps} {...FILE_UPLOADER_MODAL_INFO[type]} />
    </div>
  );
}

DocumentationCardInfo.propTypes = {
  incumbent: string,
  index: number,
  modalTarget: string,
  name: string,
  receivedTarget: string,
  successTarget: string,
  target: string,
  type: string
};

export default DocumentationCardInfo;
