import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, func, shape, string } from 'prop-types';
import i18next from 'i18next';

import { MODALS } from '~redux/Modal/constants';
import CustomModal from '~app/components/CustomModal';
import FileUploader from '~app/components/FileUploader';
import FileActions from '~redux/File/actions';
import ButtonLoading from '~app/components/ButtonLoading';
import { isEmpty } from '~utils/array';
import { hasSurpassedMaxSize } from '~utils/file';

import styles from './styles.module.scss';
import FilesUploaded from './components/FilesUploaded';
import FileUploaderModalHeader from './components/FileUploaderModalHeader';
import { FILE_ERRORS_MESSAGES } from './constants';

function FileUploaderModal({
  addFilesToUpload,
  uploadFiles,
  fileUploaderCustomText,
  fileUploaderClassName,
  fileUploaderTextClassName,
  filesClassName,
  filesWithErrorClassName,
  modalTarget,
  targetsMultipleFiles = true,
  ...props
}) {
  const target = modalTarget || MODALS.FILE_UPLOADER;
  const dispatch = useDispatch();
  const { filesToUpload, uploadFilesLoading, uploadFilesError, fileError } = useSelector(state => state.file);
  const open = useSelector(state => state.modal[target]);
  const closeModal = () => {
    dispatch(FileActions.resetFiles(target));
  };
  const hasMaxSizeError = useMemo(
    () => filesToUpload.some(file => hasSurpassedMaxSize(file)),
    [filesToUpload]
  );
  return (
    <CustomModal
      className={styles.modal}
      modal={target}
      onClose={closeModal}
      isOpen={open}
      hideCloseButton={uploadFilesLoading}
      shouldCloseOnOverlayClick={!uploadFilesLoading}>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <FileUploaderModalHeader {...props} />
          <FileUploader
            handleFiles={addFilesToUpload}
            loading={uploadFilesLoading}
            customText={fileUploaderCustomText}
            buttonClassName={fileUploaderClassName}
            buttonTextClassName={fileUploaderTextClassName}
            targetsMultipleFiles={targetsMultipleFiles}
          />
          <FilesUploaded
            containerClassName={filesClassName}
            containerWithErrorClassName={filesWithErrorClassName}
            hasMaxSizeError={hasMaxSizeError}
          />
        </div>
        <div className={styles.uploadButtonContainer}>
          {(uploadFilesError || hasMaxSizeError) && (
            <span className={styles.error}>
              {FILE_ERRORS_MESSAGES[fileError] ||
                i18next.t(`FileUploaderModal:${hasMaxSizeError ? 'maxSizeError' : 'error'}`)}
            </span>
          )}
          <ButtonLoading
            primary
            loading={uploadFilesLoading}
            onClick={uploadFiles(target)}
            disabled={isEmpty(filesToUpload) || hasMaxSizeError}>
            {i18next.t('FileUploaderModal:save')}
          </ButtonLoading>
        </div>
      </div>
    </CustomModal>
  );
}

FileUploaderModal.propTypes = {
  addFilesToUpload: func,
  filesClassName: string,
  filesWithErrorClassName: string,
  fileUploaderClassName: string,
  fileUploaderCustomText: string,
  fileUploaderTextClassName: string,
  modalTarget: string,
  targetsMultipleFiles: bool,
  uploadFiles: func,
  userProperties: shape({})
};

export default FileUploaderModal;
