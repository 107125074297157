import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

import { useRedirect } from '~hooks/useRedirect';
import UniqueKeyScraper from '~components/UniqueKeyScraper';
import { INCUMBENT_TYPES } from '~constants/incumbent';
import { ROUTES } from '~constants/routes';
import ScrapersActions from '~redux/Scrapers/actions';
import { mapAllDataScraperValues } from '~models/scrapers';
import SimulationNewFlowWrapper from '~components/SimulationNewFlowWrapper';
import StepsActions from '~redux/Steps/actions';
import { STEPS_STATES, STEPS_TARGETS } from '~constants/steps';

import UniqueKeyLoading from '../UniqueKeyLoading';

function ClientUniqueKey() {
  const dispatch = useDispatch();
  const { scraperAllDataLoading, scraperAllDataError } = useSelector(state => state.scrapers);
  const { changeIncumbentLoading, clientFlowChoice } = useSelector(state => state.simulation);
  const handleRedirect = useRedirect();
  const customPostSuccessAction = () => {
    dispatch(StepsActions.setStepState(STEPS_TARGETS.CLIENT_FINANCIAL_INFORMATION, STEPS_STATES.COMPLETED));
    handleRedirect({
      customStepName: ROUTES.CLIENT_UNIQUE_KEY_SUCCESS.stepName
    })();
  };
  const automaticToManual = ({ className }) => (
    <span className={className}>{i18next.t('AutomaticToManual:title')}</span>
  );
  const showFlowChanger = submitCount => submitCount >= 2 && scraperAllDataError;
  const handleLogAndReset = () => {
    dispatch(ScrapersActions.resetErrors());
  };
  return (
    <SimulationNewFlowWrapper
      previousStepName={ROUTES.CLIENT_FLOW_CHOICE.stepName}
      disabled={scraperAllDataLoading || changeIncumbentLoading}
      customAction={handleLogAndReset}
      loading={changeIncumbentLoading}>
      <UniqueKeyLoading loading={scraperAllDataLoading}>
        <UniqueKeyScraper
          customPostSuccessAction={customPostSuccessAction}
          incumbent={INCUMBENT_TYPES.CLIENT}
          scraperAction={ScrapersActions.scrapeAllData}
          mapIncumbentValues={mapAllDataScraperValues}
          Title={automaticToManual}
          currentStepName={ROUTES.CLIENT_INCOMES.stepName}
          currentFlowChoice={clientFlowChoice}
          showFlowChanger={showFlowChanger}
        />
      </UniqueKeyLoading>
    </SimulationNewFlowWrapper>
  );
}

export default ClientUniqueKey;
