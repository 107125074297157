import { createTypes, completeTypes } from 'redux-recompose';

import { DOCS_TARGETS } from './constants';

export const actions = createTypes(
  completeTypes(
    [],
    [
      'SET_DOCUMENTATION_RECEIVED',
      'SET_DOCUMENTATION_WARNING',
      'SET_CLIENT_CONFIDENTIAL_INFO',
      'SET_CLIENT_PERSONAL_DOCUMENTATION',
      'SET_GUARANTOR_PERSONAL_DOCUMENTATION'
    ]
  ),
  '@@DOCUMENTATION'
);

export const actionCreators = {
  setDocumentationReceived: (documentation, state) => ({
    type: actions.SET_DOCUMENTATION_RECEIVED,
    target: DOCS_TARGETS[documentation],
    payload: state
  }),
  setDocumentationWarning: (documentation, state) => ({
    type: actions.SET_DOCUMENTATION_WARNING,
    target: DOCS_TARGETS[documentation],
    payload: state
  }),
  setClientConfidentialInfo: values => ({
    type: actions.SET_CLIENT_CONFIDENTIAL_INFO,
    target: DOCS_TARGETS.CLIENT_CONFIDENTIAL_INFO,
    payload: values
  }),
  setClientPersonalDocumentation: values => ({
    type: actions.SET_CLIENT_PERSONAL_DOCUMENTATION,
    target: DOCS_TARGETS.CLIENT_PERSONAL_DOCUMENTATION,
    payload: values
  }),
  setGuarantorPersonalDocs: values => ({
    type: actions.SET_GUARANTOR_PERSONAL_DOCUMENTATION,
    target: DOCS_TARGETS.GUARANTOR_PERSONAL_DOCUMENTATION,
    payload: values
  })
};

export default actionCreators;
