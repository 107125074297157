import numeral from 'numeral';
import { format, parse } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import 'numeral/locales';
// switch between locales
numeral.locale('es');

export const formatNumber = number => (number ? numeral(Math.round(number)).format('0,0') : null);

export const formatMoney = money => (money ? '$'.concat(formatNumber(money)) : '$0');

export const formatStringMoney = money => formatMoney(parseInt(money, 10));

export const formatDate = date => (date ? format(new Date(date), 'dd/MM/yy') : null);

export const formatSmallDate = date => format(new Date(date), 'dd/MM', { locale: esLocale });

export const formatDayString = date => formatDate(parse(date, 'yyyy-MM-dd', new Date()));

export const paymentPercentage = (outstandingBalance, grossAmount) =>
  100 - Math.floor((outstandingBalance * 100) / grossAmount);

export const formatRate = rate => (rate ? `${rate}%` : null);

export const formatPhone = phone => (phone ? `+${phone}` : null);
