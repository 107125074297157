import React from 'react';
import { int, node, string } from 'prop-types';

import { setMoneyAmount } from '~utils/money';

import IconRow from '../IconRow';

function LabelAmount({ icon, label, amount, className, defaultAmount }) {
  return (
    <IconRow icon={icon}>
      <div className={`column full-width ${className}`}>
        <div className="row medium">{label}</div>
        <div className="row bold">{setMoneyAmount(amount, defaultAmount)}</div>
      </div>
    </IconRow>
  );
}

LabelAmount.propTypes = {
  amount: int,
  className: string,
  defaultAmount: int,
  icon: node,
  label: string
};

export default LabelAmount;
