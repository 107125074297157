import i18next from 'i18next';
import React from 'react';

import CreditEndingStatus from '~components/CreditEndingStatus';
import NavBarWrapper from '~components/NavBarWrapper';
import { ReactComponent as Approved } from '~assets/credit-approved.svg';

function CreditApproved() {
  return (
    <NavBarWrapper disabled>
      <CreditEndingStatus
        title={i18next.t('CreditApproved:title')}
        info={i18next.t('CreditApproved:info')}
        Image={Approved}
      />
    </NavBarWrapper>
  );
}

export default CreditApproved;
