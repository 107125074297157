import { camelToSnakeCase } from '~utils/string';

const getValidDownpayment = (isConsumer, downpayment) => (isConsumer ? 0 : downpayment);

const META_DOWNPAYMENT_INFO = {
  HAS_DOWNPAYMENT: 'simulation_has_downpayment_meta',
  WHEN_BUY: 'simulation_when_buy_meta'
};

export const getCreditSimulationState = ({ amount, currentTerm, downpayment, isConsumer }) => ({
  amount,
  term: currentTerm?.term,
  downpayment: getValidDownpayment(isConsumer, downpayment)
});

export const postDownpaymentInfoMapper = (hasDownpayment, whenBuy) => [
  {
    kind: META_DOWNPAYMENT_INFO.HAS_DOWNPAYMENT,
    value: camelToSnakeCase(hasDownpayment.value)
  },
  {
    kind: META_DOWNPAYMENT_INFO.WHEN_BUY,
    value: camelToSnakeCase(whenBuy.value)
  }
];
