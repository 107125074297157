import React from 'react';
import { string, bool } from 'prop-types';

import { ReactComponent as Warning } from '~assets/warning.svg';
import { ReactComponent as CircleWarning } from '~assets/circle-warning.svg';

import StateModal from '../StateModal';

import { getErrorModalType } from './utils';

function ErrorModal({ type, hasCircleWarning, ...props }) {
  const modalType = getErrorModalType(type);
  return <StateModal {...props} modalType={modalType} Icon={hasCircleWarning ? CircleWarning : Warning} />;
}

ErrorModal.propTypes = {
  hasCircleWarning: bool,
  type: string
};

export default ErrorModal;
