import api from '~config/api';

const TIMEOUT = {
  timeout: 25000
};

export const findLastSimulation = params => api.get('/clients/find_last_simulation', params, TIMEOUT);

export const getLoanDraft = kind => api.get(`/drafts/loan/${kind}`);

export const getTerms = ({ kind, amount, productType, term = '', downpayment = '' }) =>
  api.get(
    `/drafts/calculate?kind=${kind}&amount=${amount}&product_type=${productType}&term=${term}&downpayment=${downpayment}`
  );

export const createSimulation = values => api.post('/simulations', values, TIMEOUT);

export const changeIncumbent = ({ quickCode, values }) =>
  api.post(`/simulations/${quickCode}/change`, values);

export const updateSimulation = ({ quickCode, values }) => api.put(`/simulations/${quickCode}`, values);

export const getStepsProgress = quickCode => api.get(`/simulations/${quickCode}/step_progress`);

export const fetchSimulation = quickCode => api.get(`/simulations/${quickCode}/fetch`);
