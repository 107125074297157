import i18next from 'i18next';

import { ReactComponent as WhatsApp } from '~assets/whatsapp-alternative.svg';
import { ReactComponent as Phone } from '~assets/phone.svg';
import { formatPhoneNumber } from '~utils/phoneNumber';

export const CONTACTS = {
  WHATSAPP: {
    Logo: WhatsApp,
    buttonText: i18next.t('MigranteContacts:whatsAppButtonText'),
    handleClick: () => window?.open(process.env.REACT_APP_WHASTAPP_CONTACT)?.focus(),
    afterText: i18next.t('MigranteContacts:whatsappAfterText')
  },
  PHONE: {
    Logo: Phone,
    buttonText: i18next.t('MigranteContacts:phoneButtonText', {
      phone: formatPhoneNumber(process.env.REACT_APP_PHONE_CONTACT)
    }),
    href: `tel:${process.env.REACT_APP_PHONE_CONTACT}`
  }
};
