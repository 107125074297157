import React from 'react';
import i18next from 'i18next';
import { func, shape, string } from 'prop-types';
import { useParams } from 'react-router';

import ErrorModal from '~components/ErrorModal';
import { useFiles } from '~hooks/useFiles';
import FileUploaderModal from '~components/FileUploaderModal';
import { FILE_UPLOADER_MODAL_INFO } from '~constants/fileUploader';
import { ERROR_MODALS } from '~constants/modals';
import { useRut } from '~app/hooks/useRut';

import ScraperModal from './components/ScraperModal';

function IncomesModals({ prefix, values, modalType, handleConfirm, typeHandlers, warningInfo }) {
  const { quickCode } = useParams();
  const handlers = typeHandlers[modalType];
  const currentRut = useRut(prefix);
  const filesProps = useFiles(quickCode, prefix, modalType);
  return (
    <>
      <ScraperModal
        type={modalType}
        typeHandlers={handlers}
        prefix={prefix}
        employmentSituation={values.employmentSituation}
        rut={currentRut}
        warning={warningInfo[modalType]}
      />
      <ErrorModal
        type={ERROR_MODALS.REMEMBER}
        title={i18next.t('IncomesForm:remember')}
        info={i18next.t('IncomesForm:missingInfo')}
        boldInfo={i18next.t('IncomesForm:complete')}
        buttonText={i18next.t('IncomesForm:understand')}
        handleClick={handleConfirm}
        hasCircleWarning
      />
      <FileUploaderModal
        prefix={prefix}
        type={modalType}
        {...filesProps}
        {...FILE_UPLOADER_MODAL_INFO[modalType]}
      />
    </>
  );
}

IncomesModals.propTypes = {
  handleConfirm: func,
  modalType: string,
  prefix: string,
  typeHandlers: shape,
  values: shape,
  warningInfo: shape
};
export default IncomesModals;
