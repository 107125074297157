import Immutable from 'seamless-immutable';
import { completeState, completeReducer, createReducer } from 'redux-recompose';

import { actions } from './actions';
import { VERIFICATIONS_DEFAULT_STATE } from './constants';

const defaultState = completeState(VERIFICATIONS_DEFAULT_STATE);

const reducerDescription = {
  primaryActions: [actions.TRIGGER_CHECKOUT]
};

const reducer = createReducer(new Immutable(defaultState), completeReducer(reducerDescription));

export default reducer;
