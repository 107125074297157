export const LABOR_FIELDS = {
  LABOR_SITUATION: 'laborSituation',
  EMITTED_INVOICES_LAST_YEAR: 'emittedInvoicesLastYear',
  MONTHLY_INCOMES: 'monthlyIncomes',
  JOB_START_DATE: 'jobStartDate'
};

export const INITIAL_VALUES = {
  [LABOR_FIELDS.LABOR_SITUATION]: null,
  [LABOR_FIELDS.EMITTED_INVOICES_LAST_YEAR]: null,
  [LABOR_FIELDS.MONTHLY_INCOMES]: '',
  [LABOR_FIELDS.JOB_START_DATE]: ''
};

export const LABOR_REMAINING_KEYS = [
  LABOR_FIELDS.LABOR_SITUATION,
  LABOR_FIELDS.EMITTED_INVOICES_LAST_YEAR,
  LABOR_FIELDS.MONTHLY_INCOMES,
  LABOR_FIELDS.JOB_START_DATE
];
