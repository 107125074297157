import i18next from 'i18next';
import React from 'react';

import { ReactComponent as NotFound } from '~assets/not-found.svg';

import ErrorScreen from '../ErrorScreen';

import styles from './styles.module.scss';

function NotFoundScreen() {
  return (
    <ErrorScreen
      title={i18next.t('NotFoundScreen:title')}
      subtitle={i18next.t('NotFoundScreen:subtitle')}
      backgroundClassName={styles.background}
      picture={NotFound}
      hideSecondary
    />
  );
}

export default NotFoundScreen;
