import i18next from 'i18next';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import * as Yup from 'yup';
import { addYears } from 'date-fns';

import { validateRut, validateRutLength } from '~utils/rut';
import { DATE_REGEX, EMAIL_REGEX } from '~utils/string/regex';
import {
  hasEmittedInvoicesLastYear,
  isUnemployed
} from '~app/components/LaborInformation/LaborForm/LaborFields/constants';
import { isValidInputDate } from '~utils/date/regex';

import { PHONE_COUNTRY_CODE } from './phone';
import { NAME_REGEX, RUT_REGEX } from './regex';

export const NAME_VALIDATION = Yup.string()
  .trim()
  .required(i18next.t('IdentityFormErrors:required'))
  .matches(NAME_REGEX, i18next.t('IdentityFormErrors:invalid', { field: 'nombre y apellido' }));

export const EMAIL_VALIDATION = Yup.string()
  .trim()
  .required(i18next.t('IdentityFormErrors:required'))
  .matches(EMAIL_REGEX, i18next.t('IdentityFormErrors:invalid', { field: 'mail' }));

export const PHONE_VALIDATION = Yup.string()
  .trim()
  .required(i18next.t('IdentityFormErrors:required'))
  .test({
    name: 'phoneValidation',
    message: i18next.t('IdentityFormErrors:invalidPhoneFormat'),
    test() {
      return isValidPhoneNumber(this.originalValue?.trim() || '', PHONE_COUNTRY_CODE);
    }
  });

export const IDENTITY_VALIDATION_SCHEMA = {
  email: EMAIL_VALIDATION,
  name: NAME_VALIDATION,
  phone: PHONE_VALIDATION
};

export const RUT_VALIDATION = Yup.string()
  .required(i18next.t('IdentityFormErrors:required'))
  .matches(RUT_REGEX, i18next.t('IdentityFormErrors:invalid', { field: 'RUT' }))
  .test({
    name: 'lengthValidation',
    message: i18next.t('IdentityFormErrors:invalidLength'),
    test() {
      return validateRutLength(this.originalValue?.trim());
    }
  })
  .test({
    name: 'rutValidation',
    message: i18next.t('IdentityFormErrors:invalid', { field: 'dígito verificador' }),
    test() {
      return validateRut(this.originalValue?.trim());
    }
  });

export const VALIDATION_SCHEMA_INCOMES = Yup.object().shape({
  employmentSituation: Yup.string().required(i18next.t('IncomesFormErrors:required')),
  incomes: Yup.number()
    .required(i18next.t('IncomesFormErrors:required'))
    .min(1, i18next.t('IncomesFormErrors:min'))
});

export const GENDER_VALIDATION = Yup.string()
  .trim()
  .required(i18next.t('BirthInformationFormErrors:required'));

export const BIRTHDAY_VALIDATION = Yup.string()
  .trim()
  .required(i18next.t('BirthInformationFormErrors:required'))
  .length(10, i18next.t('BirthInformationFormErrors:invalidBirthday'))
  .matches(DATE_REGEX, i18next.t('BirthInformationFormErrors:invalidBirthday'))
  .test({
    name: 'birthValidation',
    message: i18next.t('BirthInformationFormErrors:invalidBirthday'),
    test() {
      return isValidInputDate(
        this.originalValue?.trim(),
        addYears(new Date(), -(process.env.REACT_APP_MAX_AGE || 80)),
        addYears(new Date(), -(process.env.REACT_APP_MIN_AGE || 18))
      );
    }
  });

export const NATIONALITY_VALIDATION = Yup.string()
  .trim()
  .required(i18next.t('BirthInformationFormErrors:required'));

export const INFORMATION_VALIDATION_SCHEMA = {
  gender: GENDER_VALIDATION,
  birthday: BIRTHDAY_VALIDATION,
  nationality: NATIONALITY_VALIDATION
};

export const LABOR_SITUATION_VALIDATION = Yup.string()
  .trim()
  .required(i18next.t('LaborInformationFormErrors:required'));

export const EMITTED_INVOCES_VALIDATION = laborSituation =>
  hasEmittedInvoicesLastYear(laborSituation) &&
  Yup.string().trim().required(i18next.t('LaborInformationFormErrors:required'));

export const MONTHLY_INCOMES_VALIDATION = laborSituation =>
  (!laborSituation || !isUnemployed(laborSituation)) &&
  Yup.string()
    .trim()
    .required(i18next.t('LaborInformationFormErrors:required'))
    .min(1, i18next.t('LaborInformationFormErrors:min'));

export const JOB_START_DATE_VALIDATION = laborSituation =>
  (!laborSituation || !isUnemployed(laborSituation)) &&
  Yup.string()
    .trim()
    .required(i18next.t('LaborInformationFormErrors:required'))
    .length(10, i18next.t('LaborInformationFormErrors:invalidDate'))
    .matches(DATE_REGEX, i18next.t('LaborInformationFormErrors:invalidDate'))
    .test({
      name: 'dateValidation',
      message: i18next.t('LaborInformationFormErrors:invalidDate'),
      test() {
        return isValidInputDate(
          this.originalValue?.trim(),
          addYears(new Date(), -(process.env.REACT_APP_MAX_YEARS_WORKED || 50)),
          new Date()
        );
      }
    });

export const LABOR_INFORMATION_VALIDATION = laborSituation => ({
  laborSituation: LABOR_SITUATION_VALIDATION,
  emittedInvoicesLastYear: EMITTED_INVOCES_VALIDATION(laborSituation),
  monthlyIncomes: MONTHLY_INCOMES_VALIDATION(laborSituation),
  jobStartDate: JOB_START_DATE_VALIDATION(laborSituation)
});
