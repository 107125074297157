import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';

import FormWrapper from '~components/FormWrapper';
import SimulationActions from '~redux/Simulation/actions';
import LoadingWrapper from '~components/LoadingWrapper';
import { getCurrentRutAndDv } from '~utils/rut';
import { useCreateSimulation } from '~hooks/useCreateSimulation';
import { useSimulationQueryParams } from '~app/hooks/useSimulationQueryParams';
import { SIMULATION_NEEDS_GUARANTOR } from '~constants/simulations';
import SegmentService from '~services/SegmentService';
import { SEGMENT_EVENT_NAMES } from '~constants/segment';
import { PRODUCTS_NOT_AVAILABLE_PATHS, ROUTES } from '~constants/routes';

import IdentityForm from './components/IdentityForm';
import { getFormConfiguration, getIdentityInitialValues } from './utils';
import styles from './styles.module.scss';

function ClientIdentity() {
  const dispatch = useDispatch();
  const { kind } = useParams();
  const queryParams = useSimulationQueryParams();
  const { amount, downpayment, src, referralCode, owner, productCondition, paymentType } = queryParams;
  const { createSimulationLoading, loanDraftLoading, lastSimulationLoading } = useSelector(
    state => state.simulation
  );
  const createNewSimulation = useCreateSimulation();
  const handleSubmit = values => {
    const { rut, dv } = getCurrentRutAndDv(values.rut);
    dispatch(
      SimulationActions.findLastSimulation(
        {
          kind,
          email: values.email,
          rut,
          dv
        },
        createNewSimulation(values)?.()
      )
    );
  };
  useEffect(() => {
    dispatch(SimulationActions.getLoanDraft(kind));
    SegmentService.trackEvent(SEGMENT_EVENT_NAMES.CHECKOUT_STARTED, {
      amount,
      downpayment,
      productType: kind,
      condition: productCondition,
      referralCode,
      referral: src,
      executiveCode: owner,
      guarantors: SIMULATION_NEEDS_GUARANTOR[kind] ? 1 : 0,
      url: window.location.href,
      paymentType
    });
  }, []);
  const formConfig = getFormConfiguration(kind);

  return PRODUCTS_NOT_AVAILABLE_PATHS.includes(kind) ? (
    <Redirect to={ROUTES.PRODUCT_NOT_AVAILABLE.path} />
  ) : (
    <LoadingWrapper loading={loanDraftLoading}>
      <div className={styles.container}>
        <FormWrapper
          form={IdentityForm}
          initialValues={getIdentityInitialValues(queryParams, kind)}
          validationSchema={formConfig.validationSchema}
          onSubmit={handleSubmit}
          buttonLoading={createSimulationLoading || lastSimulationLoading}
          createNewSimulation={createNewSimulation}
          additionalFields={formConfig.additionalFields}
        />
      </div>
    </LoadingWrapper>
  );
}

export default ClientIdentity;
