import Immutable from 'seamless-immutable';
import { completeState, completeReducer, createReducer, onReadValue } from 'redux-recompose';

import { actions } from './actions';
import { SCRAPERS_DEFAULT_STATE } from './constants';

const defaultState = completeState(SCRAPERS_DEFAULT_STATE);

const reducerDescription = {
  primaryActions: [
    actions.SCRAPE_CMF_DATA,
    actions.SCRAPE_SII_DATA,
    actions.SCRAPE_AFP_DATA,
    actions.SCRAPE_ALL_DATA
  ],
  override: {
    [actions.SET_SCRAPER_INFO]: onReadValue()
  }
};

const reducer = createReducer(new Immutable(defaultState), completeReducer(reducerDescription));

export default reducer;
