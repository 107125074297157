import i18next from 'i18next';
import { int, bool, func, shape, string } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SimulationActions from '~redux/Simulation/actions';
import { SIMULATION_TARGETS } from '~redux/Simulation/constants';
import RadioGroup from '~app/components/RadioGroup';
import { ReactComponent as Error } from '~assets/error.svg';
import { isMotorcycleSimulation } from '~models/simulations';

import AdvancePaymentModal from '../AdvancePaymentModal';
import { mapSimulationValues } from '../../mappers';

import styles from './styles.module.scss';
import { OPTIONS } from './constants';
import { isFinanced } from './utils';
import FinancedPayment from './components/FinancedPayment';

function InstallmentsSelect({ currentValue, isConsumer, paymentOption, handlePaymentOption, ...props }) {
  const dispatch = useDispatch();
  const { downpayment, productType, kind } = useSelector(state => state.simulation);
  const handleAdvancePaymentChange = value => {
    dispatch(SimulationActions.setSimulationInfo(SIMULATION_TARGETS.DOWNPAYMENT, value));
  };
  useEffect(() => {
    if (kind && productType && currentValue) {
      const values = mapSimulationValues({
        currentValue,
        productType,
        kind,
        isConsumer,
        advancePayment: downpayment
      });
      dispatch(SimulationActions.getTerms(values));
    }
  }, [kind, downpayment]);
  return (
    <>
      <div className={styles.container}>
        <span className={styles.question}>{i18next.t('CreditSimulation:question')}</span>
        {isMotorcycleSimulation(kind) && (
          <RadioGroup
            checkedOption={paymentOption}
            options={OPTIONS}
            className={styles.radioGroupContainer}
            onChange={handlePaymentOption}
          />
        )}
        {isFinanced(paymentOption) ? (
          <FinancedPayment currentValue={currentValue} isConsumer={isConsumer} {...props} />
        ) : (
          <>
            <div className={styles.noExtraCost}>
              <Error />
              <span>{i18next.t('CreditSimulation:noExtraCost')}</span>
            </div>
          </>
        )}
      </div>
      <AdvancePaymentModal
        currentValue={currentValue}
        advancePayment={downpayment}
        handleAdvancePaymentChange={handleAdvancePaymentChange}
      />
    </>
  );
}

InstallmentsSelect.propTypes = {
  currentValue: int,
  handlePaymentOption: func,
  isConsumer: bool,
  paymentOption: shape({ label: string, value: string, name: string })
};

export default InstallmentsSelect;
