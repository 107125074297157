import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import ModalActions from '~redux/Modal/actions';
import { MODALS } from '~redux/Modal/constants';
import { ROUTES } from '~constants/routes';
import SimulationActions from '~redux/Simulation/actions';
import StepsActions from '~redux/Steps/actions';
import { mapIncumbentValue, mapStepMetaInfo } from '~redux/Simulation/mappers';
import { CLIENT_STEPS_TARGETS, STEPS_STATES, STEPS_TARGETS } from '~constants/steps';
import { getConfirmationIncumbent, hasCompleted } from '~utils/steps';
import { INCUMBENT_TYPES } from '~constants/incumbent';
import { META_VALUES } from '~constants/metaValues';
import { META_BOOLEANS_VALUES } from '~constants/metaInfo';
import { SIMULATION_TARGETS } from '~redux/Simulation/constants';

import { useRedirect } from './useRedirect';

export const useContinueWithGuarantor = () => {
  const dispatch = useDispatch();
  const { quickCode } = useParams();
  const steps = useSelector(state => state.steps);
  const handleOpenModal = modal => () => dispatch(ModalActions.openModal(modal));
  const handleOpenInfoModal = handleOpenModal(MODALS.GUARANTOR_INFO);
  const handleOpenWarning = handleOpenModal(MODALS.NO_GUARANTOR_ERROR);
  const handleShareLink = handleOpenModal(MODALS.SHARE_LINK);
  const handleRedirect = useRedirect();
  const handlePostAction = () => {
    dispatch(SimulationActions.setSimulationInfo(SIMULATION_TARGETS.CLIENT_KNOWS_STATUS_SHARE_LINK, true));
    handleRedirect({ customStepName: ROUTES.GUARANTOR_IDENTITY.stepName })();
  };
  const sendClientConfirmation = (knowsShareLink, postSuccessAction) => () => {
    dispatch(
      SimulationActions.changeIncumbent({
        quickCode,
        values: [
          ...mapStepMetaInfo(STEPS_TARGETS.CLIENT_CONFIRMATION),
          ...getConfirmationIncumbent(INCUMBENT_TYPES.CLIENT, 'complete'),
          ...(knowsShareLink
            ? mapIncumbentValue(META_VALUES.CLIENT_KNOWS_SHARE_LINK_META, META_BOOLEANS_VALUES.TRUE)
            : [])
        ],
        postSuccessAction
      })
    );
  };
  const handleContinue = () => {
    if (hasCompleted(steps[CLIENT_STEPS_TARGETS.CLIENT_FINANCIAL_INFORMATION])) {
      dispatch(StepsActions.setStepState(STEPS_TARGETS.CLIENT_CONFIRMATION, STEPS_STATES.COMPLETED));
    }
    sendClientConfirmation(true, handlePostAction)();
  };
  return { handleOpenInfoModal, handleOpenWarning, handleShareLink, handleContinue, sendClientConfirmation };
};
