import i18next from 'i18next';
import React from 'react';
import { bool } from 'prop-types';
import { useSelector } from 'react-redux';

import { APP_STORE, GOOGLE_PLAY_STORE } from '~constants/urls';
import googleplay from '~assets/googleplay.png';
import { ReactComponent as AppStore } from '~assets/appstore.svg';
import { useIsView } from '~app/hooks/useIsView';

import { showDownloadAppButtons } from '../../utils';
import { APP_VIEW } from '../../constants';

import styles from './styles.module.scss';

function DownloadAppButtons({ clientAndHasCompletedGuarantor }) {
  const handleRedirect = url => () => window.open(url);
  const needsGuarantor = useSelector(state => state.simulation.needsGuarantor);
  const isAppView = useIsView(APP_VIEW);
  return showDownloadAppButtons({
    isAppView,
    needsGuarantor,
    clientAndHasCompletedGuarantor
  }) ? (
    <div className={styles.footerContainer}>
      <span className={styles.statusMessage}>{i18next.t('StatusPage:message')}</span>
      <div className={styles.storeButtons}>
        <img
          src={googleplay}
          className={styles.googlePlayButton}
          onClick={handleRedirect(GOOGLE_PLAY_STORE)}
        />
        <AppStore className={styles.appStoreButton} onClick={handleRedirect(APP_STORE)} />
      </div>
    </div>
  ) : null;
}

DownloadAppButtons.propTypes = {
  clientAndHasCompletedGuarantor: bool
};

export default DownloadAppButtons;
