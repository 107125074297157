import { SIMULATION_TARGETS, SIMULATION_TYPES_NAMES } from '~constants/simulations';
import { ReactComponent as LogoYellow } from '~assets/logos/normal/logo-yellow.svg';
import { ReactComponent as LogoBlue } from '~assets/logos/normal/logo-blue.svg';
import { isConsumerSimulation } from '~models/simulations';

import styles from './styles.module.scss';

export const STYLES_MAPPER = (primaryValue, secondaryValue) =>
  Object.keys(SIMULATION_TARGETS).reduce((prevValues, actualValue) => {
    const actualKind = SIMULATION_TYPES_NAMES[actualValue];
    return {
      ...prevValues,
      [actualKind]: isConsumerSimulation(actualKind) ? primaryValue : secondaryValue
    };
  }, {});

export const BANNER_COLORS = STYLES_MAPPER(styles.blueBackground, styles.yellowBackground);

export const LOGO = STYLES_MAPPER(LogoYellow, LogoBlue);
