export const META_INFO_WARNINGS = {
  AFP: '_retirement_fund_certificate_unknown_meta',
  SII: '_personal_invoice_unknown_meta',
  RUT_FILE: '_rut_document_unknown_meta',
  VISA_DOCUMENT: '_visa_document_unknown_meta',
  CMF_CERTIFICATE: '_debt_certificate_unknown_meta',
  PERSONAL_EARNINGS: '_personal_earnings_unknown_meta'
};

export const META_INFO_RECEIVED = {
  AFP: '_retirement_fund_certificate_file_sent_meta',
  SII: '_personal_invoice_file_sent_meta',
  FRONT_RUT: '_front_rut_file_sent_meta',
  BACK_RUT: '_back_rut_file_sent_meta',
  VISA_DOCUMENT: '_visa_document_file_sent_meta',
  CMF_CERTIFICATE: '_debt_certificate_file_sent_meta',
  PERSONAL_EARNINGS: '_personal_earnings_file_sent_meta'
};

export const META_BOOLEANS_VALUES = {
  FALSE: 'false',
  TRUE: 'true'
};
