import { EMPLOYMENT_LABORAL_SITUATION, EMPLOYMENT_STATUS } from '~constants/employmentSituation';

const hasEmploymentSituationWarning = (employmentSituation, statusKey, warning) =>
  employmentSituation === EMPLOYMENT_STATUS[statusKey] && warning;

export const isIndependentAndHasWarning = (employmentSituation, siiWarning) =>
  hasEmploymentSituationWarning(employmentSituation, EMPLOYMENT_LABORAL_SITUATION.INDEPENDENT, siiWarning);

export const isDependentAndHasWarning = (employmentSituation, siiWarning) =>
  hasEmploymentSituationWarning(employmentSituation, EMPLOYMENT_LABORAL_SITUATION.DEPENDENT, siiWarning);

const isDependentAndIndependent = employmentSituation =>
  employmentSituation === EMPLOYMENT_STATUS.DEPENDENT_AND_INDEPENDENT;

const isBothSituationsAndHasWarning = (employmentSituation, siiWarning, afpWarning) =>
  isDependentAndIndependent(employmentSituation) && (siiWarning || afpWarning);

export const isEmployed = employmentSituation =>
  !!employmentSituation && employmentSituation !== EMPLOYMENT_STATUS.UNEMPLOYED;

export const hasEmploymentAndWarning = (employmentSituation, afpWarning, siiWarning) =>
  isDependentAndHasWarning(employmentSituation, afpWarning) ||
  isIndependentAndHasWarning(employmentSituation, siiWarning) ||
  isBothSituationsAndHasWarning(employmentSituation, siiWarning, afpWarning);

export const isDependent = employmentSituation =>
  employmentSituation === EMPLOYMENT_STATUS.DEPENDENT || isDependentAndIndependent(employmentSituation);
