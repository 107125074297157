import React from 'react';
import { useSelector } from 'react-redux';

import { isManualChoice } from '~utils/flowChoice';

import ClientIncomes from '../ClientIncomes';
import ClientUniqueKey from '../ClientUniqueKey';

function ClientFinancialInformation() {
  const clientFlowChoice = useSelector(state => state.simulation.clientFlowChoice);
  return isManualChoice(clientFlowChoice) ? <ClientIncomes /> : <ClientUniqueKey />;
}

export default ClientFinancialInformation;
