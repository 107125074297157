export const STATUS_PAGE_MODAL_CONFIG = {
  GUARANTOR: {
    title: 'modalTitleGuarantor',
    info: 'modalInfoGuarantor'
  },
  CLIENT: {
    title: 'modalTitle',
    info: 'modalInfo'
  }
};
