import api from '~config/api';

const TIMEOUT = {
  timeout: 20000
};

const AFP_TIMEOUT = {
  timeout: 50000
};

export const scrapeAllData = values => api.post('/scrapers/scrape_data', values, TIMEOUT);

export const scrapeAfpData = values => api.post('/scrapers/afp', values, AFP_TIMEOUT);

export const scrapeSiiData = values => api.post('/scrapers/sii', values, TIMEOUT);

export const scrapeCmfData = values => api.post('/scrapers/cmf', values, TIMEOUT);
