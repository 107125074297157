import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useEffect } from 'react';

import ModalActions from '~redux/Modal/actions';
import { MODALS } from '~redux/Modal/constants';
import { INCUMBENT_TYPES } from '~constants/incumbent';
import VerificationsActions from '~redux/Verifications/actions';
import { addPrefix } from '~utils/string';

import { useIsView } from './useIsView';

export const useTriggerEmail = () => {
  const dispatch = useDispatch();
  const { quickCode } = useParams();
  const { currentClient, clientConfirmedEmail } = useSelector(state => state.simulation);
  const isGuarantorView = useIsView(INCUMBENT_TYPES.GUARANTOR);
  useEffect(() => {
    if (!isGuarantorView) {
      if (currentClient?.email && !clientConfirmedEmail) {
        dispatch(
          VerificationsActions.triggerCheckout({
            quickCode,
            incumbent: INCUMBENT_TYPES.CLIENT,
            value: currentClient?.email,
            field: addPrefix(INCUMBENT_TYPES.CLIENT, '_email')
          })
        );
      }
      dispatch(ModalActions[clientConfirmedEmail ? 'closeModal' : 'openModal'](MODALS.EMAIL));
    }
  }, [isGuarantorView, currentClient, clientConfirmedEmail]);
};
