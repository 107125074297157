import i18next from 'i18next';
import * as Yup from 'yup';

const REQUIRED_PERSONAL_DOC = Yup.string().required(
  i18next.t('GuarantorPersonalDocumentationErrors:required')
);

export const VALIDATION_SCHEMA = Yup.object().shape({
  identityCard: REQUIRED_PERSONAL_DOC,
  title: REQUIRED_PERSONAL_DOC
});
