import { addPrefix } from '~utils/string';

export const mapIncumbentValues = targetFiles => incumbent =>
  [
    {
      value: 'pending',
      kind: addPrefix(incumbent, targetFiles.kind),
      context: addPrefix(incumbent, targetFiles.context)
    }
  ];
