import { INCUMBENT_IDENTITY_FIELDS } from './incumbent';

export const ANALYTICS_IDENTITY_TARGETS = [
  { fieldName: INCUMBENT_IDENTITY_FIELDS.NAME, valueTarget: 'name' },
  { fieldName: INCUMBENT_IDENTITY_FIELDS.PHONE, valueTarget: 'phone' },
  { fieldName: INCUMBENT_IDENTITY_FIELDS.EMAIL, valueTarget: 'email' },
  { fieldName: INCUMBENT_IDENTITY_FIELDS.RUT, valueTarget: 'completeRutAndDv' }
];

export const SEGMENT_EVENT_NAMES = {
  CHECKOUT_STARTED: 'Checkout Started'
};
