import { shape } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { formatBytes, hasSurpassedMaxSize } from '~utils/file';
import { useFiles } from '~app/hooks/useFiles';
import { ReactComponent as TrashCan } from '~assets/trash-can.svg';
import { ReactComponent as Success } from '~assets/success.svg';
import { ReactComponent as Error } from '~assets/error.svg';

import { isUnknownError, isUploaded } from './utils';
import styles from './styles.module.scss';

function FileUploaded({ file }) {
  const { removeFileToUpload } = useFiles();
  const { fileError, uploadedFiles } = useSelector(state => state.file);
  const hasSuccess = fileError && isUploaded(file, uploadedFiles);
  const hasWarning =
    (fileError && !isUploaded(file, uploadedFiles)) ||
    (isUnknownError(fileError) && !hasSuccess) ||
    hasSurpassedMaxSize(file);
  return (
    <div className={styles.file} key={file.name}>
      <div className={styles.fileInfoContainer}>
        <div className={styles.fileNameContainer}>
          <span className={styles.fileName}>{file.name}</span>
          {hasWarning && <Error className={styles.errorIcon} />}
        </div>
        <span className={styles.fileSize}>{formatBytes(file.size)}</span>
      </div>
      {hasSuccess ? (
        <Success className={styles.uploadIcon} />
      ) : (
        <TrashCan className={styles.trashCan} onClick={removeFileToUpload(file.name)} />
      )}
    </div>
  );
}

FileUploaded.propTypes = {
  file: shape({})
};

export default FileUploaded;
