import { EXPECTED_QUERY_PARAMS, SIMULATION_QUERY_PARAMS } from '~constants/queryParams';
import { getParam, useQuery } from '~utils/query';

export const useSimulationQueryParams = () => {
  const query = useQuery();
  return Object.values(SIMULATION_QUERY_PARAMS).reduce(
    (prevValues, actualValue) => ({
      ...prevValues,
      [actualValue]: getParam(query, EXPECTED_QUERY_PARAMS[actualValue])
    }),
    {}
  );
};
