import { string } from 'prop-types';
import React, { useState } from 'react';

import { ReactComponent as Eye } from '~assets/eye.svg';
import { ReactComponent as CoveredEye } from '~assets/covered-eye.svg';

import Input from '../Input';

function PasswordInput({ ...inputProps }) {
  const [showPassword, setShowPassword] = useState(false);
  const handlePassword = () => setShowPassword(!showPassword);
  const PasswordEye = showPassword ? CoveredEye : Eye;
  const eye = () => <PasswordEye onClick={handlePassword} />;
  return <Input {...inputProps} CustomIcon={eye} type={showPassword ? 'text' : 'password'} />;
}

PasswordInput.propTypes = {
  type: string
};

export default PasswordInput;
