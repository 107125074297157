import styles from './styles.module.scss';

export const ICON_STYLES = {
  completed: {
    iconContainerStyle: styles.iconContainerCompleted,
    iconStyle: styles.iconCompleted,
    labelStyle: styles.labelCompleted
  },
  selected: {
    iconContainerStyle: styles.iconContainerSelected,
    iconStyle: styles.iconSelected,
    labelStyle: styles.labelSelected
  },
  notSelected: {
    iconContainerStyle: styles.iconContainerNotSelected,
    iconStyle: styles.iconNotSelected,
    labelStyle: styles.labelNotSelected
  }
};
