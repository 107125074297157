import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useDocumentationInfo = (documents, warningStateTarget) => {
  const documentation = useSelector(state => state.documentation);
  const hasAllRequiredDocuments = useMemo(
    () => !documents || documents.every(doc => documentation[doc.successTarget]),
    [documents, documentation]
  );
  const hasWarning = documentation[warningStateTarget];
  return { hasAllRequiredDocuments, hasWarning };
};
