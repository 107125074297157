import i18next from 'i18next';
import React from 'react';

import Button from '~components/Button';
import { useModal } from '~hooks/useModal';
import { MODALS } from '~redux/Modal/constants';

import styles from './styles.module.scss';

function GuarantorContinueInfo() {
  const { handleOpenModal } = useModal();
  return (
    <span className={styles.guarantorInfo}>
      {i18next.t('ContinueWithGuarantorUniqueKey:firstText')}
      <Button
        info
        underline
        lowercase
        onClick={handleOpenModal(MODALS.GUARANTOR_INFO)}
        className={styles.guarantorButton}>
        {i18next.t('ContinueWithGuarantorUniqueKey:guarantor')}
      </Button>
      {i18next.t('ContinueWithGuarantorUniqueKey:secondText')}
    </span>
  );
}

export default GuarantorContinueInfo;
