import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { INCUMBENT_TYPES, INCUMBENT_TYPES_NAMES } from '~constants/incumbent';
import { addPrefix } from '~utils/string';
import ModalActions from '~redux/Modal/actions';
import { MODALS } from '~redux/Modal/constants';
import SimulationActions from '~redux/Simulation/actions';
import { mapIncumbentValue } from '~redux/Simulation/mappers';
import { META_BOOLEANS_VALUES } from '~constants/metaInfo';
import { META_ACTION_TARGETS, META_VALUES } from '~constants/metaValues';
import { SIMULATION_TARGETS } from '~redux/Simulation/constants';

import { useIsView } from './useIsView';

export const useStatusPageModal = () => {
  const dispatch = useDispatch();
  const { quickCode } = useParams();
  const isGuarantorView = useIsView(INCUMBENT_TYPES.GUARANTOR);
  const simulation = useSelector(state => state.simulation);
  const target = isGuarantorView ? INCUMBENT_TYPES_NAMES.GUARANTOR : INCUMBENT_TYPES_NAMES.CLIENT;
  const knowsStatusPage = simulation[addPrefix(INCUMBENT_TYPES[target], 'KnowsStatusPage')];
  const handleTriggerModal = () => {
    const modalAction = knowsStatusPage ? ModalActions.closeModal : ModalActions.openModal;
    dispatch(modalAction(MODALS.STATUS_PAGE));
  };
  const knowsStatusPageDependencies = [knowsStatusPage];
  const handleKnowsStatusPage = () => {
    dispatch(
      SimulationActions.setSimulationInfo(
        SIMULATION_TARGETS[addPrefix(target, META_ACTION_TARGETS.STATUS_PAGE)],
        true
      )
    );
    dispatch(
      SimulationActions.changeIncumbent({
        quickCode,
        values: mapIncumbentValue(
          addPrefix(INCUMBENT_TYPES[target], META_VALUES.KNOWS_STATUS_PAGE_META),
          META_BOOLEANS_VALUES.TRUE
        )
      })
    );
  };
  return { handleTriggerModal, handleKnowsStatusPage, knowsStatusPage, knowsStatusPageDependencies };
};
