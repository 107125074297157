import { createTypes, completeTypes, withPostSuccess, withPostFailure } from 'redux-recompose';

import { addFilesToUpload, removeFileToUpload, removeFilesToUpload } from '~utils/file';
import ModalActions from '~redux/Modal/actions';
import { FILE_ERRORS, KNOWN_ERRORS_CODES } from '~constants/errors';

import * as FileService from '../../services/FileService';

import { FILE_TARGETS } from './constants';
import { dispatchWhenActionExists, getUploadAction, getUploadedFiles } from './utils';

export const actions = createTypes(
  completeTypes(
    ['UPLOAD_FILES', 'UPLOADED_FILES'],
    [
      'ADD_FILES_TO_UPLOAD',
      'RESET_UPLOAD_FILES_ERROR',
      'RESET_FILES_TO_UPLOAD',
      'REMOVE_FILE_TO_UPLOAD',
      'REMOVE_FILES_TO_UPLOAD',
      'SET_FILE_ERROR',
      'ADD_UPLOADED_FILES',
      'RESET_UPLOADED_FILES'
    ]
  ),
  '@@FILE'
);

export const actionCreators = {
  uploadFiles: ({
    prefix,
    files,
    kind,
    quickCode,
    context = null,
    type,
    targetReceived: documentationTarget,
    targetModal
  }) => ({
    type: actions.UPLOAD_FILES,
    target: FILE_TARGETS.UPLOAD_FILES,
    payload: () => ({ files, kind, quickCode, context, type }),
    service: FileService.uploadFile,
    injections: [
      withPostSuccess(dispatch => {
        const { actionReceived, actionWarning, targetReceived, targetWarning } = getUploadAction(
          prefix,
          documentationTarget
        )[type];
        dispatchWhenActionExists(dispatch, actionReceived, targetReceived, true);
        dispatchWhenActionExists(dispatch, actionWarning, targetWarning, false);
        dispatch(actionCreators.resetFiles(targetModal));
      }),
      withPostFailure((dispatch, response, state) => {
        if (response?.status === KNOWN_ERRORS_CODES.EXPECTATION_FAILED && response?.data?.errors) {
          const { filesToUpload } = state.file;
          const errors = response?.data?.errors.map(error => error.filename);
          dispatch(actionCreators.addUploadedFiles(getUploadedFiles(filesToUpload, errors)));
          dispatch(actionCreators.setFileError(FILE_ERRORS.UNABLE_TO_UPLOAD_FILES));
        } else {
          dispatch(actionCreators.setFileError(FILE_ERRORS.UNKNOWN));
        }
      })
    ]
  }),
  addFilesToUpload: files => (dispatch, getState) => {
    const { filesToUpload } = getState().file;
    dispatch({
      type: actions.ADD_FILES_TO_UPLOAD,
      target: FILE_TARGETS.FILES_TO_UPLOAD,
      payload: addFilesToUpload(files, filesToUpload)
    });
  },
  resetFilesToUpload: () => ({
    type: actions.RESET_FILES_TO_UPLOAD,
    target: FILE_TARGETS.FILES_TO_UPLOAD,
    payload: []
  }),
  resetUploadFiles: () => ({
    type: actions.RESET_UPLOAD_FILES_ERROR,
    target: FILE_TARGETS.UPLOAD_FILES_ERROR,
    payload: null
  }),
  removeFileToUpload: fileName => (dispatch, getState) => {
    const { filesToUpload } = getState().file;
    dispatch({
      type: actions.REMOVE_FILE_TO_UPLOAD,
      target: FILE_TARGETS.FILES_TO_UPLOAD,
      payload: removeFileToUpload(fileName, filesToUpload)
    });
  },
  removeFilesToUpload: files => (dispatch, getState) => {
    const { filesToUpload } = getState().file;
    dispatch({
      type: actions.REMOVE_FILES_TO_UPLOAD,
      target: FILE_TARGETS.FILES_TO_UPLOAD,
      payload: removeFilesToUpload(files, filesToUpload)
    });
  },
  setFileError: fileError => ({
    type: actions.SET_FILE_ERROR,
    target: FILE_TARGETS.FILE_ERROR,
    payload: fileError
  }),
  addUploadedFiles: newUploadedFiles => (dispatch, getState) => {
    const { uploadedFiles } = getState().file;
    dispatch({
      type: actions.ADD_UPLOADED_FILES,
      target: FILE_TARGETS.UPLOADED_FILES,
      payload: [...uploadedFiles, ...newUploadedFiles]
    });
  },
  resetUploadedFiles: () => ({
    type: actions.RESET_UPLOADED_FILES,
    target: FILE_TARGETS.UPLOADED_FILES,
    payload: []
  }),
  resetFiles: targetModal => dispatch => {
    dispatch(ModalActions.closeModal(targetModal));
    dispatch(actionCreators.resetFilesToUpload());
    dispatch(actionCreators.resetUploadFiles());
    dispatch(actionCreators.resetUploadedFiles());
    dispatch(actionCreators.setFileError(null));
  }
};

export default actionCreators;
