import i18next from 'i18next';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import CreditHeader from '~components/CreditHeader';
import SimulationLoadingWrapper from '~components/SimulationLoadingWrapper';
import { ReactComponent as Client } from '~assets/client.svg';
import { ReactComponent as Guarantor } from '~assets/guarantor.svg';
import { INCUMBENT_TYPES } from '~constants/incumbent';
import { useIsView } from '~hooks/useIsView';
import { useStatusPageModal } from '~hooks/useStatusPageModal';

import SectionsCard from './components/SectionsCard';
import { CLIENT_SECTIONS, GUARANTOR_SECTIONS } from './constants';
import styles from './styles.module.scss';
import ShareLinkCard from './components/ShareLinkCard';
import ShareLinkButton from './components/ShareLinkButton';
import {
  clientHasCompletedGuarantor,
  hasCompletedGuarantorFlow,
  hideShareLinkButton,
  isClientAndKnowsShareLink,
  showGuarantorSectionCard
} from './utils';
import StatusPageModal from './components/StatusPageModal';
import DownloadAppButtons from './components/DownloadAppButtons';
import InformationStatusMessage from './components/InformationStatusMessage';

function StatusPage() {
  const isGuarantorView = useIsView(INCUMBENT_TYPES.GUARANTOR);
  const steps = useSelector(state => state.steps);
  const { kind } = useParams();
  const { needsGuarantor, clientKnowsStatusPageShareLink, currentTerm, amount, downpayment } = useSelector(
    state => state.simulation
  );
  const { handleTriggerModal, knowsStatusPageDependencies } = useStatusPageModal();
  useEffect(handleTriggerModal, knowsStatusPageDependencies);
  const completedGuarantorFlow = hasCompletedGuarantorFlow(steps, needsGuarantor);
  const clientAndKnowsShareLink = isClientAndKnowsShareLink(clientKnowsStatusPageShareLink, isGuarantorView);
  const clientAndHasCompletedGuarantor = clientHasCompletedGuarantor(isGuarantorView, completedGuarantorFlow);
  const showGuarantor = showGuarantorSectionCard(
    isGuarantorView,
    clientAndKnowsShareLink,
    clientAndHasCompletedGuarantor
  );
  return (
    <SimulationLoadingWrapper withInitialLoading>
      <div className={styles.container}>
        <div className={styles.infoContainer}>
          <div className={styles.infoHeader}>
            <div className={styles.statusHeader}>
              <span className={styles.statusPageTitle}>{i18next.t('StatusPage:title')}</span>
            </div>
            <CreditHeader
              className={styles.creditHeader}
              kind={kind}
              term={currentTerm?.term}
              monthlyPayment={currentTerm?.monthlyPayment}
              amount={amount}
              downpayment={downpayment}
            />
          </div>
          {!isGuarantorView && (
            <SectionsCard icon={Client} owner={INCUMBENT_TYPES.CLIENT} sections={CLIENT_SECTIONS} />
          )}
          {needsGuarantor &&
            (showGuarantor ? (
              <SectionsCard
                icon={Guarantor}
                owner={INCUMBENT_TYPES.GUARANTOR}
                sections={GUARANTOR_SECTIONS}
              />
            ) : (
              <ShareLinkCard />
            ))}
          {showGuarantor && (
            <InformationStatusMessage
              steps={steps}
              needsGuarantor={needsGuarantor}
              isGuarantorView={isGuarantorView}
            />
          )}
        </div>
        <ShareLinkButton
          className={styles.shareLinkButtonContainer}
          buttonClassName={styles.shareLinkButton}
          hide={hideShareLinkButton({ clientAndKnowsShareLink, completedGuarantorFlow })}
        />
        <DownloadAppButtons clientAndHasCompletedGuarantor={clientAndHasCompletedGuarantor} />
        <StatusPageModal />
      </div>
    </SimulationLoadingWrapper>
  );
}

export default StatusPage;
