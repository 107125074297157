import Immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer, onReadValue } from 'redux-recompose';

import { actions } from './actions';
import { FILE_DEFAULT_STATE, FILE_LOCAL_STATE_TARGETS } from './constants';

const defaultState = completeState(FILE_DEFAULT_STATE, FILE_LOCAL_STATE_TARGETS);

const reducerDescription = {
  primaryActions: [actions.UPLOAD_FILES],
  override: {
    [actions.ADD_FILES_TO_UPLOAD]: onReadValue(),
    [actions.RESET_UPLOAD_FILES_ERROR]: onReadValue(),
    [actions.RESET_FILES_TO_UPLOAD]: onReadValue(),
    [actions.REMOVE_FILE_TO_UPLOAD]: onReadValue(),
    [actions.REMOVE_FILES_TO_UPLOAD]: onReadValue(),
    [actions.SET_FILE_ERROR]: onReadValue(),
    [actions.ADD_UPLOADED_FILES]: onReadValue(),
    [actions.RESET_UPLOADED_FILES]: onReadValue()
  }
};

const reducer = createReducer(new Immutable(defaultState), completeReducer(reducerDescription));

export default reducer;
