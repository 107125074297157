import React from 'react';
import cn from 'classnames';
import { bool, func, string } from 'prop-types';

import styles from './styles.module.scss';

function Checkbox({
  containerClassName,
  children,
  disabled,
  checked,
  textClassName,
  checkboxClassName,
  onChange,
  defaultChecked,
  hasError,
  ...checkboxProps
}) {
  return (
    <div className={cn(styles.container, containerClassName)}>
      <label
        className={cn(styles.checkboxLabel, checkboxClassName, { [styles.checkboxLabelError]: hasError })}>
        <input
          type="checkbox"
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          defaultChecked={defaultChecked}
          className={styles.checkboxInput}
          {...checkboxProps}
        />
        <span className={cn(styles.checkboxCustom, { [styles.checkboxCustomError]: hasError })} />
      </label>
      <span className={cn(styles.checkboxText, textClassName)}>{children}</span>
    </div>
  );
}

Checkbox.propTypes = {
  checkboxClassName: string,
  checked: bool,
  containerClassName: string,
  defaultChecked: bool,
  disabled: bool,
  hasError: bool,
  textClassName: string,
  onChange: func
};

export default Checkbox;
