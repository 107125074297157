import i18next from 'i18next';
import React from 'react';

import ShareLinkButton from '~components/ShareLinkButton';
import FlowChoice from '~components/FlowChoice';
import NavBarWrapper from '~components/NavBarWrapper';
import { INCUMBENT_TYPES } from '~constants/incumbent';

import styles from './styles.module.scss';

function GuarantorFlowChoice() {
  // TODO: Add specific props for guarantor
  const header = () => (
    <>
      <span className={styles.guarantorMainTitle}>{i18next.t('GuarantorFlowChoice:title')}</span>
      <ShareLinkButton disabled />
    </>
  );
  return (
    <NavBarWrapper isGuarantorView disabled>
      <FlowChoice Header={header} incumbent={INCUMBENT_TYPES.GUARANTOR} />
    </NavBarWrapper>
  );
}

export default GuarantorFlowChoice;
