import React from 'react';
import i18next from 'i18next';

import SelectOption from '~app/components/SelectOption';

import { GENDERS_OPTIONS, NATIONALITIES_OPTIONS } from './constants';

export const getGendersOptions = () =>
  GENDERS_OPTIONS.map(({ value }) => ({
    label: <SelectOption message={i18next.t(`BirthInformationFields:${value}`)} />,
    value
  }));

export const getNationalitiesOptions = () =>
  NATIONALITIES_OPTIONS.map(({ value }) => ({
    label: <SelectOption message={i18next.t(`BirthInformationFields:${value}`)} />,
    value
  }));
