import React from 'react';
import { useSelector } from 'react-redux';
import { bool, func, shape, string } from 'prop-types';
import cn from 'classnames';

import CustomModal from '~app/components/CustomModal';
import FormWrapper from '~app/components/FormWrapper';
import { useModal } from '~app/hooks/useModal';

import styles from './styles.module.scss';
import PriceForm from './components/PriceForm';

function PriceModal({ modalName, shouldCloseOnOverlayClick, hideCloseButton, message, ...props }) {
  const { handleCloseModal } = useModal();
  const open = useSelector(state => state.modal[modalName]);
  return (
    <CustomModal
      className={cn(styles.modal, { [styles.modalWithMessage]: message })}
      modal={modalName}
      onClose={handleCloseModal(modalName)}
      isOpen={open}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      hideCloseButton={hideCloseButton}>
      <FormWrapper form={PriceForm} message={message} {...props} />
    </CustomModal>
  );
}

PriceModal.propTypes = {
  buttonText: string,
  fieldName: string,
  hideCloseButton: bool,
  initialValues: shape,
  message: string,
  modalName: string,
  shouldCloseOnOverlayClick: bool,
  title: string,
  validationSchema: shape,
  onSubmit: func
};

export default PriceModal;
