import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';

import FileActions from '~redux/File/actions';
import { addPrefix } from '~utils/string';
import { TARGET_FILES } from '~constants/fileTargets';

export const useFiles = (quickCode, prefix, type, targetReceived = '') => {
  const dispatch = useDispatch();
  const { filesToUpload, uploadedFiles } = useSelector(state => state.file);
  const newFilesToUpload = useMemo(() => {
    const uploadedFilesNames = uploadedFiles.map(file => file.name);
    return filesToUpload.filter(file => !uploadedFilesNames.includes(file.name));
  }, [filesToUpload, uploadedFiles]);
  const uploadFiles = targetModal => () => {
    const targetFile = TARGET_FILES[type];
    dispatch(
      FileActions.uploadFiles({
        prefix,
        files: newFilesToUpload,
        kind: addPrefix(prefix, targetFile.kind),
        quickCode,
        type,
        targetReceived,
        targetModal
      })
    );
  };
  const addFilesToUpload = files => {
    dispatch(FileActions.addFilesToUpload(files));
  };
  const removeFileToUpload = file => () => {
    dispatch(FileActions.removeFileToUpload(file));
  };
  return { uploadFiles, addFilesToUpload, removeFileToUpload };
};
