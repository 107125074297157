import React from 'react';
import i18next from 'i18next';

import Input from '~components/Input';

import styles from './styles.module.scss';

export const FIELDS = {
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone',
  RUT: 'rut',
  EMAIL_DEALER: 'emailDealer'
};

export const INITIAL_VALUES = {
  [FIELDS.NAME]: '',
  [FIELDS.EMAIL]: '',
  [FIELDS.PHONE]: '',
  [FIELDS.RUT]: '',
  [FIELDS.EMAIL_DEALER]: ''
};

export const IDENTITY_REMAINING_KEYS = [FIELDS.EMAIL, FIELDS.PHONE, FIELDS.RUT];

export const IDENTITY_KEYS_WITH_DEALER = [...IDENTITY_REMAINING_KEYS, FIELDS.EMAIL_DEALER];

export const ADDITIONAL_FIELDS = [
  {
    component: () => (
      <span className={styles.sectionTitle}>{i18next.t('ClientIdentity:aboutYouDealer')}</span>
    ),
    name: 'aboutYouDealer'
  },
  {
    component: Input,
    name: 'emailDealer',
    label: i18next.t('ClientIdentity:emailDealer')
  }
];
