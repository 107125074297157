import i18next from 'i18next';
import React from 'react';

import Button from '~app/components/Button';
import { ReactComponent as Picture } from '~assets/maintenance.svg';
import { HOME_PAGE } from '~constants/urls';

import styles from './styles.module.scss';

function Maintenance() {
  const goHome = () => window.location.replace(HOME_PAGE);
  return (
    <div className={styles.container}>
      <div className={styles.background}>
        <div className={styles.maintenanceInfoContainer}>
          <span className={styles.maintenanceTitle}>{i18next.t('Maintenance:title')}</span>
          <span className={styles.maintenanceSubtitle}>{i18next.t('Maintenance:subtitle')}</span>
        </div>
        <Picture className={styles.picture} />
      </div>
      <Button onClick={goHome} primary className={styles.maintenanceButton}>
        {i18next.t('Maintenance:button')}
      </Button>
    </div>
  );
}

export default Maintenance;
