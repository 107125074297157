import i18next from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';

import TransitionCard from '~components/TransitionCard';
import { ReactComponent as Character } from '~assets/character.svg';
import SimulationLoadingWrapper from '~components/SimulationLoadingWrapper';
import { useIsView } from '~hooks/useIsView';
import { INCUMBENT_TYPES } from '~constants/incumbent';
import { useTriggerEmail } from '~hooks/useTriggerEmail';
import { NEEDED_LABOR_DOCS } from '~constants/documentation';

import { CONFIRMATION_GUARANTOR_INFO, CONTINUE_GUARANTOR_INFO } from './constants';
import styles from './styles.module.scss';
import ContinueWithGuarantorModals from './components/ContinueWithGuarantorModals';
import ContinueWithGuarantorButtons from './components/ContinueWithGuarantorButtons';
import ContinueHeader from './components/ContinueHeader';
import ContinueWithGuarantorInfo from './components/ContinueWithGuarantorInfo';
import GuarantorInfoText from './components/GuarantorInfoText';

function ContinueWithGuarantor() {
  const isGuarantorView = useIsView(INCUMBENT_TYPES.GUARANTOR);
  const clientFinancialInformation = useSelector(state => state.steps.clientFinancialInformation);
  useTriggerEmail();
  const { title, subtitle, neededInfo, underlinedButtonText, primaryText, secondaryText, hideSecondary } =
    isGuarantorView ? CONTINUE_GUARANTOR_INFO : CONFIRMATION_GUARANTOR_INFO[clientFinancialInformation];
  const guarantorInfoText = () => (
    <GuarantorInfoText firstInfo={i18next.t('ContinueWithGuarantor:guarantorData')} secondInfo=":" />
  );
  return (
    <SimulationLoadingWrapper>
      <div className={styles.container}>
        <ContinueHeader containerClassName={styles.innerContainer} title={title} subtitle={subtitle} />
        <ContinueWithGuarantorInfo
          containerClassName={styles.innerContainer}
          moreInfoClassName={styles.moreInfoButton}
          neededInfo={neededInfo}
        />
        <div className={styles.innerContainer}>
          <TransitionCard
            icon={Character}
            source="ContinueWithGuarantor"
            TitleComponent={guarantorInfoText}
            items={NEEDED_LABOR_DOCS}
            className={styles.confirmationCard}
            iconClassName={styles.iconConfirmation}
          />
        </div>
        <ContinueWithGuarantorButtons
          containerClassName={styles.innerContainer}
          hideSecondary={hideSecondary}
          underlinedButtonText={underlinedButtonText}
          primaryText={primaryText}
          secondaryText={secondaryText}
          moreInfoClassName={styles.moreInfoButton}
        />
        <ContinueWithGuarantorModals />
      </div>
    </SimulationLoadingWrapper>
  );
}

export default ContinueWithGuarantor;
