import React from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';

import { isConsumerSimulation } from '~models/simulations';

import CreditValue from '../../../CreditValue';
import InstallmentsSelect from '../../../InstallmentsSelect';

import styles from './styles.module.scss';

function SimulationValueInfo({ kind, ...props }) {
  const { loanDraft, productType, amount } = useSelector(state => state.simulation);
  const isConsumer = isConsumerSimulation(kind);
  return (
    <>
      <CreditValue
        kind={kind}
        isConsumer={isConsumer}
        currentValue={amount}
        loanDraft={loanDraft}
        productType={productType}
      />
      <div className={styles.installments}>
        <InstallmentsSelect {...props} isConsumer={isConsumer} currentValue={amount} />
      </div>
    </>
  );
}

SimulationValueInfo.propTypes = { kind: string };

export default SimulationValueInfo;
