import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import i18next from 'i18next';
import cn from 'classnames';

import Button from '~app/components/Button';
import PriceInput from '~app/components/PriceInput';

import styles from './styles.module.scss';

function PriceModalForm({
  errors,
  touched,
  values,
  setFieldValue,
  setFieldTouched,
  fieldName,
  title,
  buttonText = i18next.t('CreditSimulation:confirm'),
  customDisabled,
  message,
  showMessage = () => true
}) {
  const show = showMessage(fieldName, values);
  return (
    <div className={styles.container}>
      <span className={styles.sectionTitle}>{title}</span>
      <div
        className={cn(styles.input, {
          [styles.inputWithHidedMessage]: message && !showMessage(fieldName, values)
        })}>
        <PriceInput
          name={fieldName}
          value={values[fieldName]}
          error={errors[fieldName]}
          touched={touched[fieldName]}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
      </div>
      {message && show && <span className={styles.message}>{message}</span>}
      <Button
        type="submit"
        primary
        className={styles.button}
        disabled={customDisabled ? customDisabled(fieldName, values, touched) : !values[fieldName]}>
        {buttonText}
      </Button>
    </div>
  );
}

PriceModalForm.propTypes = {
  buttonText: string,
  customDisabled: func,
  errors: shape,
  fieldName: string,
  message: string,
  setFieldTouched: func,
  setFieldValue: func,
  showMessage: bool,
  title: string,
  touched: shape,
  values: shape
};

export default PriceModalForm;
