export const MODALS = {
  ADVANCE_PAYMENT: 'advancePayment',
  AMOUNT: 'amount',
  STATUS_PAGE: 'statusPage',
  SCRAPER: 'scraper',
  REMEMBER_ERROR: 'rememberError',
  NO_GUARANTOR_ERROR: 'noGuarantorError',
  ESSENTIAL_ERROR: 'essentialError',
  FILE_UPLOADER: 'fileUploader',
  FILE_UPLOADER_ID_FRONT: 'fileUploaderIDFront',
  FILE_UPLOADER_ID_BACK: 'fileUploaderIDBack',
  FILE_UPLOADER_IMMIGRATION: 'fileUploaderImmigration',
  FILE_UPLOADER_ADDRESS: 'fileUploaderAddress',
  FILE_UPLOADER_TITLE: 'fileUploaderTitle',
  FILE_UPLOADER_EARNINGS: 'fileUploaderPersonalEarnings',
  FILE_UPLOADER_CMF: 'fileUploaderCmf',
  GUARANTOR_INFO: 'guarantorInfo',
  EMAIL: 'email',
  SHARE_LINK: 'shareLink',
  MORE_INFO: 'moreInfo',
  PAST_SIMULATION: 'pastSimulation',
  DIFFERENCES: 'differences',
  CERTIFICATE_AFP: 'certificateAFP',
  UNIQUE_KEY_SII: 'uniqueKeySII',
  UNIQUE_KEY_CMF: 'uniqueKeyCMF',
  SUCCESS: 'success',
  STEPS_INFO: 'stepsInfo'
};
