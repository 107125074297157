export const FILE_TARGETS = {
  UPLOAD_FILES: 'uploadFiles',
  UPLOAD_FILES_ERROR: 'uploadFilesError',
  FILES_TO_UPLOAD: 'filesToUpload',
  FILE_ERROR: 'fileError',
  UPLOADED_FILES: 'uploadedFiles'
};

export const FILE_DEFAULT_STATE = {
  [FILE_TARGETS.UPLOAD_FILES]: null,
  [FILE_TARGETS.FILES_TO_UPLOAD]: [],
  [FILE_TARGETS.FILE_ERROR]: null,
  [FILE_TARGETS.UPLOADED_FILES]: []
};

export const FILE_LOCAL_STATE_TARGETS = [
  FILE_TARGETS.FILES_TO_UPLOAD,
  FILE_TARGETS.FILE_ERROR,
  FILE_TARGETS.UPLOADED_FILES
];
