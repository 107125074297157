import React from 'react';
import { useSelector } from 'react-redux';

import FormWrapper from '~app/components/FormWrapper';
import IncomesForm from '~app/components/IncomesForm';
import { ROUTES } from '~constants/routes';
import { CLIENT_ROUTES, ROUTES_NAMES, STEPS_TARGETS } from '~constants/steps';
import { getSteps } from '~utils/steps';
import StepWrapper from '~app/components/StepWrapper';
import SimulationLoadingWrapper from '~app/components/SimulationLoadingWrapper';
import { INCUMBENT_TYPES } from '~constants/incumbent';
import { VALIDATION_SCHEMA_INCOMES } from '~constants/validations';

import { INITIAL_VALUES } from './constants';
import styles from './styles.module.scss';

function ClientIncomes() {
  const { clientFinancialInfo, clientKnowsStatusPage, clientFlowChoice } = useSelector(
    state => state.simulation
  );
  return (
    <SimulationLoadingWrapper>
      <FormWrapper
        form={IncomesForm}
        initialValues={clientFinancialInfo || INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA_INCOMES}
        prefix={INCUMBENT_TYPES.CLIENT}
        nextStepName={ROUTES.CLIENT_PERSONAL_DOCUMENTATION.stepName}
        previousStepName={ROUTES.CREDIT_SIMULATION.stepName}
        previousStepTarget={STEPS_TARGETS.CLIENT_QUOTES_INFORMATION}
        currentStepTarget={STEPS_TARGETS.CLIENT_FINANCIAL_INFORMATION}
        nextStepTarget={STEPS_TARGETS.CLIENT_PERSONAL_DOCUMENTATION}
        className={styles.incomesForm}
        wrapper={StepWrapper}
        steps={getSteps(CLIENT_ROUTES)}
        selectedStep={ROUTES_NAMES.CLIENT.INCOMES}
        knowsStatusPage={clientKnowsStatusPage}
        customPreviousNavBarStepName={ROUTES.CLIENT_FLOW_CHOICE.stepName}
        flowChoice={clientFlowChoice}
        currentStepName={ROUTES.CLIENT_INCOMES.stepName}
        showFlowChanger
      />
    </SimulationLoadingWrapper>
  );
}

export default ClientIncomes;
