import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

import PersonalDocumentationForm from '~components/PersonalDocumentationForm';
import FormWrapper from '~components/FormWrapper';
import { INCUMBENT_TYPES } from '~constants/incumbent';
import { ROUTES } from '~constants/routes';
import { GUARANTOR_ROUTES, ROUTES_NAMES, STEPS_TARGETS } from '~constants/steps';
import { getSteps } from '~utils/steps';
import DocumentationActions from '~redux/Documentation/actions';
import { useIsView } from '~hooks/useIsView';
import { GUARANTOR_PERSONAL_DOCS_FORM, PERSONAL_DOCUMENTATION_FORM } from '~constants/personalDocumentation';
import SimulationLoadingWrapper from '~components/SimulationLoadingWrapper';
import GuarantorHeader from '~components/GuarantorHeader';
import StepWrapper from '~app/components/StepWrapper';

import styles from './styles.module.scss';
import { INCUMBENT_TARGETS, INITIAL_VALUES } from './constants';
import { VALIDATION_SCHEMA } from './utils';

function GuarantorPersonalDocumentation() {
  const dispatch = useDispatch();
  const guarantorPersonalDocumentation = useSelector(
    state => state.documentation.guarantorPersonalDocumentation
  );
  const { guarantorKnowsStatusPage, guarantorFinancialInfo } = useSelector(state => state.simulation);
  const isGuarantorView = useIsView(INCUMBENT_TYPES.GUARANTOR);
  const setPersonalDocumentation = values => dispatch(DocumentationActions.setGuarantorPersonalDocs(values));
  return (
    <SimulationLoadingWrapper withInitialLoading>
      <StepWrapper
        steps={getSteps(GUARANTOR_ROUTES)}
        selectedStep={ROUTES_NAMES.GUARANTOR.DOCUMENTATION}
        previousStepName={ROUTES.GUARANTOR_INCOMES.stepName}>
        <GuarantorHeader
          className={styles.shareLinkContainer}
          title={i18next.t('GuarantorPersonalDocumentation:title')}
          specialText={i18next.t('GuarantorPersonalDocumentation:specialText')}
        />
        <FormWrapper
          form={PersonalDocumentationForm}
          incumbent={INCUMBENT_TYPES.GUARANTOR}
          initialValues={guarantorPersonalDocumentation || INITIAL_VALUES}
          validationSchema={VALIDATION_SCHEMA}
          incumbentTargets={INCUMBENT_TARGETS}
          setPersonalDocumentation={setPersonalDocumentation}
          stepTarget={STEPS_TARGETS.GUARANTOR_PERSONAL_DOCS}
          nextStepName={ROUTES.GUARANTOR_CONFIRMATION.stepName}
          previousStepName={ROUTES.GUARANTOR_INCOMES.stepName}
          textTarget={isGuarantorView ? PERSONAL_DOCUMENTATION_FORM : GUARANTOR_PERSONAL_DOCS_FORM}
          knowsStatusPage={guarantorKnowsStatusPage}
          employmentSituation={guarantorFinancialInfo?.employmentSituation}
        />
      </StepWrapper>
    </SimulationLoadingWrapper>
  );
}

export default GuarantorPersonalDocumentation;
