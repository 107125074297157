import i18next from 'i18next';
import { bool, func, node, string } from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';
import cn from 'classnames';

import PrimaryAndSecondaryButtons from '~app/components/PrimaryAndSecondaryButtons';
import { ReactComponent as ErrorGuy } from '~assets/error-guy.svg';
import { HOME_PAGE } from '~constants/urls';

import styles from './styles.module.scss';

function ErrorScreen({
  title = i18next.t('Error:mainTitle'),
  subtitle = i18next.t('Error:mainSubtitle'),
  secondaryButtonText = i18next.t('Error:goHome'),
  picture: Picture = ErrorGuy,
  backgroundClassName,
  hideSecondary,
  primaryButtonText = hideSecondary ? i18next.t('Error:goHome') : i18next.t('Error:reload'),
  handlePrimaryAction,
  ...props
}) {
  const history = useHistory();
  const goBack = () => history.goBack();
  const goHome = () => window.location.replace(HOME_PAGE);
  return (
    <div className={styles.container}>
      <div className={cn(styles.background, backgroundClassName)}>
        <div className={styles.errorPictureContainer}>
          <Picture className={styles.errorPicture} />
        </div>
      </div>
      <div className={styles.errorContainer}>
        <span className={styles.errorTitle}>{title}</span>
        <span className={styles.errorSubtitle}>{subtitle}</span>
      </div>
      <PrimaryAndSecondaryButtons
        primaryText={primaryButtonText}
        secondaryText={secondaryButtonText}
        handlePrimaryAction={handlePrimaryAction || (hideSecondary ? goHome : goBack)}
        handleSecondaryAction={goHome}
        hideSecondary={hideSecondary}
        className={styles.errorButtons}
        secondaryUnderlineButton
        secondaryInfoButton
        {...props}
      />
    </div>
  );
}

ErrorScreen.propTypes = {
  backgroundClassName: string,
  handlePrimaryAction: func,
  hideSecondary: bool,
  picture: node,
  primaryButtonText: string,
  secondaryButtonText: string,
  subtitle: string,
  title: string
};

export default ErrorScreen;
