import i18next from 'i18next';
import React from 'react';

import { ReactComponent as ContinueGirl } from '~assets/continue-girl.svg';
import GuarantorInfoModal from '~components/GuarantorInfoModal';
import NavBarWrapper from '~components/NavBarWrapper';
import ShareLinkModal from '~components/ShareLinkModal';

import styles from './styles.module.scss';
import ContinueButtons from './components/ContinueButtons';
import GuarantorContinueInfo from './components/GuarantorContinueInfo';

function ContinueWithGuarantorUniqueKey() {
  return (
    <NavBarWrapper disabled>
      <div className={styles.container}>
        <span className={styles.continueTitle}>{i18next.t('ContinueWithGuarantorUniqueKey:title')}</span>
        <ContinueGirl />
        <GuarantorContinueInfo />
        <ContinueButtons />
        <ShareLinkModal disabled />
        <GuarantorInfoModal />
      </div>
    </NavBarWrapper>
  );
}

export default ContinueWithGuarantorUniqueKey;
