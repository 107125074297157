export const META_VALUES = {
  PERSONAL_INFORMATION: '_personal_information',
  FINANCIAL_INFORMATION: '_financial_information',
  PERSONAL_DOCUMENTATION: '_personal_documentation',
  CONFIRMATION: '_confirmation',
  LABOR_SITUATION: '_labor_situation_meta',
  MONTHLY_INCOMES: '_monthly_incomes_meta',
  CLIENT_KNOWS_SHARE_LINK_META: 'client_knows_status_page_share_link_meta',
  KNOWS_STATUS_PAGE_META: '_knows_status_page_meta',
  CONFIRMED_EMAIL_META: '_confirmed_email_meta',
  CLIENT_CAN_EDIT_AMOUNT_META: 'client_can_edit_amount_meta',
  SIMULATION_SUBMITTED_META: 'simulation_submitted_meta',
  SIMULATION_EMAIL_DEALER_META: 'simulation_email_dealer_meta',
  UC_SUBMITTED_META: '_unique_credential_submitted_meta',
  SIMULATION_KIND_META: 'simulation_kind_meta',
  FLOW_META: '_flow_meta',
  PAYMENT_TYPE_META: 'simulation_payment_type_meta'
};

export const META_VALUES_TARGETS = {
  CLIENT_KNOWS_SHARE_LINK_META: 'clientKnowsStatusPageShareLinkMeta',
  KNOWS_STATUS_PAGE_META: 'KnowsStatusPageMeta',
  CONFIRMED_EMAIL_META: 'ConfirmedEmailMeta',
  CLIENT_CAN_EDIT_AMOUNT_META: 'clientCanEditAmountMeta',
  UC_SUBMITTED_META: 'UniqueCredentialSubmittedMeta',
  FLOW_META: 'FlowMeta'
};

export const META_ACTION_TARGETS = {
  STATUS_PAGE: '_KNOWS_STATUS_PAGE',
  CONFIRMED_EMAIL: '_CONFIRMED_EMAIL'
};
