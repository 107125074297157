import { func, string } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { useRut } from '~app/hooks/useRut';
import { useUniqueKeyScraper } from '~app/hooks/useUniqueKeyScraper';
import ScrapersActions from '~redux/Scrapers/actions';

import FormWrapper from '../FormWrapper';

import UniqueKeyForm from './components/UniqueKeyForm';
import { INITIAL_VALUES } from './constants';
import { VALIDATION_SCHEMA } from './utils';

function UniqueKeyScraper({
  incumbent,
  customPostSuccessAction,
  scraperAction = ScrapersActions.scrapeCmfData,
  type = 'CMF',
  ...props
}) {
  const dispatch = useDispatch();
  const { quickCode } = useParams();
  const currentRut = useRut(incumbent);
  const { postSuccessAction, postErrorAction } = useUniqueKeyScraper({
    incumbent,
    customPostSuccessAction,
    ...props
  });
  const handleSubmit = ({ rut: run, uniqueKey, afpType }) => {
    dispatch(
      scraperAction(
        { run, password: uniqueKey, quickCode: `${quickCode}-${incumbent}`, afpType },
        postSuccessAction,
        postErrorAction
      )
    );
  };
  return (
    <FormWrapper
      form={UniqueKeyForm}
      onSubmit={handleSubmit}
      initialValues={{ ...INITIAL_VALUES, rut: currentRut }}
      rutDisabled={!!currentRut}
      validationSchema={VALIDATION_SCHEMA}
      incumbent={incumbent}
      type={type}
      autoComplete="off"
      {...props}
    />
  );
}

UniqueKeyScraper.propTypes = {
  customPostSuccessAction: func,
  incumbent: string,
  modalType: string,
  scraperAction: func,
  type: string
};

export default UniqueKeyScraper;
