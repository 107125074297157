import React from 'react';
import cn from 'classnames';
import { shape, string } from 'prop-types';

import { CLIENT_ROUTES, ROUTES_NAMES } from '~constants/steps';
import { getSteps } from '~utils/steps';
import { BANNER_COLORS, LOGO } from '~screens/CreditSimulation/constants';
import { ReactComponent as LogoYellow } from '~assets/logo-yellow.svg';
import Stepper from '~components/Stepper';

import styles from './styles.module.scss';

function SimulationHeader({ kind, stepperActions }) {
  const Logo = kind ? LOGO[kind] : LogoYellow;
  return (
    <>
      <div className={cn(styles.logoContainer, BANNER_COLORS[kind])}>
        <Logo className={styles.logo} />
      </div>
      <Stepper
        steps={getSteps(CLIENT_ROUTES)}
        selectedStep={ROUTES_NAMES.CLIENT.SIMULATOR}
        stepperActions={stepperActions}
        customBarStyle={styles.stepperBar}
      />
    </>
  );
}

SimulationHeader.propTypes = {
  kind: string,
  stepperActions: shape({})
};

export default SimulationHeader;
