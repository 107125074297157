import React from 'react';
import { node, string, int, bool, shape } from 'prop-types';
import { useSelector } from 'react-redux';

import { stepsProptype } from '~propTypes/steps';
import { isAllowed } from '~utils/steps';
import { useRedirect } from '~app/hooks/useRedirect';

import styles from './styles.module.scss';
import { getIconStyles, getLabel, isSelected } from './utils';

const Step = ({
  icon: Icon,
  title,
  step,
  selectedStep,
  number,
  stepName,
  previousSteps,
  stepperActions,
  customDisabled
}) => {
  const selected = isSelected(number, selectedStep);
  const steps = useSelector(state => state.steps);
  const action = stepperActions && stepperActions[step];
  const handleRedirectAction = useRedirect();
  const { iconStyle, iconContainerStyle, labelStyle } = getIconStyles(steps[step], selected);
  const handleRedirect = () => {
    if (action) {
      action();
    } else {
      handleRedirectAction({ customStepName: stepName })();
    }
  };
  const label = getLabel(number, title);
  return (
    <div className={`column center ${styles.container}`}>
      <button
        type="button"
        onClick={handleRedirect}
        className={`row center middle m-bottom-1 ${styles.iconContainer} ${iconContainerStyle}`}
        disabled={customDisabled || !isAllowed(steps, previousSteps)}>
        <Icon className={`${styles.icon} ${iconStyle}`} />
      </button>
      <div className={`${styles.stepText} ${labelStyle}`}>{label}</div>
    </div>
  );
};

Step.propTypes = {
  customDisabled: bool,
  icon: node,
  number: int,
  previousSteps: stepsProptype,
  selectedStep: int,
  step: string,
  stepName: string,
  stepperActions: shape,
  title: string
};

export default Step;
