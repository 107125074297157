import { META_BOOLEANS_VALUES } from '~constants/metaInfo';
import { META_VALUES } from '~constants/metaValues';
import { PAYMENT_TYPES } from '~constants/paymentTypes';
import {
  SIMULATION_CLIENT_META,
  SIMULATION_DEAL_FIELD,
  SIMULATION_DEAL_FIELDS,
  SIMULATION_IMAGE_LINKS,
  SIMULATION_IMAGE_LINK_META,
  SIMULATION_TYPES
} from '~constants/simulations';
import {
  NEXT_STEP_STATES,
  POSSIBLE_STEP_STATES,
  SIMULATION_NEXT_STEP,
  STEPS_TARGETS,
  STEP_SUBMITTED_META
} from '~constants/steps';
import { getParam } from '~utils/query';

export const mapIncumbentValue = (kind, value) => [{ kind, value }];

export const simulationMapper = attributes => ({
  data: {
    attributes
  }
});

export const changeIncumbentMapper = (quickCode, multiple) => ({
  quickCode,
  values: simulationMapper({ multiple })
});

export const mapStepMetaInfo = stepTarget => [
  ...mapIncumbentValue(SIMULATION_NEXT_STEP, NEXT_STEP_STATES[POSSIBLE_STEP_STATES[stepTarget]]),
  ...mapIncumbentValue(STEP_SUBMITTED_META, POSSIBLE_STEP_STATES[stepTarget])
];

export const mapDealField = (field, value, custom = true) =>
  mapIncumbentValue(SIMULATION_DEAL_FIELD, JSON.stringify({ field, value, custom }));

export const postCreateMapper = ({
  kind,
  email,
  phone,
  rut,
  dv,
  name,
  canEditAmount = false,
  simulationEmailDealerMeta = '',
  brand,
  model
}) => [
  ...mapIncumbentValue(SIMULATION_IMAGE_LINK_META, SIMULATION_IMAGE_LINKS[SIMULATION_TYPES[kind]]),
  ...mapIncumbentValue(SIMULATION_CLIENT_META, JSON.stringify({ email, phone, rut, dv, name })),
  ...mapDealField(SIMULATION_DEAL_FIELDS.BRAND, brand),
  ...mapDealField(SIMULATION_DEAL_FIELDS.MODEL, model),
  ...mapStepMetaInfo(STEPS_TARGETS.CLIENT_PERSONAL_INFORMATION),
  ...mapIncumbentValue(META_VALUES.CLIENT_CAN_EDIT_AMOUNT_META, canEditAmount.toString()),
  ...(simulationEmailDealerMeta
    ? mapIncumbentValue(META_VALUES.SIMULATION_EMAIL_DEALER_META, simulationEmailDealerMeta)
    : [])
];

export const postUpdateMapper = (stepTarget, paymentType = PAYMENT_TYPES.QUOTES) => [
  ...mapIncumbentValue(META_VALUES.SIMULATION_SUBMITTED_META, META_BOOLEANS_VALUES.TRUE),
  ...mapStepMetaInfo(stepTarget),
  ...mapIncumbentValue(META_VALUES.PAYMENT_TYPE_META, paymentType)
];

const getParamAsObject = (query, param) => ({
  [param]: getParam(query, param)
});

const utmValues = () => {
  const query = new URLSearchParams(window.location.search);
  return {
    ...getParamAsObject(query, 'utm_source'),
    ...getParamAsObject(query, 'utm_medium'),
    ...getParamAsObject(query, 'utm_campaign'),
    ...getParamAsObject(query, 'utm_id'),
    ...getParamAsObject(query, 'utm_term'),
    ...getParamAsObject(query, 'utm_content')
  };
};

export const createSimulationMapper = ({
  email,
  phone,
  rut,
  dv,
  name,
  kind = 'persona',
  amount,
  term = 18,
  productType = 'credito-consumo',
  downpayment = null,
  itemName = null,
  referralCode = null,
  src = null,
  owner = null,
  productCondition = null,
  anonymousId = null
}) =>
  simulationMapper({
    email,
    phone,
    rut,
    dv,
    name,
    kind,
    amount,
    term,
    productType,
    downpayment,
    itemName,
    referralCode,
    src,
    owner,
    productCondition,
    anonymousId,
    ...utmValues()
  });
