import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { int, bool, string, func, objectOf } from 'prop-types';
import i18next from 'i18next';

import LoadingWrapper from '~app/components/LoadingWrapper';
import { mapSimulationValues } from '~app/screens/CreditSimulation/mappers';
import { isWithoutDownpayment } from '~utils/simulation';
import SimulationActions from '~redux/Simulation/actions';
import Select from '~app/components/Select';

import AdvancePaymentButton from './components/AdvancePaymentButton';
import InstallmentsInfo from './components/InstallmentsInfo';
import styles from './styles.module.scss';
import { getHaveDownpaymentOptions, getWhenBuyOptions } from './utils';

function FinancedPayment({
  currentValue,
  isConsumer,
  hasDownpayment,
  setHasDownpayment,
  whenBuy,
  setWhenBuy
}) {
  const dispatch = useDispatch();
  const { downpayment, terms, termsLoading, productType, kind } = useSelector(state => state.simulation);
  const handleSelectHaveDownpayment = value => setHasDownpayment({ ...hasDownpayment, value, error: null });
  const handleSelectWhenBuy = value => setWhenBuy({ ...whenBuy, value, error: null });
  useEffect(() => {
    if (kind && productType && currentValue) {
      const values = mapSimulationValues({
        currentValue,
        productType,
        kind,
        isConsumer,
        advancePayment: downpayment
      });
      dispatch(SimulationActions.getTerms(values));
    }
  }, [kind, downpayment]);

  return (
    <>
      {!isWithoutDownpayment(kind) && (
        <AdvancePaymentButton advancePayment={downpayment} isConsumer={isConsumer} />
      )}
      <div className={styles.loadingContainer}>
        <LoadingWrapper withInitialLoading loading={termsLoading}>
          {terms && <InstallmentsInfo />}
          <div className="m-top-4">
            <div className={styles.sectionLabel}>{i18next.t('CreditSimulation:hasDownpaymentLabel')}</div>
            <Select
              className="m-bottom-3"
              name="have_downpayment"
              options={getHaveDownpaymentOptions()}
              placeholder={i18next.t('Common:selectPlaceholder')}
              value={hasDownpayment.value}
              error={hasDownpayment.error}
              onChange={handleSelectHaveDownpayment}
            />
            <div className={styles.sectionLabel}>{i18next.t('CreditSimulation:whenBuyLabel')}</div>
            <Select
              name="when_buy"
              options={getWhenBuyOptions()}
              placeholder={i18next.t('Common:selectPlaceholder')}
              value={whenBuy.value}
              error={whenBuy.error}
              onChange={handleSelectWhenBuy}
            />
          </div>
        </LoadingWrapper>
      </div>
    </>
  );
}

FinancedPayment.propTypes = {
  currentValue: int,
  hasDownpayment: objectOf(string),
  isConsumer: bool,
  setHasDownpayment: func,
  setWhenBuy: func,
  whenBuy: objectOf(string)
};

export default FinancedPayment;
