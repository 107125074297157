import i18next from 'i18next';
import React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import FlowChoice from '~components/FlowChoice';
import { SIMULATION_TYPES } from '~components/CreditHeader/constants';
import { isConsumerSimulation } from '~models/simulations';
import { useFlowChoice } from '~app/hooks/useFlowChoice';
import { INCUMBENT_TYPES } from '~constants/incumbent';
import { ROUTES } from '~constants/routes';
import SimulationNewFlowWrapper from '~app/components/SimulationNewFlowWrapper';

import styles from './styles.module.scss';
import { PERSONAL_CREDIT } from './constants';
import { getStepNameByFlowChoice } from './utils';

function ClientFlowChoice() {
  const { changeIncumbentLoading, clientFlowChoice, clientFinancialInfo } = useSelector(
    state => state.simulation
  );
  const { handleUniqueKeyChoice, handleManualChoice } = useFlowChoice({
    incumbent: INCUMBENT_TYPES.CLIENT,
    currentFlowChoice: clientFlowChoice,
    getStepNameByFlowChoice: getStepNameByFlowChoice(clientFinancialInfo)
  });
  const { kind } = useParams();
  const header = () => (
    <span className={styles.mainTitle}>
      {i18next.t('ClientFlowChoice:title', {
        product: isConsumerSimulation(kind) ? PERSONAL_CREDIT : SIMULATION_TYPES[kind].name
      })}
    </span>
  );
  return (
    <SimulationNewFlowWrapper
      previousStepName={ROUTES.CREDIT_SIMULATION.stepName}
      show={!changeIncumbentLoading}>
      <FlowChoice
        Header={header}
        handleUniqueKeyChoice={handleUniqueKeyChoice}
        handleManualChoice={handleManualChoice}
        loading={changeIncumbentLoading}
        incumbent={INCUMBENT_TYPES.CLIENT}
      />
    </SimulationNewFlowWrapper>
  );
}

export default ClientFlowChoice;
