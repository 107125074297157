import { string } from 'prop-types';
import React from 'react';

import styles from './styles.module.scss';

function Banner({ src, className, bannerClassName }) {
  return (
    <div className={`row full-width ${styles.container} ${className}`}>
      <img src={src} href="banner" className={`${styles.banner} ${bannerClassName}`} />
    </div>
  );
}

Banner.propTypes = {
  bannerClassName: string,
  className: string,
  src: string
};

export default Banner;
