import { CLIENT_DOCUMENTATION_TARGETS, DOCUMENTATION_TARGETS } from '~constants/documentation';

export const FIELDS = {
  IDENTITY_CARD: 'identityCard'
};

export const INITIAL_VALUES = {
  [FIELDS.IDENTITY_CARD]: ''
};

export const CLIENT_ID_TARGETS = {
  idFrontReceivedTarget: DOCUMENTATION_TARGETS.CLIENT_ID_FRONT_RECEIVED,
  idFrontReceivedStateTarget: CLIENT_DOCUMENTATION_TARGETS.CLIENT_ID_FRONT_RECEIVED,
  idBackReceivedTarget: DOCUMENTATION_TARGETS.CLIENT_ID_BACK_RECEIVED,
  idBackReceivedStateTarget: CLIENT_DOCUMENTATION_TARGETS.CLIENT_ID_BACK_RECEIVED,
  immigrationReceivedTarget: DOCUMENTATION_TARGETS.CLIENT_IMMIGRATION_RECEIVED,
  immigrationReceivedStateTarget: CLIENT_DOCUMENTATION_TARGETS.CLIENT_IMMIGRATION_RECEIVED,
  idWarningTarget: DOCUMENTATION_TARGETS.CLIENT_ID_WARNING,
  idWarningStateTarget: CLIENT_DOCUMENTATION_TARGETS.CLIENT_ID_WARNING,
  immigrationWarningTarget: DOCUMENTATION_TARGETS.CLIENT_IMMIGRATION_WARNING,
  immigrationWarningStateTarget: CLIENT_DOCUMENTATION_TARGETS.CLIENT_IMMIGRATION_WARNING
};

export const CLIENT_EARNINGS_TARGETS = {
  earningsReceivedTarget: DOCUMENTATION_TARGETS.CLIENT_EARNINGS_RECEIVED,
  earningsReceivedStateTarget: CLIENT_DOCUMENTATION_TARGETS.CLIENT_EARNINGS_RECEIVED,
  earningsWarningTarget: DOCUMENTATION_TARGETS.CLIENT_EARNINGS_WARNING,
  earningsWarningStateTarget: CLIENT_DOCUMENTATION_TARGETS.CLIENT_EARNINGS_WARNING
};

export const INCUMBENT_TARGETS = {
  id: CLIENT_ID_TARGETS,
  earnings: CLIENT_EARNINGS_TARGETS
};
