export const EMPLOYMENT_LABORAL_SITUATION = {
  DEPENDENT: 'DEPENDENT',
  INDEPENDENT: 'INDEPENDENT',
  DEPENDENT_AND_INDEPENDENT: 'DEPENDENT_AND_INDEPENDENT',
  UNEMPLOYED: 'UNEMPLOYED',
  DELIVERY_WORKER_APP: 'DELIVERY_WORKER_APP'
};

const GENERAL_EMPLOYMENT_STATUS = {
  [EMPLOYMENT_LABORAL_SITUATION.DEPENDENT]: 'dependent',
  [EMPLOYMENT_LABORAL_SITUATION.INDEPENDENT]: 'independent',
  [EMPLOYMENT_LABORAL_SITUATION.UNEMPLOYED]: 'unemployed'
};

export const EMPLOYMENT_STATUS = {
  ...GENERAL_EMPLOYMENT_STATUS,
  [EMPLOYMENT_LABORAL_SITUATION.DEPENDENT_AND_INDEPENDENT]: 'dependentAndIndependent',
  [EMPLOYMENT_LABORAL_SITUATION.DELIVERY_WORKER_APP]: 'deliveryWorkerApp'
};

export const EMPLOYMENT_ACTUAL_STATUS = {
  ...GENERAL_EMPLOYMENT_STATUS,
  [EMPLOYMENT_LABORAL_SITUATION.DEPENDENT_AND_INDEPENDENT]: 'dependent_and_independent',
  [EMPLOYMENT_LABORAL_SITUATION.DELIVERY_WORKER_APP]: 'delivery_worker_app'
};

export const EMPLOYMENT_CONVERTED_STATUS = {
  [EMPLOYMENT_ACTUAL_STATUS.DEPENDENT]: GENERAL_EMPLOYMENT_STATUS.DEPENDENT,
  [EMPLOYMENT_ACTUAL_STATUS.INDEPENDENT]: GENERAL_EMPLOYMENT_STATUS.INDEPENDENT,
  [EMPLOYMENT_ACTUAL_STATUS.DEPENDENT_AND_INDEPENDENT]: 'dependentAndIndependent',
  [EMPLOYMENT_ACTUAL_STATUS.UNEMPLOYED]: GENERAL_EMPLOYMENT_STATUS.UNEMPLOYED,
  [EMPLOYMENT_ACTUAL_STATUS.DELIVERY_WORKER_APP]: 'deliveryWorkerApp'
};

export const EMPLOYMENT_OPTIONS = [
  EMPLOYMENT_STATUS.DEPENDENT,
  EMPLOYMENT_STATUS.INDEPENDENT,
  EMPLOYMENT_STATUS.DEPENDENT_AND_INDEPENDENT,
  EMPLOYMENT_STATUS.UNEMPLOYED,
  EMPLOYMENT_STATUS.DELIVERY_WORKER_APP
];
