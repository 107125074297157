import React, { useEffect } from 'react';
import { func } from 'prop-types';

import LoadingWrapper from '~components/LoadingWrapper';

function ErrorFallback({ resetError }) {
  useEffect(() => {
    resetError();
  }, []);

  return <LoadingWrapper loading />;
}

ErrorFallback.propTypes = {
  resetError: func
};

export default ErrorFallback;
