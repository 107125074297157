import { SnakecaseSerializer } from 'cerealizr';

const SEGMENT_INSTANCE = window.analytics;

const snakeSerializer = new SnakecaseSerializer();

const trackEvent = (name, properties) => {
  SEGMENT_INSTANCE.track(name, snakeSerializer.serialize(properties));
};

const identifyUser = (userId, traits) => {
  SEGMENT_INSTANCE.identify(userId, snakeSerializer.serialize(traits));
};

const currentAnonymousId = () => {
  const user = window.analytics.user?.();
  return user && user.anonymousId();
};

export default {
  trackEvent,
  identifyUser,
  currentAnonymousId
};
