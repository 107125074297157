import React from 'react';
import { useSelector } from 'react-redux';

import SimulationLoadingWrapper from '~app/components/SimulationLoadingWrapper';

import styles from './styles.module.scss';
import CreditSimulationInfo from './components/CreditSimulationInfo';

function CreditSimulation() {
  const { loanDraftLoading } = useSelector(state => state.simulation);
  return (
    <SimulationLoadingWrapper loading={loanDraftLoading}>
      <div className={styles.container}>
        <CreditSimulationInfo />
      </div>
    </SimulationLoadingWrapper>
  );
}

export default CreditSimulation;
