import i18next from 'i18next';
import { arrayOf, node, string } from 'prop-types';
import React from 'react';

import Button from '~app/components/Button';
import SimulationLoadingWrapper from '~app/components/SimulationLoadingWrapper';
import TransitionCard from '~app/components/TransitionCard';
import { useRedirect } from '~app/hooks/useRedirect';

import MigranteContacts from '../MigranteContacts';

import styles from './styles.module.scss';

function TransitionScreen({
  title,
  transitionCardIcon,
  transitionCardIconClassName,
  transitionCardTitle,
  transitionCardSource,
  transitionCardContent,
  transitionButtonText = i18next.t('Common:continue'),
  customStepName
}) {
  const handleRedirect = useRedirect();
  return (
    <SimulationLoadingWrapper>
      <div className={styles.container}>
        <div className={styles.transitionHeader}>
          <span className={styles.transitionScreenTitle}>{title}</span>
        </div>
        <TransitionCard
          icon={transitionCardIcon}
          iconClassName={transitionCardIconClassName}
          source={transitionCardSource}
          title={transitionCardTitle}
          items={transitionCardContent}
        />
        <div className={styles.buttonsContainer}>
          <Button className={styles.transitionButton} primary onClick={handleRedirect({ customStepName })}>
            {transitionButtonText}
          </Button>
          <MigranteContacts />
        </div>
      </div>
    </SimulationLoadingWrapper>
  );
}

TransitionScreen.propTypes = {
  customStepName: string,
  title: string,
  transitionButtonText: string,
  transitionCardContent: arrayOf(string),
  transitionCardIcon: node,
  transitionCardIconClassName: string,
  transitionCardSource: string,
  transitionCardTitle: string
};

export default TransitionScreen;
