import i18next from 'i18next';
import React from 'react';

import { ReactComponent as NotFound } from '~assets/not-found.svg';
import { MARKETPLACE_PAGE } from '~constants/urls';

import ErrorScreen from '../ErrorScreen';

function ProductNotAvailable() {
  const handleRedirect = () => window.location.replace(MARKETPLACE_PAGE);
  return (
    <ErrorScreen
      title={i18next.t('ProductNotAvailable:title')}
      subtitle={i18next.t('ProductNotAvailable:subtitle')}
      primaryButtonText={i18next.t('ProductNotAvailable:button')}
      picture={NotFound}
      handlePrimaryAction={handleRedirect}
    />
  );
}

export default ProductNotAvailable;
