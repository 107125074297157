import i18next from 'i18next';
import React from 'react';
import Lottie from 'react-lottie';
import { bool } from 'prop-types';

import uniqueLoading from '~assets/lotties/unique-loading.json';
import { getLottieOptions } from '~utils/lottie';

import styles from './styles.module.scss';

function UniqueKeyLoading({ loading, children }) {
  return loading ? (
    <div className={styles.container}>
      <div className={styles.animationContainer}>
        <Lottie
          options={getLottieOptions({
            loop: true,
            autoplay: true,
            animationData: uniqueLoading
          })}
        />
      </div>
      <span className={styles.loadingTitle}>{i18next.t('UniqueKeyLoading:title')}</span>
      <span className={styles.loadingSubtitle}>{i18next.t('UniqueKeyLoading:subtitle')}</span>
    </div>
  ) : (
    children
  );
}

UniqueKeyLoading.propTypes = {
  loading: bool
};

export default UniqueKeyLoading;
