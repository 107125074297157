import i18next from 'i18next';
import { func, shape } from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import cn from 'classnames';

import PrimaryAndSecondaryButtons from '~app/components/PrimaryAndSecondaryButtons';
import GuarantorHeader from '~app/components/GuarantorHeader';
import IdentityFields from '~app/components/IdentityFields';
import { ROUTES } from '~constants/routes';
import StepsActions from '~redux/Steps/actions';
import { isFormCompleted } from '~utils/forms';
import SimulationActions from '~redux/Simulation/actions';
import { GUARANTOR_ROUTES, ROUTES_NAMES, STEPS_STATES, STEPS_TARGETS } from '~constants/steps';
import { getCurrentRutAndDv } from '~utils/rut';
import StepWrapper from '~app/components/StepWrapper';
import { getSteps } from '~utils/steps';
import { useIsView } from '~hooks/useIsView';
import { INCUMBENT_TYPES } from '~constants/incumbent';
import MigranteContacts from '~app/components/MigranteContacts';
import { useRedirect } from '~app/hooks/useRedirect';
import { onlyNumbers } from '~utils/string';
import { useShowStatusPageButton } from '~app/hooks/useShowStatusPageButton';
import { SIMULATION_TARGETS } from '~redux/Simulation/constants';
import { hasChangedStepState } from '~models/simulations';

import styles from './styles.module.scss';
import { getStepperActions, mapIncumbentValues } from './utils';

const GuarantorIdentityForm = ({
  values,
  onChange,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleSubmit
}) => {
  const showStatusPageButton = useShowStatusPageButton();
  const dispatch = useDispatch();
  const changeIncumbentLoading = useSelector(state => state.simulation.changeIncumbentLoading);
  const { quickCode } = useParams();
  const [previousState] = useState({
    ...values,
    phone: onlyNumbers(values.phone),
    ...getCurrentRutAndDv(values.rut)
  });
  const isGuarantorView = useIsView(INCUMBENT_TYPES.GUARANTOR);
  const handleRedirect = useRedirect();
  const header = () => (
    <GuarantorHeader
      title={i18next.t('GuarantorIdentityForm:title')}
      specialText={i18next.t('GuarantorIdentityForm:specialText')}
    />
  );
  const submitForm = postSuccessAction => () => {
    const { rut, dv } = getCurrentRutAndDv(values.rut);
    const phone = onlyNumbers(values.phone);
    const actualState = { ...values, phone, rut, dv };
    if (hasChangedStepState(previousState, actualState)) {
      dispatch(
        SimulationActions.setSimulationInfo(SIMULATION_TARGETS.CURRENT_GUARANTOR, {
          ...values,
          phone,
          dv,
          rut
        })
      );
      dispatch(
        SimulationActions.changeIncumbent({
          quickCode,
          values: mapIncumbentValues(actualState),
          postSuccessAction
        })
      );
    } else {
      postSuccessAction();
    }
  };
  const onSubmit = action => () => {
    handleSubmit();
    if (isFormCompleted(values, errors)) {
      action();
    }
  };
  const continueAction =
    (customStepName = ROUTES.GUARANTOR_BIRTH_INFORMATION.stepName) =>
    () => {
      dispatch(
        StepsActions.setStepState(STEPS_TARGETS.GUARANTOR_PERSONAL_INFORMATION, STEPS_STATES.COMPLETED)
      );
      handleRedirect({ customStepName })();
    };
  const handleContinue = customStepName => () => submitForm(continueAction(customStepName))();
  const stepperActions = getStepperActions(continueAction, submitForm);
  return (
    <StepWrapper
      steps={getSteps(GUARANTOR_ROUTES)}
      selectedStep={ROUTES_NAMES.GUARANTOR.IDENTITY}
      stepperActions={stepperActions}
      customDisabled={!isFormCompleted(values, errors)}
      previousStepName={ROUTES.STATUS_PAGE.stepName}>
      <div className={cn(styles.container, { [styles.guarantorContainer]: isGuarantorView })}>
        <div className={styles.formContainer}>
          <IdentityFields
            values={values}
            onChange={onChange}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            header={header}
            fieldsClassName={styles.fieldsContainer}
          />
          <PrimaryAndSecondaryButtons
            className={styles.buttonsContainer}
            handlePrimaryAction={onSubmit(handleContinue())}
            handleSecondaryAction={onSubmit(handleContinue(ROUTES.STATUS_PAGE.stepName))}
            loading={changeIncumbentLoading}
            hideSecondary={!showStatusPageButton}
          />
          <MigranteContacts className={styles.contactsContainer} />
        </div>
      </div>
    </StepWrapper>
  );
};

GuarantorIdentityForm.propTypes = {
  errors: shape,
  handleSubmit: func,
  setFieldTouched: func,
  setFieldValue: func,
  touched: shape,
  values: shape,
  onChange: func
};

export default GuarantorIdentityForm;
