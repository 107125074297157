import i18next from 'i18next';

import Input from '~app/components/Input';
import Select from '~app/components/Select';
import PriceInput from '~app/components/PriceInput';

import { getLaborSituationOptions, getEmittedInvoicesOptions } from './utils';

export const DEPENDENT = 'dependent';
export const INDEPENDENT = 'independent';
export const DELIVERY_WORKER_APP = 'deliveryWorkerApp';
export const DEPENDENT_AND_INDEPENDENT = 'dependentAndIndependent';
export const UNEMPLOYED = 'unemployed';

export const LABOR_SITUATION_OPTIONS = [
  { value: DEPENDENT },
  { value: INDEPENDENT },
  { value: DELIVERY_WORKER_APP },
  { value: DEPENDENT_AND_INDEPENDENT },
  { value: UNEMPLOYED }
];

export const EMITTED_INVOICES_OPTIONS = [
  { value: 'lessThanThree' },
  { value: 'lessThanSix' },
  { value: 'moreThanSix' },
  { value: 'none' }
];

export const isUnemployed = laborSituation => !laborSituation || laborSituation === UNEMPLOYED;
export const hasEmittedInvoicesLastYear = laborSituation =>
  !laborSituation || [INDEPENDENT, DEPENDENT_AND_INDEPENDENT, DELIVERY_WORKER_APP].includes(laborSituation);

export const FIELDS = laborSituation => [
  {
    component: Select,
    name: 'laborSituation',
    options: getLaborSituationOptions(),
    label: i18next.t('LaborInformationFields:laborSituation'),
    placeholder: i18next.t('LaborInformationFields:select')
  },
  {
    component: PriceInput,
    name: 'monthlyIncomes',
    label: i18next.t('LaborInformationFields:monthlyIncomes'),
    disabled: isUnemployed(laborSituation)
  },
  {
    component: Input,
    name: 'jobStartDate',
    label: i18next.t(`LaborInformationJobStartDate:${laborSituation || 'nan'}`),
    placeholder: 'DD/MM/AAAA',
    disabled: isUnemployed(laborSituation)
  },
  {
    component: Select,
    name: 'emittedInvoicesLastYear',
    options: getEmittedInvoicesOptions(),
    label: i18next.t('LaborInformationFields:emittedInvoicesLastYear'),
    placeholder: i18next.t('LaborInformationFields:select'),
    disabled: isUnemployed(laborSituation) || !hasEmittedInvoicesLastYear(laborSituation)
  }
];
