export const SCRAPERS_TARGETS = {
  SCRAPER_CMF_DATA: 'scraperCmfData',
  SCRAPER_CMF_DATA_ERROR: 'scraperCmfDataError',
  SCRAPER_SII_DATA: 'scraperSiiData',
  SCRAPER_SII_DATA_ERROR: 'scraperSiiDataError',
  SCRAPER_AFP_DATA: 'scraperAfpData',
  SCRAPER_AFP_DATA_ERROR: 'scraperAfpDataError',
  SCRAPER_ALL_DATA: 'scraperAllData',
  SCRAPER_ALL_DATA_ERROR: 'scraperAllDataError',
  SCRAPER_STATUS_CODE_ERROR: 'scraperStatusCodeError',
  SCRAPER_SUBMIT_COUNT: 'scraperSubmitCount'
};

export const SCRAPERS_DEFAULT_STATE = {
  [SCRAPERS_TARGETS.SCRAPER_CMF_DATA]: null,
  [SCRAPERS_TARGETS.SCRAPER_CMF_DATA_ERROR]: null,
  [SCRAPERS_TARGETS.SCRAPER_SII_DATA]: null,
  [SCRAPERS_TARGETS.SCRAPER_SII_DATA_ERROR]: null,
  [SCRAPERS_TARGETS.SCRAPER_AFP_DATA]: null,
  [SCRAPERS_TARGETS.SCRAPER_AFP_DATA_ERROR]: null,
  [SCRAPERS_TARGETS.SCRAPER_ALL_DATA]: null,
  [SCRAPERS_TARGETS.SCRAPER_ALL_DATA_ERROR]: null,
  [SCRAPERS_TARGETS.SCRAPER_STATUS_CODE_ERROR]: null,
  [SCRAPERS_TARGETS.SCRAPER_SUBMIT_COUNT]: 0
};
