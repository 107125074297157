import React, { Suspense, useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Switch } from 'react-router';

import { history } from '~redux/store';
import { ROUTES } from '~constants/routes';
import CreditCashSuccess from '~screens/CreditCashSuccess';
import ClientIdentity from '~screens/ClientIdentity';
import CreditSimulation from '~screens/CreditSimulation';
import ClientConfirmation from '~screens/ClientConfirmation';
import ThankYou from '~screens/ThankYou';
import LetsStart from '~screens/LetsStart';
import ErrorScreen from '~screens/ErrorScreen';
import ProductNotAvailable from '~screens/ProductNotAvailable';
import GuarantorConfirmation from '~screens/GuarantorConfirmation';
import GuarantorIncomes from '~screens/GuarantorIncomes';
import GuarantorIdentity from '~screens/GuarantorIdentity';
import StatusPage from '~screens/StatusPage';
import NotFoundScreen from '~screens/NotFoundScreen';
import ContinueWithGuarantor from '~screens/ContinueWithGuarantor';
import ClientPersonalDocumentation from '~screens/ClientPersonalDocumentation';
import GuarantorPersonalDocumentation from '~screens/GuarantorPersonalDocumentation';
import FailedLoadingTerms from '~screens/FailedLoadingTerms';
import { addPageView } from '~services/DataLayerService';
import ClientFlowChoice from '~screens/ClientFlowChoice';
import GuarantorFlowChoice from '~screens/GuarantorFlowChoice';
import CreditApproved from '~screens/CreditApproved';
import CreditPending from '~screens/CreditPending';
import UniqueKeyError from '~screens/UniqueKeyError';
import ClientUniqueKey from '~screens/ClientUniqueKey';
import GuarantorUniqueKey from '~screens/GuarantorUniqueKey';
import ContinueWithGuarantorUniqueKey from '~screens/ContinueWithGuarantorUniqueKey';
import Maintenance from '~screens/Maintenance';
import ClientFinancialInformation from '~app/screens/ClientFinancialInformation';
import ClientUniqueKeySuccess from '~app/screens/ClientUniqueKeySuccess';
import ClientBirthInformation from '~app/screens/ClientBirthInformation';
import GuarantorBirthInformation from '~app/screens/GuarantorBirthInformation';
import ClientLaborInformation from '~app/screens/ClientLaborInformation';
import GuarantorLaborInformation from '~app/screens/GuarantorLaborInformation';

import styles from './styles.module.scss';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import { calculateVh } from './utils';

const AppRoutesContainer = () => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    calculateVh();
    window.addEventListener('resize', calculateVh);
    return () => {
      unlisten();
    };
  }, []);
  history.listen(() => {
    addPageView({ 'path-update': window.location.pathname });
  });
  return (
    <ConnectedRouter history={history}>
      <div className={`column center ${styles.container}`}>
        <Suspense>
          <div className={`column full-height center ${styles.screensContainer}`}>
            <Switch>
              <AuthenticatedRoute {...ROUTES.MAINTENANCE} component={Maintenance} exact />
              <AuthenticatedRoute
                {...ROUTES.CLIENT_UNIQUE_KEY_SUCCESS}
                component={ClientUniqueKeySuccess}
                exact
              />
              <AuthenticatedRoute
                {...ROUTES.CONTINUE_GUARANTOR_UNIQUE_KEY}
                component={ContinueWithGuarantorUniqueKey}
                exact
              />
              <AuthenticatedRoute {...ROUTES.GUARANTOR_UNIQUE_KEY} component={GuarantorUniqueKey} exact />
              <AuthenticatedRoute {...ROUTES.CLIENT_UNIQUE_KEY} component={ClientUniqueKey} exact />
              <AuthenticatedRoute {...ROUTES.CREDIT_PENDING} component={CreditPending} exact />
              <AuthenticatedRoute {...ROUTES.CREDIT_APPROVED} component={CreditApproved} exact />
              <AuthenticatedRoute {...ROUTES.GUARANTOR_FLOW_CHOICE} component={GuarantorFlowChoice} exact />
              <AuthenticatedRoute {...ROUTES.CLIENT_FLOW_CHOICE} component={ClientFlowChoice} exact />
              <AuthenticatedRoute
                {...ROUTES.GUARANTOR_PERSONAL_DOCS}
                component={GuarantorPersonalDocumentation}
                exact
              />
              <AuthenticatedRoute
                {...ROUTES.CLIENT_PERSONAL_DOCUMENTATION}
                component={ClientPersonalDocumentation}
                exact
              />
              <AuthenticatedRoute
                {...ROUTES.CONTINUE_WITH_GUARANTOR}
                component={ContinueWithGuarantor}
                exact
              />
              <AuthenticatedRoute {...ROUTES.STATUS_PAGE} component={StatusPage} exact />
              <AuthenticatedRoute
                {...ROUTES.GUARANTOR_CONFIRMATION}
                component={GuarantorConfirmation}
                exact
              />
              <AuthenticatedRoute {...ROUTES.GUARANTOR_INCOMES} component={GuarantorIncomes} exact />
              <AuthenticatedRoute {...ROUTES.GUARANTOR_IDENTITY} component={GuarantorIdentity} exact />
              <AuthenticatedRoute
                {...ROUTES.GUARANTOR_BIRTH_INFORMATION}
                component={GuarantorBirthInformation}
                exact
              />
              <AuthenticatedRoute
                {...ROUTES.GUARANTOR_LABOR_INFORMATION}
                component={GuarantorLaborInformation}
                exact
              />
              <AuthenticatedRoute {...ROUTES.CREDIT_SIMULATION} component={CreditSimulation} exact />
              <AuthenticatedRoute {...ROUTES.CLIENT_INCOMES} component={ClientFinancialInformation} exact />
              <AuthenticatedRoute {...ROUTES.CLIENT_CONFIRMATION} component={ClientConfirmation} exact />
              <AuthenticatedRoute {...ROUTES.CLIENT_IDENTITY} component={ClientIdentity} exact />
              <AuthenticatedRoute
                {...ROUTES.CLIENT_BIRTH_INFORMATION}
                component={ClientBirthInformation}
                exact
              />
              <AuthenticatedRoute
                {...ROUTES.CLIENT_LABOR_INFORMATION}
                component={ClientLaborInformation}
                exact
              />
              <AuthenticatedRoute {...ROUTES.CREDIT_CASH_SUCCESS} component={CreditCashSuccess} exact />
              <AuthenticatedRoute {...ROUTES.THANK_YOU} component={ThankYou} exact />
              <AuthenticatedRoute {...ROUTES.LETS_START} component={LetsStart} exact />
              <AuthenticatedRoute {...ROUTES.UNIQUE_KEY_ERROR} component={UniqueKeyError} exact />
              <AuthenticatedRoute {...ROUTES.FAILED_LOADING_TERMS} component={FailedLoadingTerms} exact />
              <AuthenticatedRoute {...ROUTES.PRODUCT_NOT_AVAILABLE} component={ProductNotAvailable} exact />
              <AuthenticatedRoute {...ROUTES.ERROR} component={ErrorScreen} exact />
              <AuthenticatedRoute path="*" component={NotFoundScreen} exact />
            </Switch>
          </div>
        </Suspense>
      </div>
    </ConnectedRouter>
  );
};

export default AppRoutesContainer;
