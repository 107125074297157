import person from '~assets/banners/identity/normal/person.png';
import motorcycle from '~assets/banners/identity/normal/motorcycle.png';
import car from '~assets/banners/identity/normal/car.png';
import emobile from '~assets/banners/identity/normal/emobile.png';
import cellphone from '~assets/banners/identity/normal/cellphone.png';
import motorcycleDealer from '~assets/banners/identity/normal/motorcycleDealer.png';
import carDealer from '~assets/banners/identity/normal/carDealer.png';

import { BASE_TARGETS, SIMULATION_TYPES_TARGETS } from './simulations';

export const BASE_IDENTITY_BANNERS = {
  [SIMULATION_TYPES_TARGETS.PERSON]: person,
  [SIMULATION_TYPES_TARGETS.MOTORCYCLE]: motorcycle,
  [SIMULATION_TYPES_TARGETS.CAR]: car,
  [SIMULATION_TYPES_TARGETS.EMOBILE]: emobile,
  [SIMULATION_TYPES_TARGETS.CELLPHONE]: cellphone,
  [SIMULATION_TYPES_TARGETS.MOTORCYCLE_DEALER]: motorcycleDealer,
  [SIMULATION_TYPES_TARGETS.CAR_DEALER]: carDealer,
  [SIMULATION_TYPES_TARGETS.CAR_DEALER_AMICAR]: carDealer
};

export const IDENTITY_BANNERS = Object.keys(BASE_TARGETS).reduce((prevValues, actualValue) => {
  const withoutGuarantee = actualValue.concat('_WITHOUT_GUARANTEE');
  const actualTarget = SIMULATION_TYPES_TARGETS[actualValue];
  const actualBanner = BASE_IDENTITY_BANNERS[actualTarget];
  return {
    ...prevValues,
    [actualTarget]: actualBanner,
    [SIMULATION_TYPES_TARGETS[withoutGuarantee]]: actualBanner
  };
}, {});
