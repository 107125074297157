import i18next from 'i18next';
import { number, shape, string } from 'prop-types';
import React from 'react';

import { formatMoney } from '~utils/format';

import InformationRow from './components/InformationRow';
import styles from './styles.module.scss';

function InstallmentBoard({ installment }) {
  return (
    <div className={`row full-width middle teal-blue space-between m-top-2 ${styles.installmentContainer}`}>
      <div className="column">
        <span className="medium-title">
          {i18next.t('CreditSimulation:installments', { total: installment?.value })}
        </span>
        <span className="x-large-text semi-bold">{formatMoney(installment?.money)}</span>
      </div>
      <div className="column end m-top-1">
        <InformationRow label="rate" value={installment?.rate} />
        <InformationRow label="guarantee" value={installment?.guarantee} />
      </div>
    </div>
  );
}

InstallmentBoard.propTypes = {
  installment: shape({ value: number, money: string, rate: number, guarantee: number })
};

export default InstallmentBoard;
