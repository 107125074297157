export const IDENTITY_CARD_FIELD = 'identityCard';

export const IDENTITY_CARD_TARGET = 'IdentityCardInfo';

export const IDENTITY_CARD_VALUES = {
  YES: 'yes',
  NO: 'no',
  EXPIRED: 'expired'
};

export const IDENTITY_CARD_OPTIONS = [
  IDENTITY_CARD_VALUES.YES,
  IDENTITY_CARD_VALUES.EXPIRED,
  IDENTITY_CARD_VALUES.NO
];

export const ID_REQUIRED_VALUES = [IDENTITY_CARD_VALUES.YES, IDENTITY_CARD_VALUES.EXPIRED];

export const IMMIGRATION_VALUES = [IDENTITY_CARD_VALUES.NO, IDENTITY_CARD_VALUES.EXPIRED];
