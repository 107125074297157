import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { func, string, bool, node, number } from 'prop-types';
import cn from 'classnames';
import i18next from 'i18next';

import { MODALS } from '~redux/Modal/constants';
import ModalActions from '~redux/Modal/actions';

import CustomModal from '../CustomModal';
import ButtonLoading from '../ButtonLoading';

import styles from './styles.module.scss';

function StateModal({
  InfoComponent,
  title,
  info,
  buttonText = i18next.t('Common:continue'),
  handleClick,
  boldInfo,
  Icon,
  containerClassName,
  buttonClassName,
  loading,
  className,
  modalType,
  iconSize = 60,
  ...props
}) {
  const dispatch = useDispatch();
  const open = useSelector(state => state.modal[MODALS[modalType]]);
  const closeModal = () => dispatch(ModalActions.closeModal(MODALS[modalType]));
  return (
    <CustomModal
      {...props}
      className={cn(styles.modal, className)}
      modal={MODALS[modalType]}
      onClose={closeModal}
      isOpen={open}>
      <div className={`${styles.container} ${containerClassName}`}>
        <Icon height={iconSize} width={iconSize} />
        <div className={styles.stateTitle}>{title}</div>
        <div className={styles.stateText}>
          {InfoComponent ? <InfoComponent /> : info}
          <b>{boldInfo}</b>
        </div>
        <ButtonLoading
          primary
          onClick={handleClick || closeModal}
          className={buttonClassName}
          loading={loading}>
          {buttonText}
        </ButtonLoading>
      </div>
    </CustomModal>
  );
}

StateModal.propTypes = {
  boldInfo: string,
  buttonClassName: string,
  buttonText: string,
  className: string,
  containerClassName: string,
  handleClick: func,
  Icon: node,
  iconSize: number,
  info: string,
  InfoComponent: node,
  loading: bool,
  modalType: string,
  title: string
};

export default StateModal;
