import { MODAL_INFO_KEYS, MODAL_INFO_TARGETS, MODAL_TYPES_TARGETS, SCRAPERS } from '~constants/modals';
import { SCRAPERS_TARGETS } from '~constants/scrapers';
import { hasWarning, isReceivedWithoutWarning } from '~models/documentation';

import { addPrefix } from './string';

const getScraperTarget = (prefix, scraper) => ({
  [MODAL_INFO_KEYS(scraper).WARNING]: addPrefix(prefix, SCRAPERS_TARGETS[scraper].WARNING),
  [MODAL_INFO_KEYS(scraper).RECEIVED]: addPrefix(prefix, SCRAPERS_TARGETS[scraper].RECEIVED)
});

export const getScrapersTargets = prefix =>
  SCRAPERS.reduce(
    (map, scraper) => ({
      ...map,
      ...getScraperTarget(prefix, scraper)
    }),
    {}
  );

const hasUploadedFiles = (prefix, requirements, firstFile, secondFile) => {
  const firstFileName = addPrefix(prefix, firstFile);
  const secondFileName = addPrefix(prefix, secondFile);
  return requirements && (!!requirements[firstFileName] || !!requirements[secondFileName]);
};

export const getScrapersReceived = (prefix, requirements) =>
  SCRAPERS.reduce(
    (map, scraper) => ({
      ...map,
      [MODAL_INFO_KEYS(scraper).RECEIVED]: hasUploadedFiles(
        prefix,
        requirements,
        SCRAPERS_TARGETS[scraper].MANUAL_FILE,
        SCRAPERS_TARGETS[scraper].SCRAPER_FILE
      )
    }),
    {}
  );

export const getScraperBooleans = (prefix, scraper, requirements, received) => {
  const warning = hasWarning(prefix, requirements, SCRAPERS_TARGETS[scraper].UNKNOWN_META);
  const { WARNING, RECEIVED_WITHOUT_WARNING } = MODAL_INFO_KEYS(scraper);
  return {
    [WARNING]: warning,
    [RECEIVED_WITHOUT_WARNING]: isReceivedWithoutWarning(received, warning)
  };
};

export const getScrapersBooleans = ({ prefix, requirements, ...params }) =>
  SCRAPERS.reduce(
    (map, scraper) => ({
      ...map,
      ...getScraperBooleans(
        prefix,
        scraper,
        requirements,
        params[addPrefix(MODAL_TYPES_TARGETS[scraper], MODAL_INFO_TARGETS.RECEIVED)]
      )
    }),
    {}
  );

export const setScraperInfo = ({ dispatch, actionCreators, prefix, scraper, ...params }) => {
  const scrapersBooleans = getScrapersBooleans({ prefix, ...params });
  const { WARNING, RECEIVED_WITHOUT_WARNING } = MODAL_INFO_KEYS(scraper);
  dispatch(
    actionCreators.setInfoReceivedAndWarning(
      addPrefix(prefix, SCRAPERS_TARGETS[scraper].RECEIVED),
      addPrefix(prefix, SCRAPERS_TARGETS[scraper].WARNING),
      scrapersBooleans[RECEIVED_WITHOUT_WARNING],
      scrapersBooleans[WARNING]
    )
  );
};

export const setScrapersInfo = ({ dispatch, actionCreators, prefix, requirements, ...params }) => {
  SCRAPERS.forEach(scraper =>
    setScraperInfo({ dispatch, actionCreators, prefix, requirements, scraper, ...params })
  );
};
