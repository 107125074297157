import * as Yup from 'yup';

import { EMAIL_VALIDATION, IDENTITY_VALIDATION_SCHEMA, RUT_VALIDATION } from '~constants/validations';
import { needsDealer } from '~models/simulations';
import { getPhoneValueOrEmptyString } from '~utils/phoneNumber';
import { getValueOrEmptyString } from '~utils/string';

import { ADDITIONAL_FIELDS, FIELDS, IDENTITY_KEYS_WITH_DEALER, IDENTITY_REMAINING_KEYS } from './constants';

export const IDENTITY_VALIDATIONS = {
  ...IDENTITY_VALIDATION_SCHEMA,
  rut: RUT_VALIDATION
};

export const VALIDATION_SCHEMA = Yup.object().shape(IDENTITY_VALIDATIONS);

export const VALIDATION_SCHEMA_WITH_DEALER = Yup.object().shape({
  ...IDENTITY_VALIDATIONS,
  emailDealer: EMAIL_VALIDATION
});

const mapIdentityInitialValues = (prevValues, actualValue, queryParams, func) => ({
  ...prevValues,
  [actualValue]: func(queryParams[actualValue])
});

export const getIdentityInitialValues = (queryParams, kind) => {
  const values = [
    { name: getValueOrEmptyString(queryParams.name) },
    ...(needsDealer(kind) ? IDENTITY_KEYS_WITH_DEALER : IDENTITY_REMAINING_KEYS)
  ];
  return values.reduce((prevValues, actualValue) =>
    mapIdentityInitialValues(
      prevValues,
      actualValue,
      queryParams,
      actualValue === FIELDS.PHONE ? getPhoneValueOrEmptyString : getValueOrEmptyString
    )
  );
};

export const getFormConfiguration = kind =>
  needsDealer(kind)
    ? {
        validationSchema: VALIDATION_SCHEMA_WITH_DEALER,
        additionalFields: ADDITIONAL_FIELDS
      }
    : {
        validationSchema: VALIDATION_SCHEMA
      };
