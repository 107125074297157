import i18next from 'i18next';

import StepsActions from '~redux/Steps/actions';
import { META_VALUES } from '~constants/metaValues';
import { ROUTES } from '~constants/routes';
import { ALLOWED_GOING_FORWARD_STATES, STEPS_STATES, STEP_SUBMITTED_META } from '~constants/steps';

import { addPrefix } from './string';
import { hasEmploymentAndWarning } from './employmentSituation';

export const getSteps = routes =>
  routes.map(({ name, icon, step, route }) => ({
    id: name,
    icon,
    title: i18next.t(`Steps:${name}`),
    step,
    stepName: ROUTES[route].stepName
  }));

export const isCompleted = stepState => (stepState ? STEPS_STATES.COMPLETED : STEPS_STATES.UNCOMPLETED);

export const getConfirmationIncumbent = (prefix, value) => [
  { kind: addPrefix(prefix, '_confirmation_meta'), value },
  { kind: STEP_SUBMITTED_META, value: addPrefix(prefix, META_VALUES.CONFIRMATION) }
];

export const getPreviousSteps = (steps, index) => steps.slice(0, index);

export const isAllowed = (stepsState, previousSteps) =>
  previousSteps.every(previous => ALLOWED_GOING_FORWARD_STATES.includes(stepsState[previous.step]));

export const hasCompleted = previousStepState => previousStepState === STEPS_STATES.COMPLETED;

export const setUncompletedStepProgress = ({ dispatch, incumbentName, uncompleted, stepTarget }) => {
  if (uncompleted) {
    dispatch(StepsActions.setStepState(addPrefix(incumbentName, stepTarget), STEPS_STATES.UNCOMPLETED));
  }
};

export const setFinancialStepProgress = ({
  employmentSituation,
  afpReceivedAndWithoutWarning,
  siiReceivedAndWithoutWarning,
  cmfReceivedAndWithoutWarning,
  isSubmitted,
  isAutomatic,
  ...params
}) => {
  setUncompletedStepProgress({
    uncompleted: isAutomatic
      ? !isSubmitted
      : hasEmploymentAndWarning(
          employmentSituation,
          !afpReceivedAndWithoutWarning,
          !siiReceivedAndWithoutWarning
        ) || !cmfReceivedAndWithoutWarning,
    stepTarget: '_FINANCIAL_INFORMATION',
    ...params
  });
};
