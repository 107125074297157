export const DATE_SEPARATOR = '/';

export const getDateTerms = date => {
  const day = date.slice(0, 2);
  const month = date.slice(2, 4);
  const year = date.slice(4, 8);

  return [day, month, year];
};

const clean = value => String(value).replace(/\D/g, '');

const isDateTermComplete = maxLength => (currentDate, prevDate) =>
  currentDate.length === maxLength && currentDate.length > prevDate.length;
const isDayComplete = isDateTermComplete(2);
const isMonthComplete = isDateTermComplete(5);

export const handleDateChange = value => {
  const prevDate = String(value);
  const cleanValue = clean(value);
  const date = getDateTerms(cleanValue)
    .filter(term => !!term)
    .join(DATE_SEPARATOR);

  const nextValue = isDayComplete(date, prevDate) || isMonthComplete(date, prevDate) ? `${date}/` : date;

  return nextValue;
};

export const isValidInputDate = (value, maxAge, minAge) => {
  if (value?.length !== 10) {
    return false;
  }

  const cleanValue = clean(value);
  const [day, month, year] = getDateTerms(cleanValue);
  if (parseInt(month) > 12) {
    return false;
  }

  const date = new Date(year, month, day);

  return maxAge < date && date < minAge;
};
