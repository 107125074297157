import i18next from 'i18next';
import { shape, string, bool } from 'prop-types';
import React from 'react';

import GuarantorHeader from '~app/components/GuarantorHeader';
import StepWrapper from '~app/components/StepWrapper';
import { ROUTES } from '~constants/routes';
import { stepsProptype } from '~propTypes/steps';

import styles from './styles.module.scss';

function GuarantorIncomesWrapper({ children, ...props }) {
  return (
    <StepWrapper {...props} previousStepName={ROUTES.GUARANTOR_IDENTITY.stepName}>
      <div className={styles.container}>
        <GuarantorHeader
          title={i18next.t('GuarantorIncomes:title')}
          specialText={i18next.t('GuarantorIncomes:specialText')}
        />
        {children}
      </div>
    </StepWrapper>
  );
}

GuarantorIncomesWrapper.propTypes = {
  disableLast: bool,
  selectedStep: string,
  stepperActions: shape,
  steps: stepsProptype
};

export default GuarantorIncomesWrapper;
