import { arrayOf, func, shape, string } from 'prop-types';
import React from 'react';

import RadioButton from './components/RadioButton';

function RadioGroup({ options, className, onChange, labelClassName, radioClassName, checkedOption }) {
  const handleChange = option => {
    if (onChange) {
      onChange(option);
    }
  };

  return (
    <div className={className}>
      {options.map(({ value, label, ...optionProps }, index) => (
        <div key={value}>
          <RadioButton
            checked={checkedOption?.value === value}
            label={label}
            onClick={() => handleChange(options[index])}
            labelClassName={labelClassName}
            radioClassName={radioClassName}
            {...optionProps}
          />
        </div>
      ))}
    </div>
  );
}

RadioGroup.propTypes = {
  options: arrayOf(shape({ label: string, value: string })).isRequired,
  checkedOption: shape({ value: string, label: string }),
  className: string,
  labelClassName: string,
  radioClassName: string,
  onChange: func
};

export default RadioGroup;
