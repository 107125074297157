import { bool } from 'prop-types';
import React from 'react';

import NavBarWrapper from '../NavBarWrapper';
import SimulationLoadingWrapper from '../SimulationLoadingWrapper';

function SimulationNewFlowWrapper({ children, loading, disabled, ...props }) {
  return (
    <SimulationLoadingWrapper {...props} loading={loading}>
      <NavBarWrapper {...props} disabled={disabled}>
        {children}
      </NavBarWrapper>
    </SimulationLoadingWrapper>
  );
}

SimulationNewFlowWrapper.propTypes = {
  disabled: bool,
  loading: bool,
  navBarLoading: bool
};

export default SimulationNewFlowWrapper;
