import { INCUMBENT_TYPES } from '~constants/incumbent';
import { MIDDLE_MODAL_TYPES_TARGETS, MODAL_INFO, MODAL_INFO_TARGETS, SCRAPERS } from '~constants/modals';
import { VEHICLE_CREDIT_TYPES, WITHOUT_DOWNPAYMENT } from '~constants/simulations';
import { isConsumerSimulation } from '~models/simulations';

import { hasCompleted } from './steps';

export const isVehicleCreditType = kind => VEHICLE_CREDIT_TYPES.includes(kind);

export const canEditAmount = (queryParams, kind) => !queryParams.amount || isConsumerSimulation(kind);

export const needsToEditAmount = ({ clientQuotesInformation, canEditAmount: clientCanEditAmount }) =>
  !hasCompleted(clientQuotesInformation) && clientCanEditAmount;

export const isWithoutDownpayment = kind => WITHOUT_DOWNPAYMENT.includes(kind);

export const getDownpaymentByKind = (kind, downpayment) => (isWithoutDownpayment(kind) ? 0 : downpayment);

export const getIncumbentTargetsNames = (incumbentName, scraper) => {
  const incumbentNameScraper = incumbentName.concat(`_${scraper}`);
  const incumbentScraper = INCUMBENT_TYPES[incumbentName].concat(MIDDLE_MODAL_TYPES_TARGETS[scraper]);
  return {
    incumbentNameScraperReceived: incumbentNameScraper.concat(`_${MODAL_INFO.RECEIVED}`),
    incumbentNameScraperWarning: incumbentNameScraper.concat(`_${MODAL_INFO.WARNING}`),
    incumbentScraperReceived: incumbentScraper.concat(MODAL_INFO_TARGETS.RECEIVED),
    incumbentScraperWarning: incumbentScraper.concat(MODAL_INFO_TARGETS.WARNING)
  };
};

export const getIncumbentScrapersTargets = incumbentName =>
  SCRAPERS.reduce((map, scraper) => {
    const {
      incumbentNameScraperReceived,
      incumbentNameScraperWarning,
      incumbentScraperReceived,
      incumbentScraperWarning
    } = getIncumbentTargetsNames(incumbentName, scraper);
    return {
      ...map,
      [incumbentNameScraperReceived]: incumbentScraperReceived,
      [incumbentNameScraperWarning]: incumbentScraperWarning
    };
  }, {});

export const getScrapersDefaultState = incumbentName =>
  SCRAPERS.reduce((map, scraper) => {
    const { incumbentScraperReceived, incumbentScraperWarning } = getIncumbentTargetsNames(
      incumbentName,
      scraper
    );
    return {
      ...map,
      [incumbentScraperReceived]: false,
      [incumbentScraperWarning]: false
    };
  }, {});

export const getScrapersLocalStateTargets = incumbentName =>
  SCRAPERS.reduce((map, scraper) => {
    const { incumbentScraperReceived, incumbentScraperWarning } = getIncumbentTargetsNames(
      incumbentName,
      scraper
    );
    return [...map, incumbentScraperReceived, incumbentScraperWarning];
  }, []);
