import i18next from 'i18next';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import cn from 'classnames';

import Button from '~app/components/Button';
import { ReactComponent as CircleTick } from '~assets/circle-tick.svg';
import { ReactComponent as NewLogoYellow } from '~assets/logos/normal/new-logo-yellow.svg';
import { ROUTES } from '~constants/routes';
import { STEPS_STATES } from '~constants/steps';
import ModalActions from '~redux/Modal/actions';
import { MODALS } from '~redux/Modal/constants';
import StepsActions from '~redux/Steps/actions';
import SimulationActions from '~redux/Simulation/actions';
import { getConfirmationIncumbent, hasCompleted } from '~utils/steps';
import VerificationsActions from '~redux/Verifications/actions';
import { addPrefix } from '~utils/string';
import EmailModal from '~components/EmailModal';
import { useRedirect } from '~app/hooks/useRedirect';

import styles from './styles.module.scss';

function EndingConfirmation({
  prefix,
  title,
  firstText,
  secondText,
  email,
  previousStep,
  target,
  customNextStepName,
  customIncumbentData = [],
  continueText,
  modifyEmailText,
  setCurrentIncumbent,
  tickContainerClassName,
  tickClassName,
  disableEmailChange,
  confirmedEmail,
  confirmedEmailTarget
}) {
  const dispatch = useDispatch();
  const { quickCode } = useParams();
  const steps = useSelector(state => state.steps);
  const handleRedirectAction = useRedirect();
  const handleRedirect = () => {
    handleRedirectAction({ customStepName: customNextStepName || ROUTES.STATUS_PAGE.stepName })();
    if (!disableEmailChange) {
      dispatch(
        SimulationActions.changeIncumbent({
          quickCode,
          values: [...getConfirmationIncumbent(prefix, 'complete'), ...customIncumbentData],
          postSuccessAction:
            target &&
            hasCompleted(steps[previousStep]) &&
            StepsActions.setStepState(target, STEPS_STATES.COMPLETED)
        })
      );
    }
  };
  useEffect(() => {
    if (!disableEmailChange) {
      dispatch(
        VerificationsActions.triggerCheckout({
          quickCode,
          incumbent: prefix,
          value: email,
          field: addPrefix(prefix, '_email')
        })
      );
    }
  }, []);
  const [currentEmail, setCurrentEmail] = useState(email);
  const handleEmailChange = newEmail => {
    setCurrentEmail(newEmail);
    if (setCurrentIncumbent) {
      setCurrentIncumbent(newEmail);
    }
  };
  const handleOpenEmailModal = () => dispatch(ModalActions.openModal(MODALS.EMAIL));
  return (
    <div className={styles.container}>
      <div className={styles.background}>
        <span>{title}</span>
        <div className={cn(styles.tick, tickContainerClassName)}>
          <CircleTick className={cn(styles.tickPicture, tickClassName)} />
        </div>
      </div>
      <div className={styles.alertContainer}>
        <div className={styles.centerGroup}>
          <NewLogoYellow className={styles.newLogo} />
        </div>
        <p>
          <b>{i18next.t('EndingAlert:titleFirstPart')}</b>
        </p>
        <div className={styles.centerGroup}>
          <div className={styles.divider} />
        </div>
        <small>
          <div>
            {i18next.t('EndingAlert:descriptionFirstPart')}
            <b>{i18next.t('EndingAlert:descriptionSecondtPart')}</b>
            <br />
            {i18next.t('EndingAlert:descriptionThirdPart')}
            <br />
            {i18next.t('EndingAlert:descriptionFourPart')}
          </div>
        </small>
      </div>
      <span className={styles.info}>
        {firstText}
        <span className={styles.email}>{currentEmail}</span>
        {secondText}
      </span>
      <div className={styles.buttons}>
        <Button secondary onClick={handleRedirect}>
          {continueText || i18next.t('EndingConfirmation:continue')}
        </Button>
        {!disableEmailChange && (
          <Button lowercase underline primary className={styles.emailButton} onClick={handleOpenEmailModal}>
            {modifyEmailText || i18next.t('EndingConfirmation:notMyEmail')}
          </Button>
        )}
      </div>
      <EmailModal
        incumbent={prefix}
        email={currentEmail}
        handleEmailChange={handleEmailChange}
        confirmedEmail={confirmedEmail}
        confirmedEmailTarget={confirmedEmailTarget}
      />
    </div>
  );
}

EndingConfirmation.propTypes = {
  confirmedEmail: bool,
  confirmedEmailTarget: string,
  continueText: string,
  customIncumbentData: arrayOf(shape({ kind: string, value: string })),
  customNextStepName: string,
  disableEmailChange: bool,
  email: string,
  firstText: string,
  modifyEmailText: string,
  prefix: string,
  previousStep: string,
  secondText: string,
  setCurrentIncumbent: func,
  target: string,
  tickClassName: string,
  tickContainerClassName: string,
  title: string
};

export default EndingConfirmation;
