import React from 'react';
import { bool, int, string } from 'prop-types';

import { formatMoney } from '~utils/format';

function EdgeLabel({ money, value, className }) {
  return <span className={`teal-blue ${className}`}>{money ? formatMoney(value) : value}</span>;
}

EdgeLabel.propTypes = {
  className: string,
  money: bool,
  value: int
};

export default EdgeLabel;
