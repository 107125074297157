import { META_BOOLEANS_VALUES, META_INFO_RECEIVED, META_INFO_WARNINGS } from '~constants/metaInfo';

import { isEmptyObject } from './object';
import { addPrefix } from './string';

export const getMetaInfo = (incumbent, boolean, type, metaInfo) => ({
  kind: addPrefix(incumbent, metaInfo[type]),
  value: boolean?.toString() || META_BOOLEANS_VALUES.FALSE
});

export const getMetaInfoWarning = (incumbent, warning, type) =>
  getMetaInfo(incumbent, warning, type, META_INFO_WARNINGS);

export const getMetaInfoReceived = (incumbent, received, type) =>
  received ? getMetaInfo(incumbent, received, type, META_INFO_RECEIVED) : {};

export const getCompleteMetaInfo = ({ incumbent, documentationTargets, ...params }) => {
  const { RECEIVED_METAS, WARNING_METAS } = documentationTargets(params);
  const metas = [
    ...WARNING_METAS.map(({ boolean, docType }) => getMetaInfoWarning(incumbent, boolean, docType)),
    ...RECEIVED_METAS.map(({ boolean, docType }) => getMetaInfoReceived(incumbent, boolean, docType))
  ];
  return metas.filter(meta => !isEmptyObject(meta));
};
