export const TARGET_FILES_NAMES = {
  SII: 'SII',
  AFP: 'AFP',
  FRONT_RUT: 'FRONT_RUT',
  BACK_RUT: 'BACK_RUT',
  VISA_DOCUMENT: 'VISA_DOCUMENT',
  COLLEGE_DEGREE: 'COLLEGE_DEGREE',
  CMF: 'CMF',
  PERSONAL_EARNINGS: 'PERSONAL_EARNINGS'
};

export const IS_UNIQUE_KEY = type => [TARGET_FILES_NAMES.CMF, TARGET_FILES_NAMES.SII].includes(type);

export const DOCUMENTATION_FILES = [
  TARGET_FILES_NAMES.FRONT_RUT,
  TARGET_FILES_NAMES.BACK_RUT,
  TARGET_FILES_NAMES.VISA_DOCUMENT,
  TARGET_FILES_NAMES.PERSONAL_EARNINGS
];

export const TARGET_FILES = {
  [TARGET_FILES_NAMES.SII]: {
    kind: '_personal_invoice_file',
    context: '_sii_certificate_file'
  },
  [TARGET_FILES_NAMES.AFP]: {
    kind: '_retirement_fund_certificate_file',
    context: '_afp_scraper_file'
  },
  [TARGET_FILES_NAMES.FRONT_RUT]: {
    kind: '_front_rut_file'
  },
  [TARGET_FILES_NAMES.BACK_RUT]: {
    kind: '_back_rut_file'
  },
  [TARGET_FILES_NAMES.VISA_DOCUMENT]: {
    kind: '_visa_document_file'
  },
  [TARGET_FILES_NAMES.COLLEGE_DEGREE]: {
    kind: '_college_degree_file'
  },
  [TARGET_FILES_NAMES.CMF]: {
    kind: '_debt_certificate_file',
    context: '_cmf_scraper_file'
  },
  [TARGET_FILES_NAMES.PERSONAL_EARNINGS]: {
    kind: '_personal_earnings_file'
  }
};
