import i18next from 'i18next';
import React from 'react';

import ErrorScreen from '../ErrorScreen';

import styles from './styles.module.scss';

function FailedLoadingTerms() {
  return (
    <ErrorScreen
      title={i18next.t('FailedLoadingTerms:title')}
      subtitle={i18next.t('FailedLoadingTerms:subtitle')}
      backgroundClassName={styles.background}
    />
  );
}

export default FailedLoadingTerms;
