import i18next from 'i18next';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { func, number, string } from 'prop-types';

import ButtonLoading from '~components/ButtonLoading';
import FlowChanger from '~app/components/FlowChanger';
import { TARGET_FILES_NAMES } from '~constants/fileTargets';
import { useFlowChoice } from '~app/hooks/useFlowChoice';
import { ROUTES } from '~constants/routes';

import styles from './styles.module.scss';
import UniqueKeyHeader from './components/UniqueKeyHeader';
import UniqueKeyFormContent from './components/UniqueKeyFormContent';
import UniqueKeyFormError from './components/UniqueKeyFormError';
import CertificateHeader from './components/CertificateHeader';

function UniqueKeyForm({
  currentStepName,
  showFlowChanger,
  type,
  incumbent,
  currentFlowChoice,
  ...formProps
}) {
  const changeIncumbentLoading = useSelector(state => state.simulation.changeIncumbentLoading);
  const {
    scraperAllDataLoading,
    scraperAllDataError,
    scraperCmfDataLoading,
    scraperCmfDataError,
    scraperSiiDataLoading,
    scraperSiiDataError,
    scraperAfpDataLoading,
    scraperAfpDataError,
    scraperSubmitCount
  } = useSelector(state => state.scrapers);
  const { handleStartManualChoice } = useFlowChoice({
    incumbent,
    currentFlowChoice,
    customStepName: ROUTES.LETS_START.stepName
  });

  useEffect(() => {
    if (showFlowChanger?.(scraperSubmitCount)) {
      handleStartManualChoice();
    }
  }, [scraperSubmitCount]);

  return (
    <div className={styles.container}>
      {type === TARGET_FILES_NAMES.AFP ? <CertificateHeader /> : <UniqueKeyHeader />}
      <UniqueKeyFormContent
        customError={
          !!(scraperCmfDataError || scraperSiiDataError || scraperAfpDataError || scraperAllDataError)
        }
        type={type}
        {...formProps}
      />
      <UniqueKeyFormError />
      <FlowChanger
        show={showFlowChanger?.(scraperSubmitCount)}
        customStepName={currentStepName}
        {...formProps}
      />
      <div className={styles.continueSection}>
        <ButtonLoading
          type="submit"
          className={styles.continueButton}
          primary
          loading={
            scraperAllDataLoading ||
            scraperCmfDataLoading ||
            scraperSiiDataLoading ||
            scraperAfpDataLoading ||
            changeIncumbentLoading
          }>
          {i18next.t('UniqueKeyScraper:continue')}
        </ButtonLoading>
        <div className={styles.messageInfo}>
          {i18next.t('PrivacyPolicies:firstPart')}
          <a
            href={process.env.REACT_APP_PRIVACY_POLICIES_LINK}
            className={styles.messageLink}
            target="_blank"
            rel="noreferrer">
            {i18next.t('PrivacyPolicies:privacyPolicies')}
          </a>
          {i18next.t('PrivacyPolicies:secondPart')}
          <a
            href={process.env.REACT_APP_EVALUATION_POLICIES_LINK}
            className={styles.messageLink}
            target="_blank"
            rel="noreferrer">
            {i18next.t('PrivacyPolicies:evaluationPolicies')}
          </a>
        </div>
      </div>
    </div>
  );
}

UniqueKeyForm.propTypes = {
  currentFlowChoice: string,
  currentStepName: string,
  incumbent: string,
  showFlowChanger: func,
  submitCount: number,
  type: string
};

export default UniqueKeyForm;
