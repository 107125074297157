import i18next from 'i18next';

import { STEPS_STATES } from '~constants/steps';

export const CONFIRMATION_GUARANTOR_INFO = {
  [STEPS_STATES.COMPLETED]: {
    title: '',
    subtitle: i18next.t('ContinueWithGuarantor:preapproved'),
    secondaryText: i18next.t('ContinueWithGuarantor:shareLink'),
    neededInfo: '',
    primaryText: '',
    underlinedButtonText: '',
    hideSecondary: false
  },
  [STEPS_STATES.UNCOMPLETED]: {
    title: '',
    subtitle: i18next.t('ContinueWithGuarantor:almostThere'),
    secondaryText: i18next.t('ContinueWithGuarantor:shareLink'),
    neededInfo: '',
    primaryText: '',
    underlinedButtonText: '',
    hideSecondary: false
  }
};

export const CONTINUE_GUARANTOR_INFO = {
  title: i18next.t('ContinueWithGuarantor:yes'),
  subtitle: i18next.t('ContinueWithGuarantor:named'),
  secondaryText: '',
  neededInfo: i18next.t('ContinueWithGuarantor:neededInfo'),
  primaryText: i18next.t('ContinueWithGuarantor:complete'),
  underlinedButtonText: i18next.t('ContinueWithGuarantor:whatIsAGuarantor'),
  hideSecondary: true
};
