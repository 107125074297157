import i18next from 'i18next';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { useRedirect } from '~app/hooks/useRedirect';
import Button from '~components/Button';
import Card from '~components/Card';
import { META_BOOLEANS_VALUES } from '~constants/metaInfo';
import { META_VALUES } from '~constants/metaValues';
import { ROUTES } from '~constants/routes';
import SimulationActions from '~redux/Simulation/actions';
import { SIMULATION_TARGETS } from '~redux/Simulation/constants';
import { mapIncumbentValue } from '~redux/Simulation/mappers';

import ShareLinkButton from '../ShareLinkButton';

import styles from './styles.module.scss';

function ShareLinkCard() {
  const { quickCode } = useParams();
  const dispatch = useDispatch();
  const handlePostAction = () => {
    dispatch(SimulationActions.setSimulationInfo(SIMULATION_TARGETS.CLIENT_KNOWS_STATUS_SHARE_LINK, true));
    dispatch(
      SimulationActions.changeIncumbent({
        quickCode,
        values: mapIncumbentValue(META_VALUES.CLIENT_KNOWS_SHARE_LINK_META, META_BOOLEANS_VALUES.TRUE)
      })
    );
  };
  const handleRedirect = useRedirect();
  return (
    <Card cardClassName={styles.shareLinkCard}>
      <div className={styles.container}>
        <div className={styles.infoContainer}>
          {i18next.t('StatusPage:completeGuarantor')}
          <b>{i18next.t('StatusPage:guarantor')}</b>.
        </div>
        <div className={styles.shareLinkButtons}>
          <ShareLinkButton handlePostAction={handlePostAction} />
          <Button
            secondary
            borderPrimary
            onClick={handleRedirect({
              customStepName: ROUTES.GUARANTOR_IDENTITY.stepName,
              postAction: handlePostAction
            })}>
            {i18next.t('StatusPage:completeInfo')}
          </Button>
        </div>
      </div>
    </Card>
  );
}

export default ShareLinkCard;
