import i18next from 'i18next';
import React from 'react';

import { ReactComponent as Lock } from '~assets/lock.svg';

import styles from './styles.module.scss';

function CertificateHeader() {
  return (
    <div className={styles.container}>
      <Lock />
      <span className={styles.certificateTitle}>{i18next.t('CretificateScraper:title')}</span>
      <span className={styles.certificateSubtitle}>{i18next.t('CretificateScraper:subtitle')}</span>
    </div>
  );
}

export default CertificateHeader;
