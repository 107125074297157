import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import SimulationActions from '~redux/Simulation/actions';
import ScrapersActions from '~redux/Scrapers/actions';
import { SCRAPERS_TARGETS } from '~redux/Scrapers/constants';

export const useUniqueKeyScraper = ({
  incumbent,
  customPostSuccessAction,
  mapIncumbentValues,
  handleReceived,
  handleResetWarning
}) => {
  const scraperSubmitCount = useSelector(state => state.scrapers.scraperSubmitCount);
  const dispatch = useDispatch();
  const { quickCode } = useParams();
  const postSuccessAction = () => {
    handleReceived?.();
    handleResetWarning?.();
    dispatch(ScrapersActions.resetErrors());
    dispatch(
      SimulationActions.changeIncumbent({
        quickCode,
        values: mapIncumbentValues(incumbent),
        postSuccessAction: customPostSuccessAction
      })
    );
  };
  const postErrorAction = errorCode => () => {
    dispatch(ScrapersActions.setScraperInfo(SCRAPERS_TARGETS.SCRAPER_SUBMIT_COUNT, scraperSubmitCount + 1));
    dispatch(ScrapersActions.setScraperInfo(SCRAPERS_TARGETS.SCRAPER_STATUS_CODE_ERROR, errorCode));
  };
  return { postSuccessAction, postErrorAction };
};
