import i18next from 'i18next';
import React from 'react';

import Unique from '~assets/clave-unica.png';

import styles from './styles.module.scss';

export const STEPS = [
  { step: '1', text: i18next.t('ClientIdentity:stepOne') },
  {
    step: '2',
    text: (
      <div>
        {i18next.t('ClientIdentity:makeEasy')}
        <img className={styles.logo} src={Unique} alt="CableUnica logo" />
        {i18next.t('ClientIdentity:speedUp')}
      </div>
    )
  },
  { step: '3', text: i18next.t('ClientIdentity:stepThree') }
];
