import i18next from 'i18next';
import { string } from 'prop-types';
import React from 'react';

import { ReactComponent as Unique } from '~assets/unique.svg';

import styles from './styles.module.scss';

function ManualToAutomatic({ className }) {
  return (
    <div className={className}>
      {i18next.t('ManualToAutomatic:title')}
      <div className={styles.logoContainer}>
        {i18next.t('ManualToAutomatic:withYour')}
        <Unique className={styles.logo} />
      </div>
    </div>
  );
}

ManualToAutomatic.propTypes = {
  className: string
};

export default ManualToAutomatic;
