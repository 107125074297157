import i18next from 'i18next';
import React from 'react';
import { useParams } from 'react-router';
import { string } from 'prop-types';

import { useIsView } from '~hooks/useIsView';
import { INCUMBENT_TYPES } from '~constants/incumbent';
import { getGuarantorShareLink } from '~utils/shareLink';

import ShareLinkButton from '../ShareLinkButton';

import styles from './styles.module.scss';

function GuarantorShareableLink({ shareInfo = i18next.t('GuarantorShareableLink:info'), customLink = '' }) {
  const show = !useIsView(INCUMBENT_TYPES.GUARANTOR);
  const { quickCode, kind } = useParams();
  return show ? (
    <div className={styles.container}>
      <span className={styles.shareInfo}>{shareInfo}</span>
      <ShareLinkButton link={customLink || getGuarantorShareLink(quickCode, kind)} />
    </div>
  ) : null;
}

GuarantorShareableLink.propTypes = {
  customLink: string,
  shareInfo: string
};

export default GuarantorShareableLink;
