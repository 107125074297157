import { func, shape, string } from 'prop-types';
import React, { useMemo } from 'react';
import cn from 'classnames';

import Select from '../Select';

import { getSelectLabel, getSelectedOption } from './utils';
import styles from './styles.module.scss';

function LabelWithSelect({
  containerClassName,
  name,
  values,
  errors,
  touched,
  label,
  selectLabel,
  options,
  placeholder,
  setFieldValue,
  setFieldTouched
}) {
  const handleChange = e => {
    setFieldValue(name, e.value);
  };
  const handleBlur = () => {
    setFieldTouched(name, true, true);
  };
  const selectedOption = useMemo(() => getSelectedOption(options, values[name]), [options, values, name]);
  return (
    <div className={cn(styles.container, containerClassName)}>
      <div className={styles.sectionTitle}>{label}</div>
      <Select
        name={name}
        options={[getSelectLabel(selectLabel), ...options]}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        value={selectedOption}
        defaultValue={selectedOption}
        error={!values[name] && touched[name] && errors[name]}
      />
    </div>
  );
}

LabelWithSelect.propTypes = {
  containerClassName: string,
  errors: shape,
  label: string,
  name: string,
  options: shape,
  placeholder: string,
  selectLabel: string,
  setFieldTouched: func,
  setFieldValue: func,
  touched: shape,
  values: shape
};

export default LabelWithSelect;
