import { useSelector } from 'react-redux';

import { getScrapersTargets } from '~utils/scrapers';

export const useCMF = prefix => {
  const simulation = useSelector(state => state.simulation);
  const targets = getScrapersTargets(prefix);
  return {
    needsGuarantor: simulation.needsGuarantor,
    cmfReceived: simulation[targets.cmfReceived],
    cmfWarning: simulation[targets.cmfWarning]
  };
};
