import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { push } from 'connected-react-router';
import { bool, objectOf, string } from 'prop-types';

import FormWrapper from '~components/FormWrapper';
import LoadingWrapper from '~components/LoadingWrapper';
import { useSimulationQueryParams } from '~app/hooks/useSimulationQueryParams';
import SimulationActions from '~redux/Simulation/actions';
import { buildMainPath } from '~utils/path';
import BirthForm from '~app/components/BirthInformation/BirthForm';

import {
  getFormConfiguration,
  getIdentityInitialValues,
  mapInitialValues,
  postBirthInfoMapper
} from './utils';
import styles from './styles.module.scss';

const BirthInformation = ({ incumbent, nextStep, currentInfo, stepTarget, loading, currentTarget }) => {
  const dispatch = useDispatch();
  const { quickCode, kind } = useParams();
  const formConfig = getFormConfiguration;
  const queryParams = useSimulationQueryParams();
  const handleSubmit = values => {
    dispatch(
      SimulationActions.setSimulationInfo(currentTarget, {
        ...currentInfo,
        ...values
      })
    );
    dispatch(
      SimulationActions.changeIncumbent({
        quickCode,
        values: postBirthInfoMapper(values, incumbent, stepTarget),
        postSuccessAction: () => dispatch(push(buildMainPath(quickCode, nextStep, kind)))
      })
    );
  };

  return (
    <LoadingWrapper loading={loading}>
      <div className={styles.container}>
        <FormWrapper
          form={BirthForm}
          initialValues={currentInfo ? mapInitialValues(currentInfo) : getIdentityInitialValues(queryParams)}
          validationSchema={formConfig.validationSchema}
          onSubmit={handleSubmit}
        />
      </div>
    </LoadingWrapper>
  );
};

BirthInformation.propTypes = {
  loading: bool.isRequired,
  currentInfo: objectOf(string),
  currentTarget: string,
  incumbent: string,
  nextStep: string,
  stepTarget: string
};

export default BirthInformation;
